import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";

const styles = {
  base: css`
    display: inline-flex;
    align-items: center;
    border-radius: ${ncTheme.borderRadius.small};
    padding-block: ${ncTheme.spacing(1)};
    padding-inline: ${ncTheme.spacing(1.5)};
    text-align: center;
    vertical-align: baseline;
    justify-content: center;
    font-size: ${ncTheme.fontSizes[1]};
    font-weight: ${ncTheme.fontWeight.bold};
    line-height: 1;
    border: ${ncTheme.border(ncTheme.colors.transparent)};
    background-color: ${ncTheme.colors.light};
    min-width: 1.5rem;
    min-height: 1.75em;
  `,
  variant: {
    default: css`
      color: ${ncTheme.colors.main};
      border-color: ${ncTheme.colors.ui};
    `,
    neutral: css`
      color: ${ncTheme.colors.uiDark};
      border-color: ${ncTheme.colors.ui};
    `,
    success: css`
      color: ${ncTheme.colors.success};
      border-color: ${ncTheme.colors.ui};
    `,
    danger: css`
      color: ${ncTheme.colors.danger};
      border-color: ${ncTheme.colors.ui};
    `,
  },
};

interface NcBadgeProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof styles.variant;
  children: ReactNode;
}

export const NcBadge = ({ variant = "default", children, ...props }: NcBadgeProps) => {
  return (
    <span data-nc="NcBadge" css={css([styles.base, styles.variant[variant]])} {...props}>
      {children}
    </span>
  );
};

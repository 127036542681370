const safeLocalStorage = window.localStorage ?? {
  clear: () => undefined,
  getItem: () => null,
  key: () => null,
  length: 0,
  removeItem: () => undefined,
  setItem: () => undefined,
};

export default safeLocalStorage;

const neutralPattern = `
<svg width="440" height="440" viewBox="0 0 440 440" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.10">
<g clip-path="url(#clip0)">
<path d="M399.475 142.394C398.536 140.147 398 137.609 398 134.984V122.358C398 111.552 406.889 102.766 417.833 102.766H450.106V154.648H417.833C409.715 154.648 402.536 149.827 399.475 142.394Z" fill="#181C21"/>
<path d="M-40.5247 142.394C-41.4642 140.147 -42 137.609 -42 134.984V122.358C-42 111.552 -33.1113 102.766 -22.1674 102.766H10.1063V154.648H-22.1674C-30.2854 154.648 -37.4639 149.827 -40.5247 142.394Z" fill="#181C21"/>
<path d="M475 17.4429V56.5571C475 60.6669 471.605 64 467.419 64H427.581C423.395 64 420 60.6669 420 56.5571V17.4429C420 13.3331 423.395 10 427.581 10H467.419C471.605 10.0071 475 13.3331 475 17.4429Z" fill="#181C21"/>
<path d="M35 17.4429V56.5571C35 60.6669 31.6052 64 27.4193 64H-12.4192C-16.6052 64 -20 60.6669 -20 56.5571V17.4429C-20 13.3331 -16.6052 10 -12.4192 10H27.4193C31.6052 10.0071 35 13.3331 35 17.4429Z" fill="#181C21"/>
<path d="M84.544 48C96.4402 48 106.088 57.8296 106.088 69.9501C106.088 82.0706 96.4402 91.9002 84.544 91.9002C72.6477 91.9002 63 82.0706 63 69.9501C63.0111 57.8296 72.6477 48 84.544 48Z" fill="#181C21"/>
<path d="M111.533 266.968C103.508 266.968 97 260.713 97 253H126.059C126.067 260.713 119.559 266.968 111.533 266.968Z" fill="#181C21"/>
<path d="M111.526 294.905C119.551 294.905 126.059 301.16 126.059 308.873H97C97 301.153 103.508 294.905 111.526 294.905Z" fill="#181C21"/>
<path d="M186 225.463C186 217.473 192.282 211 200.029 211C207.775 211 214.057 217.48 214.057 225.463C214.057 233.454 207.775 239.934 200.029 239.934C192.275 239.934 186 233.454 186 225.463Z" fill="#181C21"/>
<path d="M106.589 191.904L106.508 191.951C106.494 191.925 106.475 191.894 106.461 191.869C98.6352 178.314 81.4522 173.586 68.0781 181.308C54.7039 189.03 50.2067 206.274 58.0323 219.829C58.0506 219.86 58.0651 219.885 58.0797 219.911L57.9925 219.961L29.5572 170.71L78.1537 142.652L106.589 191.904V191.904Z" fill="#181C21"/>
<path d="M330.029 316C337.775 316 344.057 322.255 344.057 329.968C344.057 337.681 337.775 343.936 330.029 343.936C322.282 343.936 316 337.681 316 329.968C316.007 322.248 322.282 316 330.029 316Z" fill="#181C21"/>
<path d="M240.491 401.088C245.473 401.088 249.509 405.331 249.509 410.567C249.509 415.803 245.473 420.045 240.491 420.045C235.509 420.045 231.472 415.803 231.472 410.567C231.472 405.331 235.509 401.088 240.491 401.088Z" fill="#181C21"/>
<path d="M88.0184 337C93.0001 337 97.0368 341.019 97.0368 345.98C97.0368 350.94 93.0001 354.959 88.0184 354.959C83.0367 354.959 79 350.94 79 345.98C79 341.019 83.0367 337 88.0184 337Z" fill="#181C21"/>
<path d="M120.057 375V431.871L92 403.439L120.057 375Z" fill="#181C21"/>
<path d="M187.057 128.181H159C159 120.468 165.284 114.213 173.032 114.213C180.781 114.22 187.057 120.468 187.057 128.181Z" fill="#181C21"/>
<path d="M49 9.40934L49 -10.0501L77.0573 -10.05L77.0573 9.40935C77.0572 14.6456 72.802 18.8842 67.5613 18.8842L58.5033 18.8842C53.2553 18.8842 49 14.6383 49 9.40934Z" fill="#181C21"/>
<path d="M49 449.409L49 429.95L77.0573 429.95L77.0573 449.409C77.0572 454.646 72.802 458.884 67.5613 458.884L58.5033 458.884C53.2553 458.884 49 454.638 49 449.409Z" fill="#181C21"/>
<g opacity="0.75">
<path d="M447.914 206.531C463.407 206.531 475.971 219.041 475.971 234.467C475.971 249.893 463.407 262.404 447.914 262.404C432.421 262.404 419.857 249.893 419.857 234.467C419.871 219.041 432.421 206.531 447.914 206.531Z" fill="#181C21"/>
<path d="M7.91724 206.531C23.41 206.531 35.9745 219.041 35.9745 234.467C35.9745 249.893 23.41 262.404 7.91724 262.404C-7.57556 262.404 -20.14 249.893 -20.14 234.467C-20.1256 219.041 -7.57556 206.531 7.91724 206.531Z" fill="#181C21"/>
<path d="M69.0947 376.808C68.6615 377.932 67.9984 378.869 67.1641 379.7L63.0623 383.784C59.5595 387.272 53.8695 387.282 50.3613 383.789L22.7292 356.275L-5.33608 384.22C-8.76937 387.639 -14.6038 387.634 -18.0318 384.22L-23.417 378.858C-26.8717 375.418 -26.8503 369.635 -23.417 366.217L4.64828 338.272L-23.0052 310.737C-25.6149 308.138 -26.3583 304.288 -24.9304 300.907C-24.5026 299.885 -23.8555 298.889 -23.0052 298.042L-18.9088 293.963C-15.406 290.476 -9.69988 290.481 -6.18102 293.985L21.4725 321.52L49.5431 293.569C52.9817 290.145 58.7841 290.14 62.2334 293.575L67.6187 298.937C71.0466 302.35 71.0627 308.149 67.624 311.573L39.5534 339.523L67.1855 367.037C69.7952 369.625 70.5279 373.443 69.0947 376.808Z" fill="#181C21"/>
<path d="M509.095 376.808C508.661 377.932 507.998 378.869 507.164 379.7L503.062 383.784C499.56 387.272 493.869 387.282 490.361 383.789L462.729 356.275L434.664 384.22C431.231 387.639 425.396 387.634 421.968 384.22L416.583 378.858C413.128 375.418 413.15 369.635 416.583 366.217L444.648 338.272L416.995 310.737C414.385 308.138 413.642 304.288 415.07 300.907C415.497 299.885 416.144 298.889 416.995 298.042L421.091 293.963C424.594 290.476 430.3 290.481 433.819 293.985L461.472 321.52L489.543 293.569C492.982 290.145 498.784 290.14 502.233 293.575L507.619 298.937C511.047 302.35 511.063 308.149 507.624 311.573L479.553 339.523L507.185 367.037C509.795 369.625 510.528 373.443 509.095 376.808Z" fill="#181C21"/>
<path d="M179.648 72.8345H139.002C134.732 72.8345 131.268 69.447 131.268 65.27V25.5238C131.268 21.3468 134.732 17.9592 139.002 17.9592H179.648C183.919 17.9592 187.382 21.3468 187.382 25.5238V65.2771C187.382 69.447 183.919 72.8345 179.648 72.8345Z" fill="#181C21"/>
<path d="M253.696 101.769H205.419L261.534 45.8958V93.9584C261.534 98.2712 258.02 101.769 253.696 101.769Z" fill="#181C21"/>
<path d="M312.573 171.736C313.05 172.809 313.241 173.911 313.241 175.058V180.696C313.241 185.511 309.341 189.429 304.523 189.429H266.571V228.007C266.571 232.726 262.56 236.732 257.852 236.732H250.455C245.711 236.732 241.737 232.726 241.737 228.007V189.429H203.755C200.171 189.429 197.005 187.283 195.654 183.967C195.242 182.968 195 181.836 195 180.667V175.036C195 170.221 198.922 166.303 203.755 166.303H241.737V127.718C241.737 122.992 245.718 119 250.455 119H257.852C262.56 119 266.571 122.992 266.571 127.718V166.303H304.523C308.1 166.296 311.236 168.428 312.573 171.736Z" fill="#181C21"/>
<path d="M358.115 86.7011V127.172C358.115 131.424 354.651 134.873 350.38 134.873H309.734C305.464 134.873 302 131.424 302 127.172V86.7011C302 82.4487 305.464 79 309.734 79H350.38C354.651 79.0073 358.115 82.4487 358.115 86.7011Z" fill="#181C21"/>
<path d="M195.117 382.701V423.172C195.117 427.424 191.591 430.873 187.244 430.873H145.872C141.525 430.873 138 427.424 138 423.172V382.701C138 378.449 141.525 375 145.872 375H187.244C191.591 375.007 195.117 378.449 195.117 382.701Z" fill="#181C21"/>
<path d="M129.057 134.939H108.735C104.464 134.939 101 131.566 101 127.407V108.379L101.389 108L129.057 134.939Z" fill="#181C21"/>
<path d="M130.266 182.585H158.323L130.266 210.521V182.585Z" fill="#181C21"/>
<path d="M249.871 296.005V298.181C248.786 308.199 240.302 316 229.997 316C218.952 316 210 307.047 210 296C210 284.953 218.952 276 229.997 276C230.183 276 230.363 276.01 230.542 276.015V290.585C230.542 293.578 232.97 296.005 235.962 296.005H250C250 296.211 249.974 296.411 249.969 296.617C249.949 296.411 249.918 296.216 249.902 296.005H249.871Z" fill="#181C21"/>
<path d="M344.999 48.8663H324.386C324.386 33.7084 312.028 21.3784 296.626 20.9693V1.55552C296.626 -2.62149 300.089 -6.00903 304.36 -6.00903H345.006C349.277 -6.00903 352.74 -2.62149 352.74 1.55552V41.3089C352.726 45.4859 349.269 48.8663 344.999 48.8663Z" fill="#181C21"/>
<path d="M344.999 488.865H324.386C324.386 473.707 312.028 461.377 296.626 460.968V441.555C296.626 437.378 300.089 433.99 304.36 433.99H345.006C349.277 433.99 352.74 437.378 352.74 441.555V481.308C352.726 485.485 349.269 488.865 344.999 488.865Z" fill="#181C21"/>
<path d="M268.896 362.66H237.287C233.264 362.66 230.006 365.953 230 370V331.613C230.067 330.996 230.092 330.365 230.11 329.729C231.045 339.732 239.359 347.566 249.5 347.566C259.892 347.566 268.376 339.355 268.963 329H269V329.185C268.951 329.531 268.89 329.871 268.89 330.23V362.66H268.896Z" fill="#181C21"/>
<path d="M316.836 269.388C312.86 269.388 309.632 266.117 309.632 262.086V224.807C309.632 220.777 312.86 217.506 316.836 217.506H327.479C331.455 217.506 334.683 220.777 334.683 224.807V262.086C334.683 266.117 331.455 269.388 327.479 269.388H316.836Z" fill="#181C21"/>
<path d="M387.536 44.9184C377.851 44.9184 370 36.8766 370 26.9628C370 17.0418 377.851 9 387.536 9C397.221 9 405.072 17.0418 405.072 26.9628C405.064 36.8766 397.214 44.9184 387.536 44.9184Z" fill="#181C21"/>
<path d="M382.032 402.878C374.284 402.878 368 396.622 368 388.909H396.057C396.064 396.63 389.781 402.878 382.032 402.878Z" fill="#181C21"/>
<path d="M327.44 365.897C327.84 366.79 328 367.708 328 368.663V373.356C328 377.364 324.734 380.625 320.7 380.625H288.924V412.737C288.924 416.665 285.566 420 281.624 420H275.431C271.458 420 268.131 416.665 268.131 412.737V380.625H236.331C233.329 380.625 230.679 378.838 229.547 376.079C229.203 375.247 229 374.304 229 373.331V368.644C229 364.636 232.284 361.375 236.331 361.375H268.131V329.257C268.131 325.323 271.465 322 275.431 322H281.624C285.566 322 288.924 325.323 288.924 329.257V361.375H320.7C323.695 361.369 326.321 363.143 327.44 365.897Z" fill="#181C21"/>
<path d="M236 -6C248.148 -6 258 3.40412 258 15C258 26.5959 248.148 36 236 36C223.852 36 214 26.5959 214 15C214.011 3.40412 223.852 -6 236 -6Z" fill="#181C21"/>
<path d="M236 434C248.148 434 258 443.404 258 455C258 466.596 248.148 476 236 476C223.852 476 214 466.596 214 455C214.011 443.404 223.852 434 236 434Z" fill="#181C21"/>
<path d="M28.0572 439.905H0V411.968H0.14679L28.0572 439.905Z" fill="#181C21"/>
<path d="M440.496 412.439L440.496 440.496L412.56 440.496L412.56 440.35L440.496 412.439Z" fill="#181C21"/>
</g>
<g opacity="0.5">
<path d="M111.53 266.968C119.553 266.968 126.059 273.223 126.059 280.936C126.059 288.65 119.553 294.905 111.53 294.905C103.507 294.905 97 288.65 97 280.936C97.0075 273.223 103.514 266.968 111.53 266.968Z" fill="#181C21"/>
<path d="M373.006 59.3856C373.006 51.397 379.513 44.9185 387.536 44.9185C395.559 44.9185 402.065 51.397 402.065 59.3856C402.065 67.3741 395.559 73.8527 387.536 73.8527C379.505 73.8527 373.006 67.3741 373.006 59.3856Z" fill="#181C21"/>
<path d="M178.057 249V276.937H150L178.057 249Z" fill="#181C21"/>
<path d="M178.057 276.937V303.875L150 276.937H178.057Z" fill="#181C21"/>
<path d="M187.057 113.968H159C159 106.255 165.284 100 173.032 100C180.781 100.007 187.057 106.255 187.057 113.968Z" fill="#181C21"/>
<path d="M62.2137 52L62.2822 52.0685C62.2616 52.0891 62.2358 52.1148 62.2152 52.1354C51.1481 63.2026 51.3449 81.3397 62.6549 92.6497C73.9649 103.96 92.102 104.156 103.169 93.0893C103.195 93.0636 103.215 93.043 103.236 93.0223L103.31 93.0961L63.0961 133.31L22.0001 92.2137L62.2137 52V52Z" fill="#181C21"/>
<path d="M415 225L415 225.103C414.968 225.103 414.927 225.103 414.895 225.103C397.557 225.103 383.504 238.933 383.504 255.996C383.504 273.059 397.557 286.889 414.895 286.889C414.935 286.889 414.968 286.889 415 286.889L415 287L352 287L352 225L415 225V225Z" fill="#181C21"/>
</g>
<g opacity="0.25">
<path d="M130.266 162.351C130.266 158.098 133.73 154.648 138.001 154.648H158.323L130.266 182.585V162.351Z" fill="#181C21"/>
<path d="M388.593 191.934H369.049V163.998H388.593C393.852 163.998 398.108 168.235 398.108 173.453V182.472C398.108 187.697 393.844 191.934 388.593 191.934Z" fill="#181C21"/>
<path d="M270 261.513V290.487C270 293.531 267.592 296 264.624 296H250.296C250.296 284.953 241.706 275.966 231 275.668V261.513C231 258.469 233.408 256 236.376 256H264.629C267.592 256 270 258.469 270 261.513Z" fill="#181C21"/>
<path d="M106.589 191.904C114.265 205.299 109.655 222.392 96.2591 230.126C82.8697 237.857 65.7556 233.306 57.9925 219.961L106.589 191.904Z" fill="#181C21"/>
<path d="M344.057 329.972C344.057 322.257 350.788 316 359.088 316V343.936C350.788 343.944 344.057 337.687 344.057 329.972Z" fill="#181C21"/>
<path d="M378.016 82.8321C378.016 77.8718 382.277 73.8525 387.536 73.8525C392.794 73.8525 397.055 77.8718 397.055 82.8321C397.055 87.7924 392.794 91.8117 387.536 91.8117C382.277 91.819 378.016 87.7924 378.016 82.8321Z" fill="#181C21"/>
<path d="M167.061 331L195.115 358.937H139L167.061 331Z" fill="#181C21"/>
<path d="M359.029 163V191.934C351.282 191.934 345 185.454 345 177.463C345 169.48 351.282 163 359.029 163Z" fill="#181C21"/>
<path d="M396.057 364.475V383.934H368V364.475C368 359.239 372.256 355 377.498 355H386.559C391.808 354.993 396.057 359.239 396.057 364.475Z" fill="#181C21"/>
<path d="M391 440.953L391.146 440.801L391 440.801L391 412L418.937 412L391 441.059L391 440.953Z" fill="#181C21"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0">
<rect width="440" height="440" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export const PagePublic = ({ ...props }) => (
  <main
    style={{ backgroundImage: `url("data:image/svg+xml;base64,${btoa(neutralPattern)}")` }}
    className={`flex h-full w-full place-content-center place-items-center overflow-auto bg-uiDark`}
    {...props}
  />
);

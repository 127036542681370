import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconArrowLeft = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#nc-icon-arrow-left_svg__a)">
      <path fill="currentColor" d="M13.097 3 2 12l11 9v-5.943h9.019V8.942h-8.922z" />
    </g>
    <defs>
      <clipPath id="nc-icon-arrow-left_svg__a">
        <path fill="#fff" d="M2 3h20v18H2z" />
      </clipPath>
    </defs>
  </NcIcon>
);
export { NcIconArrowLeft };

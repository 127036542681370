import { zodResolver } from "@hookform/resolvers/zod";
import { NcForm, NcFormDateTimeField } from "@noted/noted-components";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { restPutter } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { PrimhdCodeQueryResponse } from "~/primhd/api";
import { useToast } from "~/shared/components/alerts/alerts";

import PrimhdCodeComboBox from "../../primhd-code-combo-box";
import { PrimhdConsumerRecord } from "../../types";

type UpdateApi = Pick<
  PrimhdConsumerRecord,
  | "id"
  | "referralId"
  | "wellnessCode"
  | "accommodationCode"
  | "employmentCode"
  | "educationCode"
  | "collectionDate"
>;

interface IConsumerFormProps {
  record: PrimhdConsumerRecord;
  onRecordUpdate: () => void;
  formId: string;
  codes: PrimhdCodeQueryResponse;
}

const formSchema = z.object({
  wellnessCode: z.string(),
  accommodationCode: z.string(),
  employmentCode: z.string(),
  educationCode: z.string(),
  collectionDate: z.date(),
});

export const ConsumerForm = ({ record, onRecordUpdate, formId, codes }: IConsumerFormProps) => {
  const { t } = useI18n("primhd");
  const { enqueueError } = useToast();

  const { mutate: updateRecord } = useMutation({
    mutationFn: (body: UpdateApi) => restPutter(`/v1/primhd/records/consumer/${record.id}`, body),
    onSuccess() {
      onRecordUpdate();
    },
    onError(error) {
      const errorMessage =
        error.status === 400 && error.message
          ? error.message
          : t("primhd:records.edit.update_error");
      enqueueError(errorMessage);
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      wellnessCode: record.wellnessCode,
      accommodationCode: record.accommodationCode,
      employmentCode: record.employmentCode,
      educationCode: record.educationCode,
      collectionDate: record.collectionDate ? new Date(record.collectionDate) : undefined,
    },
  });

  const onSubmit = form.handleSubmit(data => {
    if (!data) return;
    const { wellnessCode, accommodationCode, employmentCode, educationCode, collectionDate } = data;
    updateRecord({
      id: record.id,
      referralId: record.referralId,
      wellnessCode,
      accommodationCode,
      employmentCode,
      educationCode,
      collectionDate: collectionDate.toISOString(),
    });
  });

  return (
    <NcForm form={form} onSubmit={onSubmit} id={formId}>
      <NcForm.Row>
        <PrimhdCodeComboBox
          codes={codes["CONSUMER_WELLNESS"]}
          inputWidth="full"
          className="flex-grow"
          label={t("primhd:records.edit.consumer_record.wellness_code_label")}
          name="wellnessCode"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["CONSUMER_ACCOMMODATION"]}
          inputWidth="full"
          className="flex-grow"
          label={t("primhd:records.edit.consumer_record.accommodation_code_label")}
          name="accommodationCode"
          isRequired
        />
      </NcForm.Row>
      <NcForm.Row>
        <PrimhdCodeComboBox
          codes={codes["CONSUMER_EMPLOYMENT"]}
          inputWidth="full"
          className="flex-grow"
          label={t("primhd:records.edit.consumer_record.employment_code_label")}
          name="employmentCode"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["CONSUMER_EDUCATION"]}
          inputWidth="full"
          className="flex-grow"
          label={t("primhd:records.edit.consumer_record.education_code_label")}
          name="educationCode"
          isRequired
        />
      </NcForm.Row>
      <div className="grid-cols-2 gap-5 sm:grid">
        <NcFormDateTimeField
          inputWidth="full"
          className="columns-1"
          label={t("primhd:records.edit.consumer_record.collection_date_label")}
          name="collectionDate"
          showPicker
          isRequired
        />
      </div>
    </NcForm>
  );
};

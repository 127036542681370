import { NcCollapse, NcForm, NcFormTextField, NcHeading, NcPanel } from "@noted/noted-components";
import { UseFormReturn } from "react-hook-form";

import { useI18n } from "~/hooks/use-i18n";

import { InviteClientFormData } from "./invite-client";

interface InviteClientFormProps {
  form: UseFormReturn<InviteClientFormData>;
  formId: string;
  onSubmit: () => void;
}

const InviteClientForm = ({ form, formId, onSubmit }: InviteClientFormProps) => {
  const { t } = useI18n("org");

  const { handleSubmit } = form;

  return (
    <NcForm form={form} id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 md:grid-cols-2">
        <NcFormTextField
          name="firstName"
          label={t("org:dashboard.clients.invite.form.first_name")}
          inputWidth="full"
          className="flex-grow"
          isRequired
        />
        <NcFormTextField
          name="lastName"
          label={t("org:dashboard.clients.invite.form.last_name")}
          inputWidth="full"
          className="flex-grow"
          isRequired
        />
      </div>
      <NcFormTextField
        type="email"
        name="email"
        label={t("org:dashboard.clients.invite.form.email")}
        inputWidth="full"
        isRequired
      />
      <NcPanel className="p-3">
        <NcCollapse>
          <NcCollapse.Summary>
            <NcHeading level={3} styleAs={5}>
              {t("org:dashboard.clients.invite.form.customise")}
            </NcHeading>
          </NcCollapse.Summary>
          <div className="grid gap-4 pt-2">
            <NcFormTextField
              type="textArea"
              name="inviteSubject"
              label={t("org:dashboard.clients.invite.form.subject_line")}
              inputWidth="full"
              isRequired
            />
            <NcFormTextField
              type="textArea"
              name="inviteHeading"
              label={t("org:dashboard.clients.invite.form.message_heading")}
              inputWidth="full"
              isRequired
            />
            <NcFormTextField
              type="textArea"
              name="inviteMessage"
              label={t("org:dashboard.clients.invite.form.message_body")}
              inputWidth="full"
              isRequired
            />
          </div>
        </NcCollapse>
      </NcPanel>
    </NcForm>
  );
};

export default InviteClientForm;

import { ComponentProps, forwardRef } from "react";

import styled from "../../theme";
import Text from "../primitives/text";

export const BaseTableCell = styled(Text)<ComponentProps<typeof Text>>`
  vertical-align: middle;
`.withComponent("td");

export const TableCell = forwardRef<HTMLTableCellElement, ComponentProps<typeof BaseTableCell>>(
  ({ p = 3, ...props }, ref) => <BaseTableCell p={p} ref={ref} {...props} />
);

TableCell.displayName = "TableCell";

export default TableCell;

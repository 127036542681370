import { ChangeEvent, ComponentProps, FormEvent, forwardRef } from "react";

import { deprecated } from "../../functions/deprecated";
import styled from "../../theme";
import { Box } from "../primitives";
import InputError from "./input-error";
import { Label } from "./label";

/**
 * @deprecated Use <Select> from `/shared/ui` instead.
 */
const BaseStyledDropdown = styled(Box)(
  ({
    theme: {
      colors: { neutral },
    },
  }) => `
  appearance: none;
  height: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  background-color: ${neutral.lightest};
  border-color: ${neutral.medium};
  border-width: 1px;
  border-style: solid;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: ${neutral.darkest};
  outline-color: ${neutral.mediumDark};
  &:hover {
    border-color: ${neutral.mediumDark};
  }
  &:active,
  &:focus {
    border-color: ${neutral.mediumDark};
    z-index: 1;
  }
  &:disabled {
    color: ${neutral.mediumDark};
    cursor: not-allowed;
  }
  ::placeholder {
    color: ${neutral.mediumDark};
  }
  background-image: url("data:image/svg+xml,${encodeURI(
    `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20" viewBox="0 0 8 20" fill="${neutral.mediumDark}" stroke="${neutral.mediumDark}"><path d="M4 0L0 8H8L4 0Z"/><path d="M4 20.0455L8 12.0455H0L4 20.0455Z"/></svg>`
  ).replace(/#/g, "%23")}");
  background-repeat: no-repeat;
  background-position: right .4rem center;
  background-size: .75rem .75rem;
`
).withComponent("select");

export const StyledDropdown = forwardRef<
  HTMLSelectElement,
  ComponentProps<typeof BaseStyledDropdown>
>(({ p = 2, mb = 4, borderRadius = 4, width = "100%", ...props }, ref) => (
  <BaseStyledDropdown
    ref={ref}
    p={p}
    mb={mb}
    borderRadius={borderRadius}
    width={width}
    {...props}
  />
));

StyledDropdown.displayName = "Dropdown";

interface IDropdownProps extends ComponentProps<typeof StyledDropdown> {
  label?: string;
  error?: string;
  disabled?: boolean;
}

/**
 * @deprecated Use <Select> from `/shared/ui` instead.
 */
export const Dropdown = deprecated(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLSelectElement, IDropdownProps>(({ id, label, error, ...other }, ref) => {
    return (
      <>
        {label && <Label htmlFor={id}>{label}</Label>}
        <StyledDropdown id={id} mb={error ? 0 : "4"} {...other} ref={ref} />
        {error && (
          <InputError mb="4" mt="2">
            {error}
          </InputError>
        )}
      </>
    );
  }),
  "Current <Dropdown> is deprecated. Use <Select> from `/shared/ui` instead."
);

export function eventValue(e: ChangeEvent<HTMLSelectElement> | FormEvent<HTMLDivElement>) {
  return "value" in e.target ? e.target.value : "";
}

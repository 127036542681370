import { NcFormattedName } from "@noted/noted-components";
import { DateTime } from "luxon";
import { HTMLProps } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useI18n } from "~/hooks/use-i18n";
import { LoadingLogo } from "~/shared/components/loading/loading-logo";
import { getKeyboardHandler } from "~/shared/functions/eventHelpers";
import { NavigableListItem } from "~/shared/ui/a11y/navigable-list";

import { ClientSearchResultSchema } from "../dashboard-queries";

const Message = ({ children }: HTMLProps<HTMLLIElement>) => <li className="p-4">{children}</li>;

const SearchResult = ({ searchResult }: { searchResult: ClientSearchResultSchema }) => {
  const { t } = useI18n("org");
  const navigate = useNavigate();
  const clientLink = `/n/clients/${searchResult.id}`;

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const keyboardNavigateTo = (path: string) =>
    getKeyboardHandler(["Enter"], () => navigateTo(path));

  return (
    <NavigableListItem role="presentation">
      <Link
        role="option"
        className="group grid grid-cols-[1fr,9rem] justify-between gap-1 px-3 py-2 transition-colors hover:bg-active hover:text-uiLight focus:bg-active focus:text-uiLight"
        to={clientLink}
      >
        <div className="grid content-start gap-1">
          <div>
            <NcFormattedName name={searchResult} className="font-bold" />
            {!searchResult.currentStatus?.active && (
              <span className="italic"> ({t("org:dashboard.clients.search.inactive")})</span>
            )}
          </div>
          <div>
            {(!searchResult.phone || searchResult.email) && (
              <div className="grid gap-x-2 gap-y-0.5">
                <div>{searchResult.clientId}</div>
                {searchResult.email && (
                  <div
                    className="overflow-hidden overflow-ellipsis whitespace-nowrap sm:max-w-96"
                    title={searchResult.email}
                  >
                    {searchResult.email}
                  </div>
                )}
              </div>
            )}
          </div>
          {searchResult.address && (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap italic"
              title={searchResult.address}
            >
              {searchResult.address}
            </div>
          )}
        </div>
        <div>
          <div className="flex items-center gap-1">
            <span className="text-xs italic">{t("org:dashboard.clients.search.nhi")}</span>
            {searchResult.medicalServiceId ? searchResult.medicalServiceId : "-"}
          </div>

          <div className="flex items-center gap-1">
            <span className="text-xs italic">{t("org:dashboard.clients.search.dob")}</span>
            {searchResult.dateOfBirth
              ? `${DateTime.fromISO(searchResult.dateOfBirth).toLocaleString(DateTime.DATE_SHORT)}`
              : "-"}
          </div>
          <div>
            <span
              className="cursor-pointer text-active underline group-hover:text-light"
              role="link"
              tabIndex={0}
              onClick={e => {
                e.preventDefault();
                navigateTo(`${clientLink}/records`);
              }}
              onKeyUp={keyboardNavigateTo(`${clientLink}/records`)}
            >
              {t("org:dashboard.clients.search.records")}
            </span>
          </div>
        </div>
      </Link>
    </NavigableListItem>
  );
};

interface SearchResultsProps {
  loading: boolean;
  error: boolean;
  searchResults?: ClientSearchResultSchema[];
}

const SearchResults = ({ error, loading, searchResults }: SearchResultsProps) => {
  const { t } = useI18n("org");

  if (loading) {
    return (
      <Message>
        <LoadingLogo center />
      </Message>
    );
  }

  if (error) {
    return <Message>{t("org:dashboard.clients.search.error")}</Message>;
  }
  if (searchResults && searchResults.length > 0) {
    return (
      <>
        {searchResults.map(result => (
          <SearchResult key={result.id} searchResult={result} />
        ))}
      </>
    );
  }
  return <Message>{t("org:dashboard.clients.search.no_results")}</Message>;
};

export default SearchResults;

import {
  NcBadge,
  NcFormattedMessage,
  NcGridList,
  NcLabel,
  NcPanel,
  NcTabs,
} from "@noted/noted-components";
import { useId } from "react-aria";

import { useI18n } from "~/hooks/use-i18n";

import { Invite, usePendingInvitesQuery, useReviewInvitesQuery } from "./dashboard-queries";
import { NoInvitesForReviewIcon, NoSentInvitesIcon } from "./icons";

const PanelPendingEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoSentInvitesIcon opacity="0.5" width="8rem" />
      <p className="italic">{t("org:dashboard.invites.pending.empty")}</p>
    </div>
  );
};

const PanelReviewEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoInvitesForReviewIcon opacity="0.5" width="8rem" />
      <NcFormattedMessage variant="secondary">
        {t("org:dashboard.invites.review.empty")}
      </NcFormattedMessage>
    </div>
  );
};

const InviteItem = ({ invite: { id, firstName, lastName, email } }: { invite: Invite }) => {
  return (
    <NcGridList.Item
      className="grid grid-cols-[2fr_3fr]"
      key={id}
      href={`/n/clients/${id}/edit`}
      textValue={`${firstName} ${lastName} ${email}`}
    >
      <span>
        {firstName} {lastName}
      </span>
      <span>{email}</span>
    </NcGridList.Item>
  );
};

const InvitesPanel = () => {
  const { t } = useI18n("org");
  const panelID = useId();
  const {
    data: reviewInvites,
    isLoading: isLoadingReview,
    error: errorReview,
  } = useReviewInvitesQuery();
  const {
    data: pendingInvites,
    isLoading: isLoadingPending,
    error: errorPending,
  } = usePendingInvitesQuery();

  const errorMessage = t("common:error_loading_refresh", {
    component: t("org:dashboard.invites.name"),
  });
  const errorReviewMessage = errorReview ? errorMessage : "";
  const errorPendingMessage = errorPending ? errorMessage : "";

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcPanel.Heading id={panelID} level={2}>
          {t("org:dashboard.invites.heading")}
        </NcPanel.Heading>
      </NcPanel.Header>
      <NcTabs className="flex flex-grow flex-col">
        <NcTabs.TabList aria-labelled-by={panelID}>
          <NcTabs.Tab id="reviewInvites">
            <NcLabel>{t("org:dashboard.invites.review.heading")}</NcLabel>
            <NcBadge variant="success">{reviewInvites?.length}</NcBadge>
          </NcTabs.Tab>
          <NcTabs.Tab id="pendingInvites">
            <NcLabel>{t("org:dashboard.invites.pending.heading")}</NcLabel>
            <NcBadge>{pendingInvites?.length}</NcBadge>
          </NcTabs.Tab>
        </NcTabs.TabList>
        <NcTabs.TabPanel
          variant="subPanel"
          className="p-0"
          id="reviewInvites"
          isLoading={isLoadingPending}
          error={errorReviewMessage}
        >
          {reviewInvites?.length ? (
            <NcGridList aria-label={t("org:dashboard.invites.review.heading")}>
              {reviewInvites.map((invite: Invite) => (
                <InviteItem invite={invite} key={invite.id} />
              ))}
            </NcGridList>
          ) : (
            <PanelReviewEmpty />
          )}
        </NcTabs.TabPanel>
        <NcTabs.TabPanel
          variant="subPanel"
          className="p-0"
          id="pendingInvites"
          isLoading={isLoadingReview}
          error={errorPendingMessage}
        >
          {pendingInvites?.length ? (
            <NcGridList aria-label={t("org:dashboard.invites.pending.heading")}>
              {pendingInvites.map((invite: Invite) => (
                <InviteItem invite={invite} key={invite.id} />
              ))}
            </NcGridList>
          ) : (
            <PanelPendingEmpty />
          )}
        </NcTabs.TabPanel>
      </NcTabs>
    </NcPanel>
  );
};

export default InvitesPanel;

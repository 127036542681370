import { css } from "@emotion/core";
import { forwardRef } from "react";
import {
  TextArea as ReactAriaTextArea,
  TextField as ReactAriaTextField,
  type TextFieldProps as ReactAriaTextFieldProps,
} from "react-aria-components";
import { FieldError } from "react-hook-form";

import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { inputStyles } from "./nc-input";
import { NcLabel } from "./nc-label";

const ncTextAreaStyles = css`
  ${inputStyles};
  width: 100%;
  min-width: 12rem;
  min-height: 8rem;
  field-sizing: content;
`;

export interface NcTextAreaProps extends ReactAriaTextFieldProps {
  label: string;
  hideLabel?: boolean;
  description?: string;
  variant?: NcFieldLayoutProps["variant"];
  error?: FieldError;
  autoComplete?: string;
  autoCapitalize?: string;
  maxLength?: number;
}

export const NcTextArea = forwardRef<HTMLTextAreaElement, NcTextAreaProps>(
  (
    {
      label,
      hideLabel,
      description,
      isRequired,
      variant,
      error,
      autoComplete = "off",
      autoCapitalize = "none",
      maxLength = 4000,
      ...props
    },
    ref
  ) => (
    <ReactAriaTextField {...props}>
      <NcFieldLayout variant={variant}>
        <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
          {label}
        </NcLabel>
        <ReactAriaTextArea
          data-nc="NcTextArea"
          ref={ref}
          css={ncTextAreaStyles}
          autoComplete={autoComplete}
          autoCapitalize={autoCapitalize}
          maxLength={maxLength}
        />
        {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
        <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
      </NcFieldLayout>
    </ReactAriaTextField>
  )
);

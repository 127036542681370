import { NcLoadingIndicator } from "@noted/noted-components";
import { useEffect } from "react";

import { useAuth } from "~/auth-provider";

function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout().catch(() => console.error);
  }, [logout]);

  return (
    <div className="flex h-screen items-center justify-center">
      <NcLoadingIndicator variant="logo" />
    </div>
  );
}

export default Logout;

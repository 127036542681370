import { Controller, useFormContext } from "react-hook-form";

import { NcNumberField, NcNumberFieldProps } from "./nc-number-field";

export interface NcFormNumberFieldProps
  extends Omit<
    NcNumberFieldProps,
    "name" | "validate" | "onBlur" | "value" | "validationBehavior"
  > {
  name: string; // Was optional in NcNumberFieldProps and is now required
}

export const NcFormNumberField = ({ name, onChange, ...props }: NcFormNumberFieldProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, value, onChange: formOnChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <NcNumberField
          name={name}
          value={value !== undefined ? value : null}
          onChange={value => {
            onChange?.(value);
            formOnChange(value);
          }}
          onBlur={onBlur}
          validationBehavior="aria"
          isInvalid={invalid}
          error={error}
          ref={ref}
          {...props}
        />
      )}
    />
  );
};

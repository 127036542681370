import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcIconExclamationCircle, NcIconInformationCircle, NcIconTickCircle } from "./nc-icons";

const alertStyles = {
  alert: {
    base: css`
      position: relative;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: start;
      gap: ${ncTheme.spacing(2)};
      border-radius: ${ncTheme.borderRadius.medium};
      border: ${ncTheme.border(ncTheme.colors.ui)};
      background-color: ${ncTheme.colors.light};
      padding-block: ${ncTheme.spacing(3)};
      padding-left: ${ncTheme.spacing(5)};
      padding-right: ${ncTheme.spacing(3)};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: ${ncTheme.spacing(1.5)};
        border-top-left-radius: ${ncTheme.borderRadius.medium};
        border-bottom-left-radius: ${ncTheme.borderRadius.medium};
      }
    `,
    variant: {
      default: css`
        border-color: ${ncTheme.colors.main}75;
        background-color: ${ncTheme.colors.main}08;

        &::before {
          background-color: ${ncTheme.colors.main};
        }
      `,
      success: css`
        border-color: ${ncTheme.colors.success}75;
        background-color: ${ncTheme.colors.success}08;

        &::before {
          background-color: ${ncTheme.colors.success};
        }
      `,
      danger: css`
        border-color: ${ncTheme.colors.danger}75;
        background-color: ${ncTheme.colors.danger}0d;

        &::before {
          background-color: ${ncTheme.colors.danger};
        }
      `,
    },
    heading: css`
      font-weight: ${ncTheme.fontWeight.bold};
      font-size: ${ncTheme.fontSizes[3]};
    `,
    icon: css`
      padding-top: ${ncTheme.spacing(0.5)};
      line-height: 1;
    `,
    colourVariant: {
      default: css`
        color: ${ncTheme.colors.main};
      `,
      success: css`
        color: ${ncTheme.colors.success};
      `,
      danger: css`
        color: ${ncTheme.colors.danger};
      `,
    },
  },
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${ncTheme.spacing(1)};
    height: 100%;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${ncTheme.spacing(2)};
    align-items: start;
    flex-grow: 1;
  `,
};

export interface NcAlertProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  variant?: keyof typeof alertStyles.alert.variant;
  title?: string | ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
  action?: ReactNode;
}

const getAlertIcon = (t: (value: string) => string, variant?: string) => {
  switch (variant) {
    case "success":
      return <NcIconTickCircle alt="" />;
    case "danger":
      return <NcIconExclamationCircle alt={t("alert")} />;
    default:
      return <NcIconInformationCircle alt="" />;
  }
};

const NcAlert = ({
  variant = "default",
  icon,
  title,
  action,
  children,
  ...props
}: NcAlertProps) => {
  const { t } = useI18n();
  return (
    <div
      css={css([alertStyles.alert.base, alertStyles.alert.variant[variant]])}
      {...props}
      role="alert"
      data-nc="NcAlert"
    >
      <div css={[alertStyles.alert.icon, alertStyles.alert.colourVariant[variant]]}>
        {icon ?? getAlertIcon(t, variant as string)}
      </div>
      <div css={css(alertStyles.wrapper)}>
        {title && (
          <div css={[alertStyles.alert.heading, alertStyles.alert.colourVariant[variant]]}>
            {title}
          </div>
        )}
        <div css={css(alertStyles.content)}>{children}</div>
      </div>
      {action && <>{action}</>}
    </div>
  );
};

export { NcAlert };

import { NcPage } from "@noted/noted-components";
import { ReactNode } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { Text } from "../primitives";

const SomethingWentWrong = ({ children }: { children?: ReactNode }) => {
  const { t } = useI18n();
  document.title = t("something_went_wrong_title");

  return (
    <NcPage>
      <NcPage.Wrapper className="max-w-3xl">
        <Text m="0" fontSize="7rem" lineHeight="1" as="h1">
          {t("something_went_wrong_title")}
        </Text>
        <Text fontSize="1.8rem" as="small">
          {t("something_went_wrong_subtitle")}
        </Text>
        <Text
          as="p"
          dangerouslySetInnerHTML={{
            __html: t("something_went_wrong_description"),
          }}
        />
        {children}
      </NcPage.Wrapper>
    </NcPage>
  );
};

export default SomethingWentWrong;

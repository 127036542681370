import { ComponentProps, forwardRef } from "react";
import { variant } from "styled-system";

import styled, { theme } from "../../theme";
import { Box } from "../primitives";

const defaultVariant = {
  color: theme.colors.info.medium,
  backgroundColor: theme.colors.neutral.lightest,
  borderColor: theme.colors.neutral.medium,
  "&:hover": {
    color: theme.colors.neutral.lightest,
    backgroundColor: theme.colors.info.medium,
    borderColor: theme.colors.info.medium,
  },
  '&:active,&[data-pressed="true"]': {
    color: theme.colors.neutral.lightest,
    backgroundColor: theme.colors.info.mediumLight,
    borderColor: theme.colors.info.mediumLight,
  },
};

const buttonVariants = {
  variants: {
    primary: {
      color: theme.colors.neutral.lightest,
      backgroundColor: theme.colors.success.medium,
      borderColor: theme.colors.success.mediumLight,
      "&:hover": {
        color: theme.colors.neutral.lightest,
        backgroundColor: theme.colors.success.mediumLight,
        borderColor: theme.colors.success.mediumLight,
      },
      '&:active,&[data-pressed="true"]': {
        color: theme.colors.neutral.lightest,
        backgroundColor: theme.colors.success.mediumLight,
        borderColor: theme.colors.success.mediumLight,
      },
    },
    default: defaultVariant,
    warning: {
      color: theme.colors.warning.dark,
      backgroundColor: theme.colors.neutral.lightest,
      borderColor: theme.colors.warning.dark,
      "&:hover": {
        color: theme.colors.neutral.lightest,
        backgroundColor: theme.colors.warning.dark,
        borderColor: theme.colors.warning.darkest,
      },
      "&:active": {
        color: theme.colors.neutral.lightest,
        backgroundColor: theme.colors.warning.dark,
        borderColor: theme.colors.warning.dark,
      },
    },
    danger: {
      color: theme.colors.danger.mediumDark,
      backgroundColor: theme.colors.neutral.lightest,
      borderColor: theme.colors.danger.mediumLight,
      "&:hover": {
        color: theme.colors.neutral.lightest,
        backgroundColor: theme.colors.danger.mediumDark,
        borderColor: theme.colors.danger.mediumDark,
      },
      '&:active,&[data-pressed="true"]': {
        color: theme.colors.neutral.lightest,
        backgroundColor: theme.colors.danger.mediumLight,
        borderColor: theme.colors.danger.mediumLight,
      },
    },
    info: defaultVariant,
  },
};

const variants = variant(buttonVariants);

export const ButtonBase = styled(Box)`
  position: relative;
  font-weight: 600;
  border-radius: ${theme.borderRadius.small};
  border: 0.15rem solid ${theme.colors.neutral.medium};
  ${variants};
  outline-color: ${theme.colors.info.mediumLight};
  outline-width: 0.15rem;
  outline-offset: 0.25rem;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: subpixel-antialiased;
  user-select: none;
  -webkit-touch-callout: none;
  transition:
    border-color 0.25s ease-in-out,
    box-shadow 0.1s ease-in-out,
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  padding: ${props =>
    props.narrow ? `${theme.space[1]} ${theme.space[3]}` : `${theme.space[2]} ${theme.space[4]}`};

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors.disabled};
    background-color: ${theme.colors.disabledLight};
    border-color: ${theme.colors.disabledLight};
  }

  &:focus {
    z-index: 1;
  }

  &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
    [aria-current]:not([aria-current="false"]) > & {
      background-color: ${theme.colors.info.mediumLight};
      border-color: ${theme.colors.info.mediumLight};
      color: ${theme.colors.neutral.lightest};
      z-index: 1;
    }
  }
`.withComponent("button");

export const Button = forwardRef<HTMLButtonElement, ComponentProps<typeof ButtonBase>>(
  ({ variant = "default", type = "button", ...props }, ref) => (
    <ButtonBase variant={variant} type={type} ref={ref} {...props} />
  )
);

Button.displayName = "Button";

export default Button;

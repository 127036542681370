import { z } from "zod";

const ReminderBaseSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export const ReminderSchema = ReminderBaseSchema.extend({
  sid: z.string(),
  id: z.number(),
  dueDate: z.string(),
  patientId: z.number(),
  teamId: z.number().optional(),
  patient: z.object({
    firstName: z.string(),
    lastName: z.string(),
  }),
  type: z.enum(["REMINDER"]),
});

export const ReminderEditSchema = ReminderBaseSchema.extend({
  title: z.string().min(1, "org:dashboard.reminders.edit.fields.title.required"),
  dueDate: z.date({ required_error: "org:dashboard.reminders.edit.fields.due_date.required" }),
});

export type Reminder = z.infer<typeof ReminderSchema>;
export type ReminderEdit = z.infer<typeof ReminderEditSchema>;

import { zodResolver } from "@hookform/resolvers/zod";
import { NcPage } from "@noted/noted-components";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useI18n } from "~/hooks/use-i18n";

import { config } from "../administration/users/config";
import { restPoster } from "../graphql-hooks/custom-fetcher";
import { useToast } from "../shared/components/alerts/alerts";
import { Button } from "../shared/components/forms";
import { PagePublic, Shell } from "../shared/components/layout";
import {
  OverlayMessageBox,
  OverlayMessageBoxContent,
  OverlayMessageBoxHighlight,
} from "../shared/components/overlay-message-box";
import { LogoIcon } from "../shared/icons";
import { Feedback, Form, FormRow, Input, Label } from "../shared/ui";
import { useAccount } from "./account.context";
import PasswordHint from "./password-hint";

const updatePasswordRequest = z.object({
  newPassword: z
    .string()
    .min(config.password.minLength, "account:password.min_length")
    .max(config.password.maxLength, "account:password.max_length"),
  currentPassword: z.string().min(1, "account:password.required"),
});

const updatePasswordForm = updatePasswordRequest
  .extend({
    confirmPassword: z.string().min(1, "account:password.confirmation_required"),
  })
  .refine(values => values.newPassword === values.confirmPassword, {
    path: ["confirmPassword"],
    message: "account:password.mismatch",
  });

type UpdatePasswordRequest = z.infer<typeof updatePasswordRequest>;
type UpdatePasswordForm = z.infer<typeof updatePasswordForm>;

const UpdatePassword = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t } = useI18n("account");
  const { clearShouldChangePassword } = useAccount();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePasswordForm>({ resolver: zodResolver(updatePasswordForm), mode: "onChange" });

  const {
    isPending: saving,
    error,
    mutate: updatePassword,
  } = useMutation({
    mutationFn: (body: UpdatePasswordRequest) => {
      updatePasswordRequest.parse(body);
      return restPoster("/v1/auth/userChangePassword", body);
    },
    onSuccess() {
      enqueueSuccess(t("account:update_password.update_success"));
      clearShouldChangePassword();
      onSuccess();
    },
    onError() {
      enqueueError(t("account:update_password.update_error"));
    },
  });
  const [incorrectOldPassword, setIncorrectOldPassword] = useState(false);
  const { enqueueError, enqueueSuccess } = useToast();

  useEffect(() => {
    if (error && error.message === "Incorrect current password") {
      setIncorrectOldPassword(true);
    }
  }, [error]);

  const onSubmit = async (values: UpdatePasswordForm) => {
    setIncorrectOldPassword(false);
    const { newPassword, currentPassword } = values;
    updatePassword({ currentPassword, newPassword });
  };

  return (
    <Shell>
      <PagePublic>
        <OverlayMessageBox>
          <OverlayMessageBoxHighlight>
            <LogoIcon />
          </OverlayMessageBoxHighlight>
          <OverlayMessageBoxContent>
            <NcPage.Heading>{t("account:update_password.title")}</NcPage.Heading>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormRow>
                <Label htmlFor="currentPassword">
                  {t("account:update_password.current_password")}
                </Label>
                <Input id="currentPassword" type="password" {...register("currentPassword")} />
                {errors?.currentPassword?.message && (
                  <Feedback>{t(errors.currentPassword.message, config.password)}</Feedback>
                )}
                {incorrectOldPassword && (
                  <Feedback>{t("account:update_password.incorrect_old_password")}</Feedback>
                )}
              </FormRow>
              <FormRow>
                <Label htmlFor="newPassword">{t("account:update_password.new_password")}</Label>
                <Input
                  id="newPassword"
                  type="password"
                  autoComplete="new-password"
                  {...register("newPassword")}
                />
                {errors?.newPassword?.message && (
                  <Feedback>{t(errors.newPassword.message, config.password)}</Feedback>
                )}
              </FormRow>
              <FormRow>
                <Label htmlFor="confirmPassword">
                  {t("account:update_password.confirm_password")}
                </Label>
                <Input id="confirmPassword" type="password" {...register("confirmPassword")} />
                {errors?.confirmPassword?.message && (
                  <Feedback>{t(errors.confirmPassword.message, config.password)}</Feedback>
                )}
              </FormRow>
              <PasswordHint my="4" />
              <Button type="submit" variant="primary" disabled={saving} mb="2">
                {t("account:update_password.submit_button")}
              </Button>
            </Form>
          </OverlayMessageBoxContent>
        </OverlayMessageBox>
      </PagePublic>
    </Shell>
  );
};

export default UpdatePassword;

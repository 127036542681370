import { css } from "@emotion/core";
import type { FC, ReactNode } from "react";
import { forwardRef } from "react";
import {
  Popover as ReactAriaPopover,
  Select as ReactAriaSelect,
  SelectValue as ReactAriaSelectValue,
  type Key,
  type SelectProps as ReactAriaSelectProps,
} from "react-aria-components";
import { FieldError } from "react-hook-form";

import { ncTheme } from "../nc-theme";
import { NcButton } from "./nc-button";
import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcIconChevronDown } from "./nc-icons";
import type { PresetInputWidths } from "./nc-input";
import { inputStyles, inputWidthStyles } from "./nc-input";
import { NcLabel } from "./nc-label";
import { NcListBox } from "./nc-list-box";
import { popoverStyles } from "./nc-popover";

export const selectStyles = {
  select: css`
    position: relative;
    &[data-focused="true"][data-focus-visible="true"],
    &:focus-visible {
      outline: none;
    }
  `,
  button: css`
    color: ${ncTheme.colors.dark};
    justify-content: space-between;
    text-align: left;

    [data-placeholder="true"] {
      background-color: transparent;
    }

    &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
      &:hover,
      &[data-hovered] {
        color: ${ncTheme.colors.light};
        background-color: ${ncTheme.colors.active};

        [data-placeholder="true"] {
          color: ${ncTheme.colors.light};
        }
      }

      &:focus,
      &[data-pressed="true"] {
        color: ${ncTheme.colors.dark};
        background-color: ${ncTheme.colors.light};

        [data-placeholder="true"] {
          color: ${ncTheme.colors.dark};
        }
      }
    }

    [data-invalid="true"] & {
      border-color: ${ncTheme.colors.danger};
    }
  `,
  selected: css`
    > svg {
      display: none;
    }
  `,
  popover: css`
    padding: 0;
    min-width: var(--trigger-width);
  `,
  item: css`
    &[data-focus-visible] {
      outline: none;
    }
  `,
  selectedItem: css`
    display: flex;
    gap: ${ncTheme.spacing(2)};
    align-items: center;
  `,
};

export type NcSelectItem = {
  id: Key;
  label: string;
  description?: string;
  Icon?: FC;
} & Record<string, Key | FC>;

export interface NcSelectProps extends PresetInputWidths, ReactAriaSelectProps<NcSelectItem> {
  label: string;
  hideLabel?: boolean;
  description?: string;
  variant?: NcFieldLayoutProps["variant"];
  items?: Iterable<NcSelectItem>;
  renderEmptyState?: () => ReactNode;
  error?: FieldError;
}

const BaseItem = ({ item }: { item: NcSelectItem }) => {
  const { Icon, label } = item;
  return (
    <div css={selectStyles.selectedItem}>
      {Icon && <Icon />}
      <span>{label}</span>
    </div>
  );
};

export const NcSelect = forwardRef<HTMLButtonElement, NcSelectProps>(
  (
    {
      label,
      hideLabel,
      description,
      items,
      name,
      renderEmptyState,
      inputWidth = "auto",
      variant,
      isRequired,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <ReactAriaSelect data-nc="NcFieldSelect" css={selectStyles.select} {...props}>
        <NcFieldLayout variant={variant}>
          <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
            {label}
          </NcLabel>
          <NcButton
            css={[inputStyles, selectStyles.button, inputWidthStyles[inputWidth]]}
            name={name}
            ref={ref}
          >
            <ReactAriaSelectValue css={selectStyles.selected}>
              {({ selectedItem, defaultChildren }) => {
                if (!selectedItem) {
                  return defaultChildren;
                }
                return <BaseItem item={selectedItem as NcSelectItem} />;
              }}
            </ReactAriaSelectValue>
            <NcIconChevronDown aria-hidden="true" />
          </NcButton>
          <ReactAriaPopover css={[popoverStyles.popover, selectStyles.popover]}>
            <NcListBox items={items} renderEmptyState={renderEmptyState}>
              {(item: NcSelectItem) => {
                const { id, label, description } = item;
                return (
                  <NcListBox.Item id={id} textValue={label}>
                    <div css={selectStyles.item}>
                      <BaseItem item={item} />
                      {description && (
                        <NcFormattedMessage variant="secondary" slot="description">
                          {description}
                        </NcFormattedMessage>
                      )}
                    </div>
                  </NcListBox.Item>
                );
              }}
            </NcListBox>
          </ReactAriaPopover>
          {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
          <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
        </NcFieldLayout>
      </ReactAriaSelect>
    );
  }
);

import { forwardRef } from "react";
import {
  NumberField as ReactAriaNumberField,
  NumberFieldProps as ReactAriaNumberFieldProps,
} from "react-aria-components";
import { FieldError } from "react-hook-form";

import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { NcInput, type PresetInputWidths } from "./nc-input";
import { NcLabel } from "./nc-label";

export interface NcNumberFieldProps extends ReactAriaNumberFieldProps, PresetInputWidths {
  label: string;
  hideLabel?: boolean;
  description?: string;
  variant?: NcFieldLayoutProps["variant"];
  error?: FieldError;
}

export const NcNumberField = forwardRef<HTMLInputElement, NcNumberFieldProps>(
  (
    { label, hideLabel, description, isRequired, variant, inputWidth = "auto", error, ...props },
    ref
  ) => (
    <ReactAriaNumberField {...props}>
      <NcFieldLayout variant={variant}>
        <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
          {label}
        </NcLabel>
        <NcInput ref={ref} inputWidth={inputWidth} />
        {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
        <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
      </NcFieldLayout>
    </ReactAriaNumberField>
  )
);

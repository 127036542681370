import { IResourceAdjustmentPeriod } from "../../shared/types";

export type IBlockedTime = IResourceAdjustmentPeriod;

export enum CalendarItemType {
  "APPOINTMENT" = "APPOINTMENT",
  "BLOCKED_TIME" = "BLOCKED_TIME",
}

export interface ICalendarItem {
  id: string;
  type: CalendarItemType;
  itemTypeId: number;
  top: number;
  bottom: number;
  left: number;
  width: number;
  isClashing?: boolean;
}

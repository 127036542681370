import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

import { restPoster } from "~/graphql-hooks/custom-fetcher";
import { zDateAsString } from "~/shared/functions/zodInputHelpers";

const CLIENTS_ENDPOINT = "clients";
const DUPLICATE_CLIENT_ENDPOINT = "clients/duplicates";

const clientPayloadSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
});

export type ClientPayload = z.infer<typeof clientPayloadSchema>;

const duplicateClientsObjectSchema = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  currentStatus: z
    .object({
      id: z.number(),
      active: z.boolean(),
      patientId: z.number(),
      clinicId: z.number(),
      created: zDateAsString(),
    })
    .optional(),
  clinicId: z.number().optional(),
  dateOfBirth: z.string().optional(),
  medicalServiceId: z.string().optional(),
  selfUpdatedLast: z.boolean(),
  created: zDateAsString(),
  accessControl: z.array(z.unknown()).optional(),
  restricted: z.boolean(),
  clientId: z.string(),
  accessLevel: z.string(),
  addresses: z.array(z.unknown()).optional(),
  sid: z.string(),
  securePatientId: z.number(),
  groupingKey: z.number().optional(),
});
export type DuplicateClient = z.infer<typeof duplicateClientsObjectSchema>;

const duplicateClientsResponse = z.array(duplicateClientsObjectSchema);

export type DuplicateClientsResponse = z.infer<typeof duplicateClientsResponse>;

//This endpoint is not very restful, so were using a post request inside a useQuery hook
export function useDuplicateClientsQuery(
  enabled: boolean,
  variables: ClientPayload,
  options: Omit<UseQueryOptions<unknown, unknown, DuplicateClientsResponse>, "queryKey"> = {}
) {
  return useQuery<unknown, unknown, DuplicateClientsResponse>({
    queryKey: [DUPLICATE_CLIENT_ENDPOINT, variables],
    queryFn: async () => {
      clientPayloadSchema.parse(variables);
      const resp = await restPoster(`/v1/${DUPLICATE_CLIENT_ENDPOINT}`, variables);
      return duplicateClientsResponse.parse(resp);
    },
    enabled,
    staleTime: Infinity,
    ...options,
  });
}

useDuplicateClientsQuery.getKey = () => [DUPLICATE_CLIENT_ENDPOINT];

const clientInviteResponseSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  selfUpdatedLast: z.boolean(),
  medicalChecklistId: z.number(),
  clinicId: z.number(),
  keyWorkerIds: z.array(z.number()),
  status: z.object({
    id: z.number(),
    active: z.boolean(),
    clinicId: z.number().optional(),
    created: zDateAsString(),
  }),
  statuses: z.array(
    z.object({
      id: z.number(),
      active: z.boolean(),
      created: zDateAsString(),
    })
  ),
  created: zDateAsString(),
  patientDementiaSundry: z.array(z.unknown()),
  patientRelationshipsA: z.array(z.unknown()),
  patientRelationshipsB: z.array(z.unknown()),
  patientTags: z.array(z.unknown()),
  patientsReferrals: z.array(z.unknown()),
  addresses: z.array(z.unknown()),
  teams: z.array(z.unknown()).optional(),
  restricted: z.boolean(),
  accessLevel: z.string(),
  sid: z.string(),
  securePatientId: z.number(),
  groupingKey: z.number(),
});

export type ClientInviteResponse = z.infer<typeof clientInviteResponseSchema>;

export const useInviteClientMutation = () =>
  useMutation({
    mutationFn: async ({ variables }: { variables: ClientPayload }) => {
      clientPayloadSchema.parse(variables);
      const resp = await restPoster<ClientPayload, ClientInviteResponse>(
        `/v1/${CLIENTS_ENDPOINT}`,
        variables
      );
      return clientInviteResponseSchema.parse(resp);
    },
  });

const sendInvitePayloadSchema = z.object({
  inviteSubject: z.string(),
  inviteMessage: z.string(),
  inviteHeading: z.string(),
  inviteButton: z.string(),
});

export type SendInvitePayload = z.infer<typeof sendInvitePayloadSchema>;

type SendInviteMutationVariables = {
  clientId: number;
  variables: SendInvitePayload;
};

export const useSendInviteMutation = () =>
  useMutation({
    mutationFn: async ({ clientId, variables }: SendInviteMutationVariables) => {
      sendInvitePayloadSchema.parse(variables);
      return restPoster(`/v1/client-invites/${clientId}/sendLink`, variables);
    },
  });

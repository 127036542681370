import { css } from "@emotion/core";
import { forwardRef } from "react";
import type { CheckboxGroupProps as ReactAriaCheckboxGroupProps } from "react-aria-components";
import { CheckboxGroup as ReactAriaCheckboxGroup } from "react-aria-components";
import { FieldError } from "react-hook-form";

import { ncTheme } from "../nc-theme";
import { NcCheckbox } from "./nc-checkbox";
import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { NcLabel } from "./nc-label";

export interface NcCheckboxGroupProps extends ReactAriaCheckboxGroupProps {
  options: Array<{
    label: string;
    value: string;
  }>;
  label: string;
  hideLabel?: boolean;
  description?: string;
  error?: FieldError;
  variant?: NcFieldLayoutProps["variant"];
}

export const ncCheckboxGroupStyles = css`
  display: grid;
  gap: ${ncTheme.spacing(3)};
`;

export const NcCheckboxGroup = forwardRef<HTMLDivElement, NcCheckboxGroupProps>(
  (
    { label, hideLabel, description, options, error, isRequired, children, variant, ...props },
    ref
  ) => (
    <ReactAriaCheckboxGroup
      data-nc="NcCheckboxGroup"
      css={ncCheckboxGroupStyles}
      ref={ref}
      {...props}
    >
      <NcFieldLayout variant="vertical">
        <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
          {label}
        </NcLabel>
        <NcFieldLayout variant={variant}>
          {children ? (
            <>{children}</>
          ) : (
            options.map((option, index) => (
              <NcCheckbox name={`${name}.${index}`} key={option.value} value={option.value}>
                {option.label}
              </NcCheckbox>
            ))
          )}
        </NcFieldLayout>
        {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
        <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
      </NcFieldLayout>
    </ReactAriaCheckboxGroup>
  )
);

import { css } from "@emotion/core";
import { ComponentProps, forwardRef, HTMLProps, ReactNode, type WheelEvent } from "react";

import { Box } from "../components/primitives";
import styled, { theme } from "../theme";
import Feedback from "./feedback";

function stopNumberValueChangeOnMouseWheel(e: WheelEvent<HTMLInputElement>) {
  e.currentTarget.blur();
}

export const BaseInput = styled(Box)`
  display: flex;
  border-color: ${theme.colors.neutral.medium};
  border-width: 1px;
  border-style: solid;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  color: ${theme.colors.neutral.darkest};
  outline-color: ${theme.colors.info.mediumLight};
  outline-width: 0.15rem;
  outline-offset: 0.25rem;
  background-color: ${theme.colors.neutral.lightest};

  &:hover,
  &:active,
  &:focus {
    border-color: ${theme.colors.info.mediumLight};
    z-index: 1;
  }

  &:disabled {
    color: ${theme.colors.neutral.mediumDark};
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${theme.colors.neutral.mediumDark};
  }

  &[aria-invalid="true"] {
    border-color: ${theme.colors.danger.darkest};
    outline-color: ${theme.colors.danger.darkest};
  }
`.withComponent("input");

export const Input = forwardRef<HTMLInputElement, ComponentProps<typeof BaseInput>>(
  (
    {
      maxLength = 255,
      type = "text",
      p = 2,
      mb = 0,
      mt = 0,
      borderRadius = 4,
      width = "100%",
      autoCapitalize = "none",
      autoComplete = "off",
      onWheel = stopNumberValueChangeOnMouseWheel,
      ...props
    },
    ref
  ) => (
    <BaseInput
      maxLength={maxLength}
      type={type}
      p={p}
      mb={mb}
      mt={mt}
      borderRadius={borderRadius}
      width={width}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      onWheel={onWheel}
      ref={ref}
      {...props}
    />
  )
);

Input.displayName = "Input";

export default Input;

// -----------------------------------------------------------------------------------------------
// This is a copy of the original Input component above but is not based on the "Box" component
// therefore does not have any of the styled-system extensions including the ".withComponent"
// function.
// It also as a couple enhancements, like built in "Feedback".
// We should try and move to this component as the standard and retire the original.
const inputCss = {
  self: css`
    border: solid 1px ${theme.colors.neutral.medium};
    border-radius: 4px;
    box-sizing: border-box;
    color: ${theme.colors.neutral.darkest};
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
    outline-color: ${theme.colors.info.mediumLight};
    padding: ${theme.space[2]};
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    width: 100%;

    &:hover {
      border-color: ${theme.colors.neutral.mediumDark};
    }

    &:active,
    &:focus {
      border-color: ${theme.colors.neutral.mediumDark};
      z-index: 1;
    }

    &:disabled {
      color: ${theme.colors.neutral.mediumDark};
      cursor: not-allowed;
    }

    ::placeholder {
      color: ${theme.colors.neutral.mediumDark};
    }

    &[aria-invalid="true"] {
      border-color: ${theme.colors.danger.darkest};
      outline-color: ${theme.colors.danger.darkest};
    }
  `,
};

export const Input2 = forwardRef<
  HTMLInputElement,
  HTMLProps<HTMLInputElement> & { error?: ReactNode }
>(
  (
    {
      autoCapitalize = "none",
      error,
      maxLength = 255,
      type = "text",
      autoComplete = "off",
      onWheel = type === "number" ? stopNumberValueChangeOnMouseWheel : undefined,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <input
          ref={ref}
          css={inputCss.self}
          autoCapitalize={autoCapitalize}
          maxLength={maxLength}
          type={type}
          autoComplete={autoComplete}
          onWheel={onWheel}
          {...props}
        />
        {error && <Feedback>{error}</Feedback>}
      </>
    );
  }
);
Input2.displayName = "Input";

import { NcPage } from "@noted/noted-components";

import { useI18n } from "~/hooks/use-i18n";
import { IAccount } from "~/shared/types";

import { AccProviderSettings } from "../edit/acc-provider-settings";

export const AccSettingsPage = ({ user }: { user: IAccount }) => {
  const { t } = useI18n("user-settings");
  // ACC provider settings expects a graphQL user object with name property
  const accUser = { ...user, name: `${user.firstName} ${user.lastName}` };
  return (
    <NcPage data-testid="acc-provider-page">
      <NcPage.Wrapper>
        <NcPage.Header>
          <NcPage.Heading>{t("user-settings:accSettings.title")}</NcPage.Heading>
        </NcPage.Header>
        <NcPage.Body data-testid="acc-provider-content">
          <AccProviderSettings user={accUser} />
        </NcPage.Body>
      </NcPage.Wrapper>
    </NcPage>
  );
};

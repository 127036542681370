import { BaseSyntheticEvent, ComponentProps, forwardRef } from "react";

import { Box } from "../components/primitives";

export const stopEventPropagation =
  (fn: (e: BaseSyntheticEvent) => void) => (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    fn(event);
  };

const BaseForm = Box.withComponent("form");

export const Form = forwardRef<HTMLFormElement, ComponentProps<typeof BaseForm>>(
  (
    {
      noValidate = true,
      autoComplete = "off",
      width = "100%",
      display = "grid",
      gridGap = "3",
      ...props
    },
    ref
  ) => (
    <BaseForm
      display={display}
      gridGap={gridGap}
      width={width}
      {...props}
      noValidate={noValidate}
      autoComplete={autoComplete}
      ref={ref}
      {...props}
    />
  )
);

Form.displayName = "Form";

export default Form;

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { customFetcher } from "~/graphql-hooks/custom-fetcher";
import * as Types from "~/graphql-hooks/types";

export type GetPrimhdTeamsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPrimhdTeamsQuery = { __typename?: "Query" } & {
  primhdTeams: Array<{ __typename?: "PrimhdTeam" } & Pick<Types.PrimhdTeam, "id" | "name">>;
};

export const GetPrimhdTeamsDocument = `
    query getPrimhdTeams {
  primhdTeams {
    id
    name
  }
}
    `;

export const useGetPrimhdTeamsQuery = <TData = GetPrimhdTeamsQuery, TError = unknown>(
  variables?: GetPrimhdTeamsQueryVariables,
  options?: Omit<UseQueryOptions<GetPrimhdTeamsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetPrimhdTeamsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetPrimhdTeamsQuery, TError, TData>({
    queryKey: variables === undefined ? ["getPrimhdTeams"] : ["getPrimhdTeams", variables],
    queryFn: customFetcher<GetPrimhdTeamsQuery, GetPrimhdTeamsQueryVariables>(
      GetPrimhdTeamsDocument,
      variables
    ),
    ...options,
  });
};

useGetPrimhdTeamsQuery.getKey = (variables?: GetPrimhdTeamsQueryVariables) =>
  variables === undefined ? ["getPrimhdTeams"] : ["getPrimhdTeams", variables];

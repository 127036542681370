import { variant } from "styled-system";

import styled, { theme } from "../../theme";
import Box from "../primitives/box";

const messageBoxVariants = {
  variants: {
    default: {
      color: theme.colors.neutral.dark,
      backgroundColor: theme.colors.neutral.lightest,
    },
    warning: {
      color: theme.colors.warning.darkest,
      backgroundColor: theme.colors.warning.lightest,
    },
    danger: {
      color: theme.colors.danger.darkest,
      backgroundColor: theme.colors.danger.lightest,
    },
    info: {
      color: theme.colors.info.mediumDark,
      backgroundColor: theme.colors.info.lightest,
    },
    success: {
      color: theme.colors.success.darkest,
      backgroundColor: theme.colors.success.lightest,
    },
  },
};

export const MessageBox = styled(Box)`
  ${variant(messageBoxVariants)};
  border-radius: 3px;
  padding: ${theme.space[3]};
`;

MessageBox.displayName = "MessageBox";

export default MessageBox;

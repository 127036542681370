import { ComponentProps, CSSProperties, FC, forwardRef } from "react";

import { ExclamationCircle, TickCircle } from "../../icons";
import styled, { theme } from "../../theme";
import { Box, Flex } from "../primitives";

/**
 * @deprecated Use Input from `/shared/ui` instead.
 */
const BaseInput = styled(Box)`
  display: flex;
  border-color: ${theme.colors.neutral.medium};
  border-width: 1px;
  border-style: solid;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  color: ${theme.colors.neutral.dark};
  outline-color: ${theme.colors.info.mediumLight};
  outline-width: 0.15rem;
  outline-offset: 0.25rem;

  &:hover,
  &:active,
  &:focus {
    border-color: ${theme.colors.info.mediumLight};
    z-index: 1;
  }

  &:disabled {
    background-color: ${theme.colors.disabledLight};
    color: ${theme.colors.disabled};
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${theme.colors.neutral.mediumDark};
  }
`.withComponent("input");

export const Input = forwardRef<HTMLInputElement, ComponentProps<typeof BaseInput>>(
  (
    {
      maxLength = 255,
      type = "text",
      autoCapitalize = "none",
      p = 2,
      mb = 4,
      borderRadius = 4,
      width = "100%",
      ...props
    },
    ref
  ) => (
    <BaseInput
      maxLength={maxLength}
      type={type}
      autoCapitalize={autoCapitalize}
      p={p}
      mb={mb}
      borderRadius={borderRadius}
      width={width}
      ref={ref}
      {...props}
    />
  )
);

Input.displayName = "Input";

const InputForSvgIcon = styled(Input)`
  &:focus + span,
  &:active + span {
    opacity: 1 !important;
    z-index: 1;
  }
  &:focus + svg,
  &:active + svg {
    opacity: 1 !important;
    z-index: 1;
  }
`;

type SvgInputProps = ComponentProps<typeof Input> & { icon?: FC | false };

export const InputWithIcon = forwardRef<HTMLInputElement, SvgInputProps>(
  ({ className, ...props }, ref) => {
    return (
      <Flex className={className}>
        <InputForSvgIcon
          {...props}
          style={{ paddingRight: props.icon ? "2rem" : undefined }}
          ref={ref}
        />
        {props.icon && (
          <span
            style={{
              position: "absolute",
              right: 10,
              top: "10px",
              opacity: 0.5,
              maxWidth: "1rem",
              maxHeight: "1rem",
              overflow: "hidden",
            }}
          >
            {<props.icon />}
          </span>
        )}
      </Flex>
    );
  }
);
InputWithIcon.displayName = "InputWithIcon";

type InputFormProps = ComponentProps<typeof Input> & { valid?: boolean };

export const InputForm = forwardRef<HTMLInputElement, InputFormProps>((props, ref) => {
  const style: CSSProperties = {
    position: "absolute",
    right: 10,
    top: "10px",
    opacity: 0.5,
    maxWidth: "1rem",
    maxHeight: "1rem",
    overflow: "hidden",
  };
  return (
    <Flex>
      <InputForSvgIcon
        {...props}
        style={{ paddingRight: props.valid !== undefined ? "2rem" : "" }}
        ref={ref}
      />
      {props.valid !== undefined &&
        (props.valid ? <TickCircle {...{ style }} /> : <ExclamationCircle {...{ style }} />)}
    </Flex>
  );
});
InputForm.displayName = "InputForm";

import { css } from "@emotion/core";
import { Time } from "@internationalized/date";
import { ClipboardEventHandler, forwardRef, ReactNode, useContext } from "react";
import {
  DateInput,
  DateSegment,
  Group,
  TimeField as ReactAriaTimeField,
  TimeFieldStateContext,
} from "react-aria-components";
import type { TimeFieldProps as ReactAriaTimeFieldProps } from "react-aria-components";
import { FieldError } from "react-hook-form";

import { ncDateFieldStyles } from "./nc-date-field";
import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { inputWidthStyles, PresetInputWidths } from "./nc-input";
import { NcLabel } from "./nc-label";

export interface NcTimeFieldProps extends PresetInputWidths, ReactAriaTimeFieldProps<Time> {
  label: string;
  hideLabel?: boolean;
  description?: string;
  error?: FieldError;
  variant?: NcFieldLayoutProps["variant"];
}

export const NcTimeField = forwardRef<HTMLInputElement, NcTimeFieldProps>(
  (
    {
      label,
      hideLabel,
      description,
      error,
      isRequired,
      inputWidth = "auto",
      granularity = "minute",
      shouldForceLeadingZeros = true,
      variant,
      ...props
    },
    ref
  ) => {
    return (
      <ReactAriaTimeField
        shouldForceLeadingZeros={shouldForceLeadingZeros}
        granularity={granularity}
        {...props}
      >
        <NcFieldLayout variant={variant}>
          <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
            {label}
          </NcLabel>
          <PasteHandler>
            <Group css={[ncDateFieldStyles.group, inputWidthStyles[inputWidth]]}>
              <DateInput css={ncDateFieldStyles.inputs} ref={ref}>
                {segment => <DateSegment css={ncDateFieldStyles.segment} segment={segment} />}
              </DateInput>
            </Group>
          </PasteHandler>
          {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
          <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
        </NcFieldLayout>
      </ReactAriaTimeField>
    );
  }
);

const PasteHandler = ({ children }: { children: ReactNode }) => {
  const state = useContext(TimeFieldStateContext);

  const onPaste = (e: ClipboardEvent) => {
    const pastedData = e.clipboardData?.getData("text");
    if (pastedData) {
      const normaliseString = pastedData.replaceAll(/[^0-9]/g, "");
      const parts = {
        hour: Number(normaliseString.slice(0, 2)),
        minute: Number(normaliseString.slice(2, 4)),
      };
      state?.setSegment("hour", parts.hour);
      state?.setSegment("minute", parts.minute);
    }
  };

  return (
    <div
      tabIndex={-1}
      css={css`
        display: contents;
      `}
      onPaste={onPaste as unknown as ClipboardEventHandler<HTMLDivElement>}
    >
      {children}
    </div>
  );
};

import styled, { theme } from "../../theme";
import Box from "./box";

export const Text = styled(Box)`
  line-height: ${theme.lineHeight};
`;

Text.displayName = "Text";

export default Text;

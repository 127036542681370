import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import Box, { BoxProps } from "../primitives/box";

export type BodyProps = BoxProps;

const BaseModalBody = styled(Box)<BodyProps>`
  border-bottom-color: ${theme.colors.neutral.light};
  flex: 1;
  overflow: auto;
`;

export const ModalBody = forwardRef<HTMLDivElement, ComponentProps<typeof BaseModalBody>>(
  ({ px = 3, py = 4, maxWidth = "36rem", ...props }, ref) => {
    return <BaseModalBody px={px} py={py} maxWidth={maxWidth} {...props} ref={ref} />;
  }
);

ModalBody.displayName = "ModalBody";

export default ModalBody;

import { ComponentProps, forwardRef } from "react";

import { Box } from "../components/primitives";
import styled, { theme } from "../theme";

const BaseTextArea = styled(Box)`
  display: flex;
  background-color: ${theme.colors.neutral.lightest};
  border-color: ${theme.colors.neutral.medium};
  border-width: 1px;
  border-style: solid;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  color: ${theme.colors.neutral.darkest};
  outline-color: ${theme.colors.info.mediumLight};
  outline-width: 0.15rem;
  outline-offset: 0.25rem;
  resize: vertical;
  &:hover {
    border-color: ${theme.colors.info.mediumLight};
  }
  &:active,
  &:focus {
    border-color: ${theme.colors.info.mediumLight};
    z-index: 1;
  }
  &:disabled {
    color: ${theme.colors.neutral.mediumDark};
    cursor: not-allowed;
  }
  ::placeholder {
    color: ${theme.colors.neutral.mediumDark};
  }
`.withComponent("textarea");

const TextArea = forwardRef<HTMLTextAreaElement, ComponentProps<typeof BaseTextArea>>(
  (
    {
      maxLength = 255,
      p = 2,
      mb = 0,
      mt = 0,
      borderRadius = 4,
      width = "100%",
      height = "8rem",
      ...props
    },
    ref
  ) => (
    <BaseTextArea
      maxLength={maxLength}
      p={p}
      mb={mb}
      mt={mt}
      borderRadius={borderRadius}
      width={width}
      height={height}
      ref={ref}
      {...props}
    />
  )
);

TextArea.displayName = "TextArea";

export default TextArea;

import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import Text from "../primitives/text";

const BaseModalHeader = styled(Text)`
  border-bottom-color: ${theme.colors.neutral.light};
`.withComponent("h1");

export const ModalHeader = forwardRef<HTMLHeadingElement, ComponentProps<typeof BaseModalHeader>>(
  (
    { m = 0, padding = 3, fontWeight = 400, fontSize = 3, borderBottom = "solid 1px", ...props },
    ref
  ) => {
    return (
      <BaseModalHeader
        m={m}
        padding={padding}
        fontWeight={fontWeight}
        fontSize={fontSize}
        borderBottom={borderBottom}
        {...props}
        ref={ref}
      />
    );
  }
);

ModalHeader.displayName = "ModalHeader";

export default ModalHeader;

import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import Box from "../primitives/box";

export const BaseTableHeaderRow = styled(Box)`
  border-color: ${theme.colors.neutral.medium};
`.withComponent("tr");

export const TableHeaderRow = forwardRef<
  HTMLTableRowElement,
  ComponentProps<typeof BaseTableHeaderRow>
>(({ alignItems = "center", ...props }, ref) => (
  <BaseTableHeaderRow alignItems={alignItems} ref={ref} {...props} />
));

TableHeaderRow.displayName = "TableHeaderRow";

export default TableHeaderRow;

import { css } from "@emotion/core";
import { ComponentProps } from "react";
import { NavLink } from "react-router-dom";

import { theme } from "../../theme";

const linkCss = {
  self: css`
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    display: flex;
    white-space: nowrap;
    margin-bottom: 0.75rem;
    transition: all 150ms ease;
    color: ${theme.colors.neutral.darkest};
    outline-color: ${theme.colors.info.mediumLight};
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &:focus:not(.active):not(:active),
    &:hover:not(.active):not(:active) {
      cursor: pointer;
    }
  `,
};

export const Link = (props: ComponentProps<typeof NavLink>) => (
  <NavLink css={linkCss.self} {...props} />
);

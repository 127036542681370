import { config } from "@noted/configuration";
import { NcGlobalProvider } from "@noted/noted-components";
import { Suspense, useMemo } from "react";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Logout from "~/account/logout";
import { useAuth } from "~/auth-provider";
import Dashboard from "~/dashboard";
import { configureFetchers } from "~/graphql-hooks/custom-fetcher";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { Intercom } from "~/third-parties/intercom";

import { AccountState, useAccount } from "./account/account.context";
import AnonymousErrorPage from "./account/anonymous-error-page";
import ApiOnlyUser from "./account/api-user";
import { FeatureState, useFeatures } from "./account/features.context";
import Login from "./account/login";
import ResetPassword from "./account/reset-password";
import UpdatePassword from "./account/update-password";
import Verify from "./account/verify";
import UpgradeModal from "./administration/subscription/upgrade-modal";
import { UserSettings } from "./administration/users-v2/user-settings";
import Banners from "./banners";
import ErrorBoundary from "./ErrorBoundary";
import { getToken } from "./global-token";
import Header from "./layout/header";
import Navigation from "./layout/navigation";
import UserInteractionDetection from "./layout/user-interaction-detection";
import { PrivateRoute } from "./routes/private-route";
import { LoadingPage } from "./routes/waiting-component";
import { OnboardingState } from "./scheduling/agenda/use-agenda-onboarding";
import { Shell } from "./shared/components/layout";
import { retryLazy } from "./shared/functions/retry-lazy";
import { VersionUpdateController } from "./version-update-controller";

const Administration = retryLazy(() => import("./administration/administration-routes"));
const Scheduling = retryLazy(() => import("./scheduling/scheduling-routes"));
const Primhd = retryLazy(() => import("./primhd"));
const Auditing = retryLazy(() => import("./auditing/auditing-routes"));
const Extracts = retryLazy(() => import("./extract"));
const Org = retryLazy(() => import("./org/org-routes"));
const Fragor = retryLazy(() => import("./fragor/fragor-routes"));
const AsfDemoPage = retryLazy(() => import("./asf-demo"));

const AdminProtectedRoute = () => {
  const { account } = useAccount();
  const { isAdminUser } = account;
  if (!isAdminUser) return null;
  return <Outlet />;
};

const SchedulingProtectedRoute = () => {
  const { featureEnabled } = useFeatures();
  const { canSchedule } = useAccount();
  if (!featureEnabled(FeaturePermission.BookingSystem) || !canSchedule) return null;
  return <Outlet />;
};

const FeatureProtectedRoute = ({ permission }: { permission: FeaturePermission }) => {
  const { featureEnabled } = useFeatures();
  const enabled = featureEnabled(permission);
  if (!enabled) return null;
  return <Outlet />;
};

const App = () => {
  const { t, locale } = useI18n();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, getToken, session } = useAuth();

  const uiContext = useMemo(
    () => ({
      locale,
      navigate,
      t,
    }),
    []
  );

  if (isLoading) return null;
  if (!isAuthenticated) {
    window.location.href = config.landingUrl;
    return null;
  }

  configureFetchers(getToken, session);

  const goToLandingPage = () => navigate("/dashboard");

  return (
    <NcGlobalProvider value={uiContext}>
      <ErrorBoundary fallback={<AnonymousErrorPage />}>
        <Suspense fallback={<LoadingPage />}>
          <VersionUpdateController />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/resetPassword"
              element={<Navigate to={{ ...location, pathname: "/reset-password" }} replace />}
            />
            <Route path="/verify-account" element={<Verify />} />
            <Route
              path="/verifyEmail"
              element={<Navigate to={{ ...location, pathname: "/verify-account" }} replace />}
            />
            <Route path="/api-only-user" element={<ApiOnlyUser />} />
            <Route path="/something-went-wrong" element={<AnonymousErrorPage />} />

            <Route
              path="/*"
              element={
                <AccountState>
                  <PrivateRoute>
                    <FeatureState>
                      <OnboardingState>
                        <Outlet />
                      </OnboardingState>
                    </FeatureState>
                  </PrivateRoute>
                  <Intercom />
                </AccountState>
              }
            >
              <Route path="raw/*" element={<Org />} />
              <Route
                path="update-password"
                element={<UpdatePassword onSuccess={goToLandingPage} />}
              />

              <Route
                path="*"
                element={
                  <Shell>
                    <Banners />
                    <Header />
                    <UserInteractionDetection>
                      <>
                        <Navigation />
                        <Main>
                          <Suspense fallback={<LoadingPage />}>
                            <Outlet />
                          </Suspense>
                        </Main>
                        <UpgradeModal />
                      </>
                    </UserInteractionDetection>
                  </Shell>
                }
              >
                <Route element={<AdminProtectedRoute />}>
                  <Route path="admin/*" element={<Administration />} />
                </Route>

                <Route path="dashboard" element={<Dashboard />} />

                <Route path="n/*" element={<Fragor />} />

                <Route element={<SchedulingProtectedRoute />}>
                  <Route path="scheduling/*" element={<Scheduling />} />
                </Route>

                <Route element={<FeatureProtectedRoute permission={FeaturePermission.Primhd} />}>
                  <Route path="primhd/*" element={<Primhd />} />
                </Route>

                {/* TODO: Can be removed in subsequent releases */}
                {/* Redirect PRIMHD v2 beta to the main PRIMHD page */}
                <Route path="primhdv2/*" element={<Navigate to="/primhd" />} />

                <Route
                  element={<FeatureProtectedRoute permission={FeaturePermission.AuditTrail} />}
                >
                  <Route path="auditing/*" element={<Auditing />} />
                </Route>

                <Route path="extract/*" element={<Extracts />} />
                <Route path="user-settings/*" element={<UserSettings />} />
                <Route path="asf" element={<AsfDemoPage />} />
              </Route>
            </Route>
            <Route path="/" element={<RerouteFallback />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </NcGlobalProvider>
  );
};

const Main = ({ ...props }) => (
  <main className="flex h-full w-full overflow-auto" id="main" {...props} />
);

const RerouteFallback = () => {
  const session = window.localStorage.getItem("ngStorage-session");
  if (getToken() || session) {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/login" />;
};

export default App;

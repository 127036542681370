import { NcPage } from "@noted/noted-components";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAccount } from "~/account/account.context";
import { useFeatures } from "~/account/features.context";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { SubLink, SubLinkText, SubNavigation } from "~/shared/components/navigation";

import { AccSettingsPage } from "./acc-settings-settings";
import { AssignedTeams } from "./assigned-teams";
import { UserDetails } from "./user-details";

export const UserSettings = () => {
  const { t } = useI18n("user-settings");
  const { account: user } = useAccount();
  const { featureEnabled } = useFeatures();
  const hasACC = featureEnabled(FeaturePermission.AccBilling);
  return (
    <NcPage variant="subNavigation">
      <SubNavigation alternate={true}>
        <SubLink to="user-details" variant="light">
          <SubLinkText>{t("user-settings:userDetails.title")}</SubLinkText>
        </SubLink>
        {hasACC && (
          <SubLink to="acc-settings" variant="light">
            <SubLinkText>{t("user-settings:accSettings.title")}</SubLinkText>
          </SubLink>
        )}
        <SubLink to="assigned-teams" variant="light">
          <SubLinkText>{t("user-settings:assignedTeams.title")}</SubLinkText>
        </SubLink>
      </SubNavigation>

      <Routes>
        <Route path="user-details" element={<UserDetails user={user} />} />
        {hasACC && <Route path="acc-settings" element={<AccSettingsPage user={user} />} />}
        <Route path="assigned-teams" element={<AssignedTeams user={user} />} />
        <Route path="" element={<Navigate to="user-details" replace />} />
      </Routes>
    </NcPage>
  );
};

function isEnvironment(environment: "localhost" | "staging") {
  return (window.location.hostname || "").includes(environment);
}

function makeUrl(prefix: string, protocol = window.location.protocol) {
  return (
    protocol +
    `//${prefix}` +
    window.location.hostname.replace(/^(app|admin)/, "")
  );
}

type UrlConfig = {
  apiAdminUrl: string;
  apiUrl: string;
  appUrl: string;
  darkNotedUrl: string;
  landingUrl: string;
};

type EnvConfig = UrlConfig & {
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    cookieDomain: string;
  };
  ermsUrl: string;
  intercomId?: string;
  sentryEnvironment?: string;
  stripeKey: string;
  supportPageUrl: string;
  termsPageUrl: string;
};

const commonConfig = {
  supportPageUrl: "https://support.noted.com/",
  termsPageUrl: "https://www.noted.com/standard-terms-of-use",
} as const;

const urlConfig: UrlConfig = isEnvironment("localhost")
  ? {
      apiAdminUrl: "http://localhost:8080",
      apiUrl: "http://localhost:8080",
      appUrl: "http://localhost:3000",
      darkNotedUrl: "http://localhost:4000",
      landingUrl: "http://localhost:3000/landing",
    }
  : {
      apiAdminUrl: makeUrl("admin-api"),
      apiUrl: makeUrl("api"),
      appUrl: makeUrl("app"),
      darkNotedUrl: makeUrl("admin"),
      landingUrl: makeUrl("app") + "/landing",
    };

const developmentConfig: EnvConfig = {
  ...commonConfig,
  ...urlConfig,
  auth0: {
    domain: "noted-development.au.auth0.com",
    clientId: "Z1bX11oUrgrmCZWeyd4MaFJkhRJgOmZb",
    audience: "http://dev.api.noted.com",
    cookieDomain: "localhost",
  },
  ermsUrl: "https://u-app.erms.health.nz",
  stripeKey: "pk_test_ehMi88gNOC0zjE1k4rJqTfT",
};

const stagingConfig: EnvConfig = {
  ...commonConfig,
  ...urlConfig,
  auth0: {
    domain: "noted-development.au.auth0.com",
    clientId: "Z1bX11oUrgrmCZWeyd4MaFJkhRJgOmZb",
    audience: "http://dev.api.noted.com",
    cookieDomain: window.location.hostname.substring(
      window.location.hostname.indexOf(".") + 1,
    ),
  },
  ermsUrl: "https://u-app.erms.health.nz",
  intercomId: "i17ikl0l",
  sentryEnvironment: "staging",
  stripeKey: "pk_test_ephMi88gNOC0zjE1k4rJqTfT",
};

const productionConfig: EnvConfig = {
  ...commonConfig,
  ...urlConfig,
  auth0: {
    domain: "notedauth.au.auth0.com",
    clientId: "Qv5NlqK59To1CrU7uMxkNBzcRpL8zS9G",
    audience: "https://api.noted.com",
    cookieDomain: window.location.hostname.substring(
      window.location.hostname.indexOf(".") + 1,
    ),
  },
  ermsUrl: "https://app.erms.health.nz",
  intercomId: "vvy9vlc8",
  sentryEnvironment: "prod",
  stripeKey: "pk_live_xQcYt91I9o80B2h8HTwT1EOU",
};

export const config = isEnvironment("localhost")
  ? developmentConfig
  : isEnvironment("staging")
    ? stagingConfig
    : productionConfig;

import { css } from "@emotion/core";
import type { ReactNode } from "react";
import {
  Collection as ReactAriaCollection,
  Header as ReactAriaHeader,
  ListBox as ReactAriaListBox,
  ListBoxItem as ReactAriaListBoxItem,
  Section as ReactAriaSection,
  type ListBoxItemProps as ReactAriaListBoxItemProps,
  type ListBoxProps as ReactAriaListBoxProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcIconTick } from "./nc-icons";

export const listBoxStyles = {
  list: {
    base: css`
      outline: none;

      /* Targets the last child if it's a div with role="menuitem" */
      div[role="menuitem"]:last-child {
        border-radius: 0 0 ${ncTheme.borderRadius.small} ${ncTheme.borderRadius.small};
      }

      /* Targets any a[role="menuitem"] that is immediately preceded by any element except when it's the last child */
      a[role="menuitem"]:not(:last-child) + [role="menuitem"] {
        border-radius: 0;
      }

      /* Resets the border-radius for any a[role="menuitem"] except the last child, ensuring only the actual last item gets styled */
      a[role="menuitem"]:last-child {
        border-radius: 0 0 ${ncTheme.borderRadius.small} ${ncTheme.borderRadius.small};
      }

      &[data-orientation="horizontal"] {
        display: flex;
        flex-wrap: wrap;
      }

      &[data-empty="true"] {
        font-style: italic;
        padding: ${ncTheme.spacing(3)};
      }
    `,
    variants: {
      default: css``,
      borderless: css`
        a[role="option"],
        div[role="option"] {
          border-bottom: none;
        }
      `,
    },
  },
  item: {
    base: css`
      display: flex;
      justify-content: start;
      align-items: center;
      gap: ${ncTheme.spacing(2)};
      padding: ${ncTheme.spacing(3)};
      text-align: left;
      outline-style: none;
      color: inherit;
      text-decoration: none;
      transition:
        background-color ${ncTheme.transitionSpeed.fast},
        color ${ncTheme.transitionSpeed.fast};

      &:not(:last-of-type) {
        border-bottom: ${ncTheme.border()};
      }

      &[data-selected] {
        color: ${ncTheme.colors.light};
        font-weight: ${ncTheme.fontWeight.bold};
        background-color: ${ncTheme.colors.active};
        z-index: 1;
      }

      &[data-focus-visible] {
        ${ncTheme.utilities.outlineStyles}
      }

      &[data-hovered],
      &[data-focus-visible] {
        color: ${ncTheme.colors.light};
        cursor: pointer;
      }

      &[aria-disabled="true"] {
        cursor: not-allowed;
        background: ${ncTheme.colors.disabledLight};
        color: ${ncTheme.colors.disabled};
      }

      &[data-hovered]:not([data-selected]),
      &[data-focus-visible]:not([data-selected]) {
        &[data-pressed="true"] {
          background-color: ${ncTheme.colors.active};
        }
      }
    `,
    variant: {
      default: css`
        &[data-hovered],
        &[data-focus-visible] {
          background-color: ${ncTheme.colors.main};
        }
      `,
      success: css`
        color: ${ncTheme.colors.success};

        &[data-hovered],
        &[data-focus-visible] {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.success};
        }

        &[data-selected] {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.success};
        }
      `,
      danger: css`
        color: ${ncTheme.colors.danger};

        &[data-hovered],
        &[data-focus-visible] {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.danger};
        }

        &[data-selected] {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.danger};
        }
      `,
    },
    icon: css`
      display: none;
      flex-shrink: 0;
      [role="option"] &,
      [role="menuitemradio"] &,
      [role="menuitemcheckbox"] & {
        display: initial;
        visibility: hidden;
        margin-left: auto;
      }
      [role="option"][data-selected] &,
      [role="menuitemradio"][data-selected] &,
      [role="menuitemcheckbox"][data-selected] & {
        visibility: visible;
      }
      [href] & {
        display: none;
      }
    `,
    header: css`
      display: flex;
      gap: ${ncTheme.spacing(2)};
      justify-content: start;
      align-items: center;
      padding-inline: ${ncTheme.spacing(3)};
      padding-block: ${ncTheme.spacing(2)};
      color: ${ncTheme.colors.dark};
      background-color: ${ncTheme.colors.light};
      border-bottom: 1px solid ${ncTheme.colors.uiLight};
      font-weight: bold;
      position: sticky;
      top: 0;
    `,
    section: css`
      &:not(:last-of-type) {
        margin-bottom: ${ncTheme.spacing(4)};
      }
    `,
  },
};

export interface ListBoxItemProps extends ReactAriaListBoxItemProps {
  children: ReactNode;
  variant?: keyof typeof listBoxStyles.item.variant;
  hideSelectIndicator?: boolean;
}

const NcListBoxItem = ({
  children,
  variant = "default",
  textValue,
  hideSelectIndicator = false,
  ...props
}: ListBoxItemProps) => {
  const childTextValue = typeof children === "string" ? children : undefined;
  return (
    <ReactAriaListBoxItem
      data-nc="NcListBox.Item"
      css={[listBoxStyles.item.base, listBoxStyles.item.variant[variant]]}
      textValue={textValue || childTextValue}
      {...props}
    >
      {children}
      {!hideSelectIndicator && <NcIconTick aria-hidden="true" css={listBoxStyles.item.icon} />}
    </ReactAriaListBoxItem>
  );
};

const NcDefaultEmptyState = () => {
  const { t } = useI18n();
  return <NcFormattedMessage variant="secondary">{t("None")}</NcFormattedMessage>;
};

export interface NcListBoxProps<T extends object> extends ReactAriaListBoxProps<T> {
  variant?: keyof typeof listBoxStyles.list.variants;
}

const NcListBox = function <T extends object>({
  renderEmptyState,
  variant = "default",
  ...props
}: NcListBoxProps<T>) {
  return (
    <ReactAriaListBox
      data-nc="NcListBox"
      css={[listBoxStyles.list.base, listBoxStyles.list.variants[variant]]}
      renderEmptyState={renderEmptyState ? renderEmptyState : () => <NcDefaultEmptyState />}
      {...props}
    />
  );
};

const ListBoxHeader = ({ ...props }) => (
  <ReactAriaHeader css={listBoxStyles.item.header} {...props} />
);
const ListBoxSection = ({ ...props }) => (
  <ReactAriaSection css={listBoxStyles.item.section} {...props} />
);

NcListBox.Item = NcListBoxItem;
NcListBox.Header = ListBoxHeader;
NcListBox.Section = ListBoxSection;
NcListBox.Collection = ReactAriaCollection;

export { NcListBox };

import styled, { BREAKPOINT_SMALL_MAX_QUERY, theme } from "../../theme";
import { Flex } from "../primitives";

export const OverlayMessageBox = styled(Flex)`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: ${props => props.flexBasis ?? "35rem"};
  margin: ${theme.space[3]};
  overflow: hidden;
  border-radius: 6px;
  box-shadow:
    0px 10px 20px ${theme.colors.neutral.dark}33,
    0px 4px 4px ${theme.colors.neutral.dark}52;
  @media ${BREAKPOINT_SMALL_MAX_QUERY} {
    flex-direction: column;
  }
`;

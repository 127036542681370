import {
  NcComboBoxItem,
  NcFormattedMessage,
  NcFormComboBox,
  NcFormComboBoxProps,
  NcListBox,
} from "@noted/noted-components";
import { DateTime } from "luxon";

import { useI18n } from "~/hooks/use-i18n";
import { PrimhdCode } from "~/primhd/api";

function convertCodesToComboBoxItems(codes: PrimhdCode[]) {
  return codes.map(({ code, description, endDate }: PrimhdCode) => {
    return {
      id: code,
      label: `${code} - ${description}`,
      endDate,
    };
  });
}

interface PrimhdCodeComboBoxProps
  extends Omit<NcFormComboBoxProps, "renderEmptyState" | "placeholder" | "defaultItems"> {
  codes: PrimhdCode[];
}

const PrimhdCodeComboBox = ({ codes, label, ...props }: PrimhdCodeComboBoxProps) => {
  const { t } = useI18n("primhd");
  const items = convertCodesToComboBoxItems(codes);

  return (
    <NcFormComboBox
      renderEmptyState={() => t("primhd:codes_combo_box.empty")}
      label={label}
      placeholder={t("primhd:codes_combo_box.placeholder")}
      defaultItems={items}
      {...props}
    >
      <NcListBox renderEmptyState={() => t("primhd:codes_combo_box.empty")}>
        {(item: NcComboBoxItem) => {
          const endDate = item.endDate && DateTime.fromISO(String(item.endDate));
          const isExpired = endDate && endDate < DateTime.now();
          const expiredDate = isExpired && endDate.toLocaleString(DateTime.DATE_SHORT);
          return (
            <NcListBox.Item id={item.id} textValue={`${item.label}`} className="group">
              <div className="grid gap-1">
                <div>{item.label}</div>
                {isExpired && (
                  <NcFormattedMessage
                    variant="danger"
                    className="italic group-hover:text-light group-active:text-light group-data-[selected=true]:text-light"
                  >
                    {t("primhd:codes_combo_box.expired", { date: expiredDate })}
                  </NcFormattedMessage>
                )}
              </div>
            </NcListBox.Item>
          );
        }}
      </NcListBox>
    </NcFormComboBox>
  );
};

export default PrimhdCodeComboBox;

import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";

const styles = {
  list: {
    base: css`
      gap: ${ncTheme.spacing(2.5)};
    `,
    variant: {
      vertical: css`
        display: grid;
        > * {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }
      `,
      horizontal: css`
        display: flex;
        flex-wrap: wrap;
        gap: ${ncTheme.spacing(6)};
        > * {
          display: flex;
        }
      `,
    },
  },
  item: css`
    align-items: baseline;
    gap: ${ncTheme.spacing(1.5)};
  `,
  itemValue: {
    base: css`
      font-weight: ${ncTheme.fontWeight.bold};
    `,
    variant: {
      default: css``,
      ellipsis: css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `,
    },
  },
};

export interface NcDataListItemProps extends HTMLAttributes<HTMLDivElement> {
  label: string | ReactNode;
  children: ReactNode;
  variant?: keyof typeof styles.itemValue.variant;
}

const NcDataListItem = ({
  label,
  variant = "default",
  children,
  ...props
}: NcDataListItemProps) => {
  return (
    <div data-nc="NcDataList.Item" css={styles.item} {...props}>
      <dt>{label}</dt>
      <dd css={[styles.itemValue.base, styles.itemValue.variant[variant]]}>{children}</dd>
    </div>
  );
};

export interface NcDataListProps extends HTMLAttributes<HTMLDListElement> {
  children: ReactNode;
  variant?: keyof typeof styles.list.variant;
}

export const NcDataList = ({ children, variant = "vertical", ...props }: NcDataListProps) => {
  return (
    <dl data-nc="NcDataList" css={css([styles.list.base, styles.list.variant[variant]])} {...props}>
      {children}
    </dl>
  );
};

NcDataList.Item = NcDataListItem;

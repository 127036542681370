import Rect, { PRect, useRect } from "@reach/rect";
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
  GetItemPropsOptions,
} from "downshift";
import {
  Children,
  cloneElement,
  createContext,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
  SVGProps,
  useContext,
  useRef,
} from "react";
import { createPortal } from "react-dom";

import { IconBase } from "../../icons";
import styled from "../../theme";
import { Input, Label } from "../forms";
import { Box, BoxProps, Flex } from "../primitives";

// FIXME: This file contains a lot of "any" type usage
//  We are ignoring these lint errors but need to come back and update this component to be more
//  type aware.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChoiceContext = createContext<ControllerStateAndHelpers<any>>(undefined as any);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IChoice extends Omit<DownshiftProps<any>, "children"> {
  children: ReactNode;
}

export const Choice = ({ children, ...props }: IChoice) => {
  const itemCount = useRef(0);
  return (
    <Downshift selectedItem={null} {...props}>
      {downshiftProps => (
        <ChoiceContext.Provider
          value={{ ...downshiftProps, itemCount }}
          {...downshiftProps.getRootProps({ refKey: "innerRef" })}
        >
          <ChoiceWrapper>{children}</ChoiceWrapper>
        </ChoiceContext.Provider>
      )}
    </Downshift>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChoiceWrapper = forwardRef<HTMLDivElement, any>((props, innerRef) => {
  return <Box {...props} ref={innerRef} />;
});
ChoiceWrapper.displayName = "ChoiceWrapper";

export const ChoiceLabel = forwardRef<
  HTMLLabelElement,
  LabelHTMLAttributes<HTMLLabelElement> & BoxProps
>((props, innerRef) => {
  const { getLabelProps } = useContext(ChoiceContext);
  return <Label {...props} ref={innerRef} {...getLabelProps()} />;
});
ChoiceLabel.displayName = "ChoiceLabel";

const DropdownIcon = () => (
  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
  </svg>
);

export const ChoiceInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & BoxProps & { showDropdownIcon?: boolean }
>((props, innerRef) => {
  const { getInputProps, highlightedIndex, getToggleButtonProps, isOpen, openMenu } =
    useContext(ChoiceContext);

  const { onKeyDown, ...inputProps } = getInputProps();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const escapeSubmit = (e: any) => {
    if (!highlightedIndex && e.key === "Enter") {
      e.preventDefault();
    }
    if (onKeyDown) onKeyDown(e);
  };
  return (
    <Flex zIndex={0}>
      <Input
        onClick={() => !isOpen && openMenu()}
        {...props}
        ref={innerRef}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(inputProps as any)}
        onKeyDown={escapeSubmit}
      />
      <button
        {...getToggleButtonProps()}
        style={{
          position: "absolute",
          right: "6px",
          top: "6px",
          cursor: "pointer",
          zIndex: "3",
          background: "transparent",
        }}
      >
        <DropdownIcon />
      </button>
    </Flex>
  );
});
ChoiceInput.displayName = "ChoiceInput";

export const ChoiceMenu = ({ children }: { children: ReactNode }) => {
  const { isOpen, inputValue } = useContext(ChoiceContext);
  let index = 0;
  const filteredChildren = Children.toArray(children)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((c: any) => {
      const key = c && c.props && c.props.options && c.props.options.key;
      if (typeof key === "string") {
        return key.toLowerCase().includes((inputValue || "").toLowerCase());
      }
      return true;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((c: any) => {
      if (c && c.props && c.props.options) {
        c.props.options.index = index++;
      }
      return c;
    });

  return (
    <Rect>
      {({ rect, ref }) => (
        <div ref={ref}>
          {isOpen && filteredChildren.length ? (
            <MenuPortal rect={rect}>
              {filteredChildren.map((child, i) => cloneElement(child, { key: i }))}
            </MenuPortal>
          ) : null}
        </div>
      )}
    </Rect>
  );
};

const MenuPortal = ({ rect, children }: { children: ReactNode; rect: PRect | null }) => {
  const { getMenuProps } = useContext(ChoiceContext);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuRect = useRect(menuRef);

  return (
    <>
      {createPortal(
        <StyledMenu
          {...getMenuProps()}
          style={{
            top: rect ? rect.top : 0,
            left: rect ? rect.left : 0,
            width: rect ? rect.width : "100%",
            maxHeight: Math.min(menuRect?.height ?? 0, 300),
            bottom: 10,
          }}
        >
          <Box ref={menuRef}>{children}</Box>
        </StyledMenu>,
        document.body
      )}
    </>
  );
};

const StyledMenu = styled.ul(
  ({ theme }) => `
  background: ${theme.colors.neutral.lightest};
  box-shadow: 2px 2px 2px rgba(39, 50, 63, 0.14);
  border-left: 1px solid ${theme.colors.neutral.light};
  border-top: 1px solid ${theme.colors.neutral.light};
  border-radius: 4px;
  overflow: auto;
  position: absolute;
  z-index: 3;
`
);

interface IChoiceItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: GetItemPropsOptions<any>;
}

export const ChoiceItem = forwardRef<HTMLLIElement, IChoiceItemProps>((props, innerRef) => {
  const { options, ...otherProps } = props;
  const { getItemProps, highlightedIndex } = useContext(ChoiceContext);
  return (
    <StyledItem
      {...otherProps}
      ref={innerRef}
      {...getItemProps(options)}
      selected={options.selected}
      highlighted={options.index === highlightedIndex}
    />
  );
});
ChoiceItem.displayName = "ChoiceItem";

const StyledItem = styled.li<{ highlighted: boolean; selected: boolean }>(
  ({ highlighted, selected, theme }) => `
  padding: ${theme.space[2]};
  font-style: ${selected ? "italic" : ""};
  font-weight: ${selected ? "bold" : "normal"};
  color: ${selected ? theme.colors.primary.medium : ""};
  cursor: pointer;
  ${
    highlighted
      ? `background-color: ${theme.colors.primary.dark}; color: ${theme.colors.neutral.lightest};`
      : ""
  }
`
);

export const ChoiceSeparator = styled(Box)(
  ({ theme }) => `
  background: ${theme.colors.neutral.medium};
  margin: ${theme.space[3]} ${theme.space[2]} ;
  height: 1px;
`
);

export const ChoiceItemClose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBase width="8" height="8" viewBox="0 0 13 12" {...props}>
      <rect
        width="14.1506"
        height="2.5269"
        rx="1"
        transform="matrix(0.714555 0.699579 -0.714555 0.699579 1.80563 0)"
      />
      <rect
        width="14.1506"
        height="2.5269"
        rx="1"
        transform="matrix(-0.714555 0.699579 0.714555 0.699579 10.1114 0)"
      />
    </IconBase>
  );
};

import { ComponentProps, forwardRef } from "react";

import Box from "../primitives/box";

/**
 * @deprecated Use Form from `/shared/ui` instead.
 */
const BaseForm = Box.withComponent("form");

export const Form = forwardRef<HTMLFormElement, ComponentProps<typeof BaseForm>>(
  ({ width = "100%", noValidate = true, autoComplete = "off", ...props }, ref) => (
    <BaseForm
      width={width}
      noValidate={noValidate}
      autoComplete={autoComplete}
      ref={ref}
      {...props}
    />
  )
);

Form.displayName = "Form";

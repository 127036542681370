import { ComponentProps } from "react";

import { theme } from "../../theme";
import { Text } from "../primitives/";

/**
 * @deprecated Use Feedback from `/shared/ui` instead.
 */
const InputError = (props: ComponentProps<typeof Text>) => {
  return <Text fontSize="1" color={theme.colors.danger.dark} mb="4" {...props} />;
};

export default InputError;

import { ComponentProps, CSSProperties, forwardRef, ReactNode } from "react";
import { variant } from "styled-system";

import { useI18n } from "~/hooks/use-i18n";

import { ToastClose } from "../../icons";
import styled, { theme } from "../../theme";
import { Flex, Text } from "../primitives";

const tagVariants = {
  variants: {
    primary: {
      color: theme.colors.neutral.lightest,
      backgroundColor: theme.colors.info.mediumDark,
    },
    default: {
      color: theme.colors.neutral.lightest,
      backgroundColor: theme.colors.neutral.mediumDark,
    },
    warning: {
      color: theme.colors.neutral.lightest,
      backgroundColor: theme.colors.warning.dark,
    },
    danger: {
      color: theme.colors.neutral.lightest,
      backgroundColor: theme.colors.danger.dark,
    },
    info: {
      color: theme.colors.neutral.lightest,
      backgroundColor: theme.colors.info.dark,
    },
  },
};

const StyledTag = styled(Flex)`
  ${variant(tagVariants)};
  padding: ${theme.space[1]} ${theme.space[2]};
  height: 1.5rem;
  appearance: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  -webkit-font-smoothing: subpixel-antialiased;
  user-select: none;
  -webkit-touch-callout: none;
  transition:
    border-color 0.25s ease-in-out,
    box-shadow 0.1s ease-in-out,
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  &:disabled {
    cursor: not-allowed;
  }
`.withComponent("span");

StyledTag.displayName = "Tag";

interface ITagProps extends ComponentProps<typeof Flex> {
  children?: ReactNode;
  style?: CSSProperties;
  closable?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

const Tag = forwardRef<HTMLDivElement, ITagProps>((props, ref) => {
  const { t } = useI18n();
  const { children, closable, onClose, variant = "default", onClick, ...otherProps } = props;
  return (
    <Flex ref={ref} style={{ display: "inline-flex" }} {...otherProps}>
      {closable && (
        <StyledTag
          as="button"
          justifyContent="space-around"
          variant={variant}
          borderRadius="9999px 0 0 9999px"
          onClick={() => onClose && onClose()}
          style={{ cursor: "pointer" }}
          aria-label={t("delete")}
          className="py-0"
        >
          <ToastClose width="0.5rem" height="0.5rem" fill="currentColor" role="presentation" />
        </StyledTag>
      )}
      <StyledTag
        variant={variant}
        borderRadius={closable ? "0 9999px 9999px 0" : "9999px"}
        maxWidth="10rem"
        onClick={onClick}
        className={closable ? "pl-0" : "pl-2"}
      >
        {children}
      </StyledTag>
    </Flex>
  );
});
Tag.displayName = "Tag";

export const TagText = styled(Text)`
  text-overflow: ellipsis;
  font-size: 0.8rem;
  overflow: hidden;
  line-height: 1.3;
`;

TagText.displayName = "Title";

export default Tag;

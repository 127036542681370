import { HTMLAttributes } from "react";

const bannerVariants = {
  danger: "flex gap-3 p-3 justify-center items-center text-danger bg-danger/15",
  info: "flex gap-3 p-3 justify-center items-center text-main bg-main/15",
  success: "flex gap-3 p-3 justify-center items-center text-success bg-success/15",
};

interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  variant: keyof typeof bannerVariants;
}

export const Banner = ({ variant, ...props }: BannerProps) => (
  <div className={bannerVariants[variant]} {...props} />
);

import { ChangeEvent, ComponentProps, FormEvent, forwardRef } from "react";

import { Box } from "../components/primitives";
import styled from "../theme";

const BaseSelect = styled(Box)(
  ({
    theme: {
      colors: { neutral, info },
    },
  }) => `
  appearance: none;
  height: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  background-color: ${neutral.lightest};
  border-color: ${neutral.medium};
  border-width: 1px;
  border-style: solid;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: ${neutral.darkest};
  
  outline-color: ${info.lightest};
  outline-width: 0.15rem;
  outline-offset: 0.25rem;
  &:hover {
    border-color: ${info.mediumLight};
  }
  &:active,
  &:focus {
    border-color: ${info.mediumLight};
    z-index: 1;
  }
  &:disabled {
    color: ${neutral.mediumDark};
    cursor: not-allowed;
  }
  ::placeholder {
    color: ${neutral.mediumDark};
  }
  background-image: url("data:image/svg+xml,${encodeURI(
    `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20" viewBox="0 0 8 20" fill="${neutral.mediumDark}" stroke="${neutral.mediumDark}"><path d="M4 0L0 8H8L4 0Z"/><path d="M4 20.0455L8 12.0455H0L4 20.0455Z"/></svg>`
  ).replace(/#/g, "%23")}");
  background-repeat: no-repeat;
  background-position: right .4rem center;
  background-size: .75rem .75rem;
`
).withComponent("select");

const Select = forwardRef<HTMLSelectElement, ComponentProps<typeof BaseSelect>>(
  ({ p = 2, mb = 0, mt = 0, borderRadius = 4, width = "100%", ...props }, ref) => (
    <BaseSelect
      p={p}
      mb={mb}
      mt={mt}
      borderRadius={borderRadius}
      width={width}
      ref={ref}
      {...props}
    />
  )
);

Select.displayName = "Select";

export default Select;

export function eventValue(e: ChangeEvent<HTMLSelectElement> | FormEvent<HTMLDivElement>) {
  return "value" in e.target ? e.target.value : "";
}

import { NcIconArrowLeft, NcLink, NcLinkProps } from "@noted/noted-components";

interface BackToParentProps extends Omit<NcLinkProps, "href" | "children"> {
  to: string;
  text: string;
}

export const BackToParent = ({ to, text, className, ...props }: BackToParentProps) => {
  return (
    <NcLink href={to} className={`mb-3 inline-flex items-center gap-2 ${className}`} {...props}>
      <NcIconArrowLeft className="text-xl" opacity="0.75" />
      <span>{text}</span>
    </NcLink>
  );
};

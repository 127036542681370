import {
  NcBadge,
  NcButton,
  NcCollapse,
  NcFormattedMessage,
  NcGridList,
  NcIconCalendar,
  NcIconEllipsis,
  NcLink,
  NcMenu,
  NcPanel,
  NcTooltip,
} from "@noted/noted-components";
import { DateTime } from "luxon";
import { useId } from "react-aria";

import { useI18n } from "~/hooks/use-i18n";
import config from "~/routes/config";
import { useToast } from "~/shared/components/alerts/toast-provider";

import { useAppointmentsQuery } from "../dashboard-queries";
import { NoAppointmentsIcon } from "../icons";
import { AccessLevel } from "./types";

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoAppointmentsIcon opacity="0.5" width="8rem" />
      <NcFormattedMessage variant="secondary">
        {t("org:dashboard.appointments.empty")}
      </NcFormattedMessage>
    </div>
  );
};

const AppointmentsPanel = () => {
  const { t } = useI18n("org");
  const panelId = useId();
  const { enqueueSuccess, enqueueError } = useToast();

  const { data: appointments, isLoading, error } = useAppointmentsQuery();

  function getFromTime(from: number) {
    return DateTime.fromMillis(from).toFormat("h:mma");
  }

  function isRestricted(accessLevel: AccessLevel) {
    return accessLevel !== "FULL";
  }

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcPanel.Heading id={panelId} level={2}>
          {t("org:dashboard.appointments.heading")}
        </NcPanel.Heading>
        <div className="ml-auto flex gap-2">
          <NcTooltip.Trigger>
            <NcLink href="/scheduling/booking" className="flex items-center">
              <NcIconCalendar className="text-lg" />
              <NcTooltip>{t("org:dashboard.appointments.link")}</NcTooltip>
            </NcLink>
          </NcTooltip.Trigger>
          <NcBadge>{appointments?.length}</NcBadge>
        </div>
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.appointments.name"),
              })
            : undefined
        }
      >
        {appointments?.length ? (
          <NcGridList aria-labelledby={panelId}>
            {appointments.map(appointment => {
              const {
                id,
                notes,
                client,
                service,
                from,
                duration,
                confirmed,
                arrived,
                cancelled,
                completed,
                paid,
              } = appointment;

              return (
                <NcGridList.Item
                  key={id}
                  textValue={client.name}
                  className="group grid grid-cols-[7rem,1fr,auto] items-start gap-3"
                >
                  <div className="grid gap-1">
                    {confirmed && !arrived && !cancelled && !completed ? (
                      <NcBadge variant="neutral">
                        {t("org:dashboard.appointments.status.confirmed")}
                      </NcBadge>
                    ) : null}
                    {cancelled ? (
                      <NcBadge variant="danger">
                        {t("org:dashboard.appointments.status.cancelled")}
                      </NcBadge>
                    ) : null}
                    {arrived && !completed && !cancelled ? (
                      <NcBadge>{t("org:dashboard.appointments.status.arrived")}</NcBadge>
                    ) : null}
                    {completed ? (
                      <NcBadge variant="success">
                        {t("org:dashboard.appointments.status.completed")}
                      </NcBadge>
                    ) : null}
                    {paid ? (
                      <NcBadge variant="success">
                        {t("org:dashboard.appointments.status.paid")}
                      </NcBadge>
                    ) : null}
                    <div className="flex">
                      <span>{getFromTime(from)}</span>
                      <span className="ml-auto">{duration}m</span>
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <NcLink
                      className="group-hover:text-current group-focus:text-current"
                      href={`/n/clients/${client.id}`}
                    >
                      {client.name}
                    </NcLink>
                    <NcCollapse>
                      <NcCollapse.Summary>{service.name}</NcCollapse.Summary>
                      {notes ? (
                        notes
                      ) : (
                        <NcFormattedMessage variant="secondary">
                          {t("org:dashboard.appointments.no_notes")}
                        </NcFormattedMessage>
                      )}
                    </NcCollapse>
                  </div>
                  {!isRestricted(client.accessLevel) && (
                    <NcMenu.Trigger>
                      <NcButton aria-label={t("org:dashboard.appointments.actions.trigger")}>
                        <NcIconEllipsis />
                      </NcButton>
                      <NcMenu
                        aria-label={t("org:dashboard.appointments.actions.label")}
                        placement="bottom end"
                      >
                        {appointment?.telehealthRoom && (
                          <>
                            <NcMenu.Item
                              onAction={() => {
                                window.open(
                                  `${config.telehealthAppRoute}/${appointment.telehealthRoom?.token}`,
                                  "_blank"
                                );
                              }}
                            >
                              {t("org:dashboard.appointments.actions.start_call")}
                            </NcMenu.Item>
                            <NcMenu.Item
                              onAction={() => {
                                if (!navigator.clipboard) {
                                  return enqueueError(
                                    t("org:dashboard.appointments.actions.copy_link.error")
                                  );
                                }
                                const callUrl = `${config.telehealthAppRoute}/${appointment.telehealthRoom?.token}`;
                                navigator.clipboard
                                  .writeText(callUrl)
                                  .then(() =>
                                    enqueueSuccess(
                                      t("org:dashboard.appointments.actions.copy_link.success")
                                    )
                                  );
                              }}
                            >
                              {t("org:dashboard.appointments.actions.copy_link.label")}
                            </NcMenu.Item>
                          </>
                        )}
                        <NcMenu.Item id="open-client" href={`/n/clients/${client.id}`}>
                          {t("org:dashboard.appointments.actions.open_client")}
                        </NcMenu.Item>
                        <NcMenu.Item id="open-calendar" href={`/scheduling`}>
                          {t("org:dashboard.appointments.actions.open_calendar")}
                        </NcMenu.Item>
                      </NcMenu>
                    </NcMenu.Trigger>
                  )}
                </NcGridList.Item>
              );
            })}
          </NcGridList>
        ) : (
          <PanelEmpty />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default AppointmentsPanel;

import { NcLink } from "@noted/noted-components";
import { DateTime, Interval } from "luxon";
import { cloneElement, ReactElement, useContext } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { AccountContext } from "./account/account.context";
import { Banner } from "./shared/components/layout";

const Banners = () => {
  const { organisation, isUpgradable, isExpired } = useContext(AccountContext);
  const { t } = useI18n();
  const banners: ReactElement[] = [];

  // Add the billing details required banner when we:
  // * can no longer upgrade (we have a subscription)
  // * our organisation has billing system suspended
  if (!isUpgradable && organisation.billingSystemSuspended) {
    banners.push(
      <Banner variant="danger">
        <div dangerouslySetInnerHTML={{ __html: t("banners.billing_details_required") }} />
        <NcLink variant="button" className="shrink-0" href="/admin/subscription">
          {t("banners.billing_details_required_button")}
        </NcLink>
      </Banner>
    );
  }

  // Add the trial days remaining banner when we:
  // * are not expired
  // * are upgradable
  if (!isExpired && isUpgradable) {
    const now = DateTime.local();
    const trialTimeRemaining = Math.floor(
      Interval.fromDateTimes(now, new Date(organisation.trialEnd)).length("days")
    );
    banners.push(
      <Banner variant="info">
        <div
          dangerouslySetInnerHTML={{
            __html: t("banners.trial_period_remaining", { count: trialTimeRemaining }),
          }}
        />
        <NcLink variant="button" className="shrink-0" href="?upgrade=true">
          {t("banners.trial_period_remaining_button")}
        </NcLink>
      </Banner>
    );
  }
  // Add the trial period ended banner when we:
  // * are expired
  // * are upgradable
  if (isExpired && isUpgradable) {
    banners.push(
      <Banner variant="danger">
        <div
          dangerouslySetInnerHTML={{
            __html: t("banners.trial_period_ended"),
          }}
        />
        <NcLink variant="button" className="shrink-0" href="?upgrade=true">
          {t("banners.trial_period_ended_button")}
        </NcLink>
      </Banner>
    );
  }
  return (
    <>
      {banners.map((banner, index) => {
        return cloneElement(banner, { key: index });
      })}
    </>
  );
};

export default Banners;

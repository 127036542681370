import { css } from "@emotion/core";
import { HTMLAttributes } from "react";
import {
  FieldError as ReactAriaFieldError,
  FieldErrorProps as ReactAriaFieldErrorProps,
  Text as ReactAriaText,
  TextProps as ReactAriaTextProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

export const ncFieldLayoutStyles = {
  base: css`
    display: grid;
    gap: ${ncTheme.spacing(2)};
    line-height: 1.25;
    justify-items: start;
  `,
  variant: {
    vertical: css``,
    horizontal: css`
      grid-auto-flow: column;
      align-items: center;
      justify-content: start;
      column-gap: ${ncTheme.spacing(3)};
    `,
  },
};

export interface NcFieldLayoutProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof ncFieldLayoutStyles.variant;
}

export const NcFieldLayout = ({ variant = "vertical", ...props }: NcFieldLayoutProps) => (
  <div
    data-nc="NcFieldLayout"
    css={[ncFieldLayoutStyles.base, ncFieldLayoutStyles.variant[variant]]}
    {...props}
  />
);

export const errorMessageStyles = css`
  color: ${ncTheme.colors.dangerActive};
`;

const ErrorMessage = ({ ...props }: ReactAriaFieldErrorProps) => (
  <ReactAriaFieldError data-nc="NcFieldLayout.ErrorMessage" css={errorMessageStyles} {...props} />
);

const descriptionStyles = css`
  font-size: ${ncTheme.fontSizes[1]};
  line-height: 1.25;
`;

const Description = ({ ...props }: ReactAriaTextProps) => (
  <ReactAriaText
    data-nc="NcFieldLayout.Description"
    slot="description"
    css={descriptionStyles}
    {...props}
  />
);

NcFieldLayout.ErrorMessage = ErrorMessage;
NcFieldLayout.Description = Description;

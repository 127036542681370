import { NcAlert, NcButton, NcFormattedMessage } from "@noted/noted-components";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { AccountContext } from "../../account/account.context";
import { restPoster } from "../../graphql-hooks/custom-fetcher";

const Unverified = () => {
  const { t } = useI18n("admin-org");
  const { organisation } = useContext(AccountContext);
  const [didSendVerificationEmail, setDidSendVerificationEmail] = useState(false);
  const { mutate: sendVerificationEmail, isError: sendVerificationEmailIsError } = useMutation({
    mutationFn: (email: string) => restPoster("/v1/signup/resendWelcomeEmail", { email }),
    onSuccess: () => {
      setDidSendVerificationEmail(true);
    },
  });
  const onVerifyClicked = () => {
    sendVerificationEmail(organisation.email);
  };
  return (
    <NcAlert
      className="mt-4"
      data-testid="unverified-info-box"
      title={t("admin-org:subscriptions.upgrade.verify_account_message")}
    >
      <div className="mt-2">
        {!didSendVerificationEmail && !sendVerificationEmailIsError && (
          <NcButton data-testid="resend-verification-button" onPress={onVerifyClicked}>
            {t("admin-org:subscriptions.upgrade.verify_button")}
          </NcButton>
        )}

        {didSendVerificationEmail && (
          <div data-testid="did-send-verification-email">
            {t("admin-org:subscriptions.upgrade.verify_account_email_sent", {
              email: organisation.email,
            })}
          </div>
        )}

        {sendVerificationEmailIsError && (
          <NcFormattedMessage variant="danger">
            {t("admin-org:subscriptions.upgrade.verify_account_email_sent_error", {
              email: organisation.email,
            })}
          </NcFormattedMessage>
        )}
      </div>
    </NcAlert>
  );
};

export default Unverified;

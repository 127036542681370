import { css } from "@emotion/core";
import { forwardRef } from "react";
import {
  Label as ReactAriaLabel,
  type LabelProps as ReactAriaLabelProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { NcVisuallyHidden } from "./nc-visually-hidden";

export const ncLabelStyles = css`
  display: inline-flex;
  align-items: center;
  gap: ${ncTheme.spacing(0.5)};
  font-weight: ${ncTheme.fontWeight.bold};
  line-height: 1.2;
  cursor: pointer;
`;

const requiredAsteriskStyles = css`
  &::after {
    content: "*" / "required";
    color: ${ncTheme.colors.danger};
  }
`;

interface NcLabelProps extends ReactAriaLabelProps {
  isRequired?: boolean;
  hideLabel?: boolean;
}

export const NcLabel = forwardRef<HTMLLabelElement, NcLabelProps>(
  ({ children, isRequired, hideLabel, ...props }, ref) => {
    return (
      <ReactAriaLabel
        data-nc="NcLabel"
        ref={ref}
        css={[ncLabelStyles, isRequired && !hideLabel && requiredAsteriskStyles]}
        {...props}
      >
        {hideLabel ? <NcVisuallyHidden>{children}</NcVisuallyHidden> : <>{children}</>}
      </ReactAriaLabel>
    );
  }
);

interface NcRequiredTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  isRequired?: boolean;
  hideLabel?: boolean;
}

export const NcLabelText = ({ children, isRequired, hideLabel, ...props }: NcRequiredTextProps) => (
  <span
    data-nc="NcLabelText"
    css={[ncLabelStyles, isRequired && !hideLabel && requiredAsteriskStyles]}
    {...props}
  >
    {hideLabel ? <NcVisuallyHidden>{children}</NcVisuallyHidden> : <>{children}</>}
  </span>
);

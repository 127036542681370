import styled, { theme } from "../../theme";
import { Button } from "../forms/button";

export const ButtonAsText = styled(Button)`
  user-select: none;
  cursor: pointer;
  border: none;
  background-color: unset;
  color: ${theme.colors.info.medium};
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  &:hover {
    color: ${theme.colors.info.dark};
    background-color: unset;
  }
  &:active,
  &:focus {
    border-color: ${theme.colors.info.medium};
    box-shadow: 0 0 0 3px rgba(31, 115, 183, 0.35);
  }
  &:disabled {
    color: ${theme.colors.neutral.mediumDark};
    cursor: not-allowed;
  }
`;

ButtonAsText.displayName = "ButtonAsText";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  CountryCode: { input: any; output: any };
  CurrencyCode: { input: any; output: any };
  FileUpload: { input: any; output: any };
  LocalDate: { input: any; output: any };
  Long: { input: any; output: any };
  OffsetDateTime: { input: any; output: any };
  OffsetTime: { input: any; output: any };
  PositiveFloat: { input: any; output: any };
  PositiveInt: { input: any; output: any };
};

export type AccKeystoreFileInput = {
  fileupload: Scalars["FileUpload"]["input"];
  keystorePassword: Scalars["String"]["input"];
  persistPassword: Scalars["Boolean"]["input"];
};

export type AccOrganisationDetails = {
  __typename?: "AccOrganisationDetails";
  accScheduleCounter?: Maybe<Scalars["Long"]["output"]>;
  accVendorId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  purchaseOrdersEnabled: Scalars["Boolean"]["output"];
  purchaseOrdersUnitsHours?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AccOrganisationDetailsInput = {
  accScheduleCounter?: InputMaybe<Scalars["Long"]["input"]>;
  accVendorId: Scalars["String"]["input"];
  purchaseOrdersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  purchaseOrdersUnitsHours?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AccServiceCode = {
  __typename?: "AccServiceCode";
  code?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
};

export type AccServiceCodeInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccUserDetails = {
  __typename?: "AccUserDetails";
  accBillingRate?: Maybe<Scalars["BigDecimal"]["output"]>;
  accContractId?: Maybe<Scalars["String"]["output"]>;
  accProviderId?: Maybe<Scalars["String"]["output"]>;
  accServiceCode?: Maybe<Array<AccServiceCode>>;
  accUnitBasedBilling?: Maybe<Scalars["Boolean"]["output"]>;
  accVendorId?: Maybe<Scalars["String"]["output"]>;
  allowedBillingMethods?: Maybe<Array<BillingMethod>>;
  id?: Maybe<Scalars["Long"]["output"]>;
};

export enum AccessControlType {
  Client = "CLIENT",
  Records = "RECORDS",
}

export enum AccessLevel {
  Full = "FULL",
  Limited = "LIMITED",
  Restricted = "RESTRICTED",
}

export type AccessOverridesParams = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccessOverridesPayload = PagingResult & {
  __typename?: "AccessOverridesPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<BrokenGlass>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export enum Action {
  Create = "CREATE",
  Delete = "DELETE",
  Execute = "EXECUTE",
  Export = "EXPORT",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Read = "READ",
  Search = "SEARCH",
  Update = "UPDATE",
}

export type Address = {
  __typename?: "Address";
  attentionTo?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  postcode?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
  suburb?: Maybe<Scalars["String"]["output"]>;
};

export enum AdjustmentType {
  Allocated = "ALLOCATED",
  Blocked = "BLOCKED",
  Deallocated = "DEALLOCATED",
}

export type Appointment = {
  __typename?: "Appointment";
  accessLevel?: Maybe<AccessLevel>;
  arrived?: Maybe<Scalars["Boolean"]["output"]>;
  cancelled?: Maybe<Scalars["Boolean"]["output"]>;
  client?: Maybe<Client>;
  completed?: Maybe<Scalars["Boolean"]["output"]>;
  confirmed?: Maybe<Scalars["Boolean"]["output"]>;
  duration: Scalars["Int"]["output"];
  events?: Maybe<Array<AppointmentEvent>>;
  from: Scalars["OffsetDateTime"]["output"];
  id: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  paid?: Maybe<Scalars["Boolean"]["output"]>;
  service?: Maybe<Service>;
  spaces?: Maybe<Array<Maybe<Location>>>;
  team?: Maybe<Team>;
  telehealth?: Maybe<Scalars["Boolean"]["output"]>;
  telehealthRoom?: Maybe<TelehealthRoom>;
  workers?: Maybe<Array<Maybe<User>>>;
};

export type AppointmentEmailInput = {
  appointmentId: Scalars["Long"]["input"];
  toEmail: Scalars["String"]["input"];
  toName: Scalars["String"]["input"];
};

export type AppointmentEvent = {
  __typename?: "AppointmentEvent";
  appointment?: Maybe<Appointment>;
  created?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  createdBy?: Maybe<User>;
  eventDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  eventType: EventType;
  id: Scalars["Long"]["output"];
};

export type AppointmentPayload = {
  __typename?: "AppointmentPayload";
  appointment: Appointment;
};

export type ArriveAtAppointmentInput = {
  appointmentId: Scalars["Long"]["input"];
  arrivedAt?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type AssignFeaturesToRoleInput = {
  featureIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  roleId: Scalars["Long"]["input"];
};

export type AssignModalityToTeamInput = {
  modalityId: Scalars["Long"]["input"];
  teamId: Scalars["Long"]["input"];
};

export type AssignPrivilegesToRoleInput = {
  privilegeIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  roleId: Scalars["Long"]["input"];
};

export type AssignRolesToPrivilegeInput = {
  privilegeId: Scalars["Long"]["input"];
  roleIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
};

export type AssignRolesToUserInput = {
  roleIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  userId: Scalars["Long"]["input"];
};

export type AssignTeamToTrackingFormsInput = {
  accessType?: InputMaybe<AccessControlType>;
  assign?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  trackingFormId: Scalars["Long"]["input"];
};

export type AssignTeamsToTagInput = {
  accessType?: InputMaybe<AccessControlType>;
  assign?: InputMaybe<Scalars["Boolean"]["input"]>;
  tagId: Scalars["Long"]["input"];
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
};

export type AssignTeamsToUserInput = {
  accessType?: InputMaybe<AccessControlType>;
  assign?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  userId: Scalars["Long"]["input"];
};

export type AssignUsersToRoleInput = {
  roleId: Scalars["Long"]["input"];
  userIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
};

export type AssignUsersToTeamInput = {
  teamId: Scalars["Long"]["input"];
  userIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
};

export type Attribute = {
  __typename?: "Attribute";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  locations?: Maybe<Array<Maybe<Location>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  resourceType?: Maybe<ResourceType>;
  workers?: Maybe<Array<Maybe<User>>>;
};

export type AuditTrailAggregateEntry = {
  __typename?: "AuditTrailAggregateEntry";
  end?: Maybe<Scalars["Long"]["output"]>;
  referrer?: Maybe<Scalars["String"]["output"]>;
  start?: Maybe<Scalars["Long"]["output"]>;
};

export type AuditTrailAggregateQueryPayload = {
  __typename?: "AuditTrailAggregateQueryPayload";
  entries?: Maybe<Array<Maybe<AuditTrailAggregateEntry>>>;
};

export type AuditTrailClientUserQueryPayload = {
  __typename?: "AuditTrailClientUserQueryPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<ClientUserSearchItem>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type AuditTrailEntity = {
  __typename?: "AuditTrailEntity";
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AuditTrailEntityType>;
};

export type AuditTrailEntitySearchParams = {
  actionsBy: Scalars["Boolean"]["input"];
  id: Scalars["Long"]["input"];
  type: AuditTrailEntityType;
};

export enum AuditTrailEntityType {
  Client = "CLIENT",
  Group = "GROUP",
  Record = "RECORD",
  User = "USER",
  Whanau = "WHANAU",
}

export type AuditTrailEntry = {
  __typename?: "AuditTrailEntry";
  action?: Maybe<Action>;
  auditedUserId?: Maybe<Scalars["Long"]["output"]>;
  clientId?: Maybe<Scalars["Long"]["output"]>;
  domain?: Maybe<Scalars["String"]["output"]>;
  endpoint?: Maybe<Scalars["String"]["output"]>;
  entities?: Maybe<Array<Maybe<AuditTrailEntity>>>;
  groupId?: Maybe<Scalars["Long"]["output"]>;
  id: Scalars["String"]["output"];
  ipAddress?: Maybe<Scalars["String"]["output"]>;
  organisationId?: Maybe<Scalars["Long"]["output"]>;
  referrer?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<Result>;
  timestamp?: Maybe<Scalars["Long"]["output"]>;
  user?: Maybe<AuditTrailEntity>;
  userId?: Maybe<Scalars["Long"]["output"]>;
  version?: Maybe<Scalars["PositiveInt"]["output"]>;
  whanauId?: Maybe<Scalars["Long"]["output"]>;
};

export type AuditTrailEntryQueryPayload = {
  __typename?: "AuditTrailEntryQueryPayload";
  entries?: Maybe<Array<Maybe<AuditTrailItemEntry>>>;
};

export type AuditTrailItemEntry = {
  __typename?: "AuditTrailItemEntry";
  dob?: Maybe<Scalars["LocalDate"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Long"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type AuditTrailQueryPayload = {
  __typename?: "AuditTrailQueryPayload";
  results: Array<AuditTrailEntry>;
};

export type AuditTrailSearchEntity = {
  id?: InputMaybe<Scalars["Long"]["input"]>;
  type?: InputMaybe<AuditTrailEntityType>;
};

export type AuditTrailSearchParams = {
  action?: InputMaybe<Action>;
  against: AuditTrailSearchEntity;
  domain?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  from: AuditTrailSearchEntity;
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type AuditTrailUserEntry = {
  __typename?: "AuditTrailUserEntry";
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Long"]["output"]>;
};

export type AuthenticationDetails = {
  __typename?: "AuthenticationDetails";
  lockedOutUntil?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  shouldChangePasswordHint?: Maybe<Scalars["Boolean"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type AuthenticationInput = {
  captcha?: InputMaybe<Scalars["String"]["input"]>;
  eulaAgreed: Scalars["Boolean"]["input"];
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type AuthenticationResult = {
  __typename?: "AuthenticationResult";
  lastLogin?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  lockoutSeconds?: Maybe<Scalars["Long"]["output"]>;
  result: LoginResultType;
  roles: Array<Scalars["String"]["output"]>;
  shouldChangePasswordHint?: Maybe<Scalars["Boolean"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export type AutoAssignUserObjectsToTeamInput = {
  clients?: InputMaybe<Scalars["Boolean"]["input"]>;
  records?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamId: Scalars["Long"]["input"];
  teams?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId: Scalars["Long"]["input"];
};

export type AvailableFeature = {
  __typename?: "AvailableFeature";
  permissionName: FeaturePermission;
};

export type AvailableFeatureQueryPayload = {
  __typename?: "AvailableFeatureQueryPayload";
  features: Array<AvailableFeature>;
};

export type BillingDetailsDto = {
  __typename?: "BillingDetailsDto";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  addressLine3?: Maybe<Scalars["String"]["output"]>;
  cardToken?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type BillingDetailsInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  addressLine3?: InputMaybe<Scalars["String"]["input"]>;
  cardToken?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export enum BillingMethod {
  Distance = "DISTANCE",
  Flatfee = "FLATFEE",
  Freeamount = "FREEAMOUNT",
  Time = "TIME",
  Units = "UNITS",
}

export type BrokenGlass = {
  __typename?: "BrokenGlass";
  client?: Maybe<Client>;
  created?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  ipAddress?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  records?: Maybe<Scalars["Boolean"]["output"]>;
  user?: Maybe<User>;
};

export type BulkReassignRecordsInput = {
  clientId?: InputMaybe<Scalars["Long"]["input"]>;
  fromUserId: Scalars["Long"]["input"];
  teamId: Scalars["Long"]["input"];
  toUserId: Scalars["Long"]["input"];
};

export type BulkReassignRecordsPayload = {
  __typename?: "BulkReassignRecordsPayload";
  rowCountAffected: Scalars["Long"]["output"];
};

export type CancelAppointmentInput = {
  appointmentId: Scalars["Long"]["input"];
  cancelledAt?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type CancelReportExportPayload = {
  __typename?: "CancelReportExportPayload";
  status?: Maybe<CancelStatus>;
};

export enum CancelStatus {
  InvalidStatus = "INVALID_STATUS",
  Success = "SUCCESS",
}

export enum ChargeModel {
  FlatFee = "FLAT_FEE",
  PerUnit = "PER_UNIT",
  Tiered = "TIERED",
  Volume = "VOLUME",
  Unknown = "_UNKNOWN",
}

export enum ChargeType {
  NonRecurring = "NON_RECURRING",
  Recurring = "RECURRING",
}

export type Client = {
  __typename?: "Client";
  addresses?: Maybe<Array<Maybe<Address>>>;
  clientId?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["LocalDate"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<AccessLevel>;
  medicalServiceId?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  preferredName?: Maybe<Scalars["String"]["output"]>;
  secondaryPhone?: Maybe<Scalars["String"]["output"]>;
};

export type ClientDuplicatesPayload = {
  __typename?: "ClientDuplicatesPayload";
  duplicates: Array<Client>;
};

export type ClientPayload = {
  __typename?: "ClientPayload";
  client?: Maybe<Client>;
};

export type ClientQueryParams = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  ignoreTeamCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClientQueryPayload = PagingResult & {
  __typename?: "ClientQueryPayload";
  count: Scalars["Int"]["output"];
  ignoreTeamCheck?: Maybe<Scalars["Boolean"]["output"]>;
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<Client>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type ClientStatusReason = {
  __typename?: "ClientStatusReason";
  id?: Maybe<Scalars["Long"]["output"]>;
  listType: ClientStatusReasonType;
  name: Scalars["String"]["output"];
  organisation?: Maybe<Organisation>;
  sortOrder?: Maybe<Scalars["Int"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum ClientStatusReasonType {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type ClientTag = {
  __typename?: "ClientTag";
  description?: Maybe<Scalars["String"]["output"]>;
  global?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organisation?: Maybe<Organisation>;
  sortOrder?: Maybe<Scalars["Int"]["output"]>;
  teams?: Maybe<Array<Maybe<Team>>>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ClientUserSearchItem = {
  __typename?: "ClientUserSearchItem";
  created?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  dob?: Maybe<Scalars["LocalDate"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Long"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  type: AuditTrailEntityType;
};

export type ClientUserSearchRequest = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClinicTab = {
  __typename?: "ClinicTab";
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  global?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Long"]["output"];
  teams?: Maybe<Array<Maybe<Team>>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CompleteAppointmentInput = {
  appointmentId: Scalars["Long"]["input"];
  completedAt?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type CreateAppointmentInput = {
  clientId: Scalars["Long"]["input"];
  duration: Scalars["Int"]["input"];
  forceLocations?: InputMaybe<Scalars["Boolean"]["input"]>;
  forceWorkers?: InputMaybe<Scalars["Boolean"]["input"]>;
  from: Scalars["OffsetDateTime"]["input"];
  locationIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
  name: Scalars["String"]["input"];
  newLocations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["Long"]["input"];
  teamId?: InputMaybe<Scalars["Long"]["input"]>;
  telehealth?: InputMaybe<Scalars["Boolean"]["input"]>;
  workerIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
};

export type CreateAppointmentPayload = {
  __typename?: "CreateAppointmentPayload";
  appointment?: Maybe<Appointment>;
  locationCollisions?: Maybe<Array<Maybe<LocationCollision>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  workerCollisions?: Maybe<Array<Maybe<WorkerCollision>>>;
};

export type CreateClientInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sendEmailInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreateClientStatusReasonInput = {
  listType: ClientStatusReasonType;
  name: Scalars["String"]["input"];
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  visible: Scalars["Boolean"]["input"];
};

export type CreateClientStatusReasonPayload = {
  __typename?: "CreateClientStatusReasonPayload";
  clientStatusReasons?: Maybe<Array<ClientStatusReason>>;
};

export type CreateClientTagInput = {
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  visible: Scalars["Boolean"]["input"];
};

export type CreateClientTagPayload = {
  __typename?: "CreateClientTagPayload";
  clientTags?: Maybe<Array<Maybe<ClientTag>>>;
};

export type CreateLetterSenderInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateLetterSignatureInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateLetterTemplateInput = {
  clinicId?: InputMaybe<Scalars["Long"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateLocationInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  serviceIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
};

export type CreateOutcomeInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreateOutcomePayload = {
  __typename?: "CreateOutcomePayload";
  outcome: Outcome;
};

export type CreateOutcomeReasonInput = {
  enabled: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  type: OutcomeReasonType;
};

export type CreateOutcomeReasonPayload = {
  __typename?: "CreateOutcomeReasonPayload";
  outcomeReason: OutcomeReason;
};

export type CreatePrimhdOrganisationDetailsInput = {
  folder?: InputMaybe<Scalars["String"]["input"]>;
  organisationId?: InputMaybe<Scalars["String"]["input"]>;
  organisationName?: InputMaybe<Scalars["String"]["input"]>;
  organisationType?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreatePrimhdTeamInput = {
  closeDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  facility?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  openDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  population?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<Scalars["String"]["input"]>;
  setting?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateRecordTypeInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  formType: FormType;
  name: Scalars["String"]["input"];
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  visible: Scalars["Boolean"]["input"];
};

export type CreateRecordTypePayload = {
  __typename?: "CreateRecordTypePayload";
  recordTypes?: Maybe<Array<RecordType>>;
};

export type CreateRelationshipTypeInput = {
  personTitle: Scalars["String"]["input"];
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  visible: Scalars["Boolean"]["input"];
};

export type CreateRelationshipTypePayload = {
  __typename?: "CreateRelationshipTypePayload";
  relationshipTypes: Array<RelationshipType>;
};

export type CreateResourceAdjustmentPeriodInput = {
  from: Scalars["OffsetDateTime"]["input"];
  locationId?: InputMaybe<Scalars["Long"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  to: Scalars["OffsetDateTime"]["input"];
  type: AdjustmentType;
  workerId?: InputMaybe<Scalars["Long"]["input"]>;
};

export type CreateServiceInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  defaultTelehealth?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["PositiveInt"]["input"];
  locationIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
  name: Scalars["String"]["input"];
  newLocations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  price: Scalars["PositiveFloat"]["input"];
  requiredLocations: Scalars["Int"]["input"];
  requiredWorkers: Scalars["Int"]["input"];
  workerIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
};

export type CreateTeamInput = {
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  purchaseOrdersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  recordSensitivity?: InputMaybe<RecordSensitivity>;
};

export type CreateUserInput = {
  dateOfBirth?: InputMaybe<Scalars["LocalDate"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  isAdminUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  letterSender?: InputMaybe<UpdateLetterSenderInput>;
  letterSignature?: InputMaybe<UpdateLetterSignatureInput>;
  password: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  privileges?: InputMaybe<Array<InputMaybe<PrivilegeInput>>>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  securityRoles?: InputMaybe<Array<InputMaybe<UserSecurityRoleInput>>>;
  shortCode?: InputMaybe<Scalars["String"]["input"]>;
  skipBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  suspended?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  username: Scalars["String"]["input"];
};

export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  user?: Maybe<User>;
};

export type CreateUserRoleInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateWorkingHoursInput = {
  endTime: Scalars["OffsetTime"]["input"];
  startTime: Scalars["OffsetTime"]["input"];
  weekday: Scalars["Int"]["input"];
  workerId: Scalars["Long"]["input"];
  working: Scalars["Boolean"]["input"];
};

export type CreateWorkingHoursPayload = {
  __typename?: "CreateWorkingHoursPayload";
  workingHours?: Maybe<WorkingHours>;
};

export type DateRangeInput = {
  from: Scalars["OffsetDateTime"]["input"];
  to: Scalars["OffsetDateTime"]["input"];
};

export type DeleteResourceAdjustmentPeriodPayload = {
  __typename?: "DeleteResourceAdjustmentPeriodPayload";
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum DiscountType {
  FixedAmount = "FIXED_AMOUNT",
  Percentage = "PERCENTAGE",
}

export enum DurationType {
  Forever = "FOREVER",
  LimitedPeriod = "LIMITED_PERIOD",
  OneTime = "ONE_TIME",
}

export type EstimateInput = {
  addOnIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  packageId?: InputMaybe<Scalars["Long"]["input"]>;
};

export enum EventType {
  BookedAt = "BOOKED_AT",
  CancelledAt = "CANCELLED_AT",
  ClientArrivedAt = "CLIENT_ARRIVED_AT",
  CompletedAt = "COMPLETED_AT",
  DeletedAt = "DELETED_AT",
  PaidAt = "PAID_AT",
  UpdatedAt = "UPDATED_AT",
}

export type Feature = {
  __typename?: "Feature";
  countryCode?: Maybe<Scalars["CountryCode"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name: Scalars["String"]["output"];
  orgLevelFeature?: Maybe<Scalars["Boolean"]["output"]>;
  permissionName: FeaturePermission;
};

export type FeatureAccessAddon = {
  __typename?: "FeatureAccessAddon";
  addOn?: Maybe<Scalars["String"]["output"]>;
  billableUsers?: Maybe<Scalars["Long"]["output"]>;
  chargePeriod?: Maybe<Scalars["String"]["output"]>;
  chargeType?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["CountryCode"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  orgLevelFeature?: Maybe<Scalars["Boolean"]["output"]>;
  permissionName?: Maybe<FeaturePermission>;
  totalPrice?: Maybe<Scalars["Long"]["output"]>;
  unitPrice?: Maybe<Scalars["Long"]["output"]>;
};

export type FeatureAddonQueryPayload = {
  __typename?: "FeatureAddonQueryPayload";
  features: Array<FeatureAccessAddon>;
};

export type FeaturePackage = {
  __typename?: "FeaturePackage";
  description: Scalars["String"]["output"];
  features?: Maybe<Array<Maybe<Feature>>>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name: Scalars["String"]["output"];
  packageFeatures: Array<Feature>;
  price: Scalars["Int"]["output"];
  status: FeaturePackageStatus;
};

export type FeaturePackagePlan = {
  __typename?: "FeaturePackagePlan";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  plan?: Maybe<Scalars["String"]["output"]>;
};

export type FeaturePackagePlanQueryParams = {
  featurePackageId?: InputMaybe<Scalars["Long"]["input"]>;
  organisationId?: InputMaybe<Scalars["Long"]["input"]>;
};

export type FeaturePackagePlanUpdateParams = {
  addOnIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  featurePackageId?: InputMaybe<Scalars["Long"]["input"]>;
  featurePackagePlanId?: InputMaybe<Scalars["Long"]["input"]>;
  organisationId?: InputMaybe<Scalars["Long"]["input"]>;
};

export enum FeaturePackageStatus {
  Deprecated = "DEPRECATED",
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export enum FeaturePermission {
  AccBilling = "ACC_BILLING",
  AccPurchaseOrders = "ACC_PURCHASE_ORDERS",
  AuditTrail = "AUDIT_TRAIL",
  BookingSystem = "BOOKING_SYSTEM",
  ClientCustomSearches = "CLIENT_CUSTOM_SEARCHES",
  ClientInvite = "CLIENT_INVITE",
  ClientManagement = "CLIENT_MANAGEMENT",
  ClientTagging = "CLIENT_TAGGING",
  CustomReports = "CUSTOM_REPORTS",
  DocumentUpload = "DOCUMENT_UPLOAD",
  ErmsOnline = "ERMS_ONLINE",
  Extracts = "EXTRACTS",
  FhirApiAccess = "FHIR_API_ACCESS",
  Groups = "GROUPS",
  Insights = "INSIGHTS",
  KriosMigration = "KRIOS_MIGRATION",
  NotedIdAuthentication = "NOTED_ID_AUTHENTICATION",
  Outcomes = "OUTCOMES",
  Primhd = "PRIMHD",
  RecordKeeping = "RECORD_KEEPING",
  RelationshipManagement = "RELATIONSHIP_MANAGEMENT",
  Reports = "REPORTS",
  TeamAccessControls = "TEAM_ACCESS_CONTROLS",
  TeamAdministration = "TEAM_ADMINISTRATION",
  Telehealth = "TELEHEALTH",
  Test = "TEST",
  UserAccessControl = "USER_ACCESS_CONTROL",
  Whanau = "WHANAU",
}

export type FeatureQueryPayload = {
  __typename?: "FeatureQueryPayload";
  features: Array<Feature>;
};

export type FeatureRoleAccess = {
  __typename?: "FeatureRoleAccess";
  feature?: Maybe<Feature>;
};

export enum FormType {
  ClientSession = "CLIENT_SESSION",
  Consultation = "CONSULTATION",
  GenericTabEntry = "GENERIC_TAB_ENTRY",
  GroupSession = "GROUP_SESSION",
  GroupSessionIssue = "GROUP_SESSION_ISSUE",
  Letter = "LETTER",
  LetterIssue = "LETTER_ISSUE",
  MedicalChecklist = "MEDICAL_CHECKLIST",
  Note = "NOTE",
  Question = "QUESTION",
  Referral = "REFERRAL",
  Report = "REPORT",
}

export type Group = {
  __typename?: "Group";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Long"]["output"];
  location?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type GroupQueryParams = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type GroupQueryPayload = PagingResult & {
  __typename?: "GroupQueryPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<Group>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type InvoiceInfo = {
  __typename?: "InvoiceInfo";
  amountDue?: Maybe<Scalars["Long"]["output"]>;
  amountPaid?: Maybe<Scalars["Long"]["output"]>;
  dueDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  id: Scalars["String"]["output"];
  lineItems?: Maybe<Array<Maybe<InvoiceLineItemInfo>>>;
  status?: Maybe<InvoiceStatus>;
};

export type InvoiceLineItemInfo = {
  __typename?: "InvoiceLineItemInfo";
  amount?: Maybe<Scalars["Long"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  from?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["OffsetDateTime"]["output"]>;
};

export type InvoiceQueryParams = {
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  pageOffset?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type InvoiceQueryPayload = {
  __typename?: "InvoiceQueryPayload";
  count: Scalars["Int"]["output"];
  dateFrom?: Maybe<Scalars["String"]["output"]>;
  pageOffset?: Maybe<Scalars["String"]["output"]>;
  pages: Scalars["Int"]["output"];
  results: Array<InvoiceInfo>;
  size: Scalars["Int"]["output"];
  sortDir?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export enum InvoiceStatus {
  NotPaid = "NOT_PAID",
  Paid = "PAID",
  PaymentDue = "PAYMENT_DUE",
  Pending = "PENDING",
  Posted = "POSTED",
  Voided = "VOIDED",
  Unknown = "_UNKNOWN",
}

export type LetterSender = {
  __typename?: "LetterSender";
  address?: Maybe<Scalars["String"]["output"]>;
  clinicId?: Maybe<Scalars["Long"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  sender?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["Long"]["output"]>;
};

export type LetterSignature = {
  __typename?: "LetterSignature";
  address?: Maybe<Scalars["String"]["output"]>;
  clinicId?: Maybe<Scalars["Long"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["Long"]["output"]>;
};

export type LetterTemplate = {
  __typename?: "LetterTemplate";
  clinicId: Scalars["Long"]["output"];
  content?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
};

export type Location = {
  __typename?: "Location";
  attributes?: Maybe<Array<Attribute>>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organisation?: Maybe<Organisation>;
  services?: Maybe<Array<Service>>;
};

export type LocationCollision = {
  __typename?: "LocationCollision";
  id: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
};

export type LocationPayload = {
  __typename?: "LocationPayload";
  location?: Maybe<Location>;
};

export enum LoginResultType {
  AccountSuspended = "ACCOUNT_SUSPENDED",
  EulaNotAgreed = "EULA_NOT_AGREED",
  IncorrectCredentials = "INCORRECT_CREDENTIALS",
  LockedAccount = "LOCKED_ACCOUNT",
  Success = "SUCCESS",
}

export type Modality = {
  __typename?: "Modality";
  id?: Maybe<Scalars["Long"]["output"]>;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  addAllWorkersToRole?: Maybe<UserSecurityRole>;
  arriveAtAppointment: AppointmentPayload;
  assignDefaultTeamsToUser?: Maybe<User>;
  assignFeaturesToRole?: Maybe<UserSecurityRole>;
  assignModalityToTeam?: Maybe<Team>;
  assignPrivilegesToRole?: Maybe<UserSecurityRole>;
  assignRolesToPrivilege?: Maybe<Privilege>;
  assignRolesToUser?: Maybe<User>;
  assignTeamsToTags?: Maybe<ClientTag>;
  assignTeamsToTrackingForm?: Maybe<ClinicTab>;
  assignTeamsToUser?: Maybe<User>;
  assignUsersToRole?: Maybe<UserSecurityRole>;
  assignUsersToTeam?: Maybe<Team>;
  authenticate?: Maybe<AuthenticationResult>;
  bulkReassignRecords: BulkReassignRecordsPayload;
  cancelAppointment: AppointmentPayload;
  cancelReportExport?: Maybe<CancelReportExportPayload>;
  closePrimhdTeam?: Maybe<PrimhdTeam>;
  completeAppointment: AppointmentPayload;
  createAppointment?: Maybe<CreateAppointmentPayload>;
  createClient?: Maybe<ClientPayload>;
  createClientStatusReason?: Maybe<CreateClientStatusReasonPayload>;
  createClientTag?: Maybe<CreateClientTagPayload>;
  createLetterSender?: Maybe<LetterSender>;
  createLetterSignature?: Maybe<LetterSignature>;
  createLetterTemplate?: Maybe<LetterTemplate>;
  createLocation?: Maybe<LocationPayload>;
  createOrganisationDetails?: Maybe<PrimhdOrganisationDetails>;
  createOutcome?: Maybe<CreateOutcomePayload>;
  createOutcomeReason?: Maybe<CreateOutcomeReasonPayload>;
  createPrimhdTeam?: Maybe<PrimhdTeam>;
  createRecordType?: Maybe<CreateRecordTypePayload>;
  createRelationshipType?: Maybe<CreateRelationshipTypePayload>;
  createResourceAdjustmentPeriod: ResourceAdjustmentPeriodPayload;
  createService?: Maybe<ServicePayload>;
  createTag?: Maybe<ClientTag>;
  createTeam?: Maybe<Team>;
  createUser?: Maybe<CreateUserPayload>;
  createUserRole: UserSecurityRole;
  createWorkingHours?: Maybe<CreateWorkingHoursPayload>;
  deleteAccUserDetails: SuccessPayload;
  deleteAppointment: SuccessPayload;
  deleteLetterSender: SuccessPayload;
  deleteLetterSignature: SuccessPayload;
  deleteLetterTemplate: SuccessPayload;
  deleteLocation: SuccessPayload;
  deleteOrganisationDetails: SuccessPayload;
  deletePrimhdTeam: SuccessPayload;
  deleteReportExport: SuccessPayload;
  deleteResourceAdjustmentPeriod?: Maybe<DeleteResourceAdjustmentPeriodPayload>;
  deleteService: SuccessPayload;
  deleteTeam: SuccessPayload;
  deleteUser: SuccessPayload;
  deleteUserRole: SuccessPayload;
  payAppointment: AppointmentPayload;
  removeAllWorkersFromRole?: Maybe<UserSecurityRole>;
  removeModalityFromTeam?: Maybe<Team>;
  resendAppointmentEmail: SuccessPayload;
  unassignFeaturesFromRole?: Maybe<UserSecurityRole>;
  unassignPrivilegesFromRole?: Maybe<UserSecurityRole>;
  unassignRolesFromPrivilege?: Maybe<Privilege>;
  unassignRolesFromUser?: Maybe<User>;
  unassignTeamsFromTags?: Maybe<ClientTag>;
  unassignTeamsFromTrackingForm?: Maybe<ClinicTab>;
  unassignTeamsFromUser?: Maybe<User>;
  unassignUsersFromRole?: Maybe<UserSecurityRole>;
  unassignUsersFromTeam?: Maybe<Team>;
  updateAccOrganisationDetails?: Maybe<AccOrganisationDetails>;
  updateAccUserDetails?: Maybe<AccUserDetails>;
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  updateBillingDetails?: Maybe<BillingDetailsDto>;
  updateClient?: Maybe<ClientPayload>;
  updateClientStatusReason?: Maybe<UpdateClientStatusReasonPayload>;
  updateClientTag?: Maybe<UpdateClientTagPayload>;
  updateDisabledFeaturesForRole?: Maybe<UserSecurityRole>;
  updateKeystore: SuccessPayload;
  updateLetterSender?: Maybe<LetterSender>;
  updateLetterSignature?: Maybe<LetterSignature>;
  updateLetterTemplate?: Maybe<LetterTemplate>;
  updateLocation?: Maybe<LocationPayload>;
  updateNotificationSettings?: Maybe<NotificationSettingsPayload>;
  updateOrganisation: OrganisationPayload;
  updateOrganisationAddonFeatures?: Maybe<FeatureQueryPayload>;
  updateOrganisationDetails?: Maybe<PrimhdOrganisationDetails>;
  updateOrganisationFeatures?: Maybe<FeatureQueryPayload>;
  updateOrganisationPackage?: Maybe<FeaturePackage>;
  updateOrganisationTimezone?: Maybe<OrganisationPayload>;
  updateOrganisationUserAccessSettings: OrganisationUserAccessSettings;
  updateOutcome?: Maybe<UpdateOutcomePayload>;
  updateOutcomeReason?: Maybe<UpdateOutcomeReasonPayload>;
  updatePackagePlan?: Maybe<FeaturePackagePlan>;
  updatePrimhdTeam?: Maybe<PrimhdTeam>;
  updateRecordType?: Maybe<UpdateRecordTypePayload>;
  updateRelationshipType?: Maybe<UpdateRelationshipTypePayload>;
  updateResourceAdjustmentPeriod: ResourceAdjustmentPeriodPayload;
  updateRolesForUser?: Maybe<User>;
  updateService?: Maybe<ServicePayload>;
  updateTag?: Maybe<ClientTag>;
  updateTeam?: Maybe<Team>;
  updateTrackingForm?: Maybe<ClinicTab>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserAttributes?: Maybe<UpdateUserPayload>;
  updateUserPassword?: Maybe<UpdateUserPayload>;
  updateUserRole: UserSecurityRole;
  updateUserSites?: Maybe<UpdateUserPayload>;
  updateUsersTeamAssignment?: Maybe<Team>;
  updateWorkerToServices: UpdateWorkerToServicesPayload;
  updateWorkingHours?: Maybe<UpdateWorkingHoursPayload>;
  upgradeAccount?: Maybe<UpgradePayload>;
};

export type MutationAddAllWorkersToRoleArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationArriveAtAppointmentArgs = {
  input: ArriveAtAppointmentInput;
};

export type MutationAssignDefaultTeamsToUserArgs = {
  input: AssignTeamsToUserInput;
};

export type MutationAssignFeaturesToRoleArgs = {
  input: AssignFeaturesToRoleInput;
};

export type MutationAssignModalityToTeamArgs = {
  input: AssignModalityToTeamInput;
};

export type MutationAssignPrivilegesToRoleArgs = {
  input: AssignPrivilegesToRoleInput;
};

export type MutationAssignRolesToPrivilegeArgs = {
  input?: InputMaybe<AssignRolesToPrivilegeInput>;
};

export type MutationAssignRolesToUserArgs = {
  input: AssignRolesToUserInput;
};

export type MutationAssignTeamsToTagsArgs = {
  input: AssignTeamsToTagInput;
};

export type MutationAssignTeamsToTrackingFormArgs = {
  input: AssignTeamToTrackingFormsInput;
};

export type MutationAssignTeamsToUserArgs = {
  input: AssignTeamsToUserInput;
};

export type MutationAssignUsersToRoleArgs = {
  input: AssignUsersToRoleInput;
};

export type MutationAssignUsersToTeamArgs = {
  input: AssignUsersToTeamInput;
};

export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
};

export type MutationBulkReassignRecordsArgs = {
  input: BulkReassignRecordsInput;
};

export type MutationCancelAppointmentArgs = {
  input: CancelAppointmentInput;
};

export type MutationCancelReportExportArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationClosePrimhdTeamArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationCompleteAppointmentArgs = {
  input: CompleteAppointmentInput;
};

export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationCreateClientArgs = {
  input: CreateClientInput;
};

export type MutationCreateClientStatusReasonArgs = {
  input: CreateClientStatusReasonInput;
};

export type MutationCreateClientTagArgs = {
  input: CreateClientTagInput;
};

export type MutationCreateLetterSenderArgs = {
  input?: InputMaybe<CreateLetterSenderInput>;
};

export type MutationCreateLetterSignatureArgs = {
  input?: InputMaybe<CreateLetterSignatureInput>;
};

export type MutationCreateLetterTemplateArgs = {
  input: CreateLetterTemplateInput;
};

export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateOrganisationDetailsArgs = {
  input: CreatePrimhdOrganisationDetailsInput;
};

export type MutationCreateOutcomeArgs = {
  input: CreateOutcomeInput;
};

export type MutationCreateOutcomeReasonArgs = {
  input: CreateOutcomeReasonInput;
};

export type MutationCreatePrimhdTeamArgs = {
  input: CreatePrimhdTeamInput;
};

export type MutationCreateRecordTypeArgs = {
  input: CreateRecordTypeInput;
};

export type MutationCreateRelationshipTypeArgs = {
  input: CreateRelationshipTypeInput;
};

export type MutationCreateResourceAdjustmentPeriodArgs = {
  input: CreateResourceAdjustmentPeriodInput;
};

export type MutationCreateServiceArgs = {
  input: CreateServiceInput;
};

export type MutationCreateTagArgs = {
  input: TeamTagsUpdateInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};

export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};

export type MutationCreateWorkingHoursArgs = {
  input: CreateWorkingHoursInput;
};

export type MutationDeleteAccUserDetailsArgs = {
  userId: Scalars["Long"]["input"];
};

export type MutationDeleteAppointmentArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteLetterSenderArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteLetterSignatureArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteLetterTemplateArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteLocationArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteOrganisationDetailsArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeletePrimhdTeamArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteReportExportArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteResourceAdjustmentPeriodArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteServiceArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteTeamArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationDeleteUserRoleArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationPayAppointmentArgs = {
  input: PayAppointmentInput;
};

export type MutationRemoveAllWorkersFromRoleArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationRemoveModalityFromTeamArgs = {
  input: RemoveModalityFromTeamInput;
};

export type MutationResendAppointmentEmailArgs = {
  input: AppointmentEmailInput;
};

export type MutationUnassignFeaturesFromRoleArgs = {
  input: AssignFeaturesToRoleInput;
};

export type MutationUnassignPrivilegesFromRoleArgs = {
  input: AssignPrivilegesToRoleInput;
};

export type MutationUnassignRolesFromPrivilegeArgs = {
  input?: InputMaybe<AssignRolesToPrivilegeInput>;
};

export type MutationUnassignRolesFromUserArgs = {
  input: AssignRolesToUserInput;
};

export type MutationUnassignTeamsFromTagsArgs = {
  input: AssignTeamsToTagInput;
};

export type MutationUnassignTeamsFromTrackingFormArgs = {
  input: AssignTeamToTrackingFormsInput;
};

export type MutationUnassignTeamsFromUserArgs = {
  input: AssignTeamsToUserInput;
};

export type MutationUnassignUsersFromRoleArgs = {
  input: AssignUsersToRoleInput;
};

export type MutationUnassignUsersFromTeamArgs = {
  input: AssignUsersToTeamInput;
};

export type MutationUpdateAccOrganisationDetailsArgs = {
  input: AccOrganisationDetailsInput;
};

export type MutationUpdateAccUserDetailsArgs = {
  input: UpdateAccUserDetailsInput;
};

export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};

export type MutationUpdateBillingDetailsArgs = {
  input: BillingDetailsInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateClientStatusReasonArgs = {
  input: UpdateClientStatusReasonInput;
};

export type MutationUpdateClientTagArgs = {
  input: UpdateClientTagInput;
};

export type MutationUpdateDisabledFeaturesForRoleArgs = {
  input: AssignFeaturesToRoleInput;
};

export type MutationUpdateKeystoreArgs = {
  input: AccKeystoreFileInput;
};

export type MutationUpdateLetterSenderArgs = {
  input: UpdateLetterSenderInput;
};

export type MutationUpdateLetterSignatureArgs = {
  input: UpdateLetterSignatureInput;
};

export type MutationUpdateLetterTemplateArgs = {
  input: UpdateLetterTemplateInput;
};

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};

export type MutationUpdateNotificationSettingsArgs = {
  input: NotificationSettingsInput;
};

export type MutationUpdateOrganisationArgs = {
  input: OrganisationInput;
};

export type MutationUpdateOrganisationAddonFeaturesArgs = {
  features: Array<InputMaybe<Scalars["Long"]["input"]>>;
};

export type MutationUpdateOrganisationDetailsArgs = {
  input: UpdatePrimhdOrganisationDetailsInput;
};

export type MutationUpdateOrganisationFeaturesArgs = {
  features: Array<UpdateOrganisationFeaturesInput>;
};

export type MutationUpdateOrganisationPackageArgs = {
  id: Scalars["Long"]["input"];
};

export type MutationUpdateOrganisationTimezoneArgs = {
  input: OrganisationInput;
};

export type MutationUpdateOrganisationUserAccessSettingsArgs = {
  input: UpdateOrganisationUserAccessInput;
};

export type MutationUpdateOutcomeArgs = {
  input: UpdateOutcomeInput;
};

export type MutationUpdateOutcomeReasonArgs = {
  input: UpdateOutcomeReasonInput;
};

export type MutationUpdatePackagePlanArgs = {
  input?: InputMaybe<FeaturePackagePlanUpdateParams>;
};

export type MutationUpdatePrimhdTeamArgs = {
  input: UpdatePrimhdTeamInput;
};

export type MutationUpdateRecordTypeArgs = {
  input: UpdateRecordTypeInput;
};

export type MutationUpdateRelationshipTypeArgs = {
  input: UpdateRelationshipTypeInput;
};

export type MutationUpdateResourceAdjustmentPeriodArgs = {
  input: UpdateResourceAdjustmentPeriodInput;
};

export type MutationUpdateRolesForUserArgs = {
  input: AssignRolesToUserInput;
};

export type MutationUpdateServiceArgs = {
  input: UpdateServiceInput;
};

export type MutationUpdateTagArgs = {
  input: TeamTagsUpdateInput;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};

export type MutationUpdateTrackingFormArgs = {
  input: TrackingFormUpdateInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserAttributesArgs = {
  input: UpdateUserAttributesInput;
};

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserRoleArgs = {
  input?: InputMaybe<UpdateUserRoleInput>;
};

export type MutationUpdateUserSitesArgs = {
  input: UpdateUserSitesInput;
};

export type MutationUpdateUsersTeamAssignmentArgs = {
  input: UpdateUsersTeamAssignmentInput;
};

export type MutationUpdateWorkerToServicesArgs = {
  input: UpdateWorkerToServicesInput;
};

export type MutationUpdateWorkingHoursArgs = {
  input: UpdateWorkingHoursInput;
};

export type MutationUpgradeAccountArgs = {
  input: UpgradeDetailsInput;
};

export type NotificationSettings = {
  __typename?: "NotificationSettings";
  emailAddress?: Maybe<Scalars["String"]["output"]>;
  notifyAnHourBefore?: Maybe<Scalars["Boolean"]["output"]>;
  notifyInAdvance?: Maybe<Scalars["Boolean"]["output"]>;
  notifyOnCancel?: Maybe<Scalars["Boolean"]["output"]>;
  notifyOnCreate?: Maybe<Scalars["Boolean"]["output"]>;
  notifyOnUpdate?: Maybe<Scalars["Boolean"]["output"]>;
  organisation?: Maybe<Organisation>;
};

export type NotificationSettingsInput = {
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  notifyAnHourBefore: Scalars["Boolean"]["input"];
  notifyInAdvance: Scalars["Boolean"]["input"];
  notifyOnCancel: Scalars["Boolean"]["input"];
  notifyOnCreate: Scalars["Boolean"]["input"];
  notifyOnUpdate: Scalars["Boolean"]["input"];
};

export type NotificationSettingsPayload = {
  __typename?: "NotificationSettingsPayload";
  settings?: Maybe<NotificationSettings>;
};

export type Organisation = {
  __typename?: "Organisation";
  accDetails?: Maybe<AccOrganisationDetails>;
  address?: Maybe<Scalars["String"]["output"]>;
  adminSuspended?: Maybe<Scalars["Boolean"]["output"]>;
  billingLinkId?: Maybe<Scalars["String"]["output"]>;
  billingSystemSuspended?: Maybe<Scalars["Boolean"]["output"]>;
  clientCount?: Maybe<Scalars["Int"]["output"]>;
  clients?: Maybe<Array<Client>>;
  countryCode?: Maybe<Scalars["CountryCode"]["output"]>;
  created?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  currencyCode?: Maybe<Scalars["CurrencyCode"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  emailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  featurePackage: FeaturePackage;
  gstRegistered?: Maybe<Scalars["Boolean"]["output"]>;
  healthLinkKeystorePasswordStored?: Maybe<Scalars["Boolean"]["output"]>;
  healthLinkKeystoreStored?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  legalName: Scalars["String"]["output"];
  letterSender?: Maybe<LetterSender>;
  letterSignature?: Maybe<LetterSignature>;
  manualBilling?: Maybe<Scalars["Boolean"]["output"]>;
  notificationSettings?: Maybe<NotificationSettings>;
  phone?: Maybe<Scalars["String"]["output"]>;
  readOnlyMode?: Maybe<Scalars["Boolean"]["output"]>;
  services?: Maybe<Array<Service>>;
  sessionTimeout: Scalars["Int"]["output"];
  settingsJson?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  tradingName?: Maybe<Scalars["String"]["output"]>;
  trialEnd?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  updated?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  users?: Maybe<Array<User>>;
};

export type OrganisationInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  adminSuspended?: InputMaybe<Scalars["Boolean"]["input"]>;
  billingLinkId?: InputMaybe<Scalars["String"]["input"]>;
  billingSystemSuspended?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryCode?: InputMaybe<Scalars["CountryCode"]["input"]>;
  currencyCode?: InputMaybe<Scalars["CurrencyCode"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  gstRegistered?: InputMaybe<Scalars["Boolean"]["input"]>;
  healthLinkKeystorePasswordStored?: InputMaybe<Scalars["Boolean"]["input"]>;
  healthLinkKeystoreStored?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Long"]["input"];
  legalName?: InputMaybe<Scalars["String"]["input"]>;
  letterSender?: InputMaybe<UpdateLetterSenderInput>;
  letterSignature?: InputMaybe<UpdateLetterSignatureInput>;
  manualBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sessionTimeout?: InputMaybe<Scalars["Int"]["input"]>;
  settingsJson?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  tradingName?: InputMaybe<Scalars["String"]["input"]>;
  trialEnd?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type OrganisationPayload = {
  __typename?: "OrganisationPayload";
  modalities?: Maybe<Array<Maybe<Modality>>>;
  organisation: Organisation;
  readOnlyMode?: Maybe<Scalars["Boolean"]["output"]>;
  userRoles?: Maybe<Array<Maybe<UserSecurityRole>>>;
};

export type OrganisationUserAccessSettings = {
  __typename?: "OrganisationUserAccessSettings";
  clientsRestrictedByDefault: Scalars["Boolean"]["output"];
  id?: Maybe<Scalars["Long"]["output"]>;
  organisation?: Maybe<Organisation>;
  recordsRestrictedByDefault: Scalars["Boolean"]["output"];
};

export type OrganisationWithSubscriptionPayload = {
  __typename?: "OrganisationWithSubscriptionPayload";
  addons: Array<FeatureAccessAddon>;
  features?: Maybe<Array<Maybe<AvailableFeature>>>;
  modalities?: Maybe<Array<Maybe<Modality>>>;
  organisation: Organisation;
  readOnlyMode?: Maybe<Scalars["Boolean"]["output"]>;
  subscription: SubscriptionInfo;
  userRoles?: Maybe<Array<Maybe<UserSecurityRole>>>;
};

export type Outcome = {
  __typename?: "Outcome";
  description?: Maybe<Scalars["String"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name: Scalars["String"]["output"];
};

export type OutcomeReason = {
  __typename?: "OutcomeReason";
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
  type: OutcomeReasonType;
};

export enum OutcomeReasonType {
  Added = "ADDED",
  Removed = "REMOVED",
}

export type PagingResult = {
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type PayAppointmentInput = {
  appointmentId: Scalars["Long"]["input"];
  paidAt?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
};

export type PlanInfo = {
  __typename?: "PlanInfo";
  chargeModel?: Maybe<ChargeModel>;
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<PlanStatus>;
  totalPrice?: Maybe<Scalars["Long"]["output"]>;
  unitPrice?: Maybe<Scalars["Long"]["output"]>;
};

export enum PlanStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Deleted = "DELETED",
  Unknown = "_UNKNOWN",
}

export type PrimhdOrganisationDetails = {
  __typename?: "PrimhdOrganisationDetails";
  folder?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  organisationId?: Maybe<Scalars["String"]["output"]>;
  organisationName?: Maybe<Scalars["String"]["output"]>;
  organisationType?: Maybe<Scalars["String"]["output"]>;
};

export type PrimhdTeam = {
  __typename?: "PrimhdTeam";
  closeDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  facility?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  openDate?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  population?: Maybe<Scalars["String"]["output"]>;
  service?: Maybe<Scalars["String"]["output"]>;
  setting?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Privilege = {
  __typename?: "Privilege";
  children: Array<Privilege>;
  description: Scalars["String"]["output"];
  id: Scalars["Long"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  teams?: Maybe<Array<Team>>;
};

export type PrivilegeInput = {
  id?: InputMaybe<Scalars["Long"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  accessOverridesLog?: Maybe<AccessOverridesPayload>;
  addonFeatures: FeatureAddonQueryPayload;
  appointment: Appointment;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsForPeriod?: Maybe<Array<Maybe<Appointment>>>;
  auditTrailAggregateSearch?: Maybe<AuditTrailAggregateQueryPayload>;
  auditTrailClientUserSearch?: Maybe<AuditTrailClientUserQueryPayload>;
  auditTrailEntrySearch?: Maybe<AuditTrailEntryQueryPayload>;
  auditTrailSearch?: Maybe<AuditTrailQueryPayload>;
  availableFeatures: AvailableFeatureQueryPayload;
  billingDetails: BillingDetailsDto;
  client?: Maybe<Client>;
  clientSearch?: Maybe<ClientQueryPayload>;
  clientStatusReasons: Array<ClientStatusReason>;
  clientTags?: Maybe<Array<Maybe<ClientTag>>>;
  clients?: Maybe<Array<Maybe<Client>>>;
  duplicates: ClientDuplicatesPayload;
  editableFeatures: FeatureQueryPayload;
  featurePackagePlans?: Maybe<Array<Maybe<FeaturePackagePlan>>>;
  featurePackages: Array<FeaturePackage>;
  groups?: Maybe<GroupQueryPayload>;
  invoices: InvoiceQueryPayload;
  letterSender?: Maybe<LetterSender>;
  letterSenders?: Maybe<Array<Maybe<LetterSender>>>;
  letterSignature?: Maybe<LetterSignature>;
  letterSignatures?: Maybe<Array<Maybe<LetterSignature>>>;
  letterTemplate: LetterTemplate;
  letterTemplates: Array<LetterTemplate>;
  location?: Maybe<Location>;
  locations?: Maybe<Array<Location>>;
  notificationSettings: NotificationSettingsPayload;
  organisation?: Maybe<OrganisationPayload>;
  organisationUserAccessSettings: OrganisationUserAccessSettings;
  organisationWithSubscription: OrganisationWithSubscriptionPayload;
  organisations?: Maybe<Array<Maybe<Organisation>>>;
  outcomeReasons: Array<OutcomeReason>;
  outcomes: Array<Outcome>;
  packageChangeEstimate?: Maybe<SubscriptionInfo>;
  plan?: Maybe<PlanInfo>;
  plans?: Maybe<Array<Maybe<PlanInfo>>>;
  primhdOrganisationDetails?: Maybe<PrimhdOrganisationDetails>;
  primhdTeam?: Maybe<PrimhdTeam>;
  primhdTeams: Array<PrimhdTeam>;
  privileges: Array<Privilege>;
  recordTypes: Array<RecordType>;
  relationshipTypes: Array<RelationshipType>;
  resourceAdjustmentPeriod?: Maybe<ResourceAdjustmentPeriod>;
  resourceAdjustmentPeriodsBetween: Array<ResourceAdjustmentPeriod>;
  retrieveLatestExport?: Maybe<ReportExportSearchItem>;
  schedulingState: SchedulingStatePayload;
  searchReportExports?: Maybe<ReportExportSearchResponse>;
  services?: Maybe<Array<Service>>;
  subscriptions?: Maybe<SubscriptionInfo>;
  supervisedTeams: Array<Team>;
  tag?: Maybe<ClientTag>;
  tags?: Maybe<Array<Maybe<ClientTag>>>;
  tagsPaged?: Maybe<TeamTagsQueryPayload>;
  team: Team;
  teamUsersPaged?: Maybe<TeamQueryPayload>;
  teams?: Maybe<Array<Team>>;
  teamsRestricted?: Maybe<Array<Team>>;
  timeslots: Array<Timeslot>;
  trackingForm?: Maybe<ClinicTab>;
  trackingForms?: Maybe<Array<Maybe<ClinicTab>>>;
  trackingFormsPaged?: Maybe<TrackingFormQueryPayload>;
  upcomingAppointmentsForClient?: Maybe<Array<Appointment>>;
  user: User;
  userChangeEstimate?: Maybe<SubscriptionPayload>;
  userPrivileges: Array<Privilege>;
  userSecurityRole: UserSecurityRole;
  userSecurityRoles: Array<UserSecurityRole>;
  users: UserQueryPayload;
  workers?: Maybe<UserQueryPayload>;
  workingHours?: Maybe<Array<Maybe<WorkingHours>>>;
};

export type QueryAccessOverridesLogArgs = {
  input?: InputMaybe<AccessOverridesParams>;
};

export type QueryAppointmentArgs = {
  id?: InputMaybe<Scalars["Long"]["input"]>;
};

export type QueryAppointmentsForPeriodArgs = {
  input: DateRangeInput;
};

export type QueryAuditTrailAggregateSearchArgs = {
  input: AuditTrailSearchParams;
};

export type QueryAuditTrailClientUserSearchArgs = {
  input: ClientUserSearchRequest;
};

export type QueryAuditTrailEntrySearchArgs = {
  input: AuditTrailEntitySearchParams;
};

export type QueryAuditTrailSearchArgs = {
  input: AuditTrailSearchParams;
};

export type QueryClientArgs = {
  id?: InputMaybe<Scalars["Long"]["input"]>;
};

export type QueryClientSearchArgs = {
  input: ClientQueryParams;
};

export type QueryDuplicatesArgs = {
  input: CreateClientInput;
};

export type QueryFeaturePackagePlansArgs = {
  input?: InputMaybe<FeaturePackagePlanQueryParams>;
};

export type QueryGroupsArgs = {
  input?: InputMaybe<GroupQueryParams>;
};

export type QueryInvoicesArgs = {
  input?: InputMaybe<InvoiceQueryParams>;
};

export type QueryLetterTemplateArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryLocationArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryOrganisationArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryOrganisationWithSubscriptionArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryPackageChangeEstimateArgs = {
  estimateInput?: InputMaybe<EstimateInput>;
};

export type QueryPlanArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryResourceAdjustmentPeriodArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryResourceAdjustmentPeriodsBetweenArgs = {
  input: DateRangeInput;
};

export type QuerySearchReportExportsArgs = {
  input: ReportExportSearchParams;
};

export type QueryTagArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryTagsPagedArgs = {
  input?: InputMaybe<TeamTagsQueryParams>;
};

export type QueryTeamArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryTeamUsersPagedArgs = {
  input?: InputMaybe<TeamQueryParams>;
};

export type QueryTimeslotsArgs = {
  input?: InputMaybe<TimeslotInput>;
};

export type QueryTrackingFormArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryTrackingFormsPagedArgs = {
  input?: InputMaybe<TrackingFormQueryParams>;
};

export type QueryUpcomingAppointmentsForClientArgs = {
  clientId: Scalars["Long"]["input"];
};

export type QueryUserArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryUserChangeEstimateArgs = {
  userCountUpdate: Scalars["Int"]["input"];
};

export type QueryUserSecurityRoleArgs = {
  id: Scalars["Long"]["input"];
};

export type QueryUserSecurityRolesArgs = {
  includeSystemRoles?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryUsersArgs = {
  input: UserQueryParams;
};

export type QueryWorkingHoursArgs = {
  userId: Scalars["Long"]["input"];
};

export enum RecordSensitivity {
  Open = "OPEN",
  TeamOnly = "TEAM_ONLY",
  WorkerOnly = "WORKER_ONLY",
}

export type RecordType = {
  __typename?: "RecordType";
  description?: Maybe<Scalars["String"]["output"]>;
  formType?: Maybe<FormType>;
  id?: Maybe<Scalars["Long"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organisation?: Maybe<Organisation>;
  sortOrder?: Maybe<Scalars["Int"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RelationshipType = {
  __typename?: "RelationshipType";
  id?: Maybe<Scalars["Long"]["output"]>;
  organisation?: Maybe<Organisation>;
  personTitle?: Maybe<Scalars["String"]["output"]>;
  sortOrder?: Maybe<Scalars["Int"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoveAutoAssignUserObjectsFromTeamInput = {
  clients?: InputMaybe<Scalars["Boolean"]["input"]>;
  records?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamId: Scalars["Long"]["input"];
  teams?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId: Scalars["Long"]["input"];
};

export type RemoveModalityFromTeamInput = {
  modalityId: Scalars["Long"]["input"];
  teamId: Scalars["Long"]["input"];
};

export type ReportExportSearchItem = {
  __typename?: "ReportExportSearchItem";
  completed?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["OffsetDateTime"]["output"]>;
  downloadUrl?: Maybe<Scalars["String"]["output"]>;
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Long"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  reportId?: Maybe<Scalars["Long"]["output"]>;
  status?: Maybe<ReportExportStatus>;
  storageKey?: Maybe<Scalars["String"]["output"]>;
  urlExpires?: Maybe<Scalars["OffsetDateTime"]["output"]>;
};

export type ReportExportSearchParams = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReportExportSearchResponse = PagingResult & {
  __typename?: "ReportExportSearchResponse";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<ReportExportSearchItem>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export enum ReportExportStatus {
  Cancelled = "CANCELLED",
  Complete = "COMPLETE",
  Failed = "FAILED",
  NoData = "NO_DATA",
  Pending = "PENDING",
}

export type ResourceAdjustmentPeriod = {
  __typename?: "ResourceAdjustmentPeriod";
  createdBy: User;
  from: Scalars["OffsetDateTime"]["output"];
  id: Scalars["Long"]["output"];
  location?: Maybe<Location>;
  notes?: Maybe<Scalars["String"]["output"]>;
  to: Scalars["OffsetDateTime"]["output"];
  type: AdjustmentType;
  worker?: Maybe<User>;
};

export type ResourceAdjustmentPeriodPayload = {
  __typename?: "ResourceAdjustmentPeriodPayload";
  conflictingPeriods?: Maybe<Array<ResourceAdjustmentPeriod>>;
  resourceAdjustmentPeriod?: Maybe<ResourceAdjustmentPeriod>;
};

export type ResourceAdjustmentPeriodQueryInput = {
  dateRange: DateRangeInput;
  type: AdjustmentType;
};

export enum ResourceType {
  Location = "LOCATION",
  Worker = "WORKER",
}

export enum Result {
  Failure = "FAILURE",
  Success = "SUCCESS",
}

export type SchedulingStatePayload = {
  __typename?: "SchedulingStatePayload";
  hasTimezone: Scalars["Boolean"]["output"];
  servicesMissingResources: Array<Service>;
  servicesRequiringWorkingHours: Array<Service>;
  totalBookableServices: Scalars["Int"]["output"];
  totalServices: Scalars["Int"]["output"];
  workersRequiringWorkingHours: Array<User>;
};

export type Service = {
  __typename?: "Service";
  attributes?: Maybe<Array<Attribute>>;
  clinic?: Maybe<Organisation>;
  color?: Maybe<Scalars["String"]["output"]>;
  defaultTelehealth?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["PositiveInt"]["output"]>;
  id: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
  price?: Maybe<Scalars["PositiveFloat"]["output"]>;
  requiredSpaces?: Maybe<Scalars["Int"]["output"]>;
  requiredWorkers?: Maybe<Scalars["Int"]["output"]>;
  spaces?: Maybe<Array<Location>>;
  workers?: Maybe<Array<User>>;
};

export type ServicePayload = {
  __typename?: "ServicePayload";
  service?: Maybe<Service>;
};

export type SubscriptionAddOn = {
  __typename?: "SubscriptionAddOn";
  chargeType?: Maybe<ChargeType>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Long"]["output"]>;
  totalPrice?: Maybe<Scalars["Long"]["output"]>;
};

export type SubscriptionCoupon = {
  __typename?: "SubscriptionCoupon";
  discount?: Maybe<Scalars["Float"]["output"]>;
  discountType?: Maybe<DiscountType>;
  durationType?: Maybe<DurationType>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type SubscriptionInfo = {
  __typename?: "SubscriptionInfo";
  addOns: Array<SubscriptionAddOn>;
  chargeModel?: Maybe<ChargeModel>;
  coupons: Array<SubscriptionCoupon>;
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  monthlyCost: Scalars["Long"]["output"];
  planId?: Maybe<Scalars["String"]["output"]>;
  planName?: Maybe<Scalars["String"]["output"]>;
  planPerUnitCosts: Scalars["Long"]["output"];
  totalBillableUsers: Scalars["Int"]["output"];
  totalUsers: Scalars["Int"]["output"];
};

export type SubscriptionPayload = {
  __typename?: "SubscriptionPayload";
  addons: Array<FeatureAccessAddon>;
  features: Array<AvailableFeature>;
  subscription: SubscriptionInfo;
};

export type SuccessPayload = {
  __typename?: "SuccessPayload";
  success: Scalars["Boolean"]["output"];
};

export type Team = {
  __typename?: "Team";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Long"]["output"];
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  key: Scalars["String"]["output"];
  modality?: Maybe<Modality>;
  name: Scalars["String"]["output"];
  numberOfUsers?: Maybe<Scalars["Long"]["output"]>;
  organisation?: Maybe<Organisation>;
  purchaseOrdersEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  recordAssignments?: Maybe<Array<TeamRecordAssignment>>;
  recordSensitivity?: Maybe<RecordSensitivity>;
  users?: Maybe<Array<TeamMember>>;
};

export type TeamMember = {
  __typename?: "TeamMember";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Long"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  supervisor: Scalars["Boolean"]["output"];
};

export type TeamQueryParams = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  securityRole?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
  teamId?: InputMaybe<Scalars["Long"]["input"]>;
};

export type TeamQueryPayload = PagingResult & {
  __typename?: "TeamQueryPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  paged?: Maybe<Array<TeamMember>>;
  pages: Scalars["Int"]["output"];
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  team?: Maybe<Team>;
  total: Scalars["Long"]["output"];
};

export type TeamRecordAssignment = {
  __typename?: "TeamRecordAssignment";
  count: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
  userId: Scalars["Long"]["output"];
};

export type TeamTagsCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tagId: Scalars["Long"]["input"];
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  visible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TeamTagsQueryParams = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamTagsQueryPayload = PagingResult & {
  __typename?: "TeamTagsQueryPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<ClientTag>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type TeamTagsUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tagId: Scalars["Long"]["input"];
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  visible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TelehealthRoom = {
  __typename?: "TelehealthRoom";
  id: Scalars["Long"]["output"];
  token: Scalars["String"]["output"];
};

export type Timeslot = {
  __typename?: "Timeslot";
  clientId?: Maybe<Scalars["Long"]["output"]>;
  from: Scalars["OffsetDateTime"]["output"];
  serviceId?: Maybe<Scalars["Long"]["output"]>;
  spaceIds: Array<Scalars["Long"]["output"]>;
  to: Scalars["OffsetDateTime"]["output"];
  workerIds: Array<Scalars["Long"]["output"]>;
};

export type TimeslotInput = {
  clientId?: InputMaybe<Scalars["Long"]["input"]>;
  from: Scalars["OffsetDateTime"]["input"];
  ignoreAppointments?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  serviceId: Scalars["Long"]["input"];
  spaceIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  to: Scalars["OffsetDateTime"]["input"];
  workerIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
};

export type TrackingFormQueryParams = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrackingFormQueryPayload = PagingResult & {
  __typename?: "TrackingFormQueryPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results?: Maybe<Array<Maybe<ClinicTab>>>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type TrackingFormUpdateInput = {
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  trackingFormId: Scalars["Long"]["input"];
};

export type UpdateAccUserDetailsInput = {
  accBillingRate?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  accContractId?: InputMaybe<Scalars["String"]["input"]>;
  accProviderId?: InputMaybe<Scalars["String"]["input"]>;
  accServiceCode?: InputMaybe<Array<InputMaybe<AccServiceCodeInput>>>;
  accUnitBasedBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  accVendorId?: InputMaybe<Scalars["String"]["input"]>;
  allowedBillingMethods?: InputMaybe<Array<InputMaybe<BillingMethod>>>;
  userId: Scalars["Long"]["input"];
};

export type UpdateAppointmentInput = {
  clientId?: InputMaybe<Scalars["Long"]["input"]>;
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  forceLocations?: InputMaybe<Scalars["Boolean"]["input"]>;
  forceWorkers?: InputMaybe<Scalars["Boolean"]["input"]>;
  from?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  id: Scalars["Long"]["input"];
  locationIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  serviceId?: InputMaybe<Scalars["Long"]["input"]>;
  teamId?: InputMaybe<Scalars["Long"]["input"]>;
  telehealth?: InputMaybe<Scalars["Boolean"]["input"]>;
  workerIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
};

export type UpdateAppointmentPayload = {
  __typename?: "UpdateAppointmentPayload";
  appointment?: Maybe<Appointment>;
  locationCollisions?: Maybe<Array<Maybe<LocationCollision>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  workerCollisions?: Maybe<Array<Maybe<WorkerCollision>>>;
};

export type UpdateClientInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id: Scalars["Long"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateClientStatusReasonInput = {
  id: Scalars["Long"]["input"];
  listType: ClientStatusReasonType;
  name: Scalars["String"]["input"];
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  visible: Scalars["Boolean"]["input"];
};

export type UpdateClientStatusReasonPayload = {
  __typename?: "UpdateClientStatusReasonPayload";
  clientStatusReasons?: Maybe<Array<ClientStatusReason>>;
};

export type UpdateClientTagInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Long"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  teamIds: Array<InputMaybe<Scalars["Long"]["input"]>>;
  visible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateClientTagPayload = {
  __typename?: "UpdateClientTagPayload";
  clientTags?: Maybe<Array<Maybe<ClientTag>>>;
};

export type UpdateLetterSenderInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Long"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateLetterSignatureInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Long"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateLetterTemplateInput = {
  clinicId?: InputMaybe<Scalars["Long"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Long"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateLocationInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Long"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
};

export type UpdateOrganisationFeaturesInput = {
  enabled: Scalars["Boolean"]["input"];
  id: Scalars["Long"]["input"];
};

export type UpdateOrganisationUserAccessInput = {
  clientsRestrictedByDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  recordsRestrictedByDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateOutcomeInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Long"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateOutcomePayload = {
  __typename?: "UpdateOutcomePayload";
  outcome: Outcome;
};

export type UpdateOutcomeReasonInput = {
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Long"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<OutcomeReasonType>;
};

export type UpdateOutcomeReasonPayload = {
  __typename?: "UpdateOutcomeReasonPayload";
  outcomeReason: OutcomeReason;
};

export type UpdatePrimhdOrganisationDetailsInput = {
  folder?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Long"]["input"]>;
  organisationId?: InputMaybe<Scalars["String"]["input"]>;
  organisationName?: InputMaybe<Scalars["String"]["input"]>;
  organisationType?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePrimhdTeamInput = {
  closeDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  facility?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Long"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  openDate?: InputMaybe<Scalars["OffsetDateTime"]["input"]>;
  population?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<Scalars["String"]["input"]>;
  setting?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateRecordTypeInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  formType?: InputMaybe<FormType>;
  id: Scalars["Long"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  visible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateRecordTypePayload = {
  __typename?: "UpdateRecordTypePayload";
  recordTypes?: Maybe<Array<RecordType>>;
};

export type UpdateRelationshipTypeInput = {
  id: Scalars["Long"]["input"];
  personTitle?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  visible: Scalars["Boolean"]["input"];
};

export type UpdateRelationshipTypePayload = {
  __typename?: "UpdateRelationshipTypePayload";
  relationshipTypes: Array<RelationshipType>;
};

export type UpdateResourceAdjustmentPeriodInput = {
  from: Scalars["OffsetDateTime"]["input"];
  id: Scalars["Long"]["input"];
  locationId?: InputMaybe<Scalars["Long"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  to: Scalars["OffsetDateTime"]["input"];
  type: AdjustmentType;
  workerId?: InputMaybe<Scalars["Long"]["input"]>;
};

export type UpdateServiceInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  defaultTelehealth?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["PositiveInt"]["input"];
  id: Scalars["Long"]["input"];
  locationIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
  name: Scalars["String"]["input"];
  newLocations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  price: Scalars["PositiveFloat"]["input"];
  requiredLocations: Scalars["Int"]["input"];
  requiredWorkers: Scalars["Int"]["input"];
  workerIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
};

export type UpdateTeamInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Long"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  purchaseOrdersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  recordSensitivity?: InputMaybe<RecordSensitivity>;
};

export type UpdateUserAttributesInput = {
  attributeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id: Scalars["Long"]["input"];
};

export type UpdateUserInput = {
  dateOfBirth?: InputMaybe<Scalars["LocalDate"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Long"]["input"];
  isAdminUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  letterSender?: InputMaybe<UpdateLetterSenderInput>;
  letterSignature?: InputMaybe<UpdateLetterSignatureInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  privileges?: InputMaybe<Array<InputMaybe<PrivilegeInput>>>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  shortCode?: InputMaybe<Scalars["String"]["input"]>;
  skipBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  suspended?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  user?: Maybe<User>;
};

export type UpdateUserRoleInput = {
  description: Scalars["String"]["input"];
  id: Scalars["Long"]["input"];
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type UpdateUserSitesInput = {
  id: Scalars["Long"]["input"];
  siteIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UpdateUsersTeamAssignmentInput = {
  supervisor: Scalars["Boolean"]["input"];
  teamId: Scalars["Long"]["input"];
  userId: Scalars["Long"]["input"];
};

export type UpdateWorkerToServicesInput = {
  serviceIds?: InputMaybe<Array<Scalars["Long"]["input"]>>;
  workerId: Scalars["Long"]["input"];
};

export type UpdateWorkerToServicesPayload = {
  __typename?: "UpdateWorkerToServicesPayload";
  worker?: Maybe<User>;
};

export type UpdateWorkingHoursInput = {
  endTime: Scalars["OffsetTime"]["input"];
  id: Scalars["Long"]["input"];
  startTime: Scalars["OffsetTime"]["input"];
  weekday: Scalars["Int"]["input"];
  working: Scalars["Boolean"]["input"];
};

export type UpdateWorkingHoursPayload = {
  __typename?: "UpdateWorkingHoursPayload";
  workingHours?: Maybe<WorkingHours>;
};

export type UpgradeDetailsInput = {
  addOnIds?: InputMaybe<Array<InputMaybe<Scalars["Long"]["input"]>>>;
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  addressLine3?: InputMaybe<Scalars["String"]["input"]>;
  cardToken?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  packageId?: InputMaybe<Scalars["Long"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpgradePayload = {
  __typename?: "UpgradePayload";
  addons?: Maybe<Array<Maybe<FeatureAccessAddon>>>;
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  addressLine3?: Maybe<Scalars["String"]["output"]>;
  cardToken?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  features?: Maybe<Array<Maybe<AvailableFeature>>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type User = {
  __typename?: "User";
  accUserDetails?: Maybe<AccUserDetails>;
  appointments?: Maybe<Array<Appointment>>;
  attributes?: Maybe<Array<Attribute>>;
  authenticationDetails?: Maybe<AuthenticationDetails>;
  dateOfBirth?: Maybe<Scalars["LocalDate"]["output"]>;
  defaultTeams?: Maybe<Array<Team>>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  isAdminUser?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  latestEulaAgreed?: Maybe<Scalars["Boolean"]["output"]>;
  letterSender?: Maybe<LetterSender>;
  letterSignature?: Maybe<LetterSignature>;
  name?: Maybe<Scalars["String"]["output"]>;
  organisation?: Maybe<Organisation>;
  phone?: Maybe<Scalars["String"]["output"]>;
  privileges?: Maybe<Array<Privilege>>;
  registrationNumber?: Maybe<Scalars["String"]["output"]>;
  securityRoles?: Maybe<Array<UserSecurityRole>>;
  services?: Maybe<Array<Service>>;
  settingsJson?: Maybe<Scalars["String"]["output"]>;
  shortCode?: Maybe<Scalars["String"]["output"]>;
  skipBilling?: Maybe<Scalars["Boolean"]["output"]>;
  suspended?: Maybe<Scalars["Boolean"]["output"]>;
  teams?: Maybe<Array<Team>>;
  title?: Maybe<Scalars["String"]["output"]>;
  workingHours?: Maybe<Array<WorkingHours>>;
};

export type UserQueryParams = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  securityRole?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  sortAttr?: InputMaybe<Scalars["String"]["input"]>;
  sortDir?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserQueryPayload = PagingResult & {
  __typename?: "UserQueryPayload";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
  results: Array<User>;
  searchterm?: Maybe<Scalars["String"]["output"]>;
  size: Scalars["Int"]["output"];
  sortattr?: Maybe<Scalars["String"]["output"]>;
  sortdir?: Maybe<Scalars["String"]["output"]>;
  total: Scalars["Long"]["output"];
};

export type UserSecurityRole = {
  __typename?: "UserSecurityRole";
  defaultRole?: Maybe<Scalars["Boolean"]["output"]>;
  description: Scalars["String"]["output"];
  disabledFeatures?: Maybe<Array<Maybe<FeatureRoleAccess>>>;
  id: Scalars["Long"]["output"];
  isSystem?: Maybe<Scalars["Boolean"]["output"]>;
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  privileges?: Maybe<Array<Privilege>>;
  users?: Maybe<Array<User>>;
};

export type UserSecurityRoleInput = {
  id: Scalars["Long"]["input"];
  key: Scalars["String"]["input"];
};

export type WorkerCollision = {
  __typename?: "WorkerCollision";
  id: Scalars["Long"]["output"];
  name: Scalars["String"]["output"];
};

export type WorkingHours = {
  __typename?: "WorkingHours";
  endTime?: Maybe<Scalars["OffsetTime"]["output"]>;
  id?: Maybe<Scalars["Long"]["output"]>;
  startTime?: Maybe<Scalars["OffsetTime"]["output"]>;
  weekday?: Maybe<Scalars["Int"]["output"]>;
  working?: Maybe<Scalars["Boolean"]["output"]>;
};

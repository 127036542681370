import { css } from "@emotion/core";
import { forwardRef, ReactNode } from "react";
import type {
  RadioGroupProps as ReactAriaRadioGroupProps,
  RadioProps as ReactAriaRadioProps,
} from "react-aria-components";
import { Radio as ReactAriaRadio, RadioGroup as ReactAriaRadioGroup } from "react-aria-components";
import { FieldError } from "react-hook-form";

import { ncTheme } from "../nc-theme";
import { ncCheckboxStyles } from "./nc-checkbox";
import { ncCheckboxGroupStyles } from "./nc-checkbox-group";
import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { NcLabel, ncLabelStyles } from "./nc-label";

export interface NcRadioGroupProps extends ReactAriaRadioGroupProps {
  options: Array<{
    label: string;
    value: string;
  }>;
  label: string;
  hideLabel?: boolean;
  description?: string;
  error?: FieldError;
  variant?: NcFieldLayoutProps["variant"];
}

export const ncRadioGroupStyles = {
  wrap: css`
    ${ncCheckboxStyles.wrap};
  `,
  radio: css`
    ${ncCheckboxStyles.checkbox};
    border-radius: ${ncTheme.borderRadius.rounded};
  `,
  label: css`
    ${ncLabelStyles};
    [data-disabled] > & {
      cursor: not-allowed;
    }
  `,
};

export const NcRadioGroup = forwardRef<HTMLDivElement, NcRadioGroupProps>(
  (
    { label, hideLabel, description, options, error, isRequired, children, variant, ...props },
    ref
  ) => (
    <ReactAriaRadioGroup data-nc="NcRadioGroup" css={ncCheckboxGroupStyles} ref={ref} {...props}>
      <NcFieldLayout variant="vertical">
        <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
          {label}
        </NcLabel>
        <NcFieldLayout variant={variant}>
          {children ? (
            <>{children}</>
          ) : (
            options.map(option => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))
          )}
        </NcFieldLayout>
        {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
        <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
      </NcFieldLayout>
    </ReactAriaRadioGroup>
  )
);

interface RadioProps extends ReactAriaRadioProps {
  children: ReactNode;
}

const Radio = forwardRef<HTMLLabelElement, RadioProps>(({ children, ...props }, ref) => {
  return (
    <ReactAriaRadio data-nc="NcRadio" ref={ref} css={ncRadioGroupStyles.wrap} {...props}>
      <div css={ncRadioGroupStyles.radio}></div>
      <div css={ncRadioGroupStyles.label}>{children}</div>
    </ReactAriaRadio>
  );
});

import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { customFetcher } from "~/graphql-hooks/custom-fetcher";
import * as Types from "~/graphql-hooks/types";

export type GetOrganisationUsersQueryVariables = Types.Exact<{
  input: Types.UserQueryParams;
}>;

export type GetOrganisationUsersQuery = { __typename?: "Query" } & {
  users: { __typename?: "UserQueryPayload" } & Pick<Types.UserQueryPayload, "pages"> & {
      results: Array<
        { __typename?: "User" } & Pick<
          Types.User,
          "id" | "firstName" | "lastName" | "email" | "phone" | "suspended"
        > & {
            authenticationDetails?: Types.Maybe<
              { __typename?: "AuthenticationDetails" } & Pick<
                Types.AuthenticationDetails,
                "username"
              >
            >;
            securityRoles?: Types.Maybe<
              Array<
                { __typename?: "UserSecurityRole" } & Pick<
                  Types.UserSecurityRole,
                  "id" | "key" | "name"
                >
              >
            >;
          }
      >;
    };
};

export type UserPrivilegesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserPrivilegesQuery = { __typename?: "Query" } & {
  userPrivileges: Array<
    { __typename?: "Privilege" } & Pick<Types.Privilege, "id" | "name" | "key" | "description">
  >;
};

export type SecurityRolesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SecurityRolesQuery = { __typename?: "Query" } & {
  userSecurityRoles: Array<
    { __typename?: "UserSecurityRole" } & Pick<
      Types.UserSecurityRole,
      "id" | "name" | "key" | "description" | "defaultRole"
    > & {
        disabledFeatures?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "FeatureRoleAccess" } & {
                feature?: Types.Maybe<
                  { __typename?: "Feature" } & Pick<Types.Feature, "id" | "name">
                >;
              }
            >
          >
        >;
        privileges?: Types.Maybe<
          Array<
            { __typename?: "Privilege" } & Pick<
              Types.Privilege,
              "id" | "key" | "name" | "description"
            >
          >
        >;
      }
  >;
};

export type EditableFeaturesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EditableFeaturesQuery = { __typename?: "Query" } & {
  editableFeatures: { __typename?: "FeatureQueryPayload" } & {
    features: Array<
      { __typename?: "Feature" } & Pick<
        Types.Feature,
        "id" | "permissionName" | "name" | "description" | "enabled"
      >
    >;
  };
};

export type UserDetailsFragment = { __typename?: "User" } & Pick<
  Types.User,
  | "id"
  | "title"
  | "firstName"
  | "lastName"
  | "name"
  | "registrationNumber"
  | "phone"
  | "email"
  | "shortCode"
  | "suspended"
  | "isAdminUser"
  | "deleted"
> & {
    authenticationDetails?: Types.Maybe<
      { __typename?: "AuthenticationDetails" } & Pick<Types.AuthenticationDetails, "username">
    >;
    securityRoles?: Types.Maybe<
      Array<
        { __typename?: "UserSecurityRole" } & Pick<Types.UserSecurityRole, "id" | "name" | "key">
      >
    >;
    services?: Types.Maybe<Array<{ __typename?: "Service" } & Pick<Types.Service, "id" | "name">>>;
    privileges?: Types.Maybe<
      Array<{ __typename?: "Privilege" } & Pick<Types.Privilege, "id" | "name" | "key">>
    >;
  };

export type GetUserQueryVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type GetUserQuery = { __typename?: "Query" } & {
  user: { __typename?: "User" } & Pick<
    Types.User,
    | "id"
    | "title"
    | "firstName"
    | "lastName"
    | "name"
    | "registrationNumber"
    | "phone"
    | "email"
    | "shortCode"
    | "suspended"
    | "isAdminUser"
    | "deleted"
  > & {
      authenticationDetails?: Types.Maybe<
        { __typename?: "AuthenticationDetails" } & Pick<Types.AuthenticationDetails, "username">
      >;
      securityRoles?: Types.Maybe<
        Array<
          { __typename?: "UserSecurityRole" } & Pick<Types.UserSecurityRole, "id" | "name" | "key">
        >
      >;
      services?: Types.Maybe<
        Array<{ __typename?: "Service" } & Pick<Types.Service, "id" | "name">>
      >;
      privileges?: Types.Maybe<
        Array<{ __typename?: "Privilege" } & Pick<Types.Privilege, "id" | "name" | "key">>
      >;
    };
};

export type CreateUserMutationVariables = Types.Exact<{
  user: Types.CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: "Mutation" } & {
  createUser?: Types.Maybe<
    { __typename?: "CreateUserPayload" } & {
      user?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id">>;
    }
  >;
};

export type UserUpdateMutationVariables = Types.Exact<{
  user: Types.UpdateUserInput;
}>;

export type UserUpdateMutation = { __typename?: "Mutation" } & {
  updateUser?: Types.Maybe<
    { __typename?: "UpdateUserPayload" } & {
      user?: Types.Maybe<
        { __typename?: "User" } & Pick<
          Types.User,
          | "id"
          | "title"
          | "firstName"
          | "lastName"
          | "name"
          | "registrationNumber"
          | "phone"
          | "email"
          | "shortCode"
          | "suspended"
          | "isAdminUser"
          | "deleted"
        > & {
            authenticationDetails?: Types.Maybe<
              { __typename?: "AuthenticationDetails" } & Pick<
                Types.AuthenticationDetails,
                "username"
              >
            >;
            securityRoles?: Types.Maybe<
              Array<
                { __typename?: "UserSecurityRole" } & Pick<
                  Types.UserSecurityRole,
                  "id" | "name" | "key"
                >
              >
            >;
            services?: Types.Maybe<
              Array<{ __typename?: "Service" } & Pick<Types.Service, "id" | "name">>
            >;
            privileges?: Types.Maybe<
              Array<{ __typename?: "Privilege" } & Pick<Types.Privilege, "id" | "name" | "key">>
            >;
          }
      >;
    }
  >;
};

export type UpdateUserPasswordMutationVariables = Types.Exact<{
  user: Types.UpdateUserInput;
}>;

export type UpdateUserPasswordMutation = { __typename?: "Mutation" } & {
  updateUserPassword?: Types.Maybe<
    { __typename?: "UpdateUserPayload" } & {
      user?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id">>;
    }
  >;
};

export type UserDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type UserDeleteMutation = { __typename?: "Mutation" } & {
  deleteUser: { __typename?: "SuccessPayload" } & Pick<Types.SuccessPayload, "success">;
};

export type UpdateRolesForUserMutationVariables = Types.Exact<{
  input: Types.AssignRolesToUserInput;
}>;

export type UpdateRolesForUserMutation = { __typename?: "Mutation" } & {
  updateRolesForUser?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id">>;
};

export type WorkingHoursFragment = { __typename?: "WorkingHours" } & Pick<
  Types.WorkingHours,
  "id" | "weekday" | "working" | "startTime" | "endTime"
>;

export type GetUserWorkingHoursQueryVariables = Types.Exact<{
  userId: Types.Scalars["Long"]["input"];
}>;

export type GetUserWorkingHoursQuery = { __typename?: "Query" } & {
  workingHours?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "WorkingHours" } & Pick<
          Types.WorkingHours,
          "id" | "weekday" | "working" | "startTime" | "endTime"
        >
      >
    >
  >;
};

export type CreateUserWorkingHoursMutationVariables = Types.Exact<{
  input: Types.CreateWorkingHoursInput;
}>;

export type CreateUserWorkingHoursMutation = { __typename?: "Mutation" } & {
  createWorkingHours?: Types.Maybe<
    { __typename?: "CreateWorkingHoursPayload" } & {
      workingHours?: Types.Maybe<
        { __typename?: "WorkingHours" } & Pick<
          Types.WorkingHours,
          "id" | "weekday" | "working" | "startTime" | "endTime"
        >
      >;
    }
  >;
};

export type UpdateUserWorkingHoursMutationVariables = Types.Exact<{
  input: Types.UpdateWorkingHoursInput;
}>;

export type UpdateUserWorkingHoursMutation = { __typename?: "Mutation" } & {
  updateWorkingHours?: Types.Maybe<
    { __typename?: "UpdateWorkingHoursPayload" } & {
      workingHours?: Types.Maybe<
        { __typename?: "WorkingHours" } & Pick<
          Types.WorkingHours,
          "id" | "weekday" | "working" | "startTime" | "endTime"
        >
      >;
    }
  >;
};

export type GetUserServicesQueryVariables = Types.Exact<{
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type GetUserServicesQuery = { __typename?: "Query" } & {
  services?: Types.Maybe<
    Array<
      { __typename?: "Service" } & Types.MakeOptional<
        Pick<
          Types.Service,
          | "id"
          | "name"
          | "duration"
          | "description"
          | "price"
          | "color"
          | "requiredWorkers"
          | "requiredSpaces"
          | "defaultTelehealth"
        >,
        "defaultTelehealth"
      > & {
          workers?: Types.Maybe<
            Array<
              { __typename?: "User" } & Pick<Types.User, "id" | "firstName" | "lastName" | "email">
            >
          >;
          spaces?: Types.Maybe<
            Array<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>
          >;
        }
    >
  >;
};

export type UpdateWorkerToServicesMutationVariables = Types.Exact<{
  input: Types.UpdateWorkerToServicesInput;
}>;

export type UpdateWorkerToServicesMutation = { __typename?: "Mutation" } & {
  updateWorkerToServices: { __typename?: "UpdateWorkerToServicesPayload" } & {
    worker?: Types.Maybe<
      { __typename?: "User" } & {
        services?: Types.Maybe<
          Array<{ __typename?: "Service" } & Pick<Types.Service, "id" | "name">>
        >;
      }
    >;
  };
};

export type GetUserAccDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type GetUserAccDetailsQuery = { __typename?: "Query" } & {
  user: { __typename?: "User" } & Pick<Types.User, "id"> & {
      accUserDetails?: Types.Maybe<
        { __typename?: "AccUserDetails" } & Pick<
          Types.AccUserDetails,
          "accProviderId" | "accContractId" | "accVendorId" | "allowedBillingMethods"
        > & {
            accServiceCode?: Types.Maybe<
              Array<{ __typename?: "AccServiceCode" } & Pick<Types.AccServiceCode, "id" | "code">>
            >;
          }
      >;
    };
};

export type UpdateUserAccDetailsMutationVariables = Types.Exact<{
  input: Types.UpdateAccUserDetailsInput;
}>;

export type UpdateUserAccDetailsMutation = { __typename?: "Mutation" } & {
  updateAccUserDetails?: Types.Maybe<
    { __typename?: "AccUserDetails" } & Pick<
      Types.AccUserDetails,
      "id" | "accProviderId" | "accContractId" | "accVendorId" | "allowedBillingMethods"
    > & {
        accServiceCode?: Types.Maybe<
          Array<{ __typename?: "AccServiceCode" } & Pick<Types.AccServiceCode, "id" | "code">>
        >;
      }
  >;
};

export type DeleteUserAccDetailsMutationVariables = Types.Exact<{
  userId: Types.Scalars["Long"]["input"];
}>;

export type DeleteUserAccDetailsMutation = { __typename?: "Mutation" } & {
  deleteAccUserDetails: { __typename?: "SuccessPayload" } & Pick<Types.SuccessPayload, "success">;
};

export type UserWithTeamsFragment = { __typename?: "User" } & Pick<Types.User, "id"> & {
    teams?: Types.Maybe<
      Array<
        { __typename?: "Team" } & Pick<
          Types.Team,
          "id" | "name" | "description" | "key" | "isDefault"
        >
      >
    >;
    defaultTeams?: Types.Maybe<
      Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
    >;
  };

export type GetUserWithTeamQueryVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type GetUserWithTeamQuery = { __typename?: "Query" } & {
  user: { __typename?: "User" } & Pick<Types.User, "id"> & {
      teams?: Types.Maybe<
        Array<
          { __typename?: "Team" } & Pick<
            Types.Team,
            "id" | "name" | "description" | "key" | "isDefault"
          >
        >
      >;
      defaultTeams?: Types.Maybe<
        Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
      >;
    };
};

export type AssignDefaultTeamsToUserMutationVariables = Types.Exact<{
  input: Types.AssignTeamsToUserInput;
}>;

export type AssignDefaultTeamsToUserMutation = { __typename?: "Mutation" } & {
  assignDefaultTeamsToUser?: Types.Maybe<
    { __typename?: "User" } & Pick<Types.User, "id"> & {
        teams?: Types.Maybe<
          Array<
            { __typename?: "Team" } & Pick<
              Types.Team,
              "id" | "name" | "description" | "key" | "isDefault"
            >
          >
        >;
        defaultTeams?: Types.Maybe<
          Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
        >;
      }
  >;
};

export type AssignTeamsToUserMutationVariables = Types.Exact<{
  input: Types.AssignTeamsToUserInput;
}>;

export type AssignTeamsToUserMutation = { __typename?: "Mutation" } & {
  assignTeamsToUser?: Types.Maybe<
    { __typename?: "User" } & Pick<Types.User, "id"> & {
        teams?: Types.Maybe<
          Array<
            { __typename?: "Team" } & Pick<
              Types.Team,
              "id" | "name" | "description" | "key" | "isDefault"
            >
          >
        >;
        defaultTeams?: Types.Maybe<
          Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
        >;
      }
  >;
};

export type UnassignTeamsFromUserMutationVariables = Types.Exact<{
  input: Types.AssignTeamsToUserInput;
}>;

export type UnassignTeamsFromUserMutation = { __typename?: "Mutation" } & {
  unassignTeamsFromUser?: Types.Maybe<
    { __typename?: "User" } & Pick<Types.User, "id"> & {
        teams?: Types.Maybe<
          Array<
            { __typename?: "Team" } & Pick<
              Types.Team,
              "id" | "name" | "description" | "key" | "isDefault"
            >
          >
        >;
        defaultTeams?: Types.Maybe<
          Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
        >;
      }
  >;
};

export type AssignTeamAndSetDefaultForUserMutationVariables = Types.Exact<{
  input: Types.AssignTeamsToUserInput;
}>;

export type AssignTeamAndSetDefaultForUserMutation = { __typename?: "Mutation" } & {
  assignTeamsToUser?: Types.Maybe<
    { __typename?: "User" } & Pick<Types.User, "id"> & {
        teams?: Types.Maybe<
          Array<
            { __typename?: "Team" } & Pick<
              Types.Team,
              "id" | "name" | "description" | "key" | "isDefault"
            >
          >
        >;
        defaultTeams?: Types.Maybe<
          Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
        >;
      }
  >;
  assignDefaultTeamsToUser?: Types.Maybe<
    { __typename?: "User" } & Pick<Types.User, "id"> & {
        teams?: Types.Maybe<
          Array<
            { __typename?: "Team" } & Pick<
              Types.Team,
              "id" | "name" | "description" | "key" | "isDefault"
            >
          >
        >;
        defaultTeams?: Types.Maybe<
          Array<{ __typename?: "Team" } & Pick<Types.Team, "id" | "name" | "description" | "key">>
        >;
      }
  >;
};

export const UserDetailsFragmentDoc = `
    fragment UserDetails on User {
  id
  authenticationDetails {
    username
  }
  title
  firstName
  lastName
  name
  registrationNumber
  phone
  email
  shortCode
  suspended
  isAdminUser
  securityRoles {
    id
    name
    key
  }
  services {
    id
    name
  }
  privileges {
    id
    name
    key
  }
  deleted
}
    `;
export const WorkingHoursFragmentDoc = `
    fragment WorkingHours on WorkingHours {
  id
  weekday
  working
  startTime
  endTime
}
    `;
export const UserWithTeamsFragmentDoc = `
    fragment UserWithTeams on User {
  id
  teams {
    id
    name
    description
    key
    isDefault
  }
  defaultTeams {
    id
    name
    description
    key
  }
}
    `;
export const GetOrganisationUsersDocument = `
    query getOrganisationUsers($input: UserQueryParams!) {
  users(input: $input) {
    results {
      id
      authenticationDetails {
        username
      }
      firstName
      lastName
      email
      phone
      securityRoles {
        id
        key
        name
      }
      suspended
    }
    pages
  }
}
    `;

export const useGetOrganisationUsersQuery = <TData = GetOrganisationUsersQuery, TError = unknown>(
  variables: GetOrganisationUsersQueryVariables,
  options?: Omit<UseQueryOptions<GetOrganisationUsersQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetOrganisationUsersQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetOrganisationUsersQuery, TError, TData>({
    queryKey: ["getOrganisationUsers", variables],
    queryFn: customFetcher<GetOrganisationUsersQuery, GetOrganisationUsersQueryVariables>(
      GetOrganisationUsersDocument,
      variables
    ),
    ...options,
  });
};

useGetOrganisationUsersQuery.getKey = (variables: GetOrganisationUsersQueryVariables) => [
  "getOrganisationUsers",
  variables,
];

export const UserPrivilegesDocument = `
    query userPrivileges {
  userPrivileges {
    id
    name
    key
    description
  }
}
    `;

export const useUserPrivilegesQuery = <TData = UserPrivilegesQuery, TError = unknown>(
  variables?: UserPrivilegesQueryVariables,
  options?: Omit<UseQueryOptions<UserPrivilegesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<UserPrivilegesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<UserPrivilegesQuery, TError, TData>({
    queryKey: variables === undefined ? ["userPrivileges"] : ["userPrivileges", variables],
    queryFn: customFetcher<UserPrivilegesQuery, UserPrivilegesQueryVariables>(
      UserPrivilegesDocument,
      variables
    ),
    ...options,
  });
};

useUserPrivilegesQuery.getKey = (variables?: UserPrivilegesQueryVariables) =>
  variables === undefined ? ["userPrivileges"] : ["userPrivileges", variables];

export const SecurityRolesDocument = `
    query securityRoles {
  userSecurityRoles {
    id
    name
    key
    description
    defaultRole
    disabledFeatures {
      feature {
        id
        name
      }
    }
    privileges {
      id
      key
      name
      description
    }
  }
}
    `;

export const useSecurityRolesQuery = <TData = SecurityRolesQuery, TError = unknown>(
  variables?: SecurityRolesQueryVariables,
  options?: Omit<UseQueryOptions<SecurityRolesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<SecurityRolesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<SecurityRolesQuery, TError, TData>({
    queryKey: variables === undefined ? ["securityRoles"] : ["securityRoles", variables],
    queryFn: customFetcher<SecurityRolesQuery, SecurityRolesQueryVariables>(
      SecurityRolesDocument,
      variables
    ),
    ...options,
  });
};

useSecurityRolesQuery.getKey = (variables?: SecurityRolesQueryVariables) =>
  variables === undefined ? ["securityRoles"] : ["securityRoles", variables];

export const EditableFeaturesDocument = `
    query editableFeatures {
  editableFeatures {
    features {
      id
      permissionName
      name
      description
      enabled
    }
  }
}
    `;

export const useEditableFeaturesQuery = <TData = EditableFeaturesQuery, TError = unknown>(
  variables?: EditableFeaturesQueryVariables,
  options?: Omit<UseQueryOptions<EditableFeaturesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<EditableFeaturesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<EditableFeaturesQuery, TError, TData>({
    queryKey: variables === undefined ? ["editableFeatures"] : ["editableFeatures", variables],
    queryFn: customFetcher<EditableFeaturesQuery, EditableFeaturesQueryVariables>(
      EditableFeaturesDocument,
      variables
    ),
    ...options,
  });
};

useEditableFeaturesQuery.getKey = (variables?: EditableFeaturesQueryVariables) =>
  variables === undefined ? ["editableFeatures"] : ["editableFeatures", variables];

export const GetUserDocument = `
    query getUser($id: Long!) {
  user(id: $id) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

export const useGetUserQuery = <TData = GetUserQuery, TError = unknown>(
  variables: GetUserQueryVariables,
  options?: Omit<UseQueryOptions<GetUserQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetUserQuery, TError, TData>({
    queryKey: ["getUser", variables],
    queryFn: customFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
    ...options,
  });
};

useGetUserQuery.getKey = (variables: GetUserQueryVariables) => ["getUser", variables];

export const CreateUserDocument = `
    mutation createUser($user: CreateUserInput!) {
  createUser(input: $user) {
    user {
      id
    }
  }
}
    `;

export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>
) => {
  return useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>({
    mutationKey: ["createUser"],
    mutationFn: (variables?: CreateUserMutationVariables) =>
      customFetcher<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        variables
      )(),
    ...options,
  });
};

export const UserUpdateDocument = `
    mutation userUpdate($user: UpdateUserInput!) {
  updateUser(input: $user) {
    user {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;

export const useUserUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UserUpdateMutation, TError, UserUpdateMutationVariables, TContext>
) => {
  return useMutation<UserUpdateMutation, TError, UserUpdateMutationVariables, TContext>({
    mutationKey: ["userUpdate"],
    mutationFn: (variables?: UserUpdateMutationVariables) =>
      customFetcher<UserUpdateMutation, UserUpdateMutationVariables>(
        UserUpdateDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateUserPasswordDocument = `
    mutation updateUserPassword($user: UpdateUserInput!) {
  updateUserPassword(input: $user) {
    user {
      id
    }
  }
}
    `;

export const useUpdateUserPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserPasswordMutation,
    TError,
    UpdateUserPasswordMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateUserPasswordMutation,
    TError,
    UpdateUserPasswordMutationVariables,
    TContext
  >({
    mutationKey: ["updateUserPassword"],
    mutationFn: (variables?: UpdateUserPasswordMutationVariables) =>
      customFetcher<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
        UpdateUserPasswordDocument,
        variables
      )(),
    ...options,
  });
};

export const UserDeleteDocument = `
    mutation userDelete($id: Long!) {
  deleteUser(id: $id) {
    success
  }
}
    `;

export const useUserDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>
) => {
  return useMutation<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>({
    mutationKey: ["userDelete"],
    mutationFn: (variables?: UserDeleteMutationVariables) =>
      customFetcher<UserDeleteMutation, UserDeleteMutationVariables>(
        UserDeleteDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateRolesForUserDocument = `
    mutation updateRolesForUser($input: AssignRolesToUserInput!) {
  updateRolesForUser(input: $input) {
    id
  }
}
    `;

export const useUpdateRolesForUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateRolesForUserMutation,
    TError,
    UpdateRolesForUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateRolesForUserMutation,
    TError,
    UpdateRolesForUserMutationVariables,
    TContext
  >({
    mutationKey: ["updateRolesForUser"],
    mutationFn: (variables?: UpdateRolesForUserMutationVariables) =>
      customFetcher<UpdateRolesForUserMutation, UpdateRolesForUserMutationVariables>(
        UpdateRolesForUserDocument,
        variables
      )(),
    ...options,
  });
};

export const GetUserWorkingHoursDocument = `
    query getUserWorkingHours($userId: Long!) {
  workingHours(userId: $userId) {
    ...WorkingHours
  }
}
    ${WorkingHoursFragmentDoc}`;

export const useGetUserWorkingHoursQuery = <TData = GetUserWorkingHoursQuery, TError = unknown>(
  variables: GetUserWorkingHoursQueryVariables,
  options?: Omit<UseQueryOptions<GetUserWorkingHoursQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserWorkingHoursQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetUserWorkingHoursQuery, TError, TData>({
    queryKey: ["getUserWorkingHours", variables],
    queryFn: customFetcher<GetUserWorkingHoursQuery, GetUserWorkingHoursQueryVariables>(
      GetUserWorkingHoursDocument,
      variables
    ),
    ...options,
  });
};

useGetUserWorkingHoursQuery.getKey = (variables: GetUserWorkingHoursQueryVariables) => [
  "getUserWorkingHours",
  variables,
];

export const CreateUserWorkingHoursDocument = `
    mutation createUserWorkingHours($input: CreateWorkingHoursInput!) {
  createWorkingHours(input: $input) {
    workingHours {
      ...WorkingHours
    }
  }
}
    ${WorkingHoursFragmentDoc}`;

export const useCreateUserWorkingHoursMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateUserWorkingHoursMutation,
    TError,
    CreateUserWorkingHoursMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateUserWorkingHoursMutation,
    TError,
    CreateUserWorkingHoursMutationVariables,
    TContext
  >({
    mutationKey: ["createUserWorkingHours"],
    mutationFn: (variables?: CreateUserWorkingHoursMutationVariables) =>
      customFetcher<CreateUserWorkingHoursMutation, CreateUserWorkingHoursMutationVariables>(
        CreateUserWorkingHoursDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateUserWorkingHoursDocument = `
    mutation updateUserWorkingHours($input: UpdateWorkingHoursInput!) {
  updateWorkingHours(input: $input) {
    workingHours {
      ...WorkingHours
    }
  }
}
    ${WorkingHoursFragmentDoc}`;

export const useUpdateUserWorkingHoursMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserWorkingHoursMutation,
    TError,
    UpdateUserWorkingHoursMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateUserWorkingHoursMutation,
    TError,
    UpdateUserWorkingHoursMutationVariables,
    TContext
  >({
    mutationKey: ["updateUserWorkingHours"],
    mutationFn: (variables?: UpdateUserWorkingHoursMutationVariables) =>
      customFetcher<UpdateUserWorkingHoursMutation, UpdateUserWorkingHoursMutationVariables>(
        UpdateUserWorkingHoursDocument,
        variables
      )(),
    ...options,
  });
};

export const GetUserServicesDocument = `
    query getUserServices($telehealthEnabled: Boolean!) {
  services {
    id
    name
    duration
    description
    price
    color
    requiredWorkers
    requiredSpaces
    workers {
      id
      firstName
      lastName
      email
    }
    spaces {
      id
      name
    }
    defaultTelehealth @include(if: $telehealthEnabled)
  }
}
    `;

export const useGetUserServicesQuery = <TData = GetUserServicesQuery, TError = unknown>(
  variables: GetUserServicesQueryVariables,
  options?: Omit<UseQueryOptions<GetUserServicesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserServicesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetUserServicesQuery, TError, TData>({
    queryKey: ["getUserServices", variables],
    queryFn: customFetcher<GetUserServicesQuery, GetUserServicesQueryVariables>(
      GetUserServicesDocument,
      variables
    ),
    ...options,
  });
};

useGetUserServicesQuery.getKey = (variables: GetUserServicesQueryVariables) => [
  "getUserServices",
  variables,
];

export const UpdateWorkerToServicesDocument = `
    mutation updateWorkerToServices($input: UpdateWorkerToServicesInput!) {
  updateWorkerToServices(input: $input) {
    worker {
      services {
        id
        name
      }
    }
  }
}
    `;

export const useUpdateWorkerToServicesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateWorkerToServicesMutation,
    TError,
    UpdateWorkerToServicesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateWorkerToServicesMutation,
    TError,
    UpdateWorkerToServicesMutationVariables,
    TContext
  >({
    mutationKey: ["updateWorkerToServices"],
    mutationFn: (variables?: UpdateWorkerToServicesMutationVariables) =>
      customFetcher<UpdateWorkerToServicesMutation, UpdateWorkerToServicesMutationVariables>(
        UpdateWorkerToServicesDocument,
        variables
      )(),
    ...options,
  });
};

export const GetUserAccDetailsDocument = `
    query getUserAccDetails($id: Long!) {
  user(id: $id) {
    id
    accUserDetails {
      accProviderId
      accContractId
      accServiceCode {
        id
        code
      }
      accVendorId
      allowedBillingMethods
    }
  }
}
    `;

export const useGetUserAccDetailsQuery = <TData = GetUserAccDetailsQuery, TError = unknown>(
  variables: GetUserAccDetailsQueryVariables,
  options?: Omit<UseQueryOptions<GetUserAccDetailsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserAccDetailsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetUserAccDetailsQuery, TError, TData>({
    queryKey: ["getUserAccDetails", variables],
    queryFn: customFetcher<GetUserAccDetailsQuery, GetUserAccDetailsQueryVariables>(
      GetUserAccDetailsDocument,
      variables
    ),
    ...options,
  });
};

useGetUserAccDetailsQuery.getKey = (variables: GetUserAccDetailsQueryVariables) => [
  "getUserAccDetails",
  variables,
];

export const UpdateUserAccDetailsDocument = `
    mutation updateUserAccDetails($input: UpdateAccUserDetailsInput!) {
  updateAccUserDetails(input: $input) {
    id
    accProviderId
    accContractId
    accServiceCode {
      id
      code
    }
    accVendorId
    allowedBillingMethods
  }
}
    `;

export const useUpdateUserAccDetailsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserAccDetailsMutation,
    TError,
    UpdateUserAccDetailsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateUserAccDetailsMutation,
    TError,
    UpdateUserAccDetailsMutationVariables,
    TContext
  >({
    mutationKey: ["updateUserAccDetails"],
    mutationFn: (variables?: UpdateUserAccDetailsMutationVariables) =>
      customFetcher<UpdateUserAccDetailsMutation, UpdateUserAccDetailsMutationVariables>(
        UpdateUserAccDetailsDocument,
        variables
      )(),
    ...options,
  });
};

export const DeleteUserAccDetailsDocument = `
    mutation deleteUserAccDetails($userId: Long!) {
  deleteAccUserDetails(userId: $userId) {
    success
  }
}
    `;

export const useDeleteUserAccDetailsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteUserAccDetailsMutation,
    TError,
    DeleteUserAccDetailsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteUserAccDetailsMutation,
    TError,
    DeleteUserAccDetailsMutationVariables,
    TContext
  >({
    mutationKey: ["deleteUserAccDetails"],
    mutationFn: (variables?: DeleteUserAccDetailsMutationVariables) =>
      customFetcher<DeleteUserAccDetailsMutation, DeleteUserAccDetailsMutationVariables>(
        DeleteUserAccDetailsDocument,
        variables
      )(),
    ...options,
  });
};

export const GetUserWithTeamDocument = `
    query getUserWithTeam($id: Long!) {
  user(id: $id) {
    ...UserWithTeams
  }
}
    ${UserWithTeamsFragmentDoc}`;

export const useGetUserWithTeamQuery = <TData = GetUserWithTeamQuery, TError = unknown>(
  variables: GetUserWithTeamQueryVariables,
  options?: Omit<UseQueryOptions<GetUserWithTeamQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserWithTeamQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetUserWithTeamQuery, TError, TData>({
    queryKey: ["getUserWithTeam", variables],
    queryFn: customFetcher<GetUserWithTeamQuery, GetUserWithTeamQueryVariables>(
      GetUserWithTeamDocument,
      variables
    ),
    ...options,
  });
};

useGetUserWithTeamQuery.getKey = (variables: GetUserWithTeamQueryVariables) => [
  "getUserWithTeam",
  variables,
];

export const AssignDefaultTeamsToUserDocument = `
    mutation assignDefaultTeamsToUser($input: AssignTeamsToUserInput!) {
  assignDefaultTeamsToUser(input: $input) {
    ...UserWithTeams
  }
}
    ${UserWithTeamsFragmentDoc}`;

export const useAssignDefaultTeamsToUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AssignDefaultTeamsToUserMutation,
    TError,
    AssignDefaultTeamsToUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AssignDefaultTeamsToUserMutation,
    TError,
    AssignDefaultTeamsToUserMutationVariables,
    TContext
  >({
    mutationKey: ["assignDefaultTeamsToUser"],
    mutationFn: (variables?: AssignDefaultTeamsToUserMutationVariables) =>
      customFetcher<AssignDefaultTeamsToUserMutation, AssignDefaultTeamsToUserMutationVariables>(
        AssignDefaultTeamsToUserDocument,
        variables
      )(),
    ...options,
  });
};

export const AssignTeamsToUserDocument = `
    mutation assignTeamsToUser($input: AssignTeamsToUserInput!) {
  assignTeamsToUser(input: $input) {
    ...UserWithTeams
  }
}
    ${UserWithTeamsFragmentDoc}`;

export const useAssignTeamsToUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AssignTeamsToUserMutation,
    TError,
    AssignTeamsToUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AssignTeamsToUserMutation,
    TError,
    AssignTeamsToUserMutationVariables,
    TContext
  >({
    mutationKey: ["assignTeamsToUser"],
    mutationFn: (variables?: AssignTeamsToUserMutationVariables) =>
      customFetcher<AssignTeamsToUserMutation, AssignTeamsToUserMutationVariables>(
        AssignTeamsToUserDocument,
        variables
      )(),
    ...options,
  });
};

export const UnassignTeamsFromUserDocument = `
    mutation unassignTeamsFromUser($input: AssignTeamsToUserInput!) {
  unassignTeamsFromUser(input: $input) {
    ...UserWithTeams
  }
}
    ${UserWithTeamsFragmentDoc}`;

export const useUnassignTeamsFromUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnassignTeamsFromUserMutation,
    TError,
    UnassignTeamsFromUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UnassignTeamsFromUserMutation,
    TError,
    UnassignTeamsFromUserMutationVariables,
    TContext
  >({
    mutationKey: ["unassignTeamsFromUser"],
    mutationFn: (variables?: UnassignTeamsFromUserMutationVariables) =>
      customFetcher<UnassignTeamsFromUserMutation, UnassignTeamsFromUserMutationVariables>(
        UnassignTeamsFromUserDocument,
        variables
      )(),
    ...options,
  });
};

export const AssignTeamAndSetDefaultForUserDocument = `
    mutation assignTeamAndSetDefaultForUser($input: AssignTeamsToUserInput!) {
  assignTeamsToUser(input: $input) {
    ...UserWithTeams
  }
  assignDefaultTeamsToUser(input: $input) {
    ...UserWithTeams
  }
}
    ${UserWithTeamsFragmentDoc}`;

export const useAssignTeamAndSetDefaultForUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AssignTeamAndSetDefaultForUserMutation,
    TError,
    AssignTeamAndSetDefaultForUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AssignTeamAndSetDefaultForUserMutation,
    TError,
    AssignTeamAndSetDefaultForUserMutationVariables,
    TContext
  >({
    mutationKey: ["assignTeamAndSetDefaultForUser"],
    mutationFn: (variables?: AssignTeamAndSetDefaultForUserMutationVariables) =>
      customFetcher<
        AssignTeamAndSetDefaultForUserMutation,
        AssignTeamAndSetDefaultForUserMutationVariables
      >(AssignTeamAndSetDefaultForUserDocument, variables)(),
    ...options,
  });
};

import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";
import { createContext, useContext } from "react";
import {
  GridList as ReactAriaGridList,
  GridListItem as ReactAriaGridListItem,
  type GridListItemProps as ReactAriaGridListItemProps,
  type GridListProps as ReactAriaGridListProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcLoadingOverlay } from "./nc-loading-overlay";

export type GridListTableBreakpoint = Omit<
  keyof typeof ncTheme.mediaQueries.width,
  "xsmallOnly" | "smallOnly" | "mediumOnly" | "largeOnly"
>;

const defaultBreakpoint = "large";

const GridListTableContext = createContext<{
  tableLayoutBreakpoint: GridListTableBreakpoint;
  emptyMessage?: string;
}>({ tableLayoutBreakpoint: defaultBreakpoint });

const FilterSortContext = createContext<{
  gridListId: string | null;
}>({ gridListId: null });

const getBreakpointQuery = (breakpoint: GridListTableBreakpoint) => {
  const queries = ncTheme.mediaQueries.width;
  return queries[breakpoint as keyof typeof queries] || undefined;
};

const getGridListTableStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    display: grid;
    width: 100%;

    ${getBreakpointQuery(breakpoint)} {
      display: table;
    }
  `;
};
interface GridListTableStateProps {
  error?: boolean | string;
  emptyMessage?: string;
  isLoading?: boolean;
}

interface GridListTableProps extends HTMLAttributes<HTMLDivElement>, GridListTableStateProps {
  children: ReactNode;
  tableLayoutBreakpoint?: GridListTableBreakpoint;
}

export const NcGridListTable = ({
  children,
  tableLayoutBreakpoint = defaultBreakpoint,
  emptyMessage,
  isLoading,
  error,
  ...props
}: GridListTableProps) => {
  if (error) {
    return <GridListTableError error={error} />;
  }
  return (
    <GridListTableContext.Provider value={{ tableLayoutBreakpoint, emptyMessage }}>
      <NcLoadingOverlay isLoading={isLoading}>
        <div
          data-nc="NcGridListTable"
          css={getGridListTableStyles(tableLayoutBreakpoint)}
          {...props}
        >
          {children}
        </div>
      </NcLoadingOverlay>
    </GridListTableContext.Provider>
  );
};

const GridListTableError = ({ error }: { error: GridListTableProps["error"] }) => {
  const { t } = useI18n();
  const errorText = typeof error === "string" ? error : t("error_default");
  return <NcFormattedMessage variant="danger">{errorText}</NcFormattedMessage>;
};

const getHeaderStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    display: none;
    ${getBreakpointQuery(breakpoint)} {
      display: table-row;
    }
  `;
};

type HeaderProps = HTMLAttributes<HTMLDivElement>;

const Header = ({ children, ...props }: HeaderProps) => {
  const { tableLayoutBreakpoint } = useContext(GridListTableContext);
  return (
    <div
      data-nc="NcGridListTable.Header"
      role="presentation"
      css={getHeaderStyles(tableLayoutBreakpoint)}
      {...props}
    >
      {children}
    </div>
  );
};

const getHeaderCellStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    font-weight: bold;
    color: ${ncTheme.colors.main};

    ${getBreakpointQuery(breakpoint)} {
      display: table-cell;
      border-bottom: 1px solid ${ncTheme.colors.ui};
      padding: ${ncTheme.spacing(2)} ${ncTheme.spacing(3)};
    }
  `;
};

type HeaderCellProps = HTMLAttributes<HTMLDivElement>;

const HeaderCell = ({ children, ...props }: HeaderCellProps) => {
  const { tableLayoutBreakpoint } = useContext(GridListTableContext);
  return (
    <div
      data-nc="NcGridListTable.HeaderCell"
      css={getHeaderCellStyles(tableLayoutBreakpoint)}
      {...props}
    >
      {children}
    </div>
  );
};

const getBodyRowStyles = (breakpoint: GridListTableBreakpoint) => {
  return css`
    display: grid;
    gap: ${ncTheme.spacing(2)};
    transition: background-color ${ncTheme.transitionSpeed.fast};
    padding: ${ncTheme.spacing(2)} ${ncTheme.spacing(3)};
    outline-style: none;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${ncTheme.colors.ui};
    }

    &[data-href],
    &[data-selection-mode] {
      cursor: pointer;
      &[data-hovered="true"],
      &[data-focused="true"] {
        background-color: ${ncTheme.colors.focused};
      }
    }
    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles}
    }

    ${getBreakpointQuery("small")} {
      grid-template-columns: 1fr 1fr;
    }

    ${getBreakpointQuery(breakpoint)} {
      display: table-row;
      &:not(:last-of-type) {
        > * {
          border-bottom: 1px solid ${ncTheme.colors.ui};
        }
      }
    }
  `;
};

const Body = function <T extends object>({ ...props }: ReactAriaGridListProps<T>) {
  const { t } = useI18n();
  const { gridListId } = useContext(FilterSortContext);
  const { emptyMessage } = useContext(GridListTableContext);
  const emptyText = typeof emptyMessage === "string" ? emptyMessage : t("empty_default");
  const contents = (
    <ReactAriaGridList
      css={css`
        display: contents;
      `}
      {...{
        ...(gridListId ? { id: gridListId } : {}),
        renderEmptyState: () => (
          <NcFormattedMessage
            variant="secondary"
            css={css`
              padding: ${ncTheme.spacing(2)} ${ncTheme.spacing(3)};
            `}
          >
            {emptyText}
          </NcFormattedMessage>
        ),
        ...props,
      }}
    />
  );

  return gridListId ? (
    <div
      css={css`
        display: contents;
      `}
      aria-live="polite"
    >
      {contents}
    </div>
  ) : (
    <>{contents}</>
  );
};

const BodyRow = ({ children, ...props }: ReactAriaGridListItemProps) => {
  const contextBreakpoint = useContext(GridListTableContext);
  return (
    <ReactAriaGridListItem
      data-nc="NcGridListTable.BodyRow"
      css={getBodyRowStyles(contextBreakpoint.tableLayoutBreakpoint)}
      {...props}
    >
      {children}
    </ReactAriaGridListItem>
  );
};

const getBodyCellStyles = (breakpoint: GridListTableBreakpoint) => {
  const breakpointQuery = getBreakpointQuery(breakpoint);
  return {
    itemCell: css`
      padding: ${ncTheme.spacing(2)} ${ncTheme.spacing(3)};

      ${breakpointQuery} {
        display: table-cell;
        vertical-align: middle;
      }
    `,
    heading: css`
      font-weight: bold;
      color: ${ncTheme.colors.main};
      margin-block-end: ${ncTheme.spacing(1)};

      ${breakpointQuery} {
        ${ncTheme.utilities.visuallyHiddenStyles}
      }
    `,
  };
};

interface BodyCellProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  heading: string;
}

const BodyCell = ({ children, heading, ...props }: BodyCellProps) => {
  const contextBreakpoint = useContext(GridListTableContext);
  const styles = getBodyCellStyles(contextBreakpoint.tableLayoutBreakpoint);
  return (
    <div data-nc="NcGridListTable.BodyCell" css={styles.itemCell} {...props}>
      <div css={styles.heading}>{heading}</div>
      {children}
    </div>
  );
};

NcGridListTable.Header = Header;
NcGridListTable.HeaderCell = HeaderCell;
NcGridListTable.Body = Body;
NcGridListTable.BodyRow = BodyRow;
NcGridListTable.BodyCell = BodyCell;

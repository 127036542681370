const shadowColor = "0deg 0% 0%";

const colorStacks = {
  neutral: {
    // Greys derived secondary blue
    10: "#07080A",
    9: "#181C21",
    8: "#293139",
    7: "#3A4550", // white text AAA // uiDark
    6: "#5D666F", // white text AA
    5: "#9BA1A8", // black text AA
    4: "#AFB4BA", // black text AAA // ui
    3: "#C8CBD0",
    2: "#DCDEE2",
    1: "#F0F1F3", // uiLight
    0: "#F9FAFB", // light
  },
  brand: {
    9: "#0f1e23",
    8: "#102a32",
    7: "#0f3641",
    6: "#0a4251",
    5: "#004F62", // white text AAA // brand - encycolorpedia.com/004f62#copy-1-0
    4: "#407887", // white text AA // brandActive
    3: "#628693",
    2: "#89a3ad", // black text AA
    1: "#afc1c8", // black text AAA
  },
  main: {
    9: "#0f1723",
    8: "#0f1e32",
    7: "#0e2642", // mainDark
    6: "#092f51",
    5: "#003762", // white text AAA // main - analogous to brand
    4: "#39547b", // white text AA // active
    3: "#617394",
    2: "#8794ae", // black text AA
    1: "#D5DEE5", // black text AAA // focused
  },
  success: {
    9: "#00261B",
    8: "#003727",
    7: "#004832",
    6: "#00583E", // white text AAA
    5: "#006949", // success - analogous to brand
    4: "#278065", // successActive - white text AA
    3: "#34886E", // black text AA
    2: "#69A794", // black text AAA
    1: "#D1E4DE",
  },
  danger: {
    9: "#3f1306",
    8: "#5c1508",
    7: "#791408",
    6: "#921A13", // white text AAA // danger
    5: "#AA1F1D",
    4: "#C32528", // white text AA dangerActive
    3: "#DA7778", // black text AA
    2: "#E19293", // black text AAA
    1: "#F5DBDB",
  },
};

const colors = {
  light: colorStacks.neutral[0],
  dark: colorStacks.neutral[9],
  uiDark: colorStacks.neutral[7],
  ui: colorStacks.neutral[4],
  uiLight: colorStacks.neutral[1],
  // disabled greys are flatter than the blue-toned ui greys
  disabled: "#606060", // white text AA - disabledLight AA
  disabledLight: "#DDDDDD", // black text AAA - disabled AA
  active: colorStacks.main[4],
  focused: colorStacks.main[1],
  brand: colorStacks.brand[5],
  brandActive: colorStacks.brand[4],
  main: colorStacks.main[5],
  mainDark: colorStacks.main[7],
  danger: colorStacks.danger[6],
  dangerActive: colorStacks.danger[4],
  success: colorStacks.success[5],
  successActive: colorStacks.success[4],
  transparent: "rgba(0, 0, 0, 0)",
};

const fontSizes = [
  "0.8rem", // 0
  "0.9rem", // 1
  "1rem", // 2
  "1.1rem", // 3
  "1.25rem", // 4
  "1.5rem", // 5
  "1.75rem", // 6
  "2rem", // 7
  "2.25rem", // 8
  "2.5rem", // 9
  "3rem", // 10
  "3.5rem", // 11
  "4rem", // 12
];

const spacing = (size: number = 1, base: number = 0.25) => {
  return base * size + "rem";
};

const borderWidth = {
  fine: "1px",
  medium: "0.15rem",
  bold: "0.3rem",
};

const border = (color = colors.ui, variant = "standard") => {
  if (variant === "bold") {
    return `${borderWidth.bold} solid ${color}`;
  }
  if (variant === "medium") {
    return `${borderWidth.medium} solid ${color}`;
  }
  return `${borderWidth.fine} solid ${color}`;
};

const borderRadius = {
  small: "0.25rem",
  medium: "0.3rem",
  large: "0.5rem",
  rounded: "9999px",
};

const widthBreakpoints = {
  xsmall: "480px",
  small: "640px",
  medium: "880px",
  large: "1024px",
  xlarge: "1300px",
  xxlarge: "1600px",
};

const containers = {
  page: "100rem",
  text: "40rem",
};

const transitionSpeed = {
  fast: "150ms",
  medium: "300ms",
  slow: "600ms",
};

const transition = (props: string, speed = transitionSpeed.medium) => {
  return `${props} ${speed} ease-in-out`;
};

const outlineStyles = `
  outline-style: auto;
  outline-width: ${borderWidth.medium};
  outline-offset: ${spacing(1)};
  outline-color: ${colors.active};
`;

const visuallyHiddenStyles = `
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  word-wrap: normal;
`;

const gradients = {
  stripedUiLight: `repeating-linear-gradient(45deg, ${colors.uiLight}, ${colors.uiLight} 15px, ${colors.light} 15px, ${colors.light} 30px)`,
};

export const ncTheme = {
  colors,
  containers,
  fontSizes,
  gradients,
  spacing,
  fontWeight: {
    standard: 400,
    bold: 600,
    heavy: 700,
  },
  lineHeight: 1.5,
  shadows: {
    xsmall: `0px 1px 2px hsl(${shadowColor} / 0.1)`,
    small: `0px 2px 2px hsl(${shadowColor} / 0.1)`,
    medium: `
      0px 0.6px 1px hsl(${shadowColor} / 0.1),0px 1px 1.2px -0.5px hsl(${shadowColor} / 0.1),
      0px 2.3px 2.8px -1px hsl(${shadowColor} / 0.12)
    `,
    large: `
      hsl(${shadowColor}) 0px 0px 0px 0px,
      hsl(${shadowColor}) 0px 0px 0px 0px,
      hsl(${shadowColor} / 0.1) 0px 10px 15px -3px,
      hsl(${shadowColor} / 0.1) 0px 4px 6px -4px
    `,
    xlarge: `
      hsl(${shadowColor}) 0px 0px 0px 0px, 
      hsl(${shadowColor}) 0px 0px 0px 0px,
      hsl(${shadowColor} / 0.1) 0px 20px 25px -5px,
      hsl(${shadowColor} / 0.1) 0px 8px 10px -6px
    `,
  },
  borderWidth,
  border,
  borderRadius,
  transitionSpeed,
  transition,
  mediaQueries: {
    width: {
      xxsmallOnly: `@media screen and (width < ${widthBreakpoints.xsmall})`,
      xsmall: `@media screen and (width >= ${widthBreakpoints.xsmall})`,
      xsmallOnly: `@media screen and (width < ${widthBreakpoints.small})`,
      small: `@media screen and (width >= ${widthBreakpoints.small})`,
      smallOnly: `@media screen and (${widthBreakpoints.small} <= width < ${widthBreakpoints.medium})`,
      medium: `@media screen and (width >= ${widthBreakpoints.medium})`,
      mediumOnly: `@media screen and (${widthBreakpoints.medium} <= width < ${widthBreakpoints.large})`,
      large: `@media screen and (width >= ${widthBreakpoints.large})`,
      largeOnly: `@media screen and (${widthBreakpoints.large} <= width < ${widthBreakpoints.xlarge})`,
      xlarge: `@media screen and (width >= ${widthBreakpoints.xlarge})`,
    },
  },
  utilities: {
    outlineStyles,
    visuallyHiddenStyles,
  },
};

import { zodResolver } from "@hookform/resolvers/zod";
import { NcForm, NcFormDateTimeField } from "@noted/noted-components";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { restPutter } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { PrimhdCodeQueryResponse } from "~/primhd/api";
import { useToast } from "~/shared/components/alerts/toast-provider";

import PrimhdCodeComboBox from "../../primhd-code-combo-box";
import { PrimhdClassificationRecord } from "../../types";
import RecordDetailsPanel from "./record-details-panel";

type UpdateApi = Pick<
  PrimhdClassificationRecord,
  | "id"
  | "referralId"
  | "clinicalCodingId"
  | "clinicalCodingValue"
  | "diagnosisType"
  | "startDate"
  | "endDate"
>;

interface ClassificationFormProps {
  record: PrimhdClassificationRecord;
  onRecordUpdate: () => void;
  formId: string;
  codes: PrimhdCodeQueryResponse;
}

const baseFormSchema = (t: (arg: string) => string) =>
  z
    .object({
      startDate: z.date(),
      endDate: z.date().optional(),
      clinicalCodingId: z.string(),
      clinicalCodingValue: z.string(),
      diagnosisType: z.string(),
    })
    .refine(
      ({ startDate, endDate }) => {
        if (!endDate) {
          return true;
        }
        return startDate < endDate;
      },
      {
        path: ["endDate"],
        message: t("primhd:records.edit.date_range_error"),
      }
    );

export const ClassificationForm = ({
  record,
  onRecordUpdate,
  formId,
  codes,
}: ClassificationFormProps) => {
  const { t } = useI18n("primhd");
  const { enqueueError } = useToast();

  const { mutate: updateRecord } = useMutation({
    mutationFn: (body: UpdateApi) =>
      restPutter(`/v1/primhd/records/classification/${record.id}`, body),
    onSuccess() {
      onRecordUpdate();
    },
    onError(error) {
      const errorMessage =
        error.status === 400 && error.message
          ? error.message
          : t("primhd:records.edit.update_error");
      enqueueError(errorMessage);
    },
  });

  const formSchema = baseFormSchema(t);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clinicalCodingId: record.clinicalCodingId,
      clinicalCodingValue: record.clinicalCodingValue,
      diagnosisType: record.diagnosisType,
      startDate: record.startDate ? new Date(record.startDate) : undefined,
      endDate: record.endDate ? new Date(record.endDate) : undefined,
    },
  });

  const onSubmit = form.handleSubmit(data => {
    if (!data) return;
    const { clinicalCodingId, clinicalCodingValue, diagnosisType, startDate, endDate } = data;
    updateRecord({
      id: record.id,
      referralId: record.referralId,
      clinicalCodingId,
      clinicalCodingValue,
      diagnosisType,
      startDate: startDate.toISOString(),
      endDate: endDate ? endDate.toISOString() : undefined,
    });
  });

  return (
    <RecordDetailsPanel>
      <NcForm form={form} onSubmit={onSubmit} id={formId}>
        <NcForm.Row>
          <PrimhdCodeComboBox
            codes={codes["CLASSIFICATION_CODING"]}
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.classification_record.coding_id_label")}
            name="clinicalCodingId"
            isRequired
          />
          <PrimhdCodeComboBox
            codes={codes["CLASSIFICATION_VALUE"]}
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.classification_record.coding_value_label")}
            name="clinicalCodingValue"
            isRequired
          />
        </NcForm.Row>
        <PrimhdCodeComboBox
          codes={codes["CLASSIFICATION_DIAGNOSIS"]}
          inputWidth="full"
          className="flex-grow"
          label={t("primhd:records.edit.classification_record.diagnosis_type")}
          name="diagnosisType"
          isRequired
        />
        <NcForm.Row>
          <NcFormDateTimeField
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.classification_record.start_date_label")}
            name="startDate"
            showPicker
            isRequired
          />
          <NcFormDateTimeField
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.classification_record.end_date_label")}
            name="endDate"
            showPicker
          />
        </NcForm.Row>
      </NcForm>
    </RecordDetailsPanel>
  );
};

import { css } from "@emotion/core";
import { getLocalTimeZone, parseAbsoluteToLocal, parseDateTime } from "@internationalized/date";
import type { ClipboardEventHandler, ReactNode } from "react";
import { forwardRef, useContext } from "react";
import type { DateValue, DatePickerProps as ReactAriaDateFieldProps } from "react-aria-components";
import {
  Calendar,
  CalendarCell,
  CalendarGrid,
  DatePickerStateContext,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
  DateInput as ReactAriaDateInput,
  DatePicker as ReactAriaDatePicker,
} from "react-aria-components";
import { FieldError } from "react-hook-form";

import { ncTheme } from "../nc-theme";
import { NcButton } from "./nc-button";
import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { NcIconCalendar, NcIconChevronLeft, NcIconChevronRight } from "./nc-icons";
import type { PresetInputWidths } from "./nc-input";
import { inputStyles, inputWidthStyles } from "./nc-input";
import { NcLabel } from "./nc-label";

export const ncDateFieldStyles = {
  group: css`
    ${inputStyles};
    display: flex;
    align-items: center;
    gap: ${ncTheme.spacing(2)};

    &:focus-within:not([data-invalid="true"]) {
      border-color: ${ncTheme.colors.active};
    }
  `,
  inputs: css`
    display: flex;
    gap: ${ncTheme.spacing(1)};
    flex-grow: 1;
  `,
  segment: css`
    outline-color: ${ncTheme.colors.focused};
  `,
  pickerButton: css`
    justify-self: flex-end;

    & > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  `,
  calendar: {
    popover: css`
      background: ${ncTheme.colors.light};
      border: ${ncTheme.border()};
      border-radius: ${ncTheme.borderRadius.medium};
      padding: ${ncTheme.spacing(4)} ${ncTheme.spacing(5)};
      box-shadow: ${ncTheme.shadows.medium};

      &:focus-visible {
        outline-color: ${ncTheme.colors.active};
      }
    `,
    header: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${ncTheme.spacing(2)};
      padding-bottom: ${ncTheme.spacing(2)};
      border-bottom: ${ncTheme.border()};
    `,
    calendar: css`
      min-height: 15rem;

      button,
      input {
        outline-color: ${ncTheme.colors.active};
      }

      th {
        padding-bottom: ${ncTheme.spacing(2)};
        font-weight: ${ncTheme.fontWeight.bold};
      }

      &:focus-visible {
        outline-color: ${ncTheme.colors.active};
      }
    `,
    cell: css`
      border-radius: ${ncTheme.borderRadius.small};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: ${ncTheme.spacing(2)};
      color: ${ncTheme.colors.main};
      font-weight: ${ncTheme.fontWeight.bold};
      outline-color: ${ncTheme.colors.active};

      &[data-hovered="true"],
      &[data-active="true"] {
        outline-color: ${ncTheme.colors.focused};
        background-color: ${ncTheme.colors.main};
        color: ${ncTheme.colors.light};
      }

      &[aria-disabled="true"],
      &[aria-disabled="true"]:hover,
      &[aria-disabled="true"]:active {
        cursor: not-allowed;
        color: ${ncTheme.colors.disabled};
        font-weight: ${ncTheme.fontWeight.standard};
      }

      &[data-selected="true"] {
        background-color: ${ncTheme.colors.active};
        color: ${ncTheme.colors.light};
      }

      &[data-selection-start="true"] {
        border-radius: ${ncTheme.borderRadius.small} 0 0 ${ncTheme.borderRadius.small};
      }

      &[data-selection-end="true"] {
        border-radius: 0 ${ncTheme.borderRadius.small} ${ncTheme.borderRadius.small} 0;
      }
    `,
  },
};

const PasteHandler = ({ children }: { children: ReactNode }) => {
  const { setValue } = useContext(DatePickerStateContext) as {
    setValue: (value: DateValue) => void;
  };

  const onPaste = (e: ClipboardEvent) => {
    const pastedData = e.clipboardData?.getData("text");
    if (pastedData) {
      const normaliseString = pastedData.replaceAll(/[^0-9]/g, "");
      const parts = {
        day: normaliseString.slice(0, 2),
        month: normaliseString.slice(2, 4),
        year: normaliseString.slice(4, 8),
        hour: normaliseString.slice(8, 10),
        minute: normaliseString.slice(10, 12),
      };
      const dateString = `${parts.year}-${parts.month}-${parts.day}`;
      setValue(
        parts.hour && parts.minute
          ? parseDateTime(`${dateString}T${parts.hour}:${parts.minute}`)
          : parseDateTime(dateString)
      );
    }
  };
  return (
    <div
      tabIndex={-1}
      css={css`
        display: contents;
      `}
      onPaste={onPaste as unknown as ClipboardEventHandler<HTMLDivElement>}
    >
      {children}
    </div>
  );
};

export const PickerCalendar = () => {
  return (
    <Popover css={ncDateFieldStyles.calendar.popover}>
      <Dialog>
        <Calendar>
          <header css={ncDateFieldStyles.calendar.header}>
            <NcButton variant="icon" slot="previous">
              <NcIconChevronLeft />
            </NcButton>
            <Heading />
            <NcButton variant="icon" slot="next">
              <NcIconChevronRight />
            </NcButton>
          </header>
          <CalendarGrid css={ncDateFieldStyles.calendar.calendar}>
            {date => <CalendarCell date={date} css={ncDateFieldStyles.calendar.cell} />}
          </CalendarGrid>
        </Calendar>
      </Dialog>
    </Popover>
  );
};

export interface NcDateFieldProps extends PresetInputWidths, ReactAriaDateFieldProps<DateValue> {
  showPicker?: boolean;
  label: string;
  hideLabel?: boolean;
  description?: string;
  error?: FieldError;
  variant?: NcFieldLayoutProps["variant"];
}

export const NcDateField = forwardRef<HTMLInputElement, NcDateFieldProps>(
  (
    {
      label,
      hideLabel,
      description,
      error,
      isRequired,
      showPicker,
      inputWidth = "auto",
      shouldForceLeadingZeros = true,
      hourCycle = 24,
      variant,
      ...props
    },
    ref
  ) => {
    return (
      <ReactAriaDatePicker
        data-nc="NcDateField"
        shouldForceLeadingZeros={shouldForceLeadingZeros}
        hourCycle={hourCycle}
        {...props}
      >
        <NcFieldLayout variant={variant}>
          <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
            {label}
          </NcLabel>
          <PasteHandler>
            <Group css={[ncDateFieldStyles.group, inputWidthStyles[inputWidth]]}>
              <ReactAriaDateInput css={ncDateFieldStyles.inputs} ref={ref}>
                {segment => <DateSegment css={ncDateFieldStyles.segment} segment={segment} />}
              </ReactAriaDateInput>
              {showPicker && (
                <NcButton variant="icon" css={ncDateFieldStyles.pickerButton}>
                  <NcIconCalendar />
                </NcButton>
              )}
            </Group>
            {showPicker && <PickerCalendar />}
          </PasteHandler>
          {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
          <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
        </NcFieldLayout>
      </ReactAriaDatePicker>
    );
  }
);

export const dateValueToDate = (dateValue: DateValue | undefined) => {
  return dateValue ? dateValue.toDate(getLocalTimeZone()) : undefined;
};

export const dateTimeToDateValue = (date: Date | undefined) => {
  return date ? parseAbsoluteToLocal(date.toISOString()) : null;
};

import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { useAccount } from "~/account/account.context";
import { useMeQuery } from "~/account/use-me-query";
import { restPutter } from "~/graphql-hooks/custom-fetcher";

import { UnescapedTFunction, useI18n } from "./use-i18n";

type Organisation = {
  id: number;
  settingsJson: string;
};

export interface GenericTabOverviewSetting {
  visible: boolean;
  id: number;
  title: string;
}

export interface VisibilitySettingBoolean {
  visible: boolean;
}

interface VisibilitySettingValues {
  [key: string]: VisibilitySettingBoolean | GenericTabOverviewSetting[] | null;
}

export interface PatientOverviewSettingsValues extends VisibilitySettingValues {
  genericTabs: GenericTabOverviewSetting[] | null;
}

export interface RequiredFieldsSection {
  [key: string]: boolean;
}

export interface RequiredFieldsSettings {
  patient: RequiredFieldsSection;
}

export interface ClientInviteSettings {
  inviteHeading: string;
  inviteMessage: string;
  inviteButton: string;
  inviteSubject: string;
}

export interface OrganisationSettings {
  patientOverview: PatientOverviewSettingsValues;
  requiredFields: RequiredFieldsSettings;
  clientInviteDefaults: ClientInviteSettings;
  patientAddEdit: VisibilitySettingValues;
  patientRecords: VisibilitySettingValues;
}

export const defaultPatientOverviewSettings: PatientOverviewSettingsValues = {
  addresses: { visible: true },
  alerts: { visible: true },
  allergies: { visible: true },
  backgroundInfo: { visible: false },
  clientQuestionnaire: { visible: true },
  clientRelationships: { visible: true },
  clientTags: { visible: true },
  emergencyContact: { visible: true },
  goals: { visible: true },
  gpInformation: { visible: true },
  keyWorker: { visible: true },
  medicalHistory: { visible: true },
  medications: { visible: true },
  referrals: { visible: false },
  reminders: { visible: true },
  genericTabs: null,
};

export const defaultPatientAddEditSettings: VisibilitySettingValues = {
  addresses: { visible: true },
  alerts: { visible: true },
  allergies: { visible: true },
  clientQuestionnaire: { visible: true },
  clientRelationships: { visible: true },
  clientTags: { visible: true },
  contactDetails: { visible: true },
  ethnicity: { visible: true },
  goals: { visible: true },
  gpInformation: { visible: true },
  keyWorker: { visible: true },
  medicalHistory: { visible: true },
  medications: { visible: true },
  referrals: { visible: true },
};

export const defaultPatientRecordsSettings: VisibilitySettingValues = {
  alerts: { visible: true },
  allergies: { visible: true },
  clientQuestionnaire: { visible: true },
  goals: { visible: true },
  medicalHistory: { visible: true },
  medications: { visible: true },
};

export type PatientAddEditSettingsKey = keyof typeof defaultPatientAddEditSettings;

const defaultRequiredFields = {
  patient: {
    title: false,
    firstName: true,
    preferredName: false,
    middleName: false,
    gender: false,
    ethnicityType: false,
    occupation: false,
    medicalServiceId: false,
    dateOfBirth: false,
    primaryPhone: false,
    secondaryPhone: false,
    email: false,
    contactName: false,
    contactPhone: false,
    contactRelationship: false,
    gpName: false,
    gpPracticeName: false,
    gpPracticeAddress: false,
    gpPracticePhone: false,
    keyWorker: false,
  },
};

const getDefaultClientInviteSettings = (t: UnescapedTFunction) => ({
  inviteHeading: t("admin-client-settings:clientInvite.defaults.inviteHeading"),
  inviteMessage: t("admin-client-settings:clientInvite.defaults.inviteMessage"),
  inviteButton: t("admin-client-settings:clientInvite.defaults.inviteButton"),
  inviteSubject: t("admin-client-settings:clientInvite.defaults.inviteSubject"),
});

function migrateVisibilitySettings(
  settings: PatientOverviewSettingsValues | VisibilitySettingValues,
  defaultSettings: PatientOverviewSettingsValues | VisibilitySettingValues
) {
  const validatedSettings = validateKeysBasedOnDefaults(settings, defaultSettings);
  return addMissingDefaultKeys(validatedSettings, defaultSettings);
}

function validateKeysBasedOnDefaults(
  settings: VisibilitySettingValues,
  defaultSettings: VisibilitySettingValues
) {
  const settingsEntries = Object.entries(settings);
  const defaultKeys = Object.keys(defaultSettings);

  return Object.fromEntries(settingsEntries.filter(([key]) => defaultKeys.includes(key)));
}

function addMissingDefaultKeys(
  settings: VisibilitySettingValues,
  defaultSettings: VisibilitySettingValues
) {
  const settingsKeys = Object.keys(settings);
  const defaultKeys = Object.keys(defaultSettings);
  const updatedSettings = { ...settings };

  defaultKeys.forEach(defaultKey => {
    const isExistingSetting = settingsKeys.find(key => defaultKey === key);
    if (!isExistingSetting) {
      updatedSettings[defaultKey] = defaultSettings[defaultKey];
    }
  });
  return updatedSettings;
}

function useUpdateOrganisationSettingsMutation() {
  const queryClient = useQueryClient();
  return useMutation<Organisation, unknown, Organisation>({
    mutationFn: async (organisation: Organisation) =>
      restPutter(`/v1/organisations/${organisation.id}`, organisation),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useMeQuery.getKey() });
    },
  });
}

export const useOrganisationPersonalisationSettings = () => {
  const { organisation } = useAccount();
  const { t } = useI18n("admin-client-settings");
  const organisationSettings = useMemo(
    () => JSON.parse(organisation.settingsJson || "{}"),
    [organisation]
  ) as OrganisationSettings;

  const { mutate: updateOrganisation, ...mutationProperties } =
    useUpdateOrganisationSettingsMutation();

  const getOrganisationSettings = <K extends keyof OrganisationSettings>(
    key: K
  ): OrganisationSettings[K] => {
    switch (key) {
      case "patientOverview":
        return organisationSettings.patientOverview
          ? (migrateVisibilitySettings(
              organisationSettings.patientOverview,
              defaultPatientOverviewSettings
            ) as OrganisationSettings[K])
          : (defaultPatientOverviewSettings as OrganisationSettings[K]);
      case "requiredFields":
        return organisationSettings.requiredFields
          ? (organisationSettings.requiredFields as OrganisationSettings[K])
          : (defaultRequiredFields as unknown as OrganisationSettings[K]);
      case "clientInviteDefaults":
        return organisationSettings.clientInviteDefaults
          ? (organisationSettings.clientInviteDefaults as OrganisationSettings[K])
          : (getDefaultClientInviteSettings(t) as OrganisationSettings[K]);
      case "patientAddEdit":
        return organisationSettings.patientAddEdit
          ? (migrateVisibilitySettings(
              organisationSettings.patientAddEdit,
              defaultPatientAddEditSettings
            ) as OrganisationSettings[K])
          : (defaultPatientAddEditSettings as OrganisationSettings[K]);
      case "patientRecords":
        return organisationSettings.patientRecords
          ? (migrateVisibilitySettings(
              organisationSettings.patientRecords,
              defaultPatientRecordsSettings
            ) as OrganisationSettings[K])
          : (defaultPatientRecordsSettings as OrganisationSettings[K]);
      default:
        throw new Error(`Invalid key: ${key}`);
    }
  };

  const updateOrganisationSettingsMutation = async <K extends keyof OrganisationSettings>(
    settingsKey: K,
    settings: OrganisationSettings[K],
    queryOptions: UseMutationOptions<Organisation, unknown, Organisation>
  ) => {
    const newSettings = {
      ...organisationSettings,
      [settingsKey]: settings,
    };
    const settingsJson = JSON.stringify(newSettings);
    const { ...options } = queryOptions;
    updateOrganisation({ ...organisation, settingsJson }, options);
  };

  return {
    getOrganisationSettings,
    updateOrganisationSettings: {
      mutate: updateOrganisationSettingsMutation,
      ...mutationProperties,
    },
  };
};

import { NcAlert, NcAlertProps, NcButton, NcIconCross } from "@noted/noted-components";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { animated, useTransition } from "react-spring";

import { appInsideIframe as isIframe } from "../../../app-inside-iframe";
import config from "../../../routes/config";

const isStorybook = (() => {
  try {
    return window.parent?.location?.host === "localhost:9009";
  } catch {
    return false;
  }
})();
const appInsideIframe = isStorybook ? false : isIframe;
const TIMEOUT = 6000;

type ToastPosition =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";
type ToastChildren = ReactNode | ((e: { close: () => void }) => ReactNode);
interface IToast {
  id: number;
  notification: ToastChildren;
  options?: IToastOptions;
}
interface IToastOptions {
  timeout?: number | "persist";
  position?: ToastPosition;
}

const toastToParent = (message: string, type: string, dismiss = false) =>
  window.parent.postMessage(
    {
      toast: {
        message,
        type,
        config: dismiss
          ? { dismissButton: dismiss, dismissOnTimeout: false } // manual dismiss
          : { timeout: TIMEOUT }, // use custom timeout
      },
    },
    config.angularOrigin
  );

export const useToast = () => {
  const { toast } = useContext(ToastContext);
  const notify = useCallback(
    (
      message: string,
      options?: {
        variant?: NcAlertProps["variant"];
        position?: ToastPosition;
      }
    ) => {
      const variant = options?.variant ?? "default";
      const position = options?.position ?? "top-center";
      toast(toastBox(variant, message), {
        timeout: variant === "success" ? 3000 : "persist",
        position,
      });
    },
    [toast]
  );

  const enqueueSuccess = useCallback(
    (msg: string) =>
      appInsideIframe
        ? toastToParent(msg, "success")
        : notify(msg, { variant: "success", position: "top-center" }),
    [notify]
  );
  const enqueueError = useCallback(
    (msg: string) =>
      appInsideIframe
        ? toastToParent(msg, "danger", true)
        : notify(msg, { variant: "danger", position: "top-center" }),
    [notify]
  );
  const enqueueInfo = useCallback(
    (msg: string) =>
      appInsideIframe
        ? toastToParent(msg, "info", true)
        : notify(msg, { variant: "default", position: "top-center" }),
    [notify]
  );

  return {
    toast,
    notify,
    enqueueSuccess,
    enqueueError,
    enqueueInfo,
  };
};

export const ToastProvider = ({
  children,
  defaultTimeout = 3000,
  stack = 3,
}: {
  children: ReactNode;
  defaultTimeout?: number;
  stack?: number;
}) => {
  const [state, setState] = useState({ toasts: [] as IToast[], key: 0 });
  const toast = useCallback(
    (notification: ToastChildren, options?: IToastOptions) => {
      setState(oldState => {
        const newArr = [...oldState.toasts, { id: oldState.key, notification, options }];
        // Take out the last item
        const newToasts = new Array(Math.min(stack, newArr.length))
          .fill(undefined)
          .map((_, i) => i)
          .map(i => newArr[newArr.length - 1 - i])
          .reverse();
        return { key: oldState.key + 1, toasts: newToasts };
      });
    },
    [stack]
  );

  const removeToast = useCallback((id: number) => {
    setState(oldState => {
      return {
        toasts: oldState.toasts.filter(t => t.id !== id),
        key: oldState.key + 1,
      };
    });
  }, []);

  return (
    <>
      <ToastPortal {...{ defaultTimeout, state, removeToast }} />
      <MemoizedProvider {...{ toast, children }} />
    </>
  );
};

const MemoizedProvider = memo(
  ({
    toast,
    children,
  }: {
    children: ReactNode;
    toast: (t: ToastChildren, options?: IToastOptions) => void;
  }) => {
    return <ToastContext.Provider value={{ toast }}>{children}</ToastContext.Provider>;
  }
);
MemoizedProvider.displayName = "MemoizedProvider";

const ToastPortal = ({
  defaultTimeout,
  state,
  removeToast,
}: {
  defaultTimeout: number;
  removeToast: (id: number) => void;
  state: { toasts: IToast[]; key: number };
}) => {
  const reducedToasts = useMemo(() => {
    return state.toasts.reduce(
      (res, item) => {
        const position = item?.options?.position ?? "bottom-right";
        switch (position) {
          case "top-left":
            return { ...res, topLeft: [...res.topLeft, item] };
          case "top-center":
            return { ...res, topCenter: [...res.topCenter, item] };
          case "top-right":
            return { ...res, topRight: [...res.topRight, item] };
          case "bottom-left":
            return { ...res, bottomLeft: [...res.bottomLeft, item] };
          case "bottom-center":
            return { ...res, bottomCenter: [...res.bottomCenter, item] };
          case "bottom-right":
            return { ...res, bottomRight: [...res.bottomRight, item] };
        }
      },
      {
        topLeft: [] as IToast[],
        topCenter: [] as IToast[],
        topRight: [] as IToast[],
        bottomLeft: [] as IToast[],
        bottomCenter: [] as IToast[],
        bottomRight: [] as IToast[],
      }
    );
  }, [state]);

  const topLeft = useTransition(reducedToasts.topLeft, transition("left"));
  const topCenter = useTransition(reducedToasts.topCenter, transition("top"));
  const topRight = useTransition(reducedToasts.topRight, transition("right"));
  const bottomLeft = useTransition(reducedToasts.bottomLeft, transition("left"));
  const bottomCenter = useTransition(reducedToasts.bottomCenter, transition("bottom"));
  const bottomRight = useTransition(reducedToasts.bottomRight, transition("right"));

  return (
    <>
      <ToastContainer style={{ top: 0, right: 0 }}>
        {topRight((style, t) => (
          <AnimatedToast key={t.id} style={{ ...style, alignSelf: "flex-end" }}>
            <InnerToast {...{ t, removeToast, defaultTimeout }} />
          </AnimatedToast>
        ))}
      </ToastContainer>
      <ToastContainer style={{ top: 0, left: 0 }} right={0}>
        {topCenter((style, t) => (
          <AnimatedToast key={t.id} style={{ ...style, alignSelf: "center" }}>
            <InnerToast {...{ t, removeToast, defaultTimeout }} />
          </AnimatedToast>
        ))}
      </ToastContainer>
      <ToastContainer style={{ top: 0, left: 0 }}>
        {topLeft((style, t) => (
          <AnimatedToast key={t.id} style={{ ...style, alignSelf: "flex-start" }}>
            <InnerToast {...{ t, removeToast, defaultTimeout }} />
          </AnimatedToast>
        ))}
      </ToastContainer>
      <ToastContainer style={{ bottom: 0, right: 0 }}>
        {bottomRight((style, t) => (
          <AnimatedToast key={t.id} style={{ ...style, alignSelf: "flex-end" }}>
            <InnerToast {...{ t, removeToast, defaultTimeout }} />
          </AnimatedToast>
        ))}
      </ToastContainer>
      <ToastContainer style={{ bottom: 0, left: 0 }} right={0}>
        {bottomCenter((style, t) => (
          <AnimatedToast key={t.id} style={{ ...style, alignSelf: "center" }}>
            <InnerToast {...{ t, removeToast, defaultTimeout }} />
          </AnimatedToast>
        ))}
      </ToastContainer>
      <ToastContainer style={{ bottom: 0, left: 0 }}>
        {bottomLeft((style, t) => (
          <AnimatedToast key={t.id} style={{ ...style, alignSelf: "flex-start" }}>
            <InnerToast {...{ t, removeToast, defaultTimeout }} />
          </AnimatedToast>
        ))}
      </ToastContainer>
    </>
  );
};
ToastPortal.displayName = "ToastPortal";

const ToastContext = createContext<{
  toast: (styledToast: ToastChildren, options?: IToastOptions) => void;
}>({ toast: () => undefined });

const ToastContainer = ({ ...props }) => (
  <div
    className="pointer-events-none absolute inset-0 z-50 flex max-w-full flex-col gap-2 p-2"
    {...props}
  />
);

const AnimatedWrapper = ({ ...props }) => (
  <div className="pointer-events-auto max-w-full" {...props} />
);
const AnimatedToast = animated(AnimatedWrapper);

const InnerToast = (props: {
  t: IToast;
  removeToast: (id: number) => void;
  defaultTimeout: number;
}) => {
  const { t, removeToast, defaultTimeout } = props;
  const expiry = useMemo(
    () =>
      t.options === undefined || t.options.timeout === undefined
        ? defaultTimeout
        : t.options.timeout === "persist"
          ? undefined
          : t.options.timeout,
    [t.options, defaultTimeout]
  );

  const close = useCallback(() => removeToast(t.id), [removeToast, t.id]);

  return (
    <ToastTimeout removeToast={close} expiry={expiry}>
      {typeof t.notification === "function" ? t.notification({ close }) : t.notification}
    </ToastTimeout>
  );
};
InnerToast.displayName = "InnerToast";

const ToastTimeout = (props: { children: ReactNode; removeToast: () => void; expiry?: number }) => {
  const { removeToast, expiry, children } = props;
  useEffect(() => {
    if (expiry !== undefined) {
      const timer = setTimeout(() => {
        removeToast();
      }, expiry);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [expiry, removeToast]);
  return <>{children}</>;
};

function getAlertTextColor(variant: NcAlertProps["variant"]) {
  switch (variant) {
    case "success":
      return "text-success";
    case "danger":
      return "text-danger";
    default:
      return "text-main";
  }
}

type ToastBoxProps = NcAlertProps & { close?: () => void };

export const ToastBox = ({ variant = "default", children: message, close }: ToastBoxProps) => {
  if (typeof close === "function") {
    return (
      <NcAlert
        variant={variant}
        className="max-w-prose bg-light shadow-lg"
        action={
          <NcButton
            variant="icon"
            aria-label="Close alert"
            onPress={close}
            className={getAlertTextColor(variant)}
          >
            <NcIconCross />
          </NcButton>
        }
      >
        <div data-cy="toast-message" className={getAlertTextColor(variant)}>
          {message}
        </div>
      </NcAlert>
    );
  }
  return <NcAlert variant={variant}>{message}</NcAlert>;
};

const toastBox =
  // eslint-disable-next-line react/display-name
  (variant: NcAlertProps["variant"], message: string | ReactNode) => (e: { close: () => void }) => (
    <ToastBox {...{ variant, children: message, close: e.close }} />
  );

const transition = (place: "left" | "right" | "top" | "bottom") => {
  const maxHeight = "999px";
  if (place === "top" || place === "bottom") {
    return {
      from: {
        transform: `translate3d(0,${place === "top" ? "-" : ""}100%,0)`,
        opacity: 0,
        maxHeight,
      },
      enter: { transform: `translate3d(0,0px,0)`, opacity: 1, maxHeight },
      leave: {
        transform: `translate3d(0,${place === "top" ? "-" : ""}100%,0)`,
        opacity: 0,
        maxHeight: "0px",
      },
    };
  }
  return {
    from: { [place]: "-100%", opacity: 0, maxHeight },
    enter: { [place]: "0%", opacity: 1, maxHeight },
    leave: { [place]: "-100%", opacity: 0, maxHeight: "0px" },
  };
};

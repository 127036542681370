import { lazy } from "react";

export const retryLazy = (...params: Parameters<typeof lazy>) => lazy(() => retry(params));

const retry = ([fn]: Parameters<typeof lazy>, retriesLeft = 5, interval = 200) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry([fn], retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  }) as ReturnType<typeof fn>;
};

import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { useFilter } from "react-aria";
import { ComboBox as ReactAriaComboBox, Popover as ReactAriaPopover } from "react-aria-components";
import type { Key, ComboBoxProps as ReactAriaComboBoxProps } from "react-aria-components";
import { FieldError } from "react-hook-form";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { buttonStyles, NcButton } from "./nc-button";
import { NcFieldLayout, NcFieldLayoutProps } from "./nc-field-layout";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcIconChevronDown, NcIconCross } from "./nc-icons";
import type { PresetInputWidths } from "./nc-input";
import { inputWidthStyles, NcInput } from "./nc-input";
import { NcLabel } from "./nc-label";
import { NcListBox } from "./nc-list-box";
import { NcLoadingIndicator } from "./nc-loading-indicator";
import { popoverStyles } from "./nc-popover";

export const comboBoxStyles = {
  comboBox: css`
    position: relative;
  `,
  inputWrapper: css`
    position: relative;
    display: flex;
  `,
  input: css`
    padding-right: ${ncTheme.spacing(8)};
  `,
  action: css`
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding: ${ncTheme.spacing(2.5)};
    border-radius: ${ncTheme.borderRadius.small};
  `,
  loading: (isLoading: boolean | undefined) => css`
    background-color: ${ncTheme.colors.light};
    pointer-events: none;
    opacity: ${isLoading ? 1 : 0};
    transition: opacity ${ncTheme.transitionSpeed.fast} ease-in-out;
    padding-inline: ${ncTheme.spacing(2)};
  `,
  popover: css`
    padding: 0;
    min-width: var(--trigger-width);
    overflow-y: auto;
    max-height: 40vh !important;
  `,
  list: css`
    max-height: 40vh;
  `,
  item: css`
    &[data-focus-visible] {
      outline: none;
    }
  `,
};

const EmptyState = () => {
  const { t } = useI18n();
  return <NcFormattedMessage variant="secondary">{t("No items found")}</NcFormattedMessage>;
};

export type NcComboBoxItem = {
  id: number | string;
} & Record<string, Key | undefined>;

export interface NcComboBoxProps extends PresetInputWidths, ReactAriaComboBoxProps<NcComboBoxItem> {
  label: string;
  hideLabel?: boolean;
  description?: string;
  variant?: NcFieldLayoutProps["variant"];
  defaultItems?: Iterable<NcComboBoxItem>;
  items?: Iterable<NcComboBoxItem>;
  placeholder?: string;
  isLoading?: boolean;
  renderEmptyState?: () => ReactNode;
  isOpen?: boolean;
  onClearSelection?: () => void;
  error?: FieldError;
}

export const NcComboBox = forwardRef<HTMLInputElement, NcComboBoxProps>(
  (
    {
      label,
      hideLabel,
      description,
      items,
      defaultItems,
      defaultFilter,
      placeholder,
      renderEmptyState,
      isLoading,
      children,
      inputWidth = "auto",
      variant,
      isOpen,
      selectedKey,
      onClearSelection,
      error,
      isRequired,
      ...props
    },
    ref
  ) => {
    const { t } = useI18n();
    const { contains } = useFilter({ sensitivity: "base" });
    const itemFilter = (textValue: string, inputValue: string) => contains(textValue, inputValue);

    const showOpenAction = !onClearSelection || !selectedKey;

    return (
      <ReactAriaComboBox
        data-nc="NcComboBox"
        css={comboBoxStyles.comboBox}
        items={items}
        defaultItems={defaultItems}
        defaultFilter={defaultFilter ?? itemFilter}
        allowsEmptyCollection
        selectedKey={selectedKey}
        {...props}
      >
        <NcFieldLayout variant={variant}>
          <NcLabel isRequired={isRequired} hideLabel={hideLabel}>
            {label}
          </NcLabel>
          <div css={[comboBoxStyles.inputWrapper, inputWidthStyles[inputWidth]]}>
            <NcInput
              css={comboBoxStyles.input}
              placeholder={placeholder}
              aria-busy={isLoading}
              inputWidth={inputWidth}
              ref={ref}
            />
            {showOpenAction ? (
              <NcButton variant="icon" css={comboBoxStyles.action} aria-label={t("menu_open")}>
                <NcIconChevronDown aria-hidden="true" />
              </NcButton>
            ) : (
              <button
                css={[buttonStyles.base, buttonStyles.variant.icon, comboBoxStyles.action]}
                aria-label={t("clear_search")}
                onClick={onClearSelection}
              >
                <NcIconCross aria-hidden="true" />
              </button>
            )}
            <NcLoadingIndicator
              css={[comboBoxStyles.action, comboBoxStyles.loading(isLoading)]}
              aria-hidden="true"
            />
          </div>
          <ReactAriaPopover css={[popoverStyles.popover, comboBoxStyles.popover]} isOpen={isOpen}>
            {children ? (
              <>{children}</>
            ) : (
              <NcListBox
                renderEmptyState={renderEmptyState ? renderEmptyState : () => <EmptyState />}
                css={comboBoxStyles.list}
              >
                {(item: NcComboBoxItem) => (
                  <NcListBox.Item id={item.id} textValue={`${item.label}`}>
                    {item.label}
                  </NcListBox.Item>
                )}
              </NcListBox>
            )}
          </ReactAriaPopover>
          {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
          <NcFieldLayout.ErrorMessage>{error?.message}</NcFieldLayout.ErrorMessage>
        </NcFieldLayout>
      </ReactAriaComboBox>
    );
  }
);

import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import { Box } from "../primitives";
import Text from "../primitives/text";

export const BaseTableHeaderCell = styled(Text)`
  font-weight: bold;
  text-align: left;
  color: ${theme.colors.info.medium};
`.withComponent("th");

export const TableHeaderCell = forwardRef<
  HTMLTableCellElement,
  ComponentProps<typeof BaseTableHeaderCell>
>(({ p = 3, ...props }, ref) => <BaseTableHeaderCell p={p} ref={ref} {...props} />);

TableHeaderCell.displayName = "TableHeaderCell";

export default TableHeaderCell;

type SortableTableHeaderCellProps = ComponentProps<typeof TableHeaderCell> & {
  direction?: "DESC" | "ASC" | false | undefined;
};

export const SortableTableHeaderCell = forwardRef<
  HTMLTableHeaderCellElement,
  SortableTableHeaderCellProps
>((props, ref) => {
  const { children, direction, ...other } = props;
  const style = direction
    ? {
        display: "grid",
        columnGap: 8,
        gridTemplateColumns: "auto 1fr",
      }
    : {};
  return (
    <TableHeaderCell
      ref={ref}
      display={direction ? "grid" : undefined}
      {...other}
      style={{
        cursor: "pointer",
        userSelect: direction ? "none" : undefined,
        ...style,
      }}
    >
      <div className="group">
        <span className="group-hover:underline group-focus:underline">{children}</span>
      </div>
      {direction && <SortIcon dir={direction}>{"▲"}</SortIcon>}
    </TableHeaderCell>
  );
});
SortableTableHeaderCell.displayName = "SortableTableHeaderCell";

const SortIcon = styled(Box)<{ dir: "DESC" | "ASC" }>`
  transition: all 0.6s ease;
  transform: ${props => (props.dir === "DESC" ? "" : "rotate3d(1,0,0,180deg)")};
  color: ${theme.colors.info.medium};
  font-size: 1em;
  line-height: 1.6em;
`;

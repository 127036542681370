import { z } from "zod";

export function svgToDataUri(svg: string) {
  return `data:image/svg+xml,${encodeURI(svg).replace(/#/g, "%23")}`;
}

function getFieldSchema(schema: z.ZodTypeAny, fieldName: string) {
  const shape = schema._def.schema.shape;
  return shape[fieldName];
}

function isOptionalField(schema: z.ZodTypeAny) {
  return schema.isOptional() || schema.isNullable();
}

/**
 * WIP function to determine if a field is required based on the schema.
 * Returns true if the field is required, false otherwise.
 */
export function isRequiredField(schema: z.ZodTypeAny, fieldName: string) {
  const fieldSchema = getFieldSchema(schema, fieldName);
  if (isOptionalField(fieldSchema)) {
    return false;
  }
  if (fieldSchema._def?.minLength) {
    return fieldSchema._def.minLength.value > 0;
  }

  if (fieldSchema._def?.checks?.length) {
    const min =
      fieldSchema._def.checks?.find((check: { kind: string }) => check.kind === "min")?.value ?? 0;
    return min > 0;
  }

  if (fieldSchema._def?.typeName === "ZodDate") {
    return !isOptionalField(fieldSchema);
  }

  return false;
}

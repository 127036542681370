import { DateTime } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { type Appointment, type Client, type Service } from "~/graphql-hooks/types";

import { IResource } from "../resources-context";
import { ITimeslot } from "../timeslots-state-utils";

interface IAppointmentFormContext {
  appointmentId?: number;
  setAppointmentId: Dispatch<SetStateAction<number | undefined>>;
  service?: Service;
  setService: Dispatch<SetStateAction<Service | undefined>>;
  workerId?: number;
  setWorkerId: Dispatch<SetStateAction<number | undefined>>;
  spaceId?: number;
  setSpaceId: Dispatch<SetStateAction<number | undefined>>;
  date?: DateTime;
  setDate: Dispatch<SetStateAction<DateTime | undefined>>;
  placeholderDate?: DateTime;
  setPlaceholderDate: Dispatch<SetStateAction<DateTime | undefined>>;
  client?: Client;
  setClient: Dispatch<SetStateAction<Client | undefined>>;
  initialNotes?: string;
  setInitialNotes: Dispatch<SetStateAction<string | undefined>>;
  clearFormSafe: () => void;
  clear: () => void;
  isServiceSelectable: boolean;
  setIsServiceSelectable: Dispatch<SetStateAction<boolean>>;
  setFormFromAppointment: (appointment: Appointment) => void;
  telehealth?: boolean;
  setTelehealth: Dispatch<SetStateAction<boolean | undefined>>;
  autoCompleteFromTimeslotSelection: (resource: IResource, ts: ITimeslot) => void;
}

export const AppointmentFormContext = createContext<IAppointmentFormContext>(
  undefined as unknown as IAppointmentFormContext
);
export const useAppointmentForm = () => useContext(AppointmentFormContext);

export const AppointmentFormState = ({ children }: { children: ReactNode }) => {
  const [appointmentId, setAppointmentId] = useState<number>();
  const [isServiceSelectable, setIsServiceSelectable] = useState(true);

  const [service, setService] = useState<Service>();
  const [telehealth, setTelehealth] = useState<boolean>();
  const [workerId, setWorkerId] = useState<number>();
  const [spaceId, setSpaceId] = useState<number>();
  const [date, setDate] = useState<DateTime>();
  const [placeholderDate, setPlaceholderDate] = useState<DateTime>();
  const [client, setClient] = useState<Client>();
  const [initialNotes, setInitialNotes] = useState<string>();

  // only clears service if service is selectable and does not clear appointmentId
  // same with client
  const clearFormSafe = useCallback(() => {
    if (isServiceSelectable) {
      setService(undefined);
      setClient(undefined);
    }
    setWorkerId(undefined);
    setSpaceId(undefined);
    setDate(undefined);
    setPlaceholderDate(undefined);
    setInitialNotes("");
    setTelehealth(undefined);
  }, [isServiceSelectable]);

  // clears everything
  const clear = useCallback(() => {
    setAppointmentId(undefined);
    setIsServiceSelectable(true);
    setService(undefined);
    setWorkerId(undefined);
    setSpaceId(undefined);
    setDate(undefined);
    setClient(undefined);
    setInitialNotes("");
    setTelehealth(undefined);
  }, []);

  const setFormFromAppointment = useCallback(
    (appointment: Appointment, serviceIsSelectable = true) => {
      setIsServiceSelectable(serviceIsSelectable);
      setAppointmentId(appointment.id);
      setService(appointment?.service ?? undefined);
      setDate(DateTime.fromMillis(appointment.from));
      setInitialNotes(appointment.notes ?? undefined);
      setClient(appointment.client ?? undefined);
      setWorkerId(
        appointment.workers?.length ? (appointment.workers[0]?.id ?? undefined) : undefined
      );
      setSpaceId(appointment.spaces?.length ? (appointment.spaces[0]?.id ?? undefined) : undefined);
      setTelehealth(appointment.telehealth ?? undefined);
    },
    []
  );

  const autoCompleteFromTimeslotSelection = useCallback(
    (resource: IResource, ts: ITimeslot) => {
      setDate(DateTime.fromMillis(ts.from));
      if (resource.type === "worker") {
        setWorkerId(resource.id);
        if (!spaceId && ts.spaceIds && ts.spaceIds.length) {
          // set any valid space
          setSpaceId(ts.spaceIds[0]);
        }
      } else if (resource.type === "space") {
        setSpaceId(resource.id);
        if (!workerId && ts.workerIds && ts.workerIds.length) {
          // set any valid worker
          setWorkerId(ts.workerIds[0]);
        }
      }
    },
    [spaceId, workerId]
  );

  const value = useMemo(
    () => ({
      service,
      setService,
      workerId,
      setWorkerId,
      spaceId,
      setSpaceId,
      date,
      setDate,
      placeholderDate,
      setPlaceholderDate,
      client,
      setClient,
      initialNotes,
      setInitialNotes,
      clearFormSafe,
      clear,
      appointmentId,
      setAppointmentId,
      isServiceSelectable,
      setIsServiceSelectable,
      setFormFromAppointment,
      telehealth,
      setTelehealth,
      autoCompleteFromTimeslotSelection,
    }),
    [
      appointmentId,
      clear,
      clearFormSafe,
      client,
      date,
      initialNotes,
      isServiceSelectable,
      placeholderDate,
      service,
      setFormFromAppointment,
      spaceId,
      telehealth,
      workerId,
      autoCompleteFromTimeslotSelection,
    ]
  );

  return (
    <AppointmentFormContext.Provider value={value}>{children}</AppointmentFormContext.Provider>
  );
};

import { config } from "@noted/configuration";
import { NcPage } from "@noted/noted-components";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "~/auth-provider";
import { setToken } from "~/global-token";
import { restPoster } from "~/graphql-hooks/custom-fetcher";
import { LoginResultType } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { Captcha } from "~/shared/captcha/captcha";
import { useToast } from "~/shared/components/alerts/alerts";
import { ButtonAsText, LinkAsButton } from "~/shared/components/buttons";
import { Button } from "~/shared/components/forms";
import { PagePublic, Shell } from "~/shared/components/layout";
import MesageBox from "~/shared/components/message-box/message-box";
import {
  OverlayMessageBox,
  OverlayMessageBoxContent,
  OverlayMessageBoxHighlight,
} from "~/shared/components/overlay-message-box";
import { Flex, Grid, Text } from "~/shared/components/primitives";
import Box from "~/shared/components/primitives/box";
import { Info, LogoIcon } from "~/shared/icons";
import { theme } from "~/shared/theme";
import { Feedback, Form, FormRow, Input, Label } from "~/shared/ui";

import { AuthenticateMutation, useAuthenticateMutation } from "./api";

type LoginError = { type: Omit<LoginResultType, "SUCCESS"> };

function isApiOnlyUser(authentication: AuthenticateMutation["authenticate"]) {
  return Boolean(authentication?.roles.includes("API_ONLY"));
}

const Login = () => {
  const [username, setUsername] = useState("");
  const [usernameForReset, setUsernameForReset] = useState("");
  const [password, setPassword] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const { t } = useI18n("account");
  const { enqueueError, enqueueSuccess } = useToast();
  const navigate = useNavigate();
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [captcha, setCaptcha] = useState<string>();
  const { logout } = useAuth();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const hasTimedOut = searchParams.get("redirectTo");
  const useLegacyLogin = searchParams.get("legacy");
  const usernameIsEmail = username.includes("@");

  useEffect(() => {
    if (useLegacyLogin) {
      return localStorage.removeItem("usesNotedIdLogin");
    }
    if (localStorage.getItem("usesNotedIdLogin")) {
      window.location.href = config.landingUrl;
    }
  }, []);

  const {
    mutate: login,
    isPending,
    data,
    error: fail,
  } = useAuthenticateMutation<LoginError>({
    onSuccess: res => {
      if (res?.authenticate?.result === LoginResultType.Success) {
        const {
          authenticate: { shouldChangePasswordHint, token, lastLogin },
        } = res;
        setToken(token ?? undefined);
        if (shouldChangePasswordHint) {
          navigate(`/update-password?firstLogin=${!lastLogin}`);
        } else if (token) {
          if (hasTimedOut) {
            const navigationPath = new URLSearchParams(location.search).get("redirectTo");
            if (navigationPath) {
              navigate(navigationPath);
              return;
            }
          }

          navigate(isApiOnlyUser(res.authenticate) ? "/api-only-user" : "/dashboard");
        }
      }
    },
    onError: () => setIncorrectAttempts(i => i + 1),
  });

  const { mutate: requestPasswordReset } = useMutation({
    mutationFn: (username: string) =>
      restPoster("/v1/auth/triggerResetPasswordEmail", { username }),
    onSuccess: () => {
      enqueueSuccess(t("account:login.password_reset_success"));
    },
    onError: () => {
      enqueueError(t("account:login.password_reset_failure"));
    },
  });

  const onSendResetPressed = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestPasswordReset(usernameForReset);
  };

  const backToLogin = () => {
    setUsernameForReset("");
    setIsResettingPassword(false);
  };

  useEffect(() => {
    logout();
  }, [logout]);

  const error =
    (fail && fail.type) ||
    (data?.authenticate?.result &&
      data.authenticate.result !== LoginResultType.Success &&
      data.authenticate.result);

  const isCaptchaRequired = incorrectAttempts > 2;

  return (
    <Shell>
      <PagePublic>
        <Flex flexDirection="column" flexGrow="1" maxWidth="32rem">
          <OverlayMessageBox flexBasis="auto">
            <OverlayMessageBoxHighlight>
              <LogoIcon />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              {isResettingPassword ? (
                <Box>
                  <NcPage.Heading className="mb-4">
                    {t("account:login.reset_password_title")}
                  </NcPage.Heading>
                  <Form onSubmit={onSendResetPressed} gridGap="4">
                    <FormRow>
                      <Label htmlFor="usernameForReset" data-testid="username-label">
                        {t("account:login.username")}
                      </Label>
                      <Input
                        id="usernameForReset"
                        data-testid="username-input"
                        value={usernameForReset}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setUsernameForReset(e.target.value)
                        }
                      />
                    </FormRow>
                    <Flex my="3" alignItems="center">
                      <Button variant="primary" type="submit" mr="6">
                        {t("account:login.reset_password_button")}
                      </Button>
                      <ButtonAsText onClick={backToLogin}>
                        {t("account:login.back_to_login_button")}
                      </ButtonAsText>
                    </Flex>
                  </Form>
                </Box>
              ) : error === "EULA_NOT_AGREED" ? (
                <Grid gridGap="5">
                  <NcPage.Heading>{t("account:login.eula_title")}</NcPage.Heading>
                  <Text>{t("account:login.eula_description")}</Text>
                  <Flex gridGap="2" mb="2">
                    <Button
                      variant="primary"
                      onClick={() =>
                        login({ input: { username, password, eulaAgreed: true, captcha } })
                      }
                    >
                      {t("account:login.eula_agree_button")}
                    </Button>
                    <LinkAsButton to="https://noted.com/standard-terms-of-use" target="_blank">
                      {t("account:login.view_eula_button")}
                    </LinkAsButton>
                  </Flex>
                </Grid>
              ) : (
                <>
                  <NcPage.Heading className="mb-4">{t("account:login.sign_in")}</NcPage.Heading>
                  {error === "INCORRECT_CREDENTIALS" ? (
                    <MesageBox variant="warning" mb="4" data-testid="login-error">
                      {t("account:login.incorrect_credentials")}
                    </MesageBox>
                  ) : error === "ACCOUNT_SUSPENDED" ? (
                    <MesageBox variant="danger" mb="4" data-testid="login-error">
                      {t("account:login.account_suspended")}
                    </MesageBox>
                  ) : error === "LOCKED_ACCOUNT" ? (
                    <MesageBox variant="danger" mb="4" data-testid="login-error">
                      {t("account:login.locked_account")}
                    </MesageBox>
                  ) : (
                    error && (
                      <MesageBox variant="warning" mb="4" data-testid="login-error">
                        {t("account:login.unexpected_error")}
                      </MesageBox>
                    )
                  )}
                  {hasTimedOut && !error && (
                    <MesageBox variant="info" mb="4" data-testid="login-error">
                      {t("account:login.session_timeout")}
                    </MesageBox>
                  )}
                  <Form
                    data-testid="login-form"
                    gridGap="4"
                    onSubmit={e => {
                      e.preventDefault();
                      login({
                        input: {
                          username,
                          password,
                          eulaAgreed: false,
                          captcha: captcha ?? "",
                        },
                      });
                    }}
                  >
                    <FormRow>
                      <Label htmlFor="username" data-testid="username-label">
                        {t("account:login.username")}
                      </Label>
                      <Input
                        id="username"
                        data-testid="username-input"
                        value={username}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                      />
                      {usernameIsEmail && (
                        <Feedback variant="info">
                          <span>{t("account:login.noted_id.username_validation.description")}</span>{" "}
                          <a href={config.landingUrl}>
                            {t("account:login.noted_id.username_validation.action")}
                          </a>
                        </Feedback>
                      )}
                    </FormRow>
                    <FormRow>
                      <Label htmlFor="password" data-testid="password-label">
                        {t("account:login.password")}
                      </Label>
                      <Input
                        id="password"
                        data-testid="password-input"
                        type="password"
                        value={password}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                      />
                    </FormRow>
                    {isCaptchaRequired && (
                      <Flex justifyContent="space-around">
                        <Captcha
                          onVerify={token => setCaptcha(token)}
                          onError={() => setCaptcha(undefined)}
                          onExpire={() => setCaptcha(undefined)}
                        />
                      </Flex>
                    )}
                    <Flex mt="3" mb="5" alignItems="center">
                      <Button
                        variant="primary"
                        type="submit"
                        data-testid="login-submit-button"
                        width="7.5rem"
                        mr="6"
                        disabled={isPending || (isCaptchaRequired && !captcha)}
                      >
                        {t("account:login.login_button")}
                      </Button>
                      <ButtonAsText onClick={() => setIsResettingPassword(true)}>
                        {t("account:login.forgot_password_button")}
                      </ButtonAsText>
                    </Flex>
                  </Form>
                  <Text
                    fontSize="1"
                    dangerouslySetInnerHTML={{
                      __html: t("account:login.terms_of_use_description", {
                        termsPageUrl: config.termsPageUrl,
                      }),
                    }}
                  />
                </>
              )}
            </OverlayMessageBoxContent>
          </OverlayMessageBox>

          <OverlayMessageBox flexBasis="auto">
            <div className="flex grow flex-wrap items-center justify-center gap-2 bg-light p-4">
              <div className="flex shrink items-center gap-2">
                <Info fill={theme.colors.info.mediumDark} />
                <span>{t("account:login.noted_id.description")}</span>
              </div>
              <a href={config.landingUrl}>{t("account:login.noted_id.action")}</a>
            </div>
          </OverlayMessageBox>
        </Flex>
      </PagePublic>
    </Shell>
  );
};

export default Login;

import { NcPage } from "@noted/noted-components";
import { ReactNode } from "react";

import { useI18n } from "~/hooks/use-i18n";
import { BackToParent } from "~/shared/components/navigation/back-to-parent";
import { Box, Flex } from "~/shared/components/primitives";

export function UserPageHeader({ children, title }: { children?: ReactNode; title: string }) {
  const { t } = useI18n("admin-users");

  return (
    <NcPage.Header className="block">
      <BackToParent to={`/admin/users/`} text={t("admin-users:back_to_users")} />
      <Flex width="100%">
        <NcPage.Heading>{title}</NcPage.Heading>
        <Box mr="auto" />
        {children}
      </Flex>
    </NcPage.Header>
  );
}

import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import Box from "../primitives/box";

export const BaseTableRow = styled(Box)`
  border-color: ${theme.colors.neutral.medium};
  &:last-of-type {
    border-color: transparent;
  }
`.withComponent("tr");

export const TableRow = forwardRef<HTMLTableRowElement, ComponentProps<typeof BaseTableRow>>(
  ({ alignItems = "center", borderBottom = "solid 1px", ...props }, ref) => (
    <BaseTableRow alignItems={alignItems} borderBottom={borderBottom} ref={ref} {...props} />
  )
);

TableRow.displayName = "TableRow";

export default TableRow;

import { ComponentProps, forwardRef } from "react";

import styled from "../../theme";
import Box from "../primitives/box";

const BaseTable = styled(Box)`
  border-collapse: collapse;
`.withComponent("table");

export const Table = forwardRef<HTMLTableElement, ComponentProps<typeof BaseTable>>(
  ({ width = "100%", ...props }, ref) => <BaseTable width={width} ref={ref} {...props} />
);

Table.displayName = "Table";

export default Table;

import { zodResolver } from "@hookform/resolvers/zod";
import { NcPage } from "@noted/noted-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useReadOnly } from "~/account/account.context";
import {
  BillingMethod,
  type AccServiceCode,
  type AccUserDetails,
  type Maybe,
} from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/alerts";
import { DropdownSearch } from "~/shared/components/choice/dropdown-search";
import { SearchableMultiSelect } from "~/shared/components/choice/searchable-multi-select";
import { ErrorLoadingText } from "~/shared/components/errors/error-loading-text";
import { Button, CheckboxButtonGroup, RadioButtonGroup } from "~/shared/components/forms";
import { LoadingText } from "~/shared/components/loading/loading-text";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "~/shared/components/modal";
import { Text } from "~/shared/components/primitives";
import { Feedback, Form, FormGroup, FormRow, Input2 as Input, Label, Toggle } from "~/shared/ui";

import { IContractCode, IServiceCode, useACCJSON } from "../../use-acc-json";
import {
  useDeleteUserAccDetailsMutation,
  useGetUserAccDetailsQuery,
  useUpdateUserAccDetailsMutation,
} from "../api";
import { UserPageHeader } from "./user-page-header";

const billingOptions = [
  { key: BillingMethod.Flatfee, value: "Flat fee" },
  { key: BillingMethod.Time, value: "Time" },
];

const providerTypeOptions = [
  { key: "STANDARD", value: "Select from list" },
  { key: "CUSTOM", value: "Enter manually" },
];

interface IAccProviderSettingsProps {
  user: {
    id: number;
    name: string;
  };
}

export const AccProviderSettings = ({ user }: IAccProviderSettingsProps) => {
  const { t } = useI18n();
  const { serviceCodes, contractCodes } = useACCJSON();

  const { data, isError: error } = useGetUserAccDetailsQuery({ id: user.id });

  if (!data?.user || !serviceCodes.length || !contractCodes.length) {
    return <LoadingText text={t("loading")} />;
  }

  if (error) {
    return <ErrorLoadingText text={t("acc_failed_to_load")} />;
  }

  return (
    <AccProviderSettingsForm
      user={user}
      settings={data.user.accUserDetails}
      serviceCodes={serviceCodes}
      contractCodes={contractCodes}
    />
  );
};

const formSchema = z.object({
  providerId: z.string(),
  providerTypeOption: z.string(),
  contractId: z.string().min(1, "acc_provider_type_required"),
  billingMethod: z.array(z.nativeEnum(BillingMethod)).min(1, "acc_billing_method_required"),
  accServiceCode: z
    .array(z.object({ code: z.number().or(z.string()), info: z.string() }))
    .min(1, "acc_services_required"),
});

type FormType = z.infer<typeof formSchema>;

function AccProviderSettingsForm({
  user,
  serviceCodes,
  contractCodes,
  settings,
}: IAccProviderSettingsProps & {
  serviceCodes: IServiceCode[];
  contractCodes: IContractCode[];
  settings:
    | (Pick<
        AccUserDetails,
        "accProviderId" | "accContractId" | "accVendorId" | "allowedBillingMethods"
      > & { accServiceCode?: Maybe<Array<Maybe<Pick<AccServiceCode, "id" | "code">>>> })
    | null
    | undefined;
}) {
  const { t } = useI18n();
  const readOnlyMode = useReadOnly();
  const { enqueueError, enqueueSuccess } = useToast();

  const queryClient = useQueryClient();

  const { mutateAsync: onUpdateAccDetails, isPending: updatePending } =
    useUpdateUserAccDetailsMutation({
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: useGetUserAccDetailsQuery.getKey({ id: user.id }),
        }),
    });

  const { mutateAsync: onDeleteAccDetails, isPending: deletePending } =
    useDeleteUserAccDetailsMutation({
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: useGetUserAccDetailsQuery.getKey({ id: user.id }),
        }),
    });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      providerId: settings?.accProviderId ?? "",
      providerTypeOption:
        !settings?.accContractId || contractCodes.find(c => c.code === settings.accContractId)
          ? "STANDARD"
          : "CUSTOM",
      contractId: settings?.accContractId ?? "",
      billingMethod: settings?.allowedBillingMethods ?? [],
      accServiceCode:
        settings?.accServiceCode?.map(x => {
          return serviceCodes
            .map(({ code, info }) => ({ code, info }))
            .find(s => s.code === x?.code);
        }) ?? [],
    },
  });

  const onSubmit = handleSubmit(async values => {
    if (updatePending || readOnlyMode) return;

    try {
      await onUpdateAccDetails({
        input: {
          allowedBillingMethods: values.billingMethod,
          accContractId: values.contractId,
          accProviderId: values.providerId,
          accServiceCode: values.accServiceCode.map(service => ({ code: String(service.code) })),
          userId: user.id,
        },
      });
      enqueueSuccess(t("user_acc_saved"));
    } catch (e) {
      enqueueError(t("user_acc_save_error"));
    }
  });

  const providerTypeOption = watch("providerTypeOption");

  const [accDisableModal, setAccDisableModal] = useState(false);
  const [accEnabled, setAccEnabled] = useState(!!settings);

  const onAccToggle = () => {
    if (accEnabled) {
      // Render modal, delete acc details
      setAccDisableModal(true);
      return;
    }

    setAccEnabled(true);
  };

  const confirmDisableAcc = async () => {
    // Delete acc details
    try {
      await onDeleteAccDetails({ userId: user.id });
      enqueueSuccess(t("user_acc_deleted"));
      setAccEnabled(false);
      setAccDisableModal(false);
      reset();
    } catch (e) {
      enqueueError(t("user_acc_delete_error"));
    }
  };

  const cancelDisableAcc = () => {
    setAccEnabled(true);
    setAccDisableModal(false);
  };

  return (
    <>
      <Form data-testid="acc-provider-settings-form" onSubmit={onSubmit} gridGap="5">
        <FormRow variant="horizontal">
          <Toggle id="accToggle" checked={accEnabled} onChange={onAccToggle} />
          <Label htmlFor="accToggle">{t("enable_acc_provider")}</Label>
        </FormRow>

        {accEnabled && (
          <>
            <FormGroup className="mb-0 pb-0">
              <legend>{t("admin-users:acc_provider_settings")}</legend>

              <FormRow>
                <Label htmlFor="providerId">{t("acc_provider_id")}</Label>
                <Input {...register("providerId")} id="providerId" />
                <p>{t("acc_provider_id_description")}</p>
              </FormRow>

              <FormRow>
                <Label htmlFor="providerType">{t("acc_provider_type")}</Label>
                <Controller
                  control={control}
                  name="providerTypeOption"
                  render={({ field }) => (
                    <RadioButtonGroup
                      name={field.name}
                      value={field.value}
                      onChange={x => {
                        field.onChange(x);
                        setValue("contractId", "");
                      }}
                      options={providerTypeOptions}
                    />
                  )}
                />
              </FormRow>

              <FormRow>
                <Text>{t("acc_provider_type_option_description")}</Text>
                {providerTypeOption === "STANDARD" && (
                  <>
                    <Controller
                      control={control}
                      name="contractId"
                      render={({ field }) => (
                        <DropdownSearch
                          id="acc-contract-id-dropdown"
                          items={contractCodes}
                          getItemId={item => item.code}
                          getItemText={item =>
                            (item.name && item.code && `${item.name} - ${item.code}`) || ""
                          }
                          onItemChange={item => field.onChange(item?.code)}
                          item={contractCodes.find(c => c.code === field.value)}
                        />
                      )}
                    />
                  </>
                )}

                {providerTypeOption === "CUSTOM" && (
                  <>
                    <Input
                      {...register("contractId")}
                      placeholder={t("acc_provider_type_manual_placeholder")}
                    />
                  </>
                )}
                {errors.contractId && <Feedback>{t(errors.contractId.message || "")}</Feedback>}
              </FormRow>

              <FormRow>
                <Label htmlFor="billingMethod">{t("acc_billing_method")}</Label>
                <Controller
                  control={control}
                  name="billingMethod"
                  render={({ field }) => (
                    <CheckboxButtonGroup
                      options={billingOptions}
                      selectedOptions={field.value}
                      onButtonClick={value => {
                        if (field.value.includes(value)) {
                          field.onChange(field.value.filter(v => v !== value));
                          return;
                        }
                        field.onChange([...field.value, value]);
                      }}
                    />
                  )}
                />
                {errors.billingMethod && (
                  <Feedback>{t(errors.billingMethod.message || "")}</Feedback>
                )}
              </FormRow>

              <FormRow>
                <Label htmlFor="services">{t("acc_services")}</Label>
                <Controller
                  control={control}
                  name="accServiceCode"
                  render={({ field }) => (
                    <SearchableMultiSelect
                      options={serviceCodes.map(({ code, info }) => ({ code, info }))}
                      selectedOptions={field.value}
                      onSelectOption={o => field.onChange([...field.value, o])}
                      onDeselectOption={o =>
                        field.onChange(field.value.filter(v => v.code !== o.code))
                      }
                      getOptionId={o => o.code || ""}
                      getOptionLabel={o => (o.code && o.info && `${o.code} - ${o.info}`) || ""}
                      highlightLastSelectedOption={true}
                    />
                  )}
                />
                {errors.accServiceCode && (
                  <Feedback>{t(errors.accServiceCode.message || "")}</Feedback>
                )}
              </FormRow>
            </FormGroup>

            <FormRow variant="submit" mt="0">
              <Button
                disabled={readOnlyMode || updatePending}
                data-testid="user-submit-button"
                variant="primary"
                type="submit"
              >
                {t("acc_submit_button")}
              </Button>
              <Button data-testid="user-discard-button" onClick={() => reset()}>
                {t("admin-users:discard_changes")}
              </Button>
            </FormRow>
          </>
        )}
      </Form>

      {accDisableModal && (
        <Modal handleExit={cancelDisableAcc} data-testid="acc-disable-modal">
          <ModalHeader>{t("disable_user_acc_settings_title")}</ModalHeader>

          <ModalBody>
            <p>{t("disable_user_acc_settings_description")}</p>
          </ModalBody>

          <ModalFooter className="flex justify-end gap-2">
            <Button disabled={deletePending} data-testid="cancel-button" onClick={cancelDisableAcc}>
              {t("cancel")}
            </Button>
            <Button
              disabled={readOnlyMode || updatePending || deletePending}
              variant="primary"
              data-testid="user-acc-delete-button"
              onClick={confirmDisableAcc}
            >
              {t("confirm_user_acc_settings_disable")}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export const AccProviderSettingsPage = ({ user }: IAccProviderSettingsProps) => {
  const { t } = useI18n("admin-users");
  return (
    <NcPage data-testid="acc-provider-page">
      <NcPage.Wrapper>
        <UserPageHeader title={t("admin-users:acc_provider_settings_title", { name: user.name })} />
        <NcPage.Body data-testid="acc-provider-content">
          <AccProviderSettings user={user} />
        </NcPage.Body>
      </NcPage.Wrapper>
    </NcPage>
  );
};

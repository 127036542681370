import { NcPage, NcPanel } from "@noted/noted-components";

import { useFeatures } from "~/account/features.context";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import PrimhdRecordErrorsPanel from "~/primhd/records/record-errors-panel";
import { LoadingLogo } from "~/shared/components/loading/loading-logo";

import AppointmentsPanel from "./appointments/appointments-panel";
import ClientActions from "./clients/client-actions";
import InvitesPanel from "./invitations-panel";
import RemindersPanel from "./reminders/reminders-panel";
import ClientSearch from "./search/client-search";
import UnsignedRecordsPanel from "./unsigned-records-panel";
import UpdatedRecordsPanel from "./updated-records-panel";

const Dashboard = () => {
  const { t } = useI18n("org");
  const { featureEnabled, featuresLoaded } = useFeatures();

  const showAppointments = featureEnabled(FeaturePermission.BookingSystem);
  const showPrimhdErrors = featureEnabled(FeaturePermission.Primhd);

  return (
    <NcPage>
      <NcPage.Wrapper>
        <NcPage.Heading className="sr-only">{t("org:dashboard.page_title")}</NcPage.Heading>
        <NcPage.Body className="gap-0">
          <NcPanel className="mb-5 gap-2 overflow-visible p-3 sm:flex-row">
            <ClientSearch />
            <ClientActions />
          </NcPanel>
          <div className="grid auto-rows-[minmax(24rem,28vh)] grid-cols-[repeat(auto-fit,minmax(27rem,1fr))] gap-8">
            {!featuresLoaded ? (
              <LoadingLogo center />
            ) : (
              <>
                <UnsignedRecordsPanel />
                <RemindersPanel />
                {showAppointments && <AppointmentsPanel />}
                <UpdatedRecordsPanel />
                <InvitesPanel />
                {showPrimhdErrors && <PrimhdRecordErrorsPanel />}
              </>
            )}
          </div>
        </NcPage.Body>
      </NcPage.Wrapper>
    </NcPage>
  );
};

export default Dashboard;

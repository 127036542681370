import { ComponentProps, forwardRef } from "react";

import { Box } from "../components/primitives";
import styled, { theme } from "../theme";

const BaseLabel = styled(Box)`
  color: ${theme.colors.neutral.darkest};
  box-sizing: border-box;
  font-family: inherit;
  cursor: pointer;
  &:first-letter {
    text-transform: uppercase;
  }
  input,
  textarea,
  select {
    font-weight: normal;
  }
`.withComponent("label");

const Label = forwardRef<HTMLLabelElement, ComponentProps<typeof BaseLabel>>(
  ({ mt = 0, mb = 0, lineHeight = 1.3, width = "100%", fontWeight = 600, ...props }, ref) => (
    <BaseLabel
      mb={mb}
      mt={mt}
      lineHeight={lineHeight}
      width={width}
      fontWeight={fontWeight}
      ref={ref}
      {...props}
    />
  )
);

Label.displayName = "Label";

export default Label;

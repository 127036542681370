import { zodResolver } from "@hookform/resolvers/zod";
import { NcPage } from "@noted/noted-components";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useAccount } from "~/account/account.context";
import { config } from "~/administration/users/config";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/toast-provider";
import { Button } from "~/shared/components/forms";
import { IAccount } from "~/shared/types";
import { Form, FormRow, Input2 as Input, Label } from "~/shared/ui";

import { useUpdateNotedIdUserMutation } from "../api";

const formSchema = z.object({
  email: z
    .string()
    .min(1, "form_validation.email_required")
    .regex(config.email.pattern, "form_validation.email_pattern"),
  title: z.string(),
  firstName: z.string().min(1, "admin-users:first_name_required"),
  lastName: z.string().optional(),
  registrationNumber: z.string(),
  shortCode: z.string().max(2, "admin-users:short_code_max_length").optional(),
  phone: z.string(),
});

type FormType = z.infer<typeof formSchema>;

export const UserDetails = ({ user }: { user: IAccount }) => {
  const { t } = useI18n(["admin-users"]);
  const { organisation, invalidate } = useAccount();
  const { mutateAsync: updateUser, isPending: saving } = useUpdateNotedIdUserMutation();
  const { enqueueSuccess, enqueueError } = useToast();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormType>({
    defaultValues: {
      title: user?.title ?? "",
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      registrationNumber: user?.registrationNumber ?? "",
      shortCode: user?.shortCode ?? "",
      phone: user?.phone ?? "",
      email: user?.email ?? "",
    },
    resolver: zodResolver(formSchema),
  });

  const onFormSubmit = handleSubmit(async (input: FormType) => {
    if (saving || organisation.readOnlyMode) {
      return;
    }

    try {
      await updateUser({
        id: user.id,
        title: input.title,
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        phone: input.phone,
        registrationNumber: input.registrationNumber,
        shortCode: input.shortCode,
        // TODO: why do I need to pass the organisation ID?  Can we default to auth'ed users org?
        organisationId: organisation.id,
        securityRoles: user.securityRoles,
        // For some reason the users privileges includes both "user privileges" and "role privileges".
        // I have no idea why this has been done, but we don't want to send "role privileges" in the
        // update user API call.
        privileges: user.privileges?.filter(p => !p.roleIds).map(({ key }) => ({ key })) ?? [],
      });

      enqueueSuccess(t("admin-users:save_success"));
      invalidate();
    } catch (e) {
      enqueueError(t("admin-users:save_error"));
    }
  });

  return (
    <NcPage>
      <NcPage.Wrapper>
        <NcPage.Header>
          <NcPage.Heading>{t("admin-users:user_details")}</NcPage.Heading>
        </NcPage.Header>
        <NcPage.Body>
          <Form data-testid="user-form" onSubmit={onFormSubmit} maxWidth="30rem">
            <FormRow>
              <Label htmlFor="title">{t("admin-users:title")}</Label>
              <Input
                {...register("title")}
                disabled={organisation.readOnlyMode}
                data-testid="user-title"
                data-cy="user-title"
                id="title"
              />
            </FormRow>

            <FormRow>
              <Label htmlFor="firstName">{t("admin-users:first_name")}</Label>
              <Input
                {...register("firstName")}
                disabled={organisation.readOnlyMode}
                data-testid="user-first-name"
                data-cy="user-first-name"
                id="firstName"
                required
                error={t(errors.firstName?.message || "")}
              />
            </FormRow>

            <FormRow>
              <Label htmlFor="lastName">{t("admin-users:last_name")}</Label>
              <Input
                {...register("lastName")}
                disabled={organisation.readOnlyMode}
                data-testid="user-last-name"
                data-cy="user-last-name"
                id="lastName"
                required
                error={t(errors.lastName?.message || "")}
              />
            </FormRow>

            <FormRow>
              <Label htmlFor="registrationNumber">{t("admin-users:registration_number")}</Label>
              <Input
                {...register("registrationNumber")}
                disabled={organisation.readOnlyMode}
                data-testid="user-registration-number"
                data-cy="user-registration-number"
                id="registrationNumber"
              />
            </FormRow>

            <FormRow>
              <Label htmlFor="shortCode">{t("admin-users:short_code")}</Label>
              <Input
                {...register("shortCode")}
                disabled={organisation.readOnlyMode}
                data-testid="user-shortcode"
                data-cy="user-shortcode"
                id="shortCode"
                error={t(errors.shortCode?.message || "")}
              />
            </FormRow>

            <FormRow>
              <Label htmlFor="email">{t("admin-users:email")}</Label>
              <Input
                {...register("email")}
                type="email"
                disabled={organisation.readOnlyMode}
                data-testid="user-email"
                data-cy="user-email"
                id="email"
                required
                error={t(errors.email?.message || "")}
              />
            </FormRow>

            <FormRow>
              <Label htmlFor="phone">{t("admin-users:phone")}</Label>
              <Input
                {...register("phone")}
                disabled={organisation.readOnlyMode}
                data-testid="user-phone"
                data-cy="user-phone"
                id="phone"
              />
            </FormRow>

            <FormRow variant="submit">
              <Button
                data-testid="user-submit-button"
                data-cy="user-submit-button"
                variant="primary"
                type="submit"
                disabled={saving || organisation.readOnlyMode}
              >
                {t("admin-users:submit")}
              </Button>
            </FormRow>
          </Form>
        </NcPage.Body>
      </NcPage.Wrapper>
    </NcPage>
  );
};

import { ComponentProps } from "react";

import styled from "../../theme";
import Button from "../forms/button";

export type ExitButtonProps = ComponentProps<typeof Button> & { active: boolean };

const ExitButton = styled(Button)<ExitButtonProps>`
  position: fixed;
  top: 0;
  right: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  background: none;
  font-size: 0;
  border: none;
  padding: 1rem;
  &:focus,
  &:hover,
  &:active {
    border: none;
  }

  cursor: pointer;
  z-index: 1;
`;

ExitButton.displayName = "ModalExitButton";

export default ExitButton;

import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

/**
 * @deprecated Use Label from `/shared/ui` instead.
 */
const BaseLabel = styled(Flex)`
  color: ${theme.colors.neutral.darkest};
  box-sizing: border-box;
  font-family: inherit;
  cursor: pointer;
  &:first-letter {
    text-transform: uppercase;
  }
`.withComponent("label");

export const Label = forwardRef<HTMLLabelElement, ComponentProps<typeof BaseLabel>>(
  ({ mb = 2, lineHeight = 1.3, width = "100%", fontWeight = 600, ...props }, ref) => (
    <BaseLabel
      mb={mb}
      lineHeight={lineHeight}
      width={width}
      fontWeight={fontWeight}
      ref={ref}
      {...props}
    />
  )
);

Label.displayName = "Label";

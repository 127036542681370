import { keyframes } from "@emotion/core";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import styled, { theme } from "../../theme";
import { Box } from "../primitives";

export const LoadingText = ({
  text,
  ...otherProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  text?: string;
}) => {
  return (
    <Container role="presentation" className="p-3" {...otherProps}>
      <Box as="span" mr="0.1rem">
        {text}
      </Box>
      <Dot />
      <Dot />
      <Dot />
    </Container>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  opacity: 0;
  color: ${theme.colors.primary.dark};
  animation: ${fadeIn} 0.25s 0.5s forwards;
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  10%, 60% {
    opacity: 0;
  }
`;

const Dot = styled.span`
  font-size: 2em;
  animation: ${pulse} 2s 2s infinite linear;
  line-height: 1rem;
  letter-spacing: -0.05em;
  &:before {
    content: ".";
  }
  &:nth-of-type(1) {
    animation-delay: 0ms;
  }
  &:nth-of-type(2) {
    animation-delay: 150ms;
  }
  &:nth-of-type(3) {
    animation-delay: 300ms;
  }
`;

import { orderBy, uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

const SERVICE_DATE_FORMAT = "d-LL-yyyy";

export interface IContractCode {
  code: string;
  name: string;
}

export interface IServiceCode {
  administrativeCode?: boolean;
  code: string | number;
  info: string;
  time: null | number;
  units: null | number;
  flatfee: null | number;
  isVariable?: boolean;
  from: string;
  to: string;
}

interface useACCJSONFilters {
  serviceFilter?: (value: IServiceCode) => boolean;
}

const getContractCodes = async () => {
  const module = await import("../assets/resources/acc.contractCodes.json");
  return module.default;
};

const getServiceCodes = async () => {
  const module = await import("../assets/resources/acc.serviceCodes.json");
  return module.default;
};

export const useACCJSON = (filters?: useACCJSONFilters) => {
  const [contractCodes, setContractCodes] = useState<IContractCode[]>([]);
  const [serviceCodes, setServiceCodes] = useState<IServiceCode[]>([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getContractCodes().then((codes: IContractCode[]) => {
        setContractCodes(codes);
      });
      getServiceCodes().then((codes: IServiceCode[]) => {
        const uniqs = uniqBy(codes, "code");
        const sorted = orderBy(uniqs, "code");
        setServiceCodes(filters?.serviceFilter ? sorted.filter(filters.serviceFilter) : sorted);
      });
    }
    return () => {
      mounted = false;
    };
  }, []);
  return { contractCodes, serviceCodes };
};

export const useAccServiceCodeForDates = (code: string, to: Date, from: Date) => {
  const [allCodes, setAllCodes] = useState<IServiceCode[] | undefined>(undefined);
  const hasAllProps = code && to && from;
  const targetCodes = allCodes && hasAllProps ? allCodes.filter(c => c.code === code) : undefined;
  const validCodes = targetCodes?.filter(c => {
    const fromDate = DateTime.fromFormat(c.from, SERVICE_DATE_FORMAT).toJSDate();
    const toDate = c.to ? DateTime.fromFormat(c.to, SERVICE_DATE_FORMAT).toJSDate() : null;
    return fromDate <= from && (!toDate || toDate >= to);
  });

  useEffect(() => {
    getServiceCodes().then((codes: IServiceCode[]) => {
      setAllCodes(codes);
    });
  }, []);

  if (!validCodes) {
    return { service: undefined, error: undefined };
  }

  if (validCodes?.length === 0) {
    return {
      service: undefined,
      error: {
        type: "INVALID",
      },
    };
  }

  return { service: validCodes[0], error: undefined };
};

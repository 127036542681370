import { NcFormattedMessage, NcPage } from "@noted/noted-components";
import { useQuery } from "@tanstack/react-query";

import { restFetcher } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import AlertMessageBox from "~/shared/components/message-box/alert-message-box";

import { LinkAsButton } from "../shared/components/buttons";
import { PagePublic, Shell } from "../shared/components/layout";
import {
  OverlayMessageBox,
  OverlayMessageBoxContent,
  OverlayMessageBoxHighlight,
} from "../shared/components/overlay-message-box";
import { LogoIcon } from "../shared/icons";

const ApiOnlyUser = () => {
  const { t } = useI18n("account");

  const { isLoading, isError } = useQuery({
    queryKey: ["api-user-health"],
    queryFn: () => restFetcher("/v1/api-user/health"),
  });

  return (
    <Shell>
      <PagePublic>
        <OverlayMessageBox>
          <OverlayMessageBoxHighlight>
            <LogoIcon />
          </OverlayMessageBoxHighlight>

          <OverlayMessageBoxContent data-testid="api-only-landing">
            <div className="mb-3 grid gap-3">
              <NcPage.Heading>{t("account:api_only_user.title")}</NcPage.Heading>
              <NcFormattedMessage>
                <p>{t("account:api_only_user.description")}</p>
              </NcFormattedMessage>

              {!isError && !isLoading && (
                <AlertMessageBox>{t("account:api_only_user.ok")}</AlertMessageBox>
              )}
              {isError && (
                <AlertMessageBox variant="warning">
                  {t("account:api_only_user.error")}
                </AlertMessageBox>
              )}
            </div>

            <LinkAsButton to="/logout">{t("account:api_only_user.logout")}</LinkAsButton>
          </OverlayMessageBoxContent>
        </OverlayMessageBox>
      </PagePublic>
    </Shell>
  );
};

export default ApiOnlyUser;

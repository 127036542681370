import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { useAccount } from "~/account/account.context";
import { useFeatures } from "~/account/features.context";
import {
  restDeleter,
  restFetcher,
  restPatcher,
  restPoster,
  restPutter,
} from "~/graphql-hooks/custom-fetcher";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/toast-provider";

import { appointmentSchema } from "./appointments/types";
import { Reminder, ReminderSchema } from "./reminders/types";

const CLIENT_SEARCH_STALE_TIME = 10 * 1000; // Cache results for 10 seconds when re-searching

const dashboardDataRefetchSettings = {
  staleTime: 10 * 1000, // Cache data for 10 seconds if refocusing window or returning to page
  keepPreviousData: true, // Keep previous data until the new data is fetched
  refetchOnWindowFocus: true, // refetch stale queries when the window regains focus
  refetchOnMount: true, // or the app programmatically returns to the dashboard
};

// Unsigned Records
// -----------------------
export const CLIENT_UNSIGNED_ENDPOINT = "records/unsigned";

const unsignedRecordSchema = z.object({
  id: z.number(),
  type: z.string(),
  recordType: z.string(),
  client: z
    .object({ id: z.number(), firstName: z.string().optional(), lastName: z.string().optional() })
    .optional(),
  group: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
});

export type UnsignedRecord = z.infer<typeof unsignedRecordSchema>;

const unsignedRecordsResponse = z.array(unsignedRecordSchema);

type UnsignedRecordsResponse = z.infer<typeof unsignedRecordsResponse>;

export function useUnsignedRecordsQuery() {
  return useQuery({
    queryKey: [CLIENT_UNSIGNED_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<UnsignedRecordsResponse>(`/v1/${CLIENT_UNSIGNED_ENDPOINT}`);
      return unsignedRecordsResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Updated Records
// -----------------------

const updatedRecordSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  patientId: z.number(),
  recordsAdded: z.number(),
});

export const CLIENT_UPDATES_ENDPOINT = "users/clientupdates";

const updatedRecordsResponse = z.array(updatedRecordSchema);

export type UpdatedRecordsResponse = z.infer<typeof updatedRecordsResponse>;

export function useUpdatedRecordsQuery() {
  return useQuery({
    queryKey: [CLIENT_UPDATES_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<UpdatedRecordsResponse>(`/v1/${CLIENT_UPDATES_ENDPOINT}`);
      return updatedRecordsResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Reminders
// -----------------------

export const GET_ALL_REMINDERS_ENDPOINT = "reminder/getAll";

const allRemindersResponse = z.array(ReminderSchema);
export type AllRemindersResponse = z.infer<typeof allRemindersResponse>;

export type ReminderV2 = {
  id: number;
  description?: string;
  dueDate: string;
  patient: {
    id: number;
    firstName: string;
    lastName: string;
  };
  worker: {
    id: number;
    firstName: string;
    lastName: string;
  };
  title?: string; // Optional because some production data have missing titles
  resolutionDate?: string;
  type: "REMINDER";
  restricted?: boolean;
  sid: string; // TODO: Only to overlap with v1 reminder remove after TAC feature release
};

type PaginatedAllRemindersResponse = {
  currentPageNumber: number;
  currentPageTotal: number;
  pageSize: number;
  results: Array<ReminderV2>;
  totalPages: number;
  totalResults: number;
};

type PatchOperations = Array<{
  op: string;
  path: string;
  value: string;
}>;

export const reminderV2Queries = {
  all: () => ["reminders"],
  lists: () => [...reminderV2Queries.all(), "list"],
  list: (filters: string) =>
    queryOptions({
      queryKey: [...reminderV2Queries.lists(), filters],
      queryFn: () => restFetcher<PaginatedAllRemindersResponse>("/v2/reminders?" + filters),
    }),
  details: () => [...reminderV2Queries.all(), "detail"],
  detail: (id: number) =>
    queryOptions({
      queryKey: [...reminderV2Queries.details(), id],
      queryFn: () => restFetcher<ReminderV2>(`/v2/reminders/${id}`),
    }),
  update: () => ({
    mutationFn: (reminder: Reminder) => {
      const { patientId, patient, ...reminderData } = reminder;
      const v2Reminder = { ...reminderData, patient: { id: patientId, ...patient } };
      return restPutter<Omit<ReminderV2, "worker">>(`/v2/reminders/${reminder.id}`, v2Reminder);
    },
  }),
  complete: () => ({
    mutationFn: ({ id }: { id: number }) =>
      restPatcher<PatchOperations, ReminderV2>(`/v2/reminders/${id}`, [
        { op: "add", path: "/resolutionDate", value: new Date().toISOString() },
      ]),
  }),
  delete: () => ({
    mutationFn: ({ id }: { id: number }) => restDeleter<ReminderV2>(`/v2/reminders/${id}`),
  }),
};

export function useAllRemindersQuery() {
  const { account } = useAccount();
  const { featureEnabled } = useFeatures();
  const tacFeatureEnabled = featureEnabled(FeaturePermission.TeamAccessControls);
  const useReminderQuery = tacFeatureEnabled ? useReminderQueryWithTac : useReminderQueryWithoutTac;

  return useReminderQuery(account.id);
}

function useReminderQueryWithTac(id: number) {
  const { data, ...query } = useQuery(reminderV2Queries.list(`userId=${id}&state=ACTIVE`));
  return {
    data: data?.results.map(r => ({ patientId: r.patient.id, ...r })),
    ...query,
  };
}

function useReminderQueryWithoutTac(_: number) {
  return useQuery({
    queryKey: [GET_ALL_REMINDERS_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<AllRemindersResponse>(`/v1/${GET_ALL_REMINDERS_ENDPOINT}`);
      return allRemindersResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

export function useUpdateReminderMutation() {
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { t } = useI18n("org");
  const { featureEnabled } = useFeatures();
  const tacFeatureEnabled = featureEnabled(FeaturePermission.TeamAccessControls);

  const errorMessage = t("org:dashboard.reminders.edit.error");
  const successMessage = t("org:dashboard.reminders.edit.success");

  const options = tacFeatureEnabled
    ? {
        ...reminderV2Queries.update(),
        onError: () => {
          enqueueError(errorMessage);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: reminderV2Queries.all() });
          enqueueSuccess(successMessage);
        },
      }
    : {
        mutationFn: (body: Reminder) => restPoster(`/v1/reminder/update`, body),
        onError: () => {
          enqueueError(errorMessage);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [GET_ALL_REMINDERS_ENDPOINT] });
          enqueueSuccess(successMessage);
        },
      };

  return useMutation(options);
}

export function useCompleteReminderMutation() {
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { t } = useI18n("org");
  const { featureEnabled } = useFeatures();
  const tacFeatureEnabled = featureEnabled(FeaturePermission.TeamAccessControls);

  const errorMessage = t("org:dashboard.reminders.actions.complete.error");
  const successMessage = t("org:dashboard.reminders.actions.complete.success");

  const options = tacFeatureEnabled
    ? {
        ...reminderV2Queries.complete(),
        onError: () => {
          enqueueError(errorMessage);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: reminderV2Queries.all() });
          enqueueSuccess(successMessage);
        },
      }
    : {
        mutationFn: (body: { id: number }) => restPoster(`/v1/reminder/resolve`, body),
        onError: () => {
          enqueueError(errorMessage);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [GET_ALL_REMINDERS_ENDPOINT] });
          enqueueSuccess(successMessage);
        },
      };

  return useMutation(options);
}

export function useDeleteReminderMutation() {
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { t } = useI18n("org");
  const { featureEnabled } = useFeatures();
  const tacFeatureEnabled = featureEnabled(FeaturePermission.TeamAccessControls);

  const errorMessage = t("org:dashboard.reminders.delete.error");
  const successMessage = t("org:dashboard.reminders.delete.success");

  const options = tacFeatureEnabled
    ? {
        ...reminderV2Queries.delete(),
        onError: () => {
          enqueueError(errorMessage);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: reminderV2Queries.all() });
          enqueueSuccess(successMessage);
        },
      }
    : {
        mutationFn: (body: { id: number }) => restPoster(`/v1/reminder/delete`, body),
        onError: () => {
          enqueueError(errorMessage);
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [GET_ALL_REMINDERS_ENDPOINT] });
          enqueueSuccess(successMessage);
        },
      };

  return useMutation(options);
}

// Appointments
// -----------------------

export const APPOINTMENTS_ENDPOINT = "appointments";

const appointmentsResponse = z.array(appointmentSchema);

export type AppointmentsResponse = z.infer<typeof appointmentsResponse>;

export function useAppointmentsQuery() {
  return useQuery({
    queryKey: [APPOINTMENTS_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<AppointmentsResponse>(`/v1/${APPOINTMENTS_ENDPOINT}`);
      return appointmentsResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Invites
// -----------------------

export const INVITES_PENDING_ENDPOINT = "client-invites/pending";

const inviteSchema = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string(),
});

export type Invite = z.infer<typeof inviteSchema>;

const invitesResponse = z.array(inviteSchema);

export type InvitesResponse = z.infer<typeof invitesResponse>;

export function usePendingInvitesQuery() {
  return useQuery({
    queryKey: [INVITES_PENDING_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<InvitesResponse>(`/v1/${INVITES_PENDING_ENDPOINT}`);
      return invitesResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

usePendingInvitesQuery.getKey = () => [INVITES_PENDING_ENDPOINT];

export const INVITES_REVIEW_ENDPOINT = "client-invites/review";

export function useReviewInvitesQuery() {
  return useQuery({
    queryKey: [INVITES_REVIEW_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<InvitesResponse>(`/v1/${INVITES_REVIEW_ENDPOINT}`);
      return invitesResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Client Search
// -----------------------

export const ARTEMIS_CLIENT_SEARCH_ENDPOINT = "clientsearch";

const clientSearchResultSchema = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  preferredName: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  address: z.string().optional(),
  clientId: z.string().optional(),
  medicalServiceId: z.string().optional(),
  dateOfBirth: z.string().optional(),
  currentStatus: z.object({ active: z.boolean() }).optional(),
  securePatientId: z.number().optional(),
  accessLevel: z.string().optional(),
  active: z.boolean().optional(),
});

export type ClientSearchResultSchema = z.infer<typeof clientSearchResultSchema>;

const clientSearchResponse = z.object({
  page: z.number(),
  results: z.array(clientSearchResultSchema),
  pages: z.number(),
  count: z.number(),
  total: z.number(),
});

type ClientSearchResponse = z.infer<typeof clientSearchResponse>;

interface ClientSearchQueryParams {
  searchTerm: string;
  page: number;
  options: {
    size: number;
    activeonly: boolean;
    searchAddresses: boolean;
    sortattr: string;
    sortdir: string;
  };
}

export function useClientSearchQuery({ searchTerm, page, options }: ClientSearchQueryParams) {
  const searchParams = new URLSearchParams({
    searchterm: searchTerm,
    page: `${page}`,
    size: `${options.size}`,
    activeonly: `${options.activeonly}`,
    searchAddresses: `${options.searchAddresses}`,
    sortattr: options.sortattr,
    sortdir: options.sortdir,
  });

  // Artemis Search
  const endpoint = `/v1/${ARTEMIS_CLIENT_SEARCH_ENDPOINT}?${searchParams.toString()}`;
  return useQuery({
    queryKey: [endpoint],
    queryFn: async () => {
      const resp = await restFetcher<ClientSearchResponse>(endpoint);
      return clientSearchResponse.parse(resp);
    },
    staleTime: CLIENT_SEARCH_STALE_TIME,
    enabled: searchTerm.length > 0,
  });
}

import { useQueryClient } from "@tanstack/react-query";
import { DateTime, Interval } from "luxon";
import { useMemo } from "react";
import { useDebounce } from "use-debounce";

import { IResourceAdjustmentPeriod } from "../../shared/types";
import { useAdjustmentPeriodsQuery } from "../api";
import { IResource } from "./resources-context";
import { periodsByResource } from "./unavailable-time";

export interface IResourceAdjustmentPeriodState {
  refetch: () => void;
  getAdjustmentsByResource: (r: IResource) => IResourceAdjustmentPeriod[];
}

const useIntervalMemo = (interval: Interval) => {
  const memoizedFromInterval = interval.start.toMillis();
  const memoizedToInterval = interval.end.toMillis();
  return useMemo(
    () =>
      Interval.fromDateTimes(
        DateTime.fromMillis(memoizedFromInterval),
        DateTime.fromMillis(memoizedToInterval)
      ),
    [memoizedFromInterval, memoizedToInterval]
  );
};

export const useResourceAdjustmentPeriods = (
  interval: Interval
): IResourceAdjustmentPeriodState => {
  const [debouncedFetchInterval] = useDebounce(useIntervalMemo(interval), 500);

  const queryClient = useQueryClient();
  const variables = {
    input: {
      from: debouncedFetchInterval.start.toMillis(),
      to: debouncedFetchInterval.end.toMillis(),
    },
  };

  let getAdjustmentsByResource: (r: IResource) => IResourceAdjustmentPeriod[] = () => [];
  const { data } = useAdjustmentPeriodsQuery(variables, { refetchOnWindowFocus: false });

  if (data?.resourceAdjustmentPeriodsBetween) {
    const periods = periodsByResource(data.resourceAdjustmentPeriodsBetween);

    getAdjustmentsByResource = (r: IResource) => {
      switch (r.type) {
        case "worker":
          return periods.workers[r.id] || [];
        case "space":
          return periods.spaces[r.id] || [];
        default:
          return [];
      }
    };
  }

  return useMemo(
    () => ({
      refetch: () =>
        queryClient.invalidateQueries({ queryKey: useAdjustmentPeriodsQuery.getKey(variables) }),
      getAdjustmentsByResource,
    }),
    [getAdjustmentsByResource, queryClient]
  );
};

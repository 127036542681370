import isPropValid from "@emotion/is-prop-valid";
import { Link } from "react-router-dom";

import styled from "../../theme";
import { ButtonBase } from "../forms/button";

export const LinkAsButton = styled(ButtonBase.withComponent(Link), {
  shouldForwardProp: prop => isPropValid(prop),
})``;

LinkAsButton.displayName = "LinkAsButton";

export default LinkAsButton;

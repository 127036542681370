import { Controller, useFormContext } from "react-hook-form";

import { NcSelect, NcSelectProps } from "./nc-select";

export interface NcFormSelectProps
  extends Omit<
    NcSelectProps,
    "name" | "validate" | "onBlur" | "selectedKey" | "validationBehavior"
  > {
  name: string; // Was optional in NcSelectProps and is now required
}

export const NcFormSelect = ({ name, onSelectionChange, ...props }: NcFormSelectProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <NcSelect
          name={name}
          selectedKey={value ? value : ""}
          onSelectionChange={key => {
            onSelectionChange?.(key);
            const value = key || undefined;
            onChange(value);
          }}
          onBlur={onBlur}
          validationBehavior="aria"
          isInvalid={invalid}
          error={error}
          ref={ref}
          {...props}
        />
      )}
    />
  );
};

import { DateTime, Info, Interval } from "luxon";
import {
  ComponentProps,
  forwardRef,
  SVGProps,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDebounce } from "use-debounce/lib";

import styled, { ITheme, theme } from "../../theme";
import ButtonCircle from "../forms/button-circle";
import { Box, BoxProps, Flex, Grid, Text } from "../primitives";
import { TransitionBox } from "./transition-box";

const current = (date: DateTime) =>
  [...Array(date.daysInMonth)].map((_, i) => date.set({ day: i + 1 }));

const previous = (date: DateTime) => {
  const lastMonth = date.set({ month: date.month - 1 });
  return [...Array(date.startOf("month").weekday - 1)]
    .map((_, i) => lastMonth.set({ day: lastMonth.endOf("month").day - i }))
    .reverse();
};

const following = (date: DateTime) => {
  const nextMonth = date.set({ month: date.month + 1 });
  return [...Array(7 - date.endOf("month").weekday)].map((_, i) =>
    nextMonth.set({ day: nextMonth.startOf("month").day + i })
  );
};

const calendarDays = (d: DateTime) => {
  let res = [...previous(d), ...current(d), ...following(d)];
  const rows = res.length / 7;
  for (let i = rows; i < 6; i++) {
    const lastDay = res[res.length - 1];
    res = [...res, ...[...Array(7)].map((_, index) => lastDay.plus({ days: index + 1 }))];
  }
  return res;
};

const isAllowedDay = (date: DateTime, allowedIntervals?: Interval[]) => {
  if (!allowedIntervals) {
    return true;
  } else {
    const from = date.startOf("day");
    const to = from.endOf("day");
    const intervalToCheck = Interval.fromDateTimes(from, to);
    return allowedIntervals.some(i => !!i.intersection(intervalToCheck));
  }
};

export interface IDayPickerProps extends Omit<BoxProps, "onChange"> {
  onChange: (day: DateTime) => void;
  selectedDay: DateTime;
  allowedIntervals?: Interval[];
  focusDayOnLoad?: boolean;
  onConfirm?: () => void;
  debounce?: number;
}

export const DayPicker = forwardRef<HTMLDivElement, IDayPickerProps>(
  (
    {
      selectedDay,
      onChange: onChangeProp,
      allowedIntervals,
      focusDayOnLoad,
      onConfirm,
      debounce = 0,
      ...boxProps
    },
    ref
  ) => {
    const [viewDate, setViewDate] = useState({
      d: selectedDay,
      direction: undefined as "left" | "right" | undefined,
    });

    const [shouldFocusAfterTransition, setShouldFocusAfterTransition] = useState(false);
    const [currentDayElement, setCurrentDayElement] = useState<HTMLButtonElement>();

    const [currentDay, setCurrentDay] = useState(selectedDay);
    useEffect(() => {
      setCurrentDay(selectedDay);
    }, [selectedDay]);

    const [onChangeDebounce, setOnChangeDebounce] = useState<number>();
    const [debouncedOnChange] = useDebounce(onChangeDebounce, debounce);

    const onChange = useCallback(
      (d: DateTime) => {
        setCurrentDay(d);
        if (!debounce) {
          onChangeProp(d);
        } else {
          setOnChangeDebounce(d.toMillis());
        }
      },
      [onChangeProp, debounce]
    );

    useEffect(() => {
      if (typeof debouncedOnChange === "number" && debounce) {
        onChangeProp(DateTime.fromMillis(debouncedOnChange));
      }
    }, [debounce, debouncedOnChange, onChangeProp]);

    useEffect(() => {
      if (shouldFocusAfterTransition && currentDayElement) {
        currentDayElement.focus();
      }
    }, [currentDayElement, shouldFocusAfterTransition]);

    useEffect(() => {
      setCurrentDay(selectedDay);
      setViewDate(prev =>
        prev.d.hasSame(selectedDay, "month")
          ? prev
          : {
              d: selectedDay,
              direction: prev.d < selectedDay ? "right" : "left",
            }
      );
      setShouldFocusAfterTransition(false);
    }, [selectedDay]);

    const [hadFocusOnInit, setHadFocusOnInit] = useState(false);
    useLayoutEffect(() => {
      if (focusDayOnLoad && !hadFocusOnInit && currentDayElement) {
        setHadFocusOnInit(true);
        if (currentDayElement) {
          currentDayElement.focus();
        }
      }
    }, [focusDayOnLoad, currentDayElement, hadFocusOnInit]);

    const days = useMemo(() => calendarDays(viewDate.d), [viewDate.d]);

    return (
      <Box overflow="hidden" pb="1" {...boxProps} ref={ref}>
        <Flex justifyContent="space-between" width="100%" minWidth="14rem" p="1">
          <ButtonCircle
            size="2"
            style={{ border: "0px" }}
            onClick={() => setViewDate({ d: viewDate.d.minus({ month: 1 }), direction: "left" })}
            data-testid="previous-month-arrow"
          >
            <PreviousMonthArrow />
          </ButtonCircle>
          <CalendarText minWidth="9rem" textAlign="center" fontSize="2" alignSelf="center">
            {viewDate.d.monthLong} {viewDate.d.year}
          </CalendarText>
          <ButtonCircle
            size="2"
            style={{ border: "0px" }}
            onClick={() => setViewDate({ d: viewDate.d.plus({ month: 1 }), direction: "right" })}
            data-testid="next-month-arrow"
          >
            <NextMonthArrow />
          </ButtonCircle>
        </Flex>
        <Grid
          gridTemplateColumns="repeat(7, minmax(2rem, 1fr))"
          borderBottom={`1px solid ${theme.colors.neutral.dark}`}
          pb="2"
          mb="2"
        >
          {Info.weekdays("short").map((d, key) => (
            <Flex justifyContent="space-around" key={key}>
              <CalendarText fontSize="1">{d}</CalendarText>
            </Flex>
          ))}
        </Grid>

        <TransitionBox
          display="grid"
          gridTemplateColumns="repeat(7, minmax(2rem, 1fr))"
          direction={viewDate.direction}
          key={viewDate.d.toFormat("yyyyLL")}
        >
          {days.map((d, i) => (
            <Flex
              justifyContent="space-around"
              key={i}
              onClick={() => {
                setCurrentDay(d);
                setShouldFocusAfterTransition(!d.hasSame(viewDate.d, "month"));
                setViewDate({
                  d,
                  direction: d.hasSame(viewDate.d, "month")
                    ? undefined
                    : d.diff(currentDay, "days").days > 0
                      ? "right"
                      : "left",
                });
                onChange(d);
              }}
              data-testid={`day-number-${d.year}-${d.month}-${d.day}`}
              onKeyDown={e => {
                if (onConfirm && e.key === "Enter" && d.hasSame(currentDay, "day")) {
                  e.stopPropagation();
                  e.preventDefault();
                  onConfirm();
                }
              }}
            >
              <ColorNumber
                circleColor={getDayBackgroundColor(currentDay, d, theme)}
                size="2"
                onButtonRendered={el => {
                  if (currentDay.hasSame(d, "day")) {
                    setCurrentDayElement(el);
                  }
                }}
              >
                <Text
                  fontSize="1"
                  color={getDayTextColor(viewDate.d, d, currentDay, theme, allowedIntervals)}
                >
                  {d.day}
                </Text>
              </ColorNumber>
            </Flex>
          ))}
        </TransitionBox>
      </Box>
    );
  }
);
DayPicker.displayName = "DayPicker";

const ColorNumber = (
  props: ComponentProps<typeof ButtonCircle> & {
    onButtonRendered: (el: HTMLButtonElement) => void;
  }
) => {
  const { onButtonRendered, ...colorProps } = props;
  const ref = useRef<HTMLButtonElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      onButtonRendered(ref.current);
    }
  }, [onButtonRendered, ref]);
  return <ButtonCircle ref={ref} {...colorProps} />;
};

const getDayBackgroundColor = (viewDate: DateTime, d: DateTime, theme: ITheme) => {
  return viewDate.hasSame(d, "day")
    ? theme.colors.info.medium
    : d.hasSame(DateTime.local(), "day")
      ? theme.colors.info.lightest
      : "transparent";
};

const getDayTextColor = (
  viewDate: DateTime,
  d: DateTime,
  selectedDay: DateTime,
  theme: ITheme,
  allowedIntervals?: Interval[]
) => {
  if (!viewDate.hasSame(d, "month")) {
    return isAllowedDay(d, allowedIntervals)
      ? theme.colors.neutral.medium
      : theme.colors.neutral.medium; // Used to be a warning color
  } else {
    if (d.hasSame(selectedDay, "day") && d.hasSame(selectedDay, "month")) {
      return theme.colors.neutral.lightest;
    } else {
      return isAllowedDay(d, allowedIntervals)
        ? theme.colors.neutral.dark
        : d.hasSame(DateTime.local(), "day")
          ? theme.colors.neutral.lightest
          : theme.colors.neutral.medium;
    }
  }
};

const CalendarText = styled(Text)`
  user-select: none;
  text-transform: uppercase;
`;

const NextMonthArrow = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="#4E5864" ref={ref} {...props}>
    <path d="M10 6.28571L1.36284e-07 0.571428L0 12L10 6.28571Z" />
  </svg>
));
NextMonthArrow.displayName = "NextMonthArrow";

const PreviousMonthArrow = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="#4E5864" ref={ref} {...props}>
    <path d="M-2.49779e-07 5.71429L10 11.4286L10 0L-2.49779e-07 5.71429Z" />
  </svg>
));
PreviousMonthArrow.displayName = "PreviousMonthArrow";

import { DateTime, Interval } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDebounce } from "use-debounce/lib";

import { MinutesInterval } from "../../../shared/functions/date-helper";
import { Mode } from "../agenda-view";
import { useResources } from "../resources-context";
import { useBookingCalendarRef } from "./calendar-ref-context";

const initVals = {
  mode: "workers" as Mode,
  setMode: () => undefined,
  scrollMidpointPosition: 0.55,
  zoomIn: () => undefined,
  zoomOut: () => undefined,
  zoom: 1,
  calendarInterval: 15 as MinutesInterval,
};

interface IBookingViewContext {
  mode: Mode;
  setMode: Dispatch<SetStateAction<Mode>>;
  scrollMidpointPosition: number;
  zoomIn: () => void;
  zoomOut: () => void;
  zoom: number;
  calendarInterval: MinutesInterval;
}

const BookingViewDateContext = createContext({
  viewDate: DateTime.local().startOf("day"),
  setViewDate: (() => undefined) as Dispatch<SetStateAction<DateTime>>,
});

const initialDate = DateTime.local().startOf("day");

const BookingViewDateState = ({ children }: { children: ReactNode }) => {
  const [viewDate, setViewDate] = useState(initialDate);
  const value = useMemo(() => ({ viewDate, setViewDate }), [viewDate, setViewDate]);
  return (
    <BookingViewDateContext.Provider value={value}>{children}</BookingViewDateContext.Provider>
  );
};

const BookingViewFetchIntervalContext = createContext({
  fetchedViewDate: initialDate,
  interval: Interval.after(initialDate, { days: 1 }),
});

export const useBookingViewDate = () => useContext(BookingViewDateContext);

const BookingViewFetchIntervalState = ({ children }: { children: ReactNode }) => {
  const { viewDate } = useBookingViewDate();
  const { weekView } = useWeekViewFlag();
  const [debouncedFetchDate] = useDebounce(viewDate, 250);

  const interval = useMemo(
    () =>
      weekView
        ? Interval.fromDateTimes(
            debouncedFetchDate.startOf("week"),
            debouncedFetchDate.endOf("week")
          )
        : Interval.after(debouncedFetchDate, { days: 1 }),
    [debouncedFetchDate, weekView]
  );

  const value = useMemo(
    () => ({ interval, fetchedViewDate: debouncedFetchDate }),
    [interval, debouncedFetchDate]
  );
  return (
    <BookingViewFetchIntervalContext.Provider value={value}>
      {children}
    </BookingViewFetchIntervalContext.Provider>
  );
};

export const useBookingFetchInterval = () => useContext(BookingViewFetchIntervalContext);

export const BookingViewContext = createContext<IBookingViewContext>(initVals);
export const useBookingView = () => useContext(BookingViewContext);

const MODE_KEY = "booking-view";
const getMode = (): Mode => {
  const savedMode = localStorage.getItem(MODE_KEY) ?? "";
  if (["workers", "spaces"].includes(savedMode)) {
    return savedMode as Mode;
  } else {
    return "workers";
  }
};
const useMode = () => {
  const state = useState(() => getMode());
  const [mode] = state;
  useEffect(() => {
    localStorage.setItem(MODE_KEY, mode);
  }, [mode]);
  return state;
};

const zoomLevels = new Array(30).fill(undefined).map((_, i) => 1 + i * 0.25);
export const BookingViewState = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useMode();
  const [zoom, setZoom] = useState<number>(2);
  const [scrollMidpointPosition, setScrollMidpointPosition] = useState(0.55);

  const { calendarRef } = useBookingCalendarRef();

  const setTheScrollPosition = useCallback(() => {
    if (calendarRef.current) {
      setScrollMidpointPosition(
        (calendarRef.current.scrollTop + calendarRef.current.clientHeight / 2) /
          calendarRef.current.scrollHeight
      );
    }
  }, [calendarRef]);

  const zoomIn = useCallback(() => {
    setTheScrollPosition();
    setZoom(z => {
      const i = zoomLevels.indexOf(z);
      return i === zoomLevels.length - 1 ? z : zoomLevels[i + 1];
    });
  }, [setTheScrollPosition]);

  const zoomOut = useCallback(() => {
    setTheScrollPosition();
    setZoom(z => {
      const i = zoomLevels.indexOf(z);
      return i === 0 ? z : zoomLevels[i - 1];
    });
  }, [setTheScrollPosition]);

  const calendarInterval: MinutesInterval = useMemo(() => {
    const zoomed = zoomLevels.indexOf(zoom) / zoomLevels.length;
    if (zoomed < 0.33) {
      return 30;
    }
    if (zoomed < 0.66) {
      return 15;
    }
    return 5;
  }, [zoom]);

  const value = useMemo(
    () => ({
      mode,
      setMode,
      zoom,
      zoomIn,
      zoomOut,
      scrollMidpointPosition,
      calendarInterval,
    }),
    [calendarInterval, mode, scrollMidpointPosition, setMode, zoom, zoomIn, zoomOut]
  );

  return (
    <BookingViewContext.Provider value={value}>
      <BookingViewDateState>
        <BookingViewFetchIntervalState>{children}</BookingViewFetchIntervalState>
      </BookingViewDateState>
    </BookingViewContext.Provider>
  );
};

const WEEK_VIEW_FLAG_KEY = "weekViewFlag";
const WEEK_VIEW_WORKER_KEY = "weekViewWorker";
const WEEK_VIEW_SPACE_KEY = "weekViewSpace";
type WeekViewResourceKey = "weekViewWorker" | "weekViewSpace";

const WeekViewFlagContext = createContext<{
  weekView: boolean;
  toggleWeekView: () => boolean;
}>({ weekView: false, toggleWeekView: () => false });

const WeekViewWorkerContext = createContext<{
  workerId?: number;
  setWorkerId: (id?: number) => void;
}>({ setWorkerId: () => ({}) });

const WeekViewSpaceContext = createContext<{
  spaceId?: number;
  setSpaceId: (id?: number) => void;
}>({ setSpaceId: () => ({}) });

const getWeekViewFlag = () => {
  try {
    const value = JSON.parse(localStorage.getItem(WEEK_VIEW_FLAG_KEY) ?? "");
    return typeof value === "boolean" ? value : false;
  } catch {
    return false;
  }
};

const toggleWeekViewFlag = () => {
  let newFlagValue: boolean;
  try {
    const value = JSON.parse(localStorage.getItem(WEEK_VIEW_FLAG_KEY) ?? "");
    newFlagValue = typeof value === "boolean" ? !value : true;
  } catch {
    newFlagValue = true;
  }
  localStorage.setItem(WEEK_VIEW_FLAG_KEY, JSON.stringify(newFlagValue));
  return newFlagValue;
};

const useWeekViewFlagLocal = () => {
  const [isWeekView, setIsWeekView] = useState(() => getWeekViewFlag());
  const toggleWeekView = useCallback(() => {
    const newValue = toggleWeekViewFlag();
    setIsWeekView(newValue);
    return newValue;
  }, []);
  return useMemo(() => ({ weekView: isWeekView, toggleWeekView }), [isWeekView, toggleWeekView]);
};

const getWeekViewResource = (key: WeekViewResourceKey) => {
  try {
    return Number(JSON.parse(localStorage.getItem(key) ?? ""));
  } catch {
    return undefined;
  }
};
const useWeekViewWorkerLocal = () => {
  const [workerId, setWorkerIdInner] = useState(() => getWeekViewResource(WEEK_VIEW_WORKER_KEY));
  const setWorkerId = useCallback((id?: number) => {
    localStorage.setItem(WEEK_VIEW_WORKER_KEY, JSON.stringify(id ?? null));
    setWorkerIdInner(id);
  }, []);
  return useMemo(() => ({ workerId, setWorkerId }), [workerId, setWorkerId]);
};
const useWeekViewSpaceLocal = () => {
  const [spaceId, setSpaceIdInner] = useState(() => getWeekViewResource(WEEK_VIEW_SPACE_KEY));
  const setSpaceId = useCallback((id?: number) => {
    localStorage.setItem(WEEK_VIEW_SPACE_KEY, JSON.stringify(id ?? null));
    setSpaceIdInner(id);
  }, []);
  return useMemo(() => ({ spaceId, setSpaceId }), [spaceId, setSpaceId]);
};

export const WeekViewState = ({ children }: { children: ReactNode }) => {
  const weekView = useWeekViewFlagLocal();
  const worker = useWeekViewWorkerLocal();
  const space = useWeekViewSpaceLocal();

  return (
    <WeekViewFlagContext.Provider value={weekView}>
      <WeekViewWorkerContext.Provider value={worker}>
        <WeekViewSpaceContext.Provider value={space}>{children}</WeekViewSpaceContext.Provider>
      </WeekViewWorkerContext.Provider>
    </WeekViewFlagContext.Provider>
  );
};

export const useWeekViewFlag = () => useContext(WeekViewFlagContext);
export const useWeekViewWorker = () => useContext(WeekViewWorkerContext);
export const useWeekViewSpace = () => useContext(WeekViewSpaceContext);
export const useWeekViewResource = () => {
  const { mode } = useBookingView();
  const { workerId: weekWorkerId } = useWeekViewWorker();
  const { spaceId: weekSpaceId } = useWeekViewSpace();
  const { workers, spaces } = useResources();

  return useMemo(
    () =>
      mode === "workers"
        ? workers.find(w => w.id === weekWorkerId)
        : spaces.find(s => s.id === weekSpaceId),
    [mode, spaces, weekSpaceId, weekWorkerId, workers]
  );
};

import { queryOptions } from "@tanstack/react-query";

import { restFetcher } from "~/graphql-hooks/custom-fetcher";

import { PrimhdRecord, PrimhdRecordSummary, WorkerErrorSummary } from "../types";

export type PaginatedResponse = {
  currentPageNumber: number;
  totalPages: number;
  totalResults: number;
  pageSize: number;
  currentPageTotal: number;
};

type PrimhdRecordApiResponse = {
  results: PrimhdRecordSummary[];
} & PaginatedResponse;

export const recordQueries = {
  all: () => ["PrimhdRecord"],
  lists: () => [...recordQueries.all(), "list"],
  list: (filters: string) =>
    queryOptions({
      queryKey: [...recordQueries.lists(), filters],
      queryFn: () => {
        const searchParams = new URLSearchParams(filters);
        searchParams.append("status", "REJECTED,WARNING");

        return restFetcher<PrimhdRecordApiResponse>(
          "/v2/primhd/records?" + searchParams.toString()
        );
      },
    }),
  details: () => [...recordQueries.all(), "detail"],
  detail: (recordSummary: PrimhdRecordSummary) =>
    queryOptions({
      queryKey: [...recordQueries.details(), recordSummary.type, recordSummary.id],
      queryFn: () =>
        restFetcher<PrimhdRecord>(
          `/v1/primhd/records/${recordSummary.type.toLocaleLowerCase()}/${recordSummary.id}`
        ),
      staleTime: Infinity,
    }),
  summary: () =>
    queryOptions({
      queryKey: [...recordQueries.all(), "summary"],
      queryFn: () => restFetcher("/v2/primhd/records/summary") as Promise<WorkerErrorSummary[]>,
    }),
};

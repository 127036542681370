import { css } from "@emotion/core";
import { Popover } from "@headlessui/react";
import { KeyboardEventHandler, ReactNode, RefObject, useRef } from "react";
import { Route, Routes } from "react-router-dom";

import { useAccount } from "~/account/account.context";
import { useFeatures } from "~/account/features.context";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { ToggleButtonIcon } from "~/shared/components/choice/dropdown-search";
import { MenuLink } from "~/shared/components/forms/menu-link";
import { Text } from "~/shared/components/primitives";
import {
  Acc,
  Administration,
  Apps,
  Auditing,
  Client,
  PRIMHD,
  Reporting,
  Scheduling,
} from "~/shared/icons";
import { BREAKPOINT_LARGE_QUERY, theme } from "~/shared/theme";
import { NavigableListItem } from "~/shared/ui/a11y/navigable-list";

type ConditionalMenuItemProps = {
  onClick?: () => void;
  condition?: boolean;
  children: ReactNode;
  to: string;
};

const styles = css`
  position: relative;
  z-index: 1;
  outline: none;

  > button {
    display: flex;
    border-radius: ${theme.borderRadius.small};
    align-items: center;
    background-color: ${theme.colors.primary.mediumDark};
    padding-block: ${theme.space[2]};
    padding-inline: ${theme.space[4]} ${theme.space[2]};
    margin: ${theme.space[3]};
    user-select: none;
    cursor: pointer;
    color: ${theme.colors.neutral.lightest};
    overflow: hidden;
    width: calc(100% - 1.5rem);
    transition: background-color 150ms;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: ${theme.colors.info.dark};
    }

    &:focus-visible {
      outline-color: ${theme.colors.primary.mediumLight};
      border-color: ${theme.colors.primary.mediumLight};
    }

    .label {
      margin-left: ${theme.space[3]};
      padding-top: 0;
      font-size: ${theme.fontSizes[2]};
    }

    @media ${BREAKPOINT_LARGE_QUERY} {
      &.sidebar-collapsed .label {
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
      }
    }
  }

  .menu {
    position: absolute;
    background: ${theme.colors.neutral.lightest};
    border: 0;
    box-shadow: ${theme.shadows.popOver};
    border-radius: ${theme.borderRadius.small};
    margin: 0 ${theme.space[3]};
    padding: ${theme.space[1]} 0;
    max-width: unset !important;
    overflow: hidden;
    width: 13.375rem;
    z-index: 1;
    top: calc(100% + ${theme.space[2]});

    &.small {
      width: 20rem;
    }
  }

  .app-switcher {
    display: flex;
    align-items: center;

    .switcher-dropdown {
      color: ${theme.colors.neutral.lightest};
      margin-left: auto;
    }
  }
`;

const ConditionalMenuItem = ({
  onClick,
  condition = true,
  children,
  to,
}: ConditionalMenuItemProps) => {
  if (!condition) return null;

  return (
    <NavigableListItem role="none">
      <MenuLink onClick={onClick} role="menuitem" to={to}>
        {children}
      </MenuLink>
    </NavigableListItem>
  );
};

const AppsMenu = ({
  menuRef,
  onNav,
}: {
  menuRef: RefObject<HTMLUListElement>;
  onNav: () => void;
}) => {
  const { t } = useI18n();
  const { featureEnabled } = useFeatures();
  const { account, canSchedule, organisation, privilegeEnabled } = useAccount();
  const accEnabled =
    organisation.healthLinkKeystoreStored && privilegeEnabled("PRIV_CAN_INVOICE_ACC_CLAIMS");

  return (
    <ul ref={menuRef} role="menu" aria-label={t("app_sections")}>
      <ConditionalMenuItem to={`/dashboard`} onClick={onNav}>
        <Client width="14" />
        <Text fontSize="2">{t("clients_app")}</Text>
      </ConditionalMenuItem>

      <ConditionalMenuItem
        onClick={onNav}
        condition={featureEnabled(FeaturePermission.AccBilling) && Boolean(accEnabled)}
        to={`/n/acc/invoicing`}
      >
        <Acc width="17" />
        <Text fontSize="2">{t("acc_billing_app")}</Text>
      </ConditionalMenuItem>

      <ConditionalMenuItem
        onClick={onNav}
        condition={
          featureEnabled(FeaturePermission.Primhd) && privilegeEnabled("PRIV_CAN_MANAGE_PRIMHD")
        }
        to="/primhd"
      >
        <PRIMHD width="13" />
        <Text fontSize="2">{t("primhd_app")}</Text>
      </ConditionalMenuItem>

      {/* There is no feature control on Reporting home route */}
      <ConditionalMenuItem onClick={onNav} to={`/n/insights`}>
        <Reporting width="14" />
        <Text fontSize="2">{t("reporting_app")}</Text>
      </ConditionalMenuItem>

      <ConditionalMenuItem
        onClick={onNav}
        condition={featureEnabled(FeaturePermission.Extracts)}
        to="/extract"
      >
        <Reporting width="14" />
        <Text fontSize="2">{t("extract_app")}</Text>
      </ConditionalMenuItem>

      <ConditionalMenuItem
        onClick={onNav}
        condition={featureEnabled(FeaturePermission.BookingSystem) && canSchedule}
        to="/scheduling"
      >
        <Scheduling width="14" />
        <Text fontSize="2">{t("scheduling_app")}</Text>
      </ConditionalMenuItem>

      <ConditionalMenuItem
        onClick={onNav}
        condition={featureEnabled(FeaturePermission.AuditTrail)}
        to="/auditing"
      >
        <Auditing width="18" />
        <Text fontSize="2">{t("auditing_app")}</Text>
      </ConditionalMenuItem>

      <ConditionalMenuItem onClick={onNav} condition={account.isAdminUser} to="/admin">
        <Administration width="14" />
        <Text fontSize="2">{t("administration_app")}</Text>
      </ConditionalMenuItem>
    </ul>
  );
};

const focusOn = (element: HTMLElement) => (element?.firstChild as HTMLElement).focus();

export const ApplicationsSwitcher = ({
  sideBarCollapsed,
  isSmall,
}: {
  role?: string;
  sideBarCollapsed?: boolean;
  isSmall?: boolean;
}) => {
  const { t } = useI18n();
  const { account } = useAccount();
  const menuList = useRef<HTMLUListElement>(null);

  const keyboardNavHandler = (event: KeyboardEvent) => {
    if (menuList.current) {
      if (["ArrowDown", "Enter", "Space"].includes(event.key)) {
        const firstItem = menuList.current.firstElementChild as HTMLElement;

        if (firstItem) {
          focusOn(firstItem);
        }
      }

      if (event.key === "ArrowUp") {
        const lastItem = menuList.current.lastElementChild as HTMLElement;
        if (lastItem) {
          focusOn(lastItem);
        }
      }
    }
  };

  return (
    <Popover css={styles}>
      <Popover.Button
        className={sideBarCollapsed ? "app-switcher sidebar-collapsed" : "app-switcher"}
        aria-haspopup
        onKeyUp={keyboardNavHandler as unknown as KeyboardEventHandler<HTMLButtonElement>}
      >
        <Apps height="22" width="16" />
        <span className="label">
          <Routes>
            {account.isAdminUser && <Route path="/admin/*" element={t("administration_app")} />}
            <Route path="/scheduling/*" element={t("scheduling_app")} />
            <Route path="/primhd/*" element={t("primhd_app")} />
            <Route path="/auditing/*" element={t("auditing_app")} />
            <Route path="/extract/*" element={t("extract_app")} />
            <Route path="/n/acc/*" element={t("acc_billing_app")} />
            <Route path="/n/insights" element={t("reporting_app")} />
            <Route path="/n/reports/*" element={t("reporting_app")} />
            <Route path="/n/*" element={t("clients_app")} />
            <Route path="/dashboard" element={t("clients_app")} />
            <Route path="/user-settings/*" element={t("clients_app")} />
          </Routes>
        </span>
        <ToggleButtonIcon className="switcher-dropdown" />
      </Popover.Button>
      <Popover.Panel className={`menu ${isSmall ? "small" : ""}`}>
        {({ close }) => <AppsMenu menuRef={menuList} onNav={close} />}
      </Popover.Panel>
    </Popover>
  );
};

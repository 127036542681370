import { forwardRef } from "react";

import { useI18n } from "~/hooks/use-i18n";

import MessageBox from "../shared/components/message-box/message-box";
import { Text } from "../shared/components/primitives";

const PasswordHint = forwardRef<HTMLDivElement, Parameters<typeof MessageBox>[0]>((props, ref) => {
  const { t } = useI18n("account");
  return (
    <MessageBox variant="info" ref={ref} {...props}>
      <Text as="h2" fontSize="3" mb="2">
        {t("account:password_hint.title")}
      </Text>
      <dl>
        <dt className="font-bold">{t("account:password_hint.unique")}</dt>
        <dd className="mb-4">{t("account:password_hint.unique_desc")}</dd>
        <dt className="font-bold">{t("account:password_hint.random")}</dt>
        <dd className="mb-4">{t("account:password_hint.random_desc")}</dd>
        <dt className="font-bold">{t("account:password_hint.memorable")}</dt>
        <dd>{t("account:password_hint.memorable_desc")}</dd>
      </dl>
    </MessageBox>
  );
});
PasswordHint.displayName = "PasswordHint";

export default PasswordHint;

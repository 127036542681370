import { NcBadge, NcFormattedMessage, NcListBox, NcPanel } from "@noted/noted-components";
import { useId } from "react-aria";

import { useI18n } from "~/hooks/use-i18n";

import { useUpdatedRecordsQuery } from "./dashboard-queries";
import { NoRecordsIcon } from "./icons";

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoRecordsIcon opacity="0.5" width="6rem" />
      <NcFormattedMessage variant="secondary">
        {t("org:dashboard.updated_records.empty")}
      </NcFormattedMessage>
    </div>
  );
};

const UpdatedRecordsPanel = () => {
  const { t } = useI18n("org");
  const panelId = useId();

  const { data: updatedRecords, isLoading, isError } = useUpdatedRecordsQuery();
  const errorMessage = isError
    ? t("common:error_loading_refresh", {
        component: t("org:dashboard.updated_records.name"),
      })
    : "";

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcPanel.Heading id={panelId} level={2} className="mr-auto">
          {t("org:dashboard.updated_records.heading")}
        </NcPanel.Heading>
        <NcBadge>{updatedRecords?.length}</NcBadge>
      </NcPanel.Header>
      <NcPanel.Body className="p-0" isLoading={isLoading} error={errorMessage}>
        {updatedRecords?.length ? (
          <NcListBox aria-labelledby={panelId} variant="borderless">
            {updatedRecords.map(({ patientId, firstName, lastName, recordsAdded }) => (
              <NcListBox.Item
                key={patientId}
                href={`/n/clients/${patientId}/records`}
                textValue={`${firstName} ${lastName}`}
              >
                <span>
                  {firstName} {lastName}
                </span>
                <NcBadge className="ml-auto">{recordsAdded}</NcBadge>
              </NcListBox.Item>
            ))}
          </NcListBox>
        ) : (
          <PanelEmpty />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default UpdatedRecordsPanel;

import { NcBadge, NcFormattedMessage, NcListBox, NcPanel } from "@noted/noted-components";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { SVGProps, useState } from "react";

import { useAccount } from "~/account/account.context";
import { useI18n } from "~/hooks/use-i18n";

import { PrimhdStatusIcon } from "../primhd-status";
import type { PrimhdRecordSummary } from "../types";
import { recordQueries } from "./api";
import { PrimhdRecordModal } from "./record-modal";

const PrimhdRecordErrorsPanel = () => {
  const { t } = useI18n("primhd");
  const [selectedRecord, setSelectedRecord] = useState<PrimhdRecordSummary | undefined>();
  const { account } = useAccount();
  const { data, isError, isLoading } = useQuery(recordQueries.list(`workerId=${account.id}`));

  const records = data?.results || [];

  return (
    <>
      <NcPanel>
        <NcPanel.Header>
          <NcPanel.Heading level={2} id="primhdErrorRecords">
            {t("primhd:records.record_errors_panel.title")}
          </NcPanel.Heading>
          <NcBadge variant="danger" className="ml-auto">
            {records.length}
          </NcBadge>
        </NcPanel.Header>
        <NcPanel.Body className="p-0" error={isError} isLoading={isLoading}>
          {records.length ? (
            <NcListBox
              variant="borderless"
              items={records}
              aria-labelledby="primhdErrorRecords"
              onAction={(id: number | string) => {
                const record = records.find(r => r.id === id);
                if (record) {
                  setSelectedRecord(record);
                }
              }}
            >
              {(item: PrimhdRecordSummary) => {
                const recordDate = DateTime.fromISO(item.clientCreated).toLocaleString(
                  DateTime.DATE_SHORT
                );
                return (
                  <NcListBox.Item
                    textValue={`${item.status} ${recordDate} ${item.client.name}`}
                    className="group"
                  >
                    <PrimhdStatusIcon
                      status={item.status}
                      className="text-danger group-hover:text-current group-focus:text-current"
                    />
                    <span>{recordDate}</span>
                    <span>{item.client.name}</span>
                  </NcListBox.Item>
                );
              }}
            </NcListBox>
          ) : (
            <PanelEmpty />
          )}
        </NcPanel.Body>
      </NcPanel>

      {selectedRecord && (
        <PrimhdRecordModal
          recordSummary={selectedRecord}
          onOpenChange={() => setSelectedRecord(undefined)}
        />
      )}
    </>
  );
};

export default PrimhdRecordErrorsPanel;

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoPrimhdRecordErrorsIcon opacity="0.5" />
      <NcFormattedMessage variant="secondary">
        {t("primhd:records.record_errors_panel.empty")}
      </NcFormattedMessage>
    </div>
  );
};

const NoPrimhdRecordErrorsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 164 224" className="w-24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2038_57)" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.9707 6.32739L103.393 13.0462H129.982C139.985 13.0462 148.08 21.0695 148.08 30.9846V147.217C144.939 145.986 141.634 145.083 138.208 144.553V30.9846C138.208 26.4837 134.523 22.8308 129.85 22.8308H107.605C107.649 22.9612 107.634 23.0917 107.62 23.2221C107.612 23.2874 107.605 23.3526 107.605 23.4178C107.671 24.2658 107.671 25.1791 107.539 26.0923L107.342 27.0708C107.079 28.2449 106.684 29.3539 106.026 30.3976C103.92 33.7896 100.234 35.8117 96.2194 35.8117H51.7294C47.7148 35.8117 44.0292 33.7896 41.9232 30.3976C41.2651 29.3539 40.8702 28.2449 40.6069 27.0708C40.5192 26.8533 40.4899 26.6359 40.4607 26.4185C40.4461 26.3097 40.4314 26.201 40.4095 26.0923C40.2779 25.2443 40.2779 24.3311 40.3437 23.4178C40.3876 23.2874 40.3729 23.1569 40.3583 23.0265C40.351 22.9612 40.3437 22.896 40.3437 22.8308H18.0987C13.5576 22.8308 9.87203 26.4837 9.87203 30.9846V194.062C9.87203 198.562 13.5576 202.215 18.0987 202.215H85.352C86.193 205.638 87.41 208.915 88.9566 212H18.0987C8.09506 212 0 203.977 0 194.062V30.9846C0 21.0695 8.09506 13.0462 18.0987 13.0462H44.6874L47.9781 6.32739C49.9525 2.41355 53.9013 0 58.3108 0H89.638C94.0475 0 97.9963 2.41355 99.9707 6.32739ZM89.7038 9.78462H58.3766C57.7184 9.78462 57.1919 10.1108 56.9287 10.6978L50.3473 23.744C50.0412 24.4114 50.2477 24.9096 50.3839 25.2383C50.3941 25.263 50.404 25.2868 50.4132 25.3095C50.5448 25.6357 51.0055 26.0923 51.7952 26.0923H96.2852C97.0749 26.0923 97.4698 25.5704 97.6673 25.3095C97.8647 25.0486 98.0621 24.4615 97.7331 23.744L91.1517 10.6978C90.8885 10.1108 90.3619 9.78462 89.7038 9.78462Z"
          fill="#3A4550"
        />
        <path
          d="M131 158C112.817 158 98 172.817 98 191C98 209.183 112.784 224 131 224C149.183 224 164 209.183 164 191C164 172.817 149.183 158 131 158Z"
          fill="#006949"
        />
        <path
          d="M150.074 180.143L147.038 176.876C146.411 176.183 145.355 176.183 144.695 176.81L126.875 194.201C126.248 194.828 125.225 194.828 124.598 194.201L117.305 187.172C116.645 186.512 115.622 186.545 114.962 187.238L111.926 190.505C111.299 191.198 111.299 192.287 111.992 192.98L124.631 205.19C125.258 205.817 126.281 205.817 126.908 205.19L150.041 182.585C150.701 181.925 150.734 180.836 150.074 180.143Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.0553 126.903L53.5304 157H35L47.7127 72H79.3149C85.2044 72 90.256 72.5878 94.4696 73.7635C98.7311 74.9 102.227 76.5067 104.956 78.5837C107.685 80.6607 109.696 83.1296 110.989 85.9903C112.33 88.8119 113 91.9274 113 95.3368C113 99.8827 112.114 104.095 110.343 107.975C108.619 111.816 106.009 115.147 102.514 117.968C99.0184 120.751 94.6372 122.945 89.3702 124.552C84.151 126.119 78.046 126.903 71.0553 126.903H58.0553ZM64.5912 83.7566L59.8508 115.029H72.9227C76.5617 115.029 79.7219 114.578 82.4033 113.677C85.0847 112.776 87.3112 111.522 89.0829 109.915C90.8545 108.269 92.1473 106.329 92.9613 104.095C93.8232 101.862 94.2542 99.3928 94.2542 96.6888C94.2542 94.7686 93.919 93.0051 93.2486 91.3983C92.5783 89.7916 91.5488 88.4396 90.1602 87.3423C88.7716 86.2059 87.0239 85.3241 84.9171 84.6971C82.8582 84.0701 80.3923 83.7566 77.5194 83.7566H64.5912Z"
          fill="#3A4550"
        />
      </g>
      <defs>
        <clipPath id="clip0_2038_57">
          <rect width="164" height="224" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { css } from "@emotion/core";
import type { HTMLAttributes } from "react";

import { ncTheme } from "../nc-theme";

export const staticListStyles = {
  item: css`
    padding: ${ncTheme.spacing(3)};
    &:not(:last-of-type) {
      border-bottom: ${ncTheme.border()};
    }
  `,
};

const NcStaticList = ({ ...props }: HTMLAttributes<HTMLUListElement>) => {
  return <ul data-nc="NcStaticList" {...props} />;
};

const Item = ({ ...props }: HTMLAttributes<HTMLLIElement>) => {
  return <li data-nc="NcStaticList.Item" css={staticListStyles.item} {...props} />;
};

NcStaticList.Item = Item;

export { NcStaticList };

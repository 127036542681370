import { Controller, useFormContext } from "react-hook-form";

import { NcTextField, NcTextFieldProps } from "./nc-text-field";

export interface NcFormTextFieldProps
  extends Omit<NcTextFieldProps, "name" | "validate" | "onBlur" | "value" | "validationBehavior"> {
  name: string; // Was optional in NcTextFieldProps and is now required
}

export const NcFormTextField = ({ name, onChange, ...props }: NcFormTextFieldProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, value, onChange: formOnChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <NcTextField
          name={name}
          value={value}
          onChange={value => {
            onChange?.(value);
            formOnChange(value);
          }}
          onBlur={onBlur}
          validationBehavior="aria"
          isInvalid={invalid}
          error={error}
          ref={ref}
          {...props}
        />
      )}
    />
  );
};

import { css } from "@emotion/core";
import type { FieldsetHTMLAttributes, ForwardedRef } from "react";
import { forwardRef } from "react";

import { ncTheme } from "../nc-theme";
import { NcFieldLayout } from "./nc-field-layout";
import { NcGroup } from "./nc-group";

export interface NcFieldsetProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  label: string;
  description?: string;
}

const styles = {
  outer: css`
    border: ${ncTheme.border()};
    border-radius: ${ncTheme.borderRadius.medium};
    padding: ${ncTheme.spacing(2)} ${ncTheme.spacing(4)} ${ncTheme.spacing(4)};
    background-color: ${ncTheme.colors.uiLight};

    &[disabled] {
      background-color: ${ncTheme.colors.disabledLight};
      color: ${ncTheme.colors.disabled};

      *:not {
        color: ${ncTheme.colors.disabled};
      }

      legend {
        background-color: ${ncTheme.colors.disabled};
        color: ${ncTheme.colors.disabledLight};
      }
    }

    &[aria-invalid="true"] {
      border-color: ${ncTheme.colors.danger};
    }

    [data-focus-within="true"] > &,
    &:focus-within {
      border-color: ${ncTheme.colors.active};
    }
  `,
  legend: css`
    padding-block: ${ncTheme.spacing(1)};
    padding-inline: ${ncTheme.spacing(2)};
    margin-bottom: ${ncTheme.spacing(1)};
    background-color: ${ncTheme.colors.main};
    border-radius: ${ncTheme.borderRadius.small};
    color: ${ncTheme.colors.light};
    font-weight: bold;

    [aria-invalid="true"] > & {
      background-color: ${ncTheme.colors.danger};
    }
  `,
  messages: css`
    :not(:empty) {
      margin-bottom: ${ncTheme.spacing(3)};
    }
  `,
  nested: css`
    display: grid;
    gap: ${ncTheme.spacing(2)};
  `,
};

export const NcFieldset = forwardRef(
  (
    { children, label, description, ...props }: NcFieldsetProps,
    ref: ForwardedRef<HTMLFieldSetElement>
  ) => {
    return (
      <NcGroup data-nc="NcFieldset">
        <fieldset css={styles.outer} ref={ref} {...props}>
          <legend css={styles.legend}>{label}</legend>
          <div css={styles.messages}>
            {description && <NcFieldLayout.Description>{description}</NcFieldLayout.Description>}
          </div>
          <div css={styles.nested}>{children}</div>
        </fieldset>
      </NcGroup>
    );
  }
);

import { css } from "@emotion/core";
import type { ValidationError } from "@react-types/shared";
import type { KeyboardEvent } from "@react-types/shared/src/events";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useFormContext, type ErrorOption } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { useI18n } from "../../use-i18n";
import { NcBadge } from "../nc-badge";
import { NcButton } from "../nc-button";
import { NcComboBox, type NcComboBoxItem } from "../nc-combo-box";
import type { NcComboBoxProps } from "../nc-combo-box";
import { NcFlexLayout } from "../nc-flex-layout";
import { NcFormattedMessage } from "../nc-formatted-message";
import { NcGridList } from "../nc-grid-list";
import { NcGroup } from "../nc-group";
import { NcIconCross, NcIconPlus } from "../nc-icons";
import { NcLabel } from "../nc-label";
import { NcListBox } from "../nc-list-box";
import { NcLoadingIndicator } from "../nc-loading-indicator";
import type { FieldProps } from "./nc-field";
import { NcFieldset } from "./nc-fieldset";
import { useValidation, type ValidationResult } from "./use-validation";

export interface ComboListProps extends FieldProps, Omit<NcComboBoxProps, "name" | "style"> {
  label: string;
  labelNode?: ReactNode;
  description?: string;
  comboboxLabel?: string;
  selectedItemsLabel?: string;
  totalSelectedCount?: number | false;
  errorMessage?: ReactNode | string;
  onAddItem?: (addedItem: NcComboBoxItem) => void;
  onRemoveItem?: (removedItem: NcComboBoxItem) => void;
  renderEmptyOptionsState?: () => ReactNode;
  renderItems?:
    | {
        renderItem: (item: NcComboBoxItem) => ReactNode;
        getItemTextValue: (item: NcComboBoxItem) => string;
      }
    | undefined;
  renderOptions?:
    | {
        renderOption: (option: NcComboBoxItem) => ReactNode;
        getOptionTextValue: (option: NcComboBoxItem) => string;
      }
    | undefined;
  isRequired?: boolean;
  isOpen?: boolean;
  minLength?: number;
  maxLength?: number;
  headingSlot?: ReactNode;
  footerSlot?: ReactNode;
  className?: string;
}

const styles = {
  outer: css`
    max-width: ${ncTheme.containers.text};
  `,
  description: css`
    margin-bottom: ${ncTheme.spacing(2)};
  `,
  options: css`
    max-width: 100%;
    overflow: hidden;
  `,
  controls: css`
    display: grid;
    gap: ${ncTheme.spacing(4)};
    grid-template-columns: 1fr auto;
    align-items: flex-end;
    border-bottom: ${ncTheme.border()};
    padding-bottom: ${ncTheme.spacing(4)};
  `,
  items: css`
    background-color: ${ncTheme.colors.light};
    border: ${ncTheme.border()};
    border-radius: ${ncTheme.borderRadius.small};
    max-height: 75vh;
    overflow-y: auto;
  `,
  inbuiltHeader: css`
    justify-content: space-between;
    margin-top: ${ncTheme.spacing(1.5)};
  `,
  header: css`
    margin-bottom: ${ncTheme.spacing(1.5)};
  `,
  footer: css`
    margin-top: ${ncTheme.spacing(1.5)};
  `,
  padding: css`
    display: flex;
    justify-content: center;
    align-items: baseline;
  `,
  loading: css`
    display: flex;
    justify-content: center;
    align-items: baseline;
  `,
};

function findItemById(items: NcComboBoxItem[], id: NcComboBoxProps["selectedKey"]) {
  return items.find(item => item.id === id);
}

export const NcFieldComboList = ({
  name,
  label,
  labelNode,
  description,
  comboboxLabel,
  selectedItemsLabel,
  totalSelectedCount,
  defaultItems = [],
  isLoading,
  defaultFilter,
  onInputChange,
  onAddItem,
  onRemoveItem,
  onFocus,
  onBlur,
  renderEmptyState,
  renderEmptyOptionsState,
  renderItems = undefined,
  renderOptions = undefined,
  inputWidth = "full",
  isRequired,
  minLength,
  maxLength,
  headingSlot,
  footerSlot,
  slot,
  isDisabled,
  isOpen,
  placeholder,
  variant,
  ...props
}: ComboListProps) => {
  const { t } = useI18n();
  const addFieldName = `_filter-combo-list:${name}`;
  const addFieldRef = useRef<HTMLInputElement | null>(null);
  const {
    watch,
    setError,
    getValues,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    name,
  });
  const selectedItems = watch(name);
  const selectableItems = useMemo(() => {
    const selectedIds = (selectedItems || []).map((item: NcComboBoxItem) => item.id);
    return [
      ...(isLoading ? [{ id: "loading" }] : []),
      ...Array.from(defaultItems).filter(item => !selectedIds.includes(item.id)),
    ];
  }, [defaultItems, selectedItems, isLoading]);

  const { validationHandler } = useValidation({
    label: label,
    rules: {
      required: isRequired ? {} : undefined,
      minLength: minLength
        ? {
            message: `{{label}} must have at least {{minLength}} items selected`,
            value: minLength,
          }
        : undefined,
      maxLength: maxLength
        ? {
            message: `{{label}} must have at most {{maxLength}} items selected`,
            value: maxLength,
          }
        : undefined,
    },
  });

  const focusOnLastItem = useCallback(
    (fields: Record<"id", string>[]) => {
      const lastItem = fields[fields.length - 1];
      if (!lastItem) {
        addFieldRef.current?.focus();
        return;
      }
      const lastItemName = `${name}.${fields.length - 1}`;
      document.getElementsByName(lastItemName)?.[0]?.focus();
    },
    [name]
  );

  const validate = useCallback(() => {
    const currentValue = getValues(name);
    const result = validationHandler(currentValue);
    if (([true, null, undefined] as ValidationResult[]).includes(result)) {
      clearErrors([name]);
      return;
    }
    // we set the error to the form to prevent the form from submitting
    setError(name, { message: result } as ErrorOption);
    focusOnLastItem(fields);
  }, [validationHandler]);

  const [fieldState, setFieldState] = useState<{
    inputValue: NcComboBoxProps["inputValue"];
    selectedKey: NcComboBoxProps["selectedKey"];
  }>({
    selectedKey: "",
    inputValue: "",
  });

  const resetAddControl = () => {
    setFieldState({ inputValue: "", selectedKey: "" });
  };

  const handleAdd = () => {
    const item = findItemById(selectableItems, fieldState.selectedKey);
    if (!item) {
      return;
    }
    append(item);
    if (onAddItem) {
      onAddItem(item);
    }
    resetAddControl();
    validate();
  };

  const handleRemove = (item: NcComboBoxItem, index: number) => {
    remove(index);
    validate();
    if (onRemoveItem) {
      onRemoveItem(item);
    }
  };

  const handleKeyboardInteractions = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      resetAddControl();
    }
    if (event.key === "Enter") {
      event.preventDefault();
      if (fieldState.selectedKey) {
        handleAdd();
      }
    }
    event.continuePropagation();
  };

  useEffect(() => {
    if (isSubmitting) {
      // validate the form when submitting
      validate();
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (selectedItems === undefined && fields.length > 0) {
      // when the form is reset, remove all fields
      remove();
    }
  }, [fields, selectedItems]);

  return (
    <NcFieldset
      {...{
        label,
        labelNode,
        name,
        errorMessage: errors?.[name]?.message as ValidationError,
        disabled: isDisabled,
        ...props,
      }}
      css={styles.outer}
    >
      {description && <div css={styles.description}>{description}</div>}
      <NcGroup css={styles.controls}>
        <NcComboBox
          name={addFieldName}
          data-testid="combo-list-combo-box"
          {...{
            label: comboboxLabel || label,
            isLoading,
            isDisabled,
            isOpen,
            onFocus,
            onBlur,
            inputWidth,
            defaultItems: selectableItems,
            selectedKey: fieldState.selectedKey,
            inputValue: fieldState.inputValue,
            defaultFilter,
            renderEmptyState: renderEmptyOptionsState || renderEmptyState,
            slot,
            placeholder,
            onKeyDown: handleKeyboardInteractions,
            onInputChange: value => {
              setFieldState(prevState => ({
                inputValue: value ?? "",
                selectedKey: value ? prevState.selectedKey : "",
              }));
              onInputChange?.(value);
            },
            onSelectionChange: id => {
              if (id === "") {
                resetAddControl();
                return;
              }
              const item = findItemById(selectableItems, id);
              if (!item) {
                return;
              }
              const label =
                item?.label ?? renderOptions?.getOptionTextValue(item) ?? item?.id ?? "";
              setFieldState({
                inputValue: String(label),
                selectedKey: id,
              });
            },
            ref: addFieldRef,
            variant,
          }}
        >
          {renderOptions && (
            <NcListBox
              renderEmptyState={renderEmptyOptionsState || renderEmptyState}
              css={styles.options}
              selectionMode="none"
              data-testid="combo-list-options"
            >
              {(item: NcComboBoxItem) => {
                if (item.id === "loading") {
                  return (
                    <NcListBox.Item
                      id="loading"
                      textValue={t("loading")}
                      css={styles.loading}
                      hideSelectIndicator
                    >
                      <NcLoadingIndicator />
                    </NcListBox.Item>
                  );
                }
                return (
                  <NcListBox.Item id={item.id} textValue={renderOptions?.getOptionTextValue(item)}>
                    {renderOptions?.renderOption(item)}
                  </NcListBox.Item>
                );
              }}
            </NcListBox>
          )}
        </NcComboBox>
        <NcButton
          onPress={handleAdd}
          isDisabled={isDisabled || !fieldState.selectedKey || !fieldState.selectedKey}
        >
          {t("Add")}
          <NcIconPlus alt="" />
        </NcButton>
      </NcGroup>
      {selectedItemsLabel && (
        <NcFlexLayout css={[styles.inbuiltHeader, headingSlot ? "" : styles.header]}>
          <NcLabel>{selectedItemsLabel}</NcLabel>
          {totalSelectedCount !== false && (
            <NcBadge>{totalSelectedCount || selectedItems?.length}</NcBadge>
          )}
        </NcFlexLayout>
      )}
      {headingSlot && <div css={styles.header}>{headingSlot}</div>}
      <NcGridList
        css={styles.items}
        aria-label={`${t("Selected")} ${label}`}
        data-testid="combo-list"
      >
        {!selectedItems?.length &&
          (isLoading ? (
            <NcGridList.Item textValue={t("loading")} data-testid="list-loading">
              <NcLoadingIndicator />
            </NcGridList.Item>
          ) : (
            <NcGridList.Item textValue={t("None")}>
              {renderEmptyState ? (
                renderEmptyState()
              ) : (
                <NcFormattedMessage variant="secondary" data-testid="empty-list">
                  {t("None")}
                </NcFormattedMessage>
              )}
            </NcGridList.Item>
          ))}
        {selectedItems?.map((item: NcComboBoxItem, index: number) => {
          return (
            <NcGridList.Item
              textValue={renderItems ? renderItems.getItemTextValue(item) : `${item?.label}`}
              data-testid="list-item"
              key={item.id}
            >
              {renderItems ? renderItems.renderItem(item) : `${item?.label}`}
              <NcButton
                aria-label={t("remove")}
                css={css`
                  margin-left: auto;
                  [data-focus-visible] & {
                    color: currentColor;
                  }
                `}
                variant="icon"
                onPress={() => handleRemove(item, index)}
              >
                <NcIconCross />
              </NcButton>
            </NcGridList.Item>
          );
        })}
      </NcGridList>
      {footerSlot && <div css={styles.footer}>{footerSlot}</div>}
    </NcFieldset>
  );
};

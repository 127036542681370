import { queryOptions } from "@tanstack/react-query";

import { restFetcher } from "~/graphql-hooks/custom-fetcher";

import { CodeType } from "../types";

export type PrimhdCode = {
  code: string;
  description: string;
  endDate?: string;
};

export type PrimhdCodeQueryResponse = Record<CodeType, PrimhdCode[]>;

export const primhdCodeQueries = {
  all: () => ["PrimhdReferralCodes"],
  lists: () => [...primhdCodeQueries.all(), "list"],
  list: (filters: string) =>
    queryOptions({
      queryKey: [...primhdCodeQueries.lists(), filters],
      queryFn: () => restFetcher<PrimhdCodeQueryResponse>(`/v2/primhd/codes?${filters}`),
      staleTime: Infinity,
    }),
};

export * from "./primhd.generated";

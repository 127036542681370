import { IResourceFilters } from "../../agenda/context/resource-filter-context";
import { IResource } from "../../agenda/resources-context";

export const getSortedResources = (resources: IResource[], sortedIds: number[]) => {
  return [...resources].sort((a, b) => {
    const indexA = sortedIds.indexOf(a.id);
    const indexB = sortedIds.indexOf(b.id);
    return indexA > -1 ? (indexB > -1 ? indexA - indexB : -1) : 1;
  });
};

export const getFilteredResources = (resources: IResource[], filters: IResourceFilters) => {
  const { serviceIds } = filters;
  return serviceIds.length
    ? resources.filter(r => r.services.some(s => serviceIds.includes(s.id)))
    : resources;
};

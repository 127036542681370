import { NcPage } from "@noted/noted-components";

import { useI18n } from "~/hooks/use-i18n";
import { mergePartitionedTeams, separateTeams } from "~/org/clients/teams-client-lists/helpers";
import { ClientTeam } from "~/org/clients/teams-client-lists/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "~/shared/components/tables";
import { IAccount } from "~/shared/types";

export const AssignedTeams = ({ user }: { user: IAccount }) => {
  const { t } = useI18n(["user-settings"]);
  const userTeams = user.teams || [];
  const sortedTeams = userTeams.sort((a, b) => a.name.localeCompare(b.name));
  const [defaultTeam, remainingTeams] = separateTeams(sortedTeams, (team: ClientTeam) =>
    Boolean(team.default)
  );
  const teams = mergePartitionedTeams(
    defaultTeam,
    remainingTeams,
    t("default_team_description")
  ) as ClientTeam[];
  return (
    <NcPage>
      <NcPage.Wrapper>
        <NcPage.Header>
          <NcPage.Heading>{t("user-settings:assignedTeams.title")}</NcPage.Heading>
        </NcPage.Header>
        <NcPage.Body>
          {teams.length > 1 ? (
            <div className="grid w-full gap-4">
              <p>{t("user-settings:assignedTeams.description")}</p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>{t("user-settings:assignedTeams.team.name")}</TableHeaderCell>
                    <TableHeaderCell>
                      {t("user-settings:assignedTeams.team.description")}
                    </TableHeaderCell>
                    <TableHeaderCell>
                      {t("user-settings:assignedTeams.team.formset.title")}
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams.map(team => (
                    <TableRow key={team.id} className={team.default ? "italic" : ""}>
                      <TableCell>{team.name}</TableCell>
                      <TableCell>{team.description}</TableCell>
                      <TableCell>
                        {team.modalityName && team.modalityName}
                        {!team.modalityName && !team.default && (
                          <p className="italic">
                            {t("user-settings:assignedTeams.team.formset.noneAssigned")}
                          </p>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <p>{t("user-settings:assignedTeams.noTeams")}</p>
          )}
        </NcPage.Body>
      </NcPage.Wrapper>
    </NcPage>
  );
};

import { Controller, useFormContext } from "react-hook-form";

import { dateTimeToDateValue, dateValueToDate, NcDateField } from "./nc-date-field";
import { NcFormDateFieldProps } from "./nc-form-date-field";

export const NcFormDateTimeField = ({
  name,
  minValue,
  maxValue,
  onChange,
  hideTimeZone = true,
  hourCycle = 12,
  ...props
}: NcFormDateFieldProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { name, value, onChange: formOnChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <NcDateField
          name={name}
          granularity="minute"
          hideTimeZone={hideTimeZone}
          hourCycle={hourCycle}
          minValue={dateTimeToDateValue(minValue) ?? undefined}
          maxValue={dateTimeToDateValue(maxValue) ?? undefined}
          value={dateTimeToDateValue(value)}
          onChange={date => {
            const parsedDate = dateValueToDate(date || undefined);
            onChange?.(parsedDate);
            formOnChange(parsedDate);
          }}
          onBlur={onBlur}
          validationBehavior="aria"
          isInvalid={invalid}
          error={error}
          ref={ref}
          {...props}
        />
      )}
    />
  );
};

import { ComponentProps, forwardRef } from "react";

import styled, { theme } from "../../theme";
import Box, { BoxProps } from "../primitives/box";

export type FooterProps = BoxProps;

const BaseModalFooter = styled(Box)<FooterProps>`
  border-top-color: ${theme.colors.neutral.light};
`;

export const ModalFooter = forwardRef<HTMLDivElement, ComponentProps<typeof BaseModalFooter>>(
  ({ m = 0, padding = 3, borderTop = "solid 1px", ...props }, ref) => {
    return <BaseModalFooter m={m} padding={padding} borderTop={borderTop} {...props} ref={ref} />;
  }
);

ModalFooter.displayName = "ModalFooter";

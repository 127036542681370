import { BackgroundProps } from "styled-system";

import styled, { BREAKPOINT_SMALL_MAX_QUERY, theme } from "../../theme";

export const OverlayMessageBoxHighlight = styled.div<BackgroundProps>`
  background: ${props => props.background ?? theme.colors.neutral.darkest};
  color: ${theme.colors.neutral.lightest};
  padding-block: ${theme.space[4]};
  padding-inline: ${theme.space[4]};

  @media ${BREAKPOINT_SMALL_MAX_QUERY} {
    padding-inline: ${theme.space[5]};
  }
`;
